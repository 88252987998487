<script setup>
// Required Package
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// Store
import store from '@/store'
// Base Layout
import AuthBase from './AuthBase'
// Base Component
import { pbButton } from 'pb-components'

const route = useRoute()
const router = useRouter()

const redirect = route.query?.returnUrl ? route.query.returnUrl : '/events'

let code = Array(6)
const verificationCode = ref('')
let dataFromPaste = undefined
// Keycode from 0 - 9
const keysAllowed = [
  48, 49, 50, 51, 52, 53, 54, 55, 56, 57
]

const email = computed(() => {
  return store.getters.verificationCodeEmailOrUserName ? store.getters.verificationCodeEmailOrUserName : route.query?.email
})

// Code Typing and Pasting Logic
// Check for number
const isNumber = (event) => {
  event.currentTarget.value = ''
  const keyPressed = event.keyCode
  if (!keysAllowed.includes(keyPressed)) {
    event.preventDefault()
  }
}
// Input from user
const handleInput = (event) => {
  // Get the input type
  const inputType = event.inputType
  let currentActiveElement = event.target
  // If input type matches then next sibling
  switch (inputType) {
  // For Pasting
  case 'insertFromPaste':
    if (dataFromPaste) {
      for (const num of dataFromPaste) {
        let id = parseInt(currentActiveElement.id.split("_")[1])
        currentActiveElement.value = num
        code[id] = num
        if (currentActiveElement.nextElementSibling) {
          currentActiveElement = currentActiveElement.nextElementSibling
          currentActiveElement.nextElementSibling?.focus()
        }
      }
    }
    break
  // For typing
  default:
    currentActiveElement.nextElementSibling?.focus()
  }
}

const handleDelete = (event) => {
  let value = event.target.value
  let currentActiveElement = event.target
  if (!value) {
    currentActiveElement.previousElementSibling?.focus()
  }
}

const onPaste = (event) => {
  dataFromPaste = event.clipboardData
    ?.getData('text')
    .trim()
    .split('')
  if (dataFromPaste) {
    for (const num of dataFromPaste) {
      // Change the number into keycode
      if (!keysAllowed.includes(num.charCodeAt(0))) {
        event.preventDefault()
      }
    }
  }
}
// Submission process
// Verification process
const submit = () => {
  verificationCode.value = code.join('')?.toString()
  const payload = {
    verificationCode: verificationCode.value,
    usernameOrEmail: email.value
  }
  store.dispatch('verifyAccount', payload).then(() => {
    store.dispatch('setAfterRegister', true)
    store.dispatch('ChangeModalType', 'event')
    router.replace(redirect)
  })
}

const checkVerifyErrorMessage = computed(() => {
  return store.getters.checkVerify
})

const sendVerificationCodeEmail = () => {
  store.dispatch('sendVerificationCode', {
    usernameOrEmail: email.value
  })
}
</script>

<template>
  <auth-base :auth-submit-function="submit"
             :reset-or-verify="true">
    <template #form-title>
        Verify Your Email
    </template>
    <template #form-subText>
      We've sent a verification code to <strong>{{ email }}</strong>.
      <br/>Don't forget to check your inbox and junk for the code?
    </template>
    <template #form-inputs>
      <span class="verify-input-container">
      <span class="verify-input-number-container">
        <input
            v-for="(n, index) in code"
            :id="'input_' + index"
            :key="index"
            v-model="code[index]"
            :qa="`qa-code-${index}`"
            type="number"
            class="verify-input-number"
            pattern="\d*"
            maxlength="1"
            @input="handleInput"
            @keypress="isNumber"
            @keydown.delete="handleDelete"
            @paste="onPaste" />
        </span>
        <span class="verify-input-error">
          {{checkVerifyErrorMessage}}
        </span>
      </span>
      <span class="verify-text">
        Didn't get the code? <br/>
        <span class="verify-text-resend"
              @click="sendVerificationCodeEmail">
          Click To Resend
        </span>
      </span>
    </template>
    <template #form-submissions>
      <pb-button id="verify"
                 :functions="submit"
                 name="verify"
                 class-name="verify-button"
                 text="Verify" />
    </template>
  </auth-base>
</template>

<style lang="scss" scoped>
.verify {
  &-input {
    &-container {
      @apply flex flex-col w-full h-[3rem] mb-8 px-4;
    }
    &-error {
      @apply w-full font-medium py-2 text-other-red text-center;
    }
    &-number {
      @apply w-12 h-10 border-2 rounded-md text-center;
      &:focus {
        @apply border-product-400;
      }
      &-container {
        @apply flex flex-row w-full justify-around;
      }
    }
  }
  &-text {
    @apply font-normal w-full text-p-xs text-center text-greys-300;
    &-resend {
      @apply text-product-400 cursor-pointer;
    }
  }
  &-button {
    @apply w-[20rem] text-p-md font-normal py-4 rounded;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
