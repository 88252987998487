import FileService from '@/services/file'
import ResourceService from "@/services/resource"
import { processStatuses } from "@/utils"

/*
const mockResources = [
  {
    'id': 1,
    'headline': 'Test1',
    'text': 'text',
    'type': 'link', // 'text', 'video', 'photo_gallery', 'file'
    'link': 'google.com',
    'videoLink': 'test.com',
    'photoLink': '',
    'fileLink': ''
  },
  {
    'id': 2,
    'headline': 'Test2',
    'text': 'text',
    'type': 'link', // 'text', 'video', 'photo_gallery', 'file'
    'link': 'google.com',
    'videoLink': '',
    'photoLink': '',
    'fileLink': ''
  },
  {
    'id': 3,
    'headline': 'Test3',
    'text': 'text',
    'type': 'link', // 'text', 'video', 'photo_gallery', 'file'
    'link': 'google.com',
    'videoLink': '',
    'photoLink': '',
    'fileLink': ''
  },
  {
    'id': 4,
    'headline': 'Test4',
    'text': 'text',
    'type': 'link', // 'text', 'video', 'photo_gallery', 'file'
    'link': 'google.com',
    'videoLink': '',
    'photoLink': '',
    'fileLink': ''
  },
  {
    'id': 5,
    'headline': 'Test5',
    'text': 'text',
    'type': 'link', // 'text', 'video', 'photo_gallery', 'file'
    'link': 'google.com',
    'videoLink': '',
    'photoLink': '',
    'fileLink': ''
  }]
*/

export default {
  state: () => ({
    resource: [],
    resources: [],
    status: processStatuses.IDLE, //'loaded', 'error'
    resourceError: {},
    resourceUploadStatus: processStatuses.IDLE

  }),
  getters: {
    getResource: state => state.status == 'loaded' ? state.resource : [],
    getResources: state => state.status == 'loaded' ? state.resources : [],
    getStatus: state => state.status,
    getResourceError: state => state.resourceError,
    getResourceUploadStatus: state => state.resourceUploadStatus
  },
  mutations: {
    RESOURCE_LOADING (state) {
      state.status = processStatuses.LOADING
    },
    RESOURCE_LOADED_SINGLE (state, resource) {
      state.status = processStatuses.LOADED
      state.resource = resource
    },
    RESOURCE_LOADED (state, resources) {
      state.status = processStatuses.LOADED
      state.resources = resources
    },
    RESOURCE_CREATED (state, data) {
      state.status = processStatuses.LOADED
      state.resources = data
    },
    RESOURCE_ERROR (state, error) {
      state.resourceError = error
    },
    RESOURCE_DELETED (state) {
      state.status = processStatuses.LOADED
    },
    RESOURCE_UPLOADING (state) {
      state.resourceUploadStatus = processStatuses.SAVING
    },
    RESOURCE_UPLOADED (state) {
      state.resourceUploadStatus = processStatuses.LOADED
    },
    RESOURCE_RESET (state) {
      state.resourceError = {}
    },
    RESET_RESOURCE_MODAL (state) {
      state.resource = []
      state.resourceError = {}
    },
    RESET_RESOURCE_ERROR (state) {
      state.resourceError = {}
    },
    RESOURCE_COVER_REMOVED (state) {
      state.resource.resourceBanner = null
    }
  },
  actions: {
    fetchResource ({ commit, state }, { resourceId } ) {
      commit('RESOURCE_LOADING')
      const resource = state.resources.find((res) => res.id === resourceId)
      if (resource) {
        commit('RESOURCE_LOADED_SINGLE', resource)
      } else {
      // If the resource with the given resourceId is not found, handle it accordingly
      console.error(`Resource with ID ${resourceId} not found.`)
      commit('RESOURCE_ERROR', `Resource with ID ${resourceId} not found.`)
      }
    },
    fetchResources ({ commit }, { eventId } ) {
      commit('RESOURCE_LOADING')
      return new Promise((resolve, reject) => {
        ResourceService.getResources({ eventId: eventId }).then((response) => {
          commit('RESOURCE_LOADED', response.data.resources)
          resolve(response.data.resources)
        }).catch((err) => {
          console.error(err)
          commit('RESOURCE_ERROR', err)
          reject(err)
        }).finally(() => {
          commit('TOGGLE_PLAN_MODAL')
        })
      })
    },
    createResource ({ commit }, data) {
      commit('RESOURCE_LOADING')
      return new Promise((resolve, reject) => {
        ResourceService.createResource(data).then((response) => {
          commit('RESOURCE_CREATED', response.data)
          resolve(response)
        }).catch((err) => {
          commit('RESOURCE_ERROR', { key: 'server', value: err.data.message })
          reject(err)
        })
      })
    },
    editResource ({ commit }, { resourceId, data }) {
      commit('RESOURCE_LOADING')
      return new Promise((resolve, reject) => {
        ResourceService.updateResource(resourceId, data).then((response) => {
          commit('RESOURCE_CREATED', response.data)
          resolve(response)
        }).catch((err) => {
          commit('RESOURCE_ERROR', { key: 'server', value: err.data.message })
          reject(err)
        })
      })
    },
    uploadResource({ commit }, data) {
      commit('RESOURCE_UPLOADING')
      return new Promise((resolve, reject) => {
        FileService.upload({ file: data.uploadFile }).then((response) => {
          commit('RESOURCE_UPLOADED')
          if (response.status === 200) {
            resolve(response.data)
          }
        }).catch(err => {
          commit('RESOURCE_ERROR', { key: 'server', value: err.data.message })
          reject(err)
        })
      })
    },
    deleteResource ({ commit }, resourceId) {
      return new Promise((resolve, reject) => {
        ResourceService.deleteResource(resourceId).then(() => {
          commit('RESOURCE_DELETED')
          resolve()
        }).catch((err) => {
          commit('RESOURCE_ERROR', { key: 'server', value: err.data.message })
          reject(err)
        })
      })
    },
    resetResource ({ commit }) {
      commit('RESOURCE_RESET')
    },
    resetResourceModal ({ commit }) {
      commit('RESET_RESOURCE_MODAL')
    },
    setUploadResourceFileError ({ commit }, error) {
      commit('RESOURCE_ERROR', { value: error })
    },
    resetUploadResourceFileError ({ commit }) {
      commit('RESET_RESOURCE_ERROR')
    },
    removeResourceCover ({ commit }) {
      commit('RESOURCE_COVER_REMOVED')
    }
  }
}
