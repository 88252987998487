import AwardService from '@/services/award'
import { processStatuses } from '@/utils'
import { cloneObject } from '@/utils/clone'

const defaultProjectAwardsState = () => {
    return {
      // Assign Awards to Projects
      projectAwards: {},
      originalProjectAwards: {},
      projectAwardsStatus: processStatuses.IDLE,
      projectAwardsError: null,
      // Sort
      sort: 'title',
      isDescending: false,
      // Award notification and publishing
      awardsPublishingStatus: processStatuses.IDLE,
      awardsPublishingError: null
    }
}

export default {
  state: defaultProjectAwardsState(),
  getters: {
    // Admin Award Management
    projectAwards: state => {
      return Object.values(state.projectAwards).sort(
          (a, b) => {
            const sortBy = state.sort ?? 'title'
            const isString = typeof a[sortBy] === 'string' || typeof b[sortBy] === 'string'
            const isArray = Array.isArray(a[sortBy]) || Array.isArray(b[sortBy])

            if (isString) {
              return !state.isDescending ? (a[sortBy].toLowerCase()).localeCompare(
                      (b[sortBy].toLowerCase())) : (b[sortBy].toLowerCase()).localeCompare(a[sortBy].toLowerCase())
            }

            if (isArray) {
              let item1 = JSON.stringify(a[sortBy] ?? [])
              let item2 = JSON.stringify(b[sortBy] ?? [])

              // If filter by awards
              if (sortBy === "awards") {
                item1 = JSON.stringify(a[sortBy][0]?.name ?? "")
                item2 = JSON.stringify(b[sortBy][0]?.name ?? "")
              }
              return state.isDescending ? item2.localeCompare(item1) : item1.localeCompare(item2)
            }
          })
    },
    originalProjectAwards: state => Object.values(state.originalAwards),
    projectAwardsStatus: state => state.projectAwardsStatus,
    projectAwardsError: state => state.projectAwardsError,
    isProjectAwardsUpdated: state => {
      if (Object.keys(state.projectAwards).length !== Object.keys(state.originalProjectAwards).length) {
        return true
      }

      for (const projectId in state.projectAwards) {
        // Compare two arrays by sorting them and convert by JSON.stringify
        const projectAwards = state.projectAwards[projectId]['awards']?.sort()
        const originalProjectAwards = state.originalProjectAwards[projectId]['awards']?.sort()
        if (JSON.stringify(projectAwards) !== JSON.stringify(originalProjectAwards)) {
          return true
        }
      }

      return false
    },
    getModifiedProjectAwards: state => {
      if (state.projectAwardsStatus === processStatuses.IDLE) {
        return { newProjectAwards: [], deletedProjectAwards: [] }
      }

      // Convert two project awards array into a map with composite key of projectId_awardId
      const currentProjectAwardsMapping = Object.values(state.projectAwards).reduce((acc, projectAward) => {
        (projectAward.awards ?? []).map(award => {
          acc[`${projectAward.id}_${award.id}`] = { projectId: projectAward.id, awardId: award.id}
        })
        return acc
      }, {})
      const originalProjectAwardsMapping = Object.values(state.originalProjectAwards).reduce((acc, projectAward) => {
        (projectAward.awards ?? []).map(award => {
          acc[`${projectAward.id}_${award.id}`] = { projectId: projectAward.id, awardId: award.id}
        })
        return acc
      }, {})

      const newProjectAwards = []
      const deletedProjectAwards = []

      // Find new project awards
      Object.keys(currentProjectAwardsMapping).forEach(key => {
        if (!(key in originalProjectAwardsMapping)) {
          newProjectAwards.push(currentProjectAwardsMapping[key])
        }
      })

      // Find deleted project awards
      Object.keys(originalProjectAwardsMapping).forEach(key => {
        if (!(key in currentProjectAwardsMapping)) {
          deletedProjectAwards.push(originalProjectAwardsMapping[key])
        }
      })

      return { newProjectAwards, deletedProjectAwards }
    },
    getProjectAwardsByProjectId: state => (projectId) => {
      return state.projectAwards[projectId]?.awards
    },
    getAwardsPublished: (state, getters, rootState, rootGetters) => {
      return rootGetters.eventSettings?.awardsPublished
    },
    getAwardsPublishingStatus: state => state.awardsPublishingStatus,
  },
  mutations: {
    PROJECT_AWARDS_LOADING(state) {
      state.projectAwardsStatus = processStatuses.LOADING
    },
    PROJECT_AWARDS_SAVING(state) {
      state.projectAwardsStatus = processStatuses.SAVING
    },
    PROJECT_AWARDS_LOADED(state, projectAwards) {
      state.projectAwards = projectAwards.reduce((acc, projectAward) => {
        acc[projectAward.id] = projectAward
        return acc
      }, {})
      state.originalProjectAwards = cloneObject(state.projectAwards)
      state.projectAwardsStatus = processStatuses.LOADED
    },
    PROJECT_AWARDS_LOAD_ERROR(state, err) {
      state.projectAwardsError = err
      state.projectAwardsStatus = processStatuses.ERROR
    },
    ADD_PROJECT_AWARD(state, newProjectAward) {
      if (state.projectAwards[newProjectAward.id].awards) {
        state.projectAwards[newProjectAward.id].awards.push(newProjectAward)
      } else {
        state.projectAwards[newProjectAward.id].awards = [newProjectAward]
      }
    },
    REMOVE_PROJECT_AWARD(state, {projectId, awardId}) {
      const projectAward = state.projectAwards[projectId]
      if (projectAward) {
        projectAward.awards = projectAward.awards.filter(award => award.id !== awardId)
      }
    },
    REPLACE_PROJECT_AWARDS(state, { projectId, awards }) {
      const projectAward = state.projectAwards[projectId]
      if (projectAward) {
          projectAward.awards = awards
      }
    },
    SET_SORT_TYPE (state, filter) {
      state.sort = filter
    },
    TOGGLE_PROJECT_AWARDS_DESC(state) {
      state.isDescending = !state.isDescending
    },
    RESET_PROJECT_AWARDS_STATE(state) {
      Object.assign(state, defaultProjectAwardsState())
    },
    AWARD_PUBLISHING(state) {
      state.awardsPublishingStatus = processStatuses.SAVING
    },
    AWARD_PUBLISHED(state) {
      state.awardsPublishingStatus = processStatuses.LOADED
    },
    AWARD_PUBLISH_ERROR(state, err) {
      state.awardsPublishingStatus = processStatuses.ERROR
      state.awardsPublishingError = err
    }
  },
  actions: {
    // Award Assignment
    fetchProjectAwards({commit, rootGetters}) {
      // Fetch Project Awards only if the event builder is loaded
      // i.e. User land on the event builder page.
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      commit('PROJECT_AWARDS_LOADING')
      return new Promise((resolve, reject) => {
        AwardService.getProjectAwards(rootGetters.ebEventId).then((response) => {
          commit('PROJECT_AWARDS_LOADED', response.data.projects)
          resolve()
        }).catch(err => {
          commit('PROJECT_AWARDS_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    assignAwardToProject({ commit }, newProjectAward) {
      commit('ADD_PROJECT_AWARD', newProjectAward)
    },
    removeAwardFromProject({ commit }, {projectId, awardId}) {
      commit('REMOVE_PROJECT_AWARD', {projectId, awardId})
    },
    replaceAwardsToProject({ commit, rootGetters }, { projectId, awardsIds }) {
      // This method depends on the allEventAwards getter so we are checking the status first
      if (rootGetters.awardsStatus !== processStatuses.LOADED) {
        throw new Error('Awards are not loaded yet')
      }

      if (awardsIds && awardsIds.length > 0) {
        const updatedAwards = rootGetters.allEventAwards.filter(award => awardsIds.includes(award.id))
        commit('REPLACE_PROJECT_AWARDS', { projectId, awards: updatedAwards })
      } else {
        commit('REPLACE_PROJECT_AWARDS', { projectId, awards: [] })
      }
    },
    async saveProjectAwards({ commit, getters, rootGetters }) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      if (!getters.isProjectAwardsUpdated) {
        return
      }

      const { newProjectAwards, deletedProjectAwards } = getters.getModifiedProjectAwards

      commit('PROJECT_AWARDS_SAVING')
      try {
        // Delete the project awards
        if (deletedProjectAwards.length > 0) {
          await AwardService.removeAwardsFromProjects(rootGetters.ebEventId, deletedProjectAwards)
        }

        // Assign the project awards
        if (newProjectAwards.length > 0) {
          await AwardService.assignAwardsToProjects(rootGetters.ebEventId, newProjectAwards)
        }

        // Fetch again
        const response = await AwardService.getProjectAwards(rootGetters.ebEventId)
        commit('PROJECT_AWARDS_LOADED', response.data.projects)
      } catch (err) {
        commit('PROJECT_AWARDS_LOAD_ERROR', err)
        throw err
      }
    },
    publishAwards({ commit, rootGetters }) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }
      // Only publish awards if the event is published
      if (rootGetters.eventSettings.status !== 'Published') {
        return
      }

      commit('AWARD_PUBLISHING')
      return new Promise((resolve, reject) => {
        AwardService.publishAwards(rootGetters.ebEventId).then((response) => {
          commit('EVENT_SETTINGS_AWARD_PUBLISHED', null, { root: true })
          commit('AWARD_PUBLISHED')
          resolve(response)
        }).catch(err => {
            commit('AWARD_PUBLISH_ERROR', err)
          reject(err)
        })
      })
    },
    resetProjectAwardsState({ commit }) {
      commit('RESET_PROJECT_AWARDS_STATE')
    },
    setProjectAwardSortBy({ commit }, sortBy) {
      commit('SET_SORT_TYPE', sortBy)
    },
    toggleProjectAwardsDesc({ commit }) {
      commit('TOGGLE_PROJECT_AWARDS_DESC')
    }
  }
}
