<script setup>
// Required Package
import {reactive, computed} from 'vue'
import { useRouter } from 'vue-router'
// Store
import store from '@/store'
// Base Component
import { pbButton, pbInputField } from 'pb-components'
// Base Layout
import AuthBase from './AuthBase'

const router = useRouter()

const user = reactive({
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  password: '',
  passwordConfirm: ''})

const formatText = (text) => {
  switch (text) {
  case 'firstName':
    return 'First Name'
  case 'lastName':
    return 'Last Name'
  case 'email':
    return 'Email Address'
  case 'passwordConfirm':
    return 'Re-enter Password'
  default:
    return text.charAt(0).toUpperCase() + text.substring(1, text.length)

  }
}

const signIn = () => {
  router.push('/login')
}

const register = () => {
  const payload = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    username: user.username,
    password: user.password,
    passwordConfirm: user.passwordConfirm
  }
  store.dispatch('validateSignupAccount', payload).then(() => {
    store.dispatch('ToggleModalLogin')
    if (!store.getters.isUserVerified) {
      store.dispatch('ResetModal')
      return router.replace({
        name: 'VerifyEmail',
        query: {
          email: store.getters.verificationCodeEmailOrUserName
        }
      })
    } else {
      store.dispatch('ChangeModalType', 'login')
    }
  })
}
const registerValidationErrorMessage = computed(() => {
  return store.getters.registerValidationError
})

const registrationValidateError = (type) => {
  let temp = ''
  Object.keys(registerValidationErrorMessage.value).filter(item =>{
    if (item === type) {
      if (item === 'password' || item === 'passwordConfirm') {
        if (Object.keys(registerValidationErrorMessage.value[type])?.length > 0) {
          temp = registerValidationErrorMessage.value[type]
        } else {
          temp = ''
        }
      } else {
        // Give back other error messages
        temp = registerValidationErrorMessage.value[type]
      }
    }
  })
  return temp
}
const passwordValidationError = computed ( () => {
  let ValidatePass = store.getters.passwordValidationError
  return Object.keys(ValidatePass).filter((key) => key.includes('password')).reduce((cur, key) => {
    return Object.assign(cur, { [key]: ValidatePass[key] })
  }, {});
})
</script>


<template>
  <auth-base :auth-submit-function="register">
    <template #auth-title>
      Welcome to ProjectBoard
    </template>
    <template #auth-description>
      Connect and celebrate young innovators worldwide with STEM project showcases.
    </template>
    <template #form-title>
      Sign Up
    </template>
    <template #form-subText>
      Do you already have an account with ProjectBoard or Make: Projects?
      <span class="register-link"
                         tabindex="1"
                         @click.prevent="signIn">
        Log In
      </span>
    </template>
    <template #form-inputs>
      <span class="register">
        <span v-for="(value, key) in user"
              :key="key">
          <label :id="key"
                 :for="key"
                 class="font-normal text-p-sm">
            {{formatText(key)}}
          </label>
          <pb-input-field v-model="user[key]"
                          :model-value="value"
                          :input-type="key.includes('password') ? 'password' : 'text'"
                          :input-name="key"
                          :input-id="key"
                          :input-place-holder="`${key !== 'passwordConfirm' ? 'Type' : 'Re-type'} ${formatText(key).replace('Re-enter', '')}`"
                          input-aria-label="firstName"
                          class-name="my-1 text-p-md"
                          :errors="registrationValidateError(`${key}`)"/>
        </span>

        <div class="error" >
          {{ registerValidationErrorMessage.registerError }}
        </div>
        <div v-for="(item, index) in passwordValidationError"
             :key="index" class="error">
          {{item}}
        </div>
      </span>
    </template>
    <template #form-submissions>
      <span class="w-full text-center py-4">
        <pb-button id="next"
                   name="next"
                   text="Sign Up"
                   class-name="btn-continue"
                   :functions="register" />
      </span>
    </template>
    <template #third-party-login>
      <div class="social">
        <ul v-if="1 == 2"
            class="social-container">
          <li>
            <span class="social-avatar">
            <i class="fa-brands fa-apple text-white"></i>
            </span>
          </li>
          <li>
            <span class="social-avatar">
            <i class="fa-brands fa-google text-white"></i>
            </span>
          </li>
          <li>
            <span class="social-avatar">
            <i class="fa-solid fa-phone text-white"></i>
            </span>
          </li>
          <li>
            <span class="social-avatar">
              <i class="fa-brands fa-windows text-white"></i>
            </span>
          </li>
        </ul>
      </div>
    </template>
  </auth-base>
</template>

<style lang="scss" scoped>
.modal-register {
  @apply max-h-[40rem] md:max-h-[80rem] md:min-h-[40rem] justify-center;
}
.register {
  @apply w-full px-10;
  &-link {
    @apply text-product-400 font-medium cursor-pointer;
  }
}
.social {
  @apply w-full text-center;
  &-container {
    @apply flex flex-row w-full justify-around px-36 py-2;
  }
  &-avatar {
    @apply inline-block h-7 w-7 rounded-full bg-purple justify-center items-center;
  }
}
.login {
  @apply py-2 mx-10 font-bold text-sm;
  &_text {
    @apply text-purple cursor-pointer;
  }
}
.btn {
  &-continue {
    @apply px-4 py-3 w-[24rem] font-normal text-p-md rounded;
  }
}
.error {
  @apply text-p-sm font-normal;
}
</style>
