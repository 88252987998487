import OrganizationService from "@/services/organization"
import { processStatuses } from '@/utils'

export default {
    state: () => ({
        organizations: {},
        organizationStatus: processStatuses.IDLE,
        organizationError: null,
        activeOrganizationId: localStorage.getItem('activeOrganizationId') ?? null
    }),
    getters: {
        getUserOrganizations: state => Object.values(state.organizations),
        getUserOrganizationStatus: state => state.organizationStatus,
        getActiveOrganization: state => {
            if (!state.organizations) {
                return null
            }
            if (state.activeOrganizationId && state.activeOrganizationId in state.organizations) {
                return state.organizations[state.activeOrganizationId]
            }
            return Object.values(state.organizations)[0]
        },
    },
    mutations: {
        ORGANIZATIONS_LOADING(state) {
            state.organizationStatus = processStatuses.LOADING
        },
        ORGANIZATIONS_LOADED(state, { organizations, userId }) {
            state.organizationStatus = processStatuses.LOADED
            state.organizations = organizations.reduce((acc, organization) => {
                acc[organization.id] = organization
                return acc
            }, {})

            // Initialize the active organization id if it is not set or not in the list of organizations
            // Default to creator's organization
            if (state.activeOrganizationId === null || !(state.activeOrganizationId in state.organizations)) {
                state.activeOrganizationId = organizations.find(m => m.userId === userId)?.id ?? organizations[0]?.id
            }
        },
        ORGANIZATIONS_ERROR(state, error) {
            state.organizationStatus = processStatuses.ERROR
            state.organizationError = error
        },
        SET_ACTIVE_ORGANIZATION_ID(state, organizationId) {
            state.activeOrganizationId = organizationId
            localStorage.setItem('activeOrganizationId', organizationId)
        },
        USER_ORGANIZATION_UPDATED(state, organization) {
            state.organizations[organization.id] = organization
        }
    },
    actions: {
        fetchOrganizationsByCurrentUser({ commit, rootGetters }) {
            // when we are fetching the current user, organizations will be populated automatically
            // See: modules/user.js: fetchCurrentUser
            // We fetch organizations separately when necessary

            // if user is not loaded or not logged in, do nothing
            if (rootGetters.userStatus !== processStatuses.LOADED || !rootGetters.isLoggedIn || !rootGetters.user?.id) {
                console.debug('User is not loaded or not logged in, skipping organization load')
                return
            }

            commit('ORGANIZATIONS_LOADING')
            return OrganizationService.getOrganizationsByUserId({ userId: rootGetters.user.id })
                .then(response => {
                    commit('ORGANIZATIONS_LOADED', { organizations: response.data, userId: rootGetters.user.id })
                })
                .catch(error => {
                    commit('ORGANIZATIONS_ERROR', error)
                })
        },
        setActiveOrganization({ commit }, organizationId) {
            commit('SET_ACTIVE_ORGANIZATION_ID', organizationId)
        },
    }
}
