<script setup>
// Required Packages
import { computed, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// Store
import store from '@/store'
// Base Components
import AuthBase from '@/pages/Auth/AuthBase'
import Loading from '@/components/Base/Loading'

// Utils
import { processStatuses } from '@/utils'

const route = useRoute()
const router = useRouter()

const token = ref(route.query.token)

const thirdPartyLogin = async () => {
  await store.dispatch('thirdPartyLogin', {
    token: token
  })
}

const thirdPartyLoginStatus = computed(() => {
  return store.getters.thirdPartyLoginStatus
})

const thirdPartyLoginError = computed(() => {
  return store.getters.thirdPartyLoginError
})

onMounted(() => {
  if (token.value) {
    store.dispatch('thirdPartyLogin', { token: token.value}).then(() => {
      redirectToHome()
    })
  }
})

watch(() => token.value, (newVal) => {
  if (newVal && thirdPartyLoginStatus.value === processStatuses.IDLE) {
    store.dispatch('thirdPartyLogin', { token: newVal}).then(() => {
      redirectToHome()
    })
  }
})

const redirectToHome = () => {
  router.push({name: 'Events'})
}
</script>

<template>
  <auth-base :auth-submit-function="thirdPartyLogin">
    <template #auth-title>
      Welcome to ProjectBoard
    </template>
    <template #auth-description>
      Connect and celebrate young innovators worldwide with STEM project showcases.
    </template>
    <template #form>
      <div class="sso-container">
        <template v-if="thirdPartyLoginStatus === processStatuses.LOADING">
          <loading />
          <p class="mt-4">Logging in... Please Wait</p>
        </template>
        <template v-else>
          <p>An error occurred while logging you in</p>
          <p class="text-red-500 text-center">{{ thirdPartyLoginStatus === processStatuses.ERROR ? thirdPartyLoginError : "Invalid token" }}</p>
        </template>
      </div>
    </template>
  </auth-base>
</template>

<style lang="scss" scoped>
  .sso-container {
    @apply pt-6;
  }
</style>
