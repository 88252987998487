import axios from 'axios'
import { config } from '@/config'
import tracking from '@/utils/tracking'

class AuthService {
    login({ usernameOrEmail, password }) {
        return axios.post(`${config.API_URL}/auth/login`, {
            usernameOrEmail: usernameOrEmail,
            password: password
        })
    }
    logout() {
        localStorage.removeItem('auth')
    }
    refresh({ username, refreshToken }, axiosConfig) {
        return axios.post(`${config.API_URL}/auth/${username}/refresh`, { refreshToken: refreshToken }, axiosConfig)
    }
    firebaseLogin({ idToken, signupSource }) {
        return axios.post(`${config.API_URL}/auth/firebase/login`, {
            idToken: idToken,
            signupSource: signupSource
        })
    }
    register(user) {
        return axios.post(`${config.API_URL}/auth/signup`, {
            user_name: user.username,
            email: user.email,
            password: user.password,
            first_name: user.firstName,
            last_name: user.lastName,
            signupMetadata: tracking.getUtmParams()
        })
    }
    validateUserNameOrEmail({ username, email }) {
        return axios.post(`${config.API_URL}/auth/check`, {
            username: username,
            email: email
        })
    }
    VerifyAccount ({usernameOrEmail, verificationCode }) {
        return axios.post(`${config.API_URL}/auth/verifyaccount`, {
            verificationCode : verificationCode,
            usernameOrEmail : usernameOrEmail
        })
    }
    sendVerificationCode ({ usernameOrEmail }) {
        return axios.post(`${config.API_URL}/auth/send-code`, {
            usernameOrEmail: usernameOrEmail
        })
    }
    resetPassword ({ usernameOrEmail }) {
        return axios.post(`${config.API_URL}/auth/reset-password`, {
            usernameOrEmail: usernameOrEmail
        })
    }
    changePassword ({ newPassword, token }) {
        return axios.post(`${config.API_URL}/auth/change-password`, {
            newPassword: newPassword,
            token: token
        })
    }
    thirdPartySso ({ token }) {
        return axios.post(`${config.API_URL}/auth/third-party/login`, {
            token: token
        })
    }
    exchangeForAccessToken({ token }) {
        return axios.post(`${config.API_URL}/auth/sso/exchange-for-access-token`, {
            token: token
        })
    }
}

export default new AuthService()
