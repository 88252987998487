import axios from 'axios'
import { config } from '@/config'

class EventService {
    getEvents({ organizationId, limit, offset, include }) {
        return axios.get(`${config.API_URL}/partners/events`, {
                params: {
                    organizationId: organizationId,
                    limit: limit,
                    offset: offset,
                    include: include,
                }
            })
    }
    getEvent({ idOrSlug, include }) {
        return axios.get(`${config.API_URL}/partners/events/${idOrSlug}`, {
            params: {
                include: include
            }
        })
    }
    createEvent({ name, description, primaryColor, secondaryColor, imagePath, plan, organizationId }) {
        return axios.post(`${config.API_URL}/partners/event`, {
            "event": {
                "name": name,
                "eventSettings": {
                    "description": description,
                    "primaryColor": primaryColor,
                    "secondaryColor": secondaryColor,
                    "image": imagePath,
                }
            },
            "organizationId": organizationId,
            "plan": plan
        })
    }
    saveGeneralSettings({ eventId, name, description, slug }, updateMask = "name,slug,event_settings.description") {
        return axios.patch(`${config.API_URL}/partners/events/${eventId}`, {
            "name": name,
            "slug": slug,
            "event_settings": {
                "description": description,
            }
        }, {
            params: {
                "updateMask": updateMask
            }
        })
    }
    updateEventSetting({id, primaryColor, secondaryColor, image}) {
        return axios.patch(`${config.API_URL}/partners/events/settings/${id}`, {primaryColor, secondaryColor, image})
    }

    deleteEvent({ eventId }) {
        return axios.delete(`${config.API_URL}/partners/events/${eventId}`)
    }

    publishEvent(eventId) {
        return axios.patch(`${config.API_URL}/partners/events/settings/publish/${eventId}`, {
            "eventId": eventId
        })
    }

    previewEvent(eventId) {
      return axios.post(`${config.API_URL}/partners/events/config/preview/${eventId}`)
    }

    eventConfigs(eventId) {
        return axios.get(`${config.API_URL}/partners/events/configs`, {
            params: {
                eventId: eventId
            }
        })
    }

    createEventConfigs({ eventId, configKey, configValue }) {
        const request = {
            eventConfigs: {
                eventId: eventId,
                configKey: configKey,
                configValue: configValue,
            },
        }
        return axios.post(`${config.API_URL}/partners/events/configs`, request)
    }

    updateEventConfigs({eventId, configKey, configValue, isConfigValueBitMask, bitwiseOperation}, updateMask = "configValue") {
        return axios.patch(`${config.API_URL}/partners/events/config/${eventId}`, {}, {
            params: {
                "updateMask": updateMask,
                configKey: configKey,
                configValue: configValue,
                isConfigValueBitMask: isConfigValueBitMask,
                bitwiseOperation: bitwiseOperation
            }
        })
    }

    submitEventFeedback(eventId, userId, feedback) {
        return axios.post(`${config.API_URL}/partners/events/createEventFeedback`, {
            userId: userId,
            eventId: eventId,
            subject: "event cancellation feedback",
            message: feedback,
        })
    }
}

export default new EventService()
