import { config } from '@/config'
import DOMPurify from 'dompurify'

// Truncated string or object
export const isTruncated = (input, maxLength = 50) => {
  switch(typeof input) {
    case 'string':
      return input.length > maxLength
    case 'object':
      return input?.firstName?.length + input?.lastName?.length > maxLength
    default:
      return false
  }
}

// MVP - Show on local or dev or QA or Staging only
export const isProdOrPP = ['production', 'pp'].includes(config.APP_ENV)
export const isLocalOrDev = ['local'].includes(config.APP_ENV)
export const isNonProd = 'production' !== config.APP_ENV

// Sanitize HTML content to prevent XSS attack
export const sanitizeHTML = (HTMLContent) => {
  return DOMPurify.sanitize(HTMLContent)
}

export const RGBAToHexA = (color) => {
  if (/^rgb/.test(color)) {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');

    // rgb to hex
    // eslint-disable-next-line no-bitwise
    let hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
        .toString(16)
        .slice(1)}`;

    // added alpha param if exists
    if (rgba[4]) {
      const alpha = Math.round(0o1 * 255);
      const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
      hex += hexAlpha;
    }

    return hex;
  }
  return color;
}


export const sanitizeAuthToken = (authToken) => {
  // Having a weird bug where the token become "<token>, Bearer <token>"
  return authToken && authToken.includes(', ') ? authToken.split(', ')[0] : authToken
}
