<script setup>
import { computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import store from '@/store'
import { ProjectTemplate } from 'project'
import EventPageBase from './EventPageBase'
import { processStatuses, eventFeatureCodes } from "@/utils"
import PostService from '@/services/post'
import BaseButton from '@/components/Base/Button/BaseButton'
import { displayModes } from 'project/src/utils/displayMode'

const router = useRouter()
const route = useRoute()

// check if event contain category module feature
const isEnableCategory = computed(() => {
  if (eventStatus.value === processStatuses.LOADED) {
    const feature = store.getters.getEventFeature(eventFeatureCodes.ENABLE_CATEGORIES)
    return feature?.value === 1
  }
  return true
})

const eventStatus = computed(() => {
  return store.getters.ebEventStatus
})

const eventCategory = computed(() => {
  return store.getters.categories
})

const hasEventCategory = computed(() => {
  return eventCategory?.value?.length === 0
})

const projectOptions = {
  project: {
    canEdit: false,
    canInvite: false,
    canComment: false,
    showComments: false,
    showInstructionsOnView: true
  },
  post: {
    canEdit: false,
    canComment: false,
    showComments: false,
    defaultView: displayModes.VIEW,
    showInstructionsOnView: true,
    showAttachmentsOnView: true
  },
  attachment: {
    canEdit: false
  },
  category: {
    showCategory:  true, // Category Section
    showCategoryList: hasEventCategory.value, // Category List
    showLockIcon: !isEnableCategory.value, // Lock Category
    canEditCategory: false
  }
}

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const user = computed(() => {
  return store.getters.user
})

const projectEditTrigger = () => {
  store.dispatch('projectValidateReset')
}

const projectMainToggle = () => {
  store.dispatch('setProjectMainToggle')
}
const comments = computed(() => {
  return store.getters.comments
})

const theme = computed(() => {
  return store.getters.getEventTheme
})

const sideMenuDesktop = computed(() => {
  return store.getters.sideMenu
})

const selectedTemplateID = computed(() => {
  return store.getters.selectedMasterTemplateId || route.params.id
})

const projectMainEdit = computed(() => {
  return store.getters.projectMainEdit
})

const projectCardError = computed(() => {
  return store.getters.projectsErrors
})

const posts = computed(() => {
  return store.getters.posts
})

// Post Modal
const fetchPost = async (postId) => {
  return new Promise((resolve, reject) => {
    PostService.getPost({ postId: postId }).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const selectedMasterTemplate = computed(() => {
  return store.getters.template
})

const selectedMasterTemplateLoaded = computed(() => {
  return store.getters.templateStatus === processStatuses.LOADED && store.getters.isPostsLoaded
})

const selectedTemplateCloneStatus = computed(() => {
  return store.getters.templateCloneStatus
})

const projectEditor = computed(() => {
  return route.name === 'Project'
})

// Comment functions
const postComment = async(data) => {
  try {
    await store.dispatch('postComment', data)
  } catch(err) {
    console.error(err)
  }
}

const postReplyComment = async(payload) => {
  try {
    await store.dispatch('postReplyComment', payload)
  } catch(err) {
    console.error(err)
  }
}

const editComment = async(payload) => {
  try {
    await store.dispatch('editComment', payload)
  } catch(err) {
    console.error(err)
  }
}

const deleteComment = async(ids) => {
  try{
    await store.dispatch('deleteComment', ids)
  } catch(err) {
    console.error(err)
  }
}

// Basic functions
const backToTemplate = () => {
  return router.push({ name: 'ProjectTemplates', params: { slug: route.params.slug }})
}

const cloneProject = async () => {
  await store.dispatch('cloneTemplate', { projectId: selectedTemplateID.value, eventId: store.getters.ebEventId })
  await store.dispatch('saveClonedTemplateAsEventTemplate')
  return router.push({ name: 'Project', params: { slug: route.params.slug, id: store.getters.clonedProjectId }})
}

onMounted(async () => {
  if (!projectEditor.value) {
    await store.dispatch('getTemplate', route.params.id)
  } else {
    await store.dispatch('getProject', route.params.id)
    // Check if project then get comments
    await store.dispatch('getComments')
  }
  await store.dispatch('getPosts', route.params.id)
})
</script>

<template>
  <event-page-base :is-visible="false">
    <template #content>
      <div v-if="selectedMasterTemplateLoaded">
        <div class="nav-container">
          <nav class="nav"
               role="navigation">
            <span class="nav-left">
              <base-button id="chooseAnotherBtn"
                           name="chooseAnotherBtn"
                           class="btn-choose"
                           text="choose another"
                           btn-type="text"
                           :functions="backToTemplate" />
            </span>
            <span class="nav-right" >
                  <span>
                    <base-button id="selectBtn"
                                 name="selectBtn"
                                 class="btn-select"
                                 :is-loading="selectedTemplateCloneStatus === processStatuses.LOADING"
                                 text="Select Template To Edit"
                                 :functions="cloneProject" />
                  </span>
              </span>
          </nav>
        </div>
        <project-template :client-event-theme="theme"
                          :on-project-detail-save="saveProject"
                          :side-menu-desktop="sideMenuDesktop"
                          :project="selectedMasterTemplate"
                          :options="projectOptions"
                          :max-char-main-text-area="700"
                          :event="event"
                          :project-main-edit="projectMainEdit"
                          :project-edit-trigger="projectEditTrigger"
                          :project-main-toggle="projectMainToggle"
                          :project-card-error="projectCardError"
                          :posts="posts"
                          :fetch-post="fetchPost"
                          :comments-data="comments"
                          :user="user"
                          :post-comment="postComment"
                          :edit-comment="editComment"
                          :delete-comment="deleteComment"
                          :post-reply-comment="postReplyComment"/>
      </div>
    </template>
    <template #event-save-button>
      <!-- Dummy span to clear the default save button -->
      <!-- Is there a better way to override the default slot? -->
      <span></span>
    </template>
    <template #event-publish-button>
      <!-- Dummy span to clear the default save button -->
      <!-- Is there a better way to override the default slot? -->
      <span></span>
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.nav {
  @apply py-3 -ml-4 flex flex-row justify-between bg-white z-[1] fixed bottom-0 shadow w-full md:w-[55rem] md:relative md:ml-0 md:py-8 md:bg-transparent md:shadow-none px-4 md:px-0;
  &-right {
   @apply flex md:justify-around w-1/2 md:w-auto justify-end;
  }
  &-left {
    @apply w-1/2 md:w-auto;
  }
  &-container {
    @apply w-full flex flex-col py-12 md:py-0 md:ml-5;
  }
}

.btn {
  &-cancel {
     @apply text-purple-600 border-transparent w-[6rem] rounded-sm;
  }
  &-back {
     @apply text-sm border-transparent text-purple-700 capitalize hidden md:inline-flex flex items-center hover:border-purple-500 hover:rounded;
  }
  &-choose {
     @apply text-purple-700 text-sm border-transparent capitalize px-2 md:mr-2 hover:border-purple-500 hover:rounded;
  }
  &-select {
     @apply text-white text-sm bg-purple-700 capitalize px-3.5 rounded md:ml-2 min-w-[12rem] uppercase font-semibold py-2 hover:border-purple-500 hover:rounded hover:bg-transparent;
  }
  &-add {
   @apply text-purple-600 w-5 h-5 border-transparent p-0;
 }
}
</style>
