<script setup>
import store from '@/store'
import {computed} from 'vue'
// Modals
import BaseInput from '@/components/Base/Fields/InputField'
import BaseButton from '@/components/Base/Button/BaseButton'
import Dropdown from '@/components/Base/Fields/Dropdown'
import ModalBase from '@/components/Modals/ModalBase'

defineProps({
  userOrEmail: {
    type: String,
    required: true
  },
  question: {
    type: String
  }
})

const setSupportModal = (modal) => {
  store.dispatch('SetSupportModal', modal)
}
const supportModalType = computed(() => {
  return store.getters.supportModalType
})
const question_type = computed(() => {
  return store.getters.questionType
})
const submitForm = () => {
  switch (supportModalType.value) {
  case 'question':
    store.dispatch('ResetSupportModal')
    break
  default:
    setSupportModal('question')
    break
  }
}
const cancelForm = () => {
  switch (supportModalType.value) {
  case 'question':
    setSupportModal('question')
    store.dispatch('ResetQuestionModal')
    break
  }
}
</script>

<template>
  <modal-base modal-class="bg-white">
    <template #modal-title>
      <h1 class="support-header">
        What Can We Help You With?
      </h1>
    </template>
    <template #modal-content>
      <form class="support-form">
        <div class="support-form-items">
          <base-input input-type="text"
                      label-text="Username or Email *"
                      input-name="userOrEmail"
                      input-id="userOrEmail"
                      input-place-holder="Your name"
                      input-aria-label="user or email"
                      :model="userOrEmail"
                      :model-value="userOrEmail"/>
        </div>
        <div class="support-form-items">
          <ul class="questionType">
            <li v-for="item in question_type" :key="item.name"
                class="form-item">
              <div v-if="supportModalType === 'question'" class="flex flex-col justify-center">
                <label class="support-form-labels">
                  {{item.text}}
                </label>
                <dropdown :id="item.name"
                          :name="item.name"
                          :options="item.data"
                          class-name="border rounded px-2 py-2 w-full"/>
              </div>
            </li>
          </ul>
        </div>
        <div class="support-form-items">
          <base-input input-type="text"
                      label-text="Your question *"
                      input-name="question"
                      input-id="question"
                      input-place-holder="Enter your question"
                      input-aria-label="question"
                      :model="question"
                      :model-value="question"/>
        </div>
        <div class="support-form-footer">
          <span class="text-[.8rem]">
            ** Response times from our team may vary, but we will try our hardest to get back to you within 24 hours.
          </span>
          <base-button id="cancel"
                        name="cancel"
                        text="Cancel"
                        class-name="cancelBtn"
                        :functions="cancelForm"/>
          <base-button id="submit"
                        name="submit"
                        text="Send"
                        class-name="submitBtn"
                        :functions="submitForm"/>
        </div>
      </form>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.support {
  &-header {
    @apply font-semibold text-2xl tracking-tight text-center md:pt-12 pt-8;
  }
  &-form {
    @apply px-4 md:px-12;
    &-items {
      @apply py-3;
    }
    &-labels {
      @apply text-xs text-gray-500;
    }
    &-footer {
      @apply w-full flex flex-row items-center text-gray-500 pt-4 pb-12 justify-between;
    }
  }
}

.cancelBtn {
  @apply border-transparent text-purple;
}
.submitBtn {
  @apply border-transparent bg-purple text-white rounded;
}
</style>
