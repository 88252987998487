<script setup>
import { ref, computed } from 'vue'
// Store
import store from '@/store'
// Prop Validation
const props = defineProps({
  name: String,
  id: {
    type: String,
    default: 'imgFileAttach'
  },
  className: String,
  modelValue: {
    type: String,
    required: true
  },
  ariaLabel: {
    type: String,
  },
  tabIndex: {
    type: Number,
    default: 0
  },
  imgFileError: String,
  imageHasChanged : {
    type: Boolean,
    required: true
  },
  imageHasRemoved : {
    type: Boolean,
    required: true
  },
  disabled: {
    type: Boolean,
    required: true
  },
  acceptedFileTypes: {
    type: Array,
    required: false
  },
  isBanner: {
    type: Boolean,
    default: false
  },
  isBrandLogo: {
    type: Boolean,
    default: false
  },
  imgUploading: {
    type: Boolean,
    default: false}
})

const isResource = computed(() => {
  return store.getters.typeOfModal === 'resource'
})

const imgFileName = ref('')
const imgFileSrc = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})
const imgFileTypeErrorMessage = ref('')
const emit = defineEmits(['update:modelValue', 'onError'])
const acceptedImgTypes = computed(() => {
  return props.acceptedFileTypes ? props.acceptedFileTypes.join(', ') : 'image/jpeg, image/jpg, image/png'
})
const checkFile = (event) => {
  const fileType = event.target.files[0].type
  const acceptedFileTypes =  acceptedImgTypes.value
  if (!acceptedFileTypes.includes(fileType)) {
    emit('onError', true)
    imgFileTypeErrorMessage.value = 'This is not an accepted image type. Please use JPEG, PNG or SVG'
  } else {
    emit('onError', false)
    imgFileTypeErrorMessage.value = ''
    imgFileSrc.value = event.target.files[0]
    imgFileName.value = event.target.files[0].name
  }
}

const imgFilePreview = computed(() => {
  if (imgFileSrc.value && imgFileSrc.value instanceof File) {
    return URL.createObjectURL(imgFileSrc.value)
  } else {
    return imgFileSrc.value
  }
})
</script>

<template>
  <input :id="id"
         type="file"
         :name="name"
         :accept="acceptedImgTypes"
         :class="className"
         style="display: none;"
         :aria-label="ariaLabel"
         :tabindex="tabIndex"
         :disabled="disabled"
         @change="checkFile"/>
  <label :class="{
          'image-label' : !isBanner,
          'image-label-banner' : isBanner,
          'image-label-resources' : isResource && !isBanner,
          'image-label-brandlogo' : isBrandLogo && !isBanner && !isResource,
          'image-label-others' : !isResource && !isBanner && !isBrandLogo
          }"
         :for="id">
    <div v-if="!imgUploading && !isResource && !isBrandLogo && imgFileSrc && !imgFileTypeErrorMessage && !imageHasRemoved"
         class="image-display">
      <img v-if="imgFileSrc && imageHasChanged"
           :class="{
              'image-regular' : !isBanner,
              'image-banner' : isBanner,
              'image-regular-circle' : !isBanner && !isResource
           }"
           :alt="name"
           :src="imgFilePreview">
      <p v-if="!isBanner"
         class="image-fileName">
        {{ imgFileName }}
      </p>
      <i v-if="!isBanner"
          class="fa-solid fa-circle-check image-check" />
      <span v-if="isBanner"
            class="image-overlay">
        <slot name="upload"/>
      </span>
    </div>
    <slot v-else name="content">
      <p class="pb-1 text-product-400 upload-label">
        <template v-if="!props.modelValue">
            <span>Upload File</span> <i class="fa-regular fa-arrow-up-from-bracket"></i>
        </template>
      </p>
    </slot>
  </label>
  <p v-if="imgFileTypeErrorMessage"
     class="errorMessage">
    {{imgFileTypeErrorMessage}}
  </p>

  <div v-if="imgFileError && imgFileError.length > 0" class="error">{{ imgFileError }}</div>
</template>

<style lang="scss" scoped>
.parent-object-fit .object-fit-image {
  object-fit: cover;
}

.image {
  &-label {
    @apply bg-white/[.5] hover:bg-white/[.7] text-black border-2 min-w-[10rem] min-h-[10rem] max-w-[10rem] max-h-[10rem] text-center flex items-center justify-center cursor-pointer text-p-sm;
    &-brandlogo {
      @apply mb-0 w-[5rem] h-[4.3rem] border-2 border rounded-lg mr-4 bg-[length:0.09rem_100%,_100%_0.09rem,_0.09rem_100%,_100%_0.09rem];
    }
    &-resources {
      @apply mb-0 w-[5rem] h-[4.3rem] rounded-lg mr-4 min-w-[6rem] min-h-[5rem] max-w-[6rem] max-h-[5rem] border-none bg-[length:0.09rem_100%,_100%_0.09rem,_0.09rem_100%,_100%_0.09rem];
      flex: 0 0 5rem;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;
      background-image: repeating-linear-gradient(0deg, #DDDDDD, #DDDDDD 10px, transparent 10px, transparent 20px),
      repeating-linear-gradient(80deg, #DDDDDD, #DDDDDD 10px, transparent 10px, transparent 20px),
      repeating-linear-gradient(180deg, #DDDDDD, #DDDDDD 10px, transparent 10px, transparent 20px),
      repeating-linear-gradient(260deg, #DDDDDD, #DDDDDD 10px, transparent 10px, transparent 20px)
    }
    &-others {
      @apply rounded-full;
    }
    &-banner {
      @apply cursor-pointer w-full h-full;
    }
  }
  &-regular {
    @apply object-fit-image min-w-[10rem] min-h-[10rem] max-w-[10rem] max-h-[10rem] absolute z-20 rounded-full bg-white;
    &-circle {
      @apply min-w-[10rem] min-h-[10rem] max-w-[10rem] max-h-[10rem];
    }
  }
  &-banner {
    @apply w-full h-[9.7rem] object-fit-image rounded-2xl opacity-60;
  }
  &-fileName {
    @apply pb-1 text-sm w-32 truncate;
  }
  &-check {
    @apply text-xl mt-1 text-green-600;
  }
  &-display {
    @apply flex flex-col justify-center items-center relative;
  }
  &-overlay {
    @apply absolute bg-black/[0.4] w-full h-full rounded-2xl;
  }
}

.branding-box {
  input#imgFileAttach + label {
    @apply rounded-xl;
    img {
      @apply rounded-xl object-cover;
    }
  }
}

.inputLabel {
  @apply text-sm pb-1;
}

input[type=search] {
  @apply pl-8 focus:outline-purple focus:outline focus:outline-2;
}

.search {
  @apply absolute text-gray-400 p-2.5;
}

.input {
  @apply bg-white font-light p-2;
  &_text {
    @apply w-full text-center border rounded-md focus:outline-purple focus:outline focus:outline-2;
  }
}

.errorMessage {
  @apply text-red-400 text-xs text-center my-2;
}
.upload-label {
  i {
    @apply hidden;
  }
}
.cover-img {
  .upload-label {
    span {
      @apply hidden;
    }
    i {
      @apply block text-gray-400 text-p-xl;
    }
  }
  input#imgFileAttach + label {
    @apply mb-0 w-[5rem] h-[4.3rem] rounded-lg mr-4 border-none bg-[length:0.09rem_100%,_100%_0.09rem,_0.09rem_100%,_100%_0.09rem];
    flex: 0 0 5rem;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    background-image: repeating-linear-gradient(0deg, #DDDDDD, #DDDDDD 10px, transparent 10px, transparent 20px), repeating-linear-gradient(80deg, #DDDDDD, #DDDDDD 10px, transparent 10px, transparent 20px), repeating-linear-gradient(180deg, #DDDDDD, #DDDDDD 10px, transparent 10px, transparent 20px), repeating-linear-gradient(260deg, #DDDDDD, #DDDDDD 10px, transparent 10px, transparent 20px)
  }
}
</style>
