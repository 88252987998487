import axios from 'axios'
import { config } from '@/config'

class FileService {
    upload({ file, directory = 'selfserve' }) {
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append("type", file.type)
        if (directory) {
            formData.append("directory", directory)
        }

        return axios
            .post(`${config.API_URL}/files`, formData, { headers: { "Content-Type": "multipart/form-data" }})
    }
}

export default new FileService()
