import TransactionService from '@/services/transactions'
import { processStatuses } from '@/utils'

export default {
  state: () => ({
    transactions: [],
    transactionsStatus: processStatuses.IDLE,
    transactionsError: null
  }),
  getters: {
    getTransactions: state => {
      return state.transactions
    },
    getTransactionsStatus: state => {
      return state.transactionsStatus
    },
    getTransactionsError: state => {
      return state.transactionsError
    }
  },
  mutations: {
    TRANSACTIONS_LOADED(state, payload) {
      state.transactions = payload
      state.transactionsStatus = processStatuses.LOADED
      state.transactionsError = null
    },
    TRANSACTIONS_LOADING(state) {
      state.transactionsStatus = processStatuses.LOADING
    },
    TRANSACTIONS_ERROR(state, error) {
      state.transactionsStatus = processStatuses.ERROR
      state.transactionsError = error
    }
  },
  actions: {
    getTransactions({commit}, {organizationId}) {
      commit('TRANSACTIONS_LOADING')
      return new Promise((resolve, reject) => {
        TransactionService.getTransactions({organizationId: organizationId, include: "organizations"}).then((response) => {
          commit('TRANSACTIONS_LOADED', response.data.transactions)
          resolve(response.data.transactions)
        }).catch((err) => {
          commit('TRANSACTIONS_ERROR', err)
          reject(err)
        })
      })
    }
  }
}
