<script setup>
// Props
import { submissionStatusType } from '@/utils/submission-status'

defineProps({
  project: {
    type: Object,
    required: true
  }
})
// Get status
const statusType = (status) => {
  switch (status) {
  case submissionStatusType.DECLINED:
    return 'Declined Projects'
  case submissionStatusType.SUBMITTED:
    return 'Projects Submitted'
  case submissionStatusType.APPROVED:
    return 'Approved Projects'
  case 'all':
    return 'All Projects'
  case 'participants':
    return 'Participants'
  default:
    return 'Draft Projects'
  }
}
</script>

<template>
  <div class="listCard">
    <span class="listCard-status-wrapper">
      <span v-if="!['all', 'participants'].includes(project.status)" :class="`listCard-dot listCard-dot-${project.text}`"/>
      <p class="listCard-status">
        {{statusType(project.status)}}
      </p>
    </span>
    <h2 class="listCard-number">
      {{project.number}}
    </h2>
  </div>
</template>

<style lang="scss" scoped>
.listCard {
  @apply rounded-xl bg-greys-100 w-[5rem] h-[4.4rem] sm:w-[12.875rem] sm:h-[5.625rem] py-1 sm:py-4 px-2 sm:px-6 flex flex-col justify-center sm:justify-start cursor-pointer;
  &.active {
    @apply outline-product-400 outline outline-2 bg-product-100;
  }
  &-dot {
    @apply sm:w-3 sm:h-3 sm:rounded-full sm:mr-1.5;
    &-draft {
      @apply bg-greys-400;
    }
    &-declined {
      @apply bg-other-red;
    }
    &-submitted {
      @apply bg-other-blue;
    }
    &-approved {
      @apply bg-other-green;
    }
  }
  &-status {
    @apply text-xs sm:text-p-md text-blacks-500 font-semibold;
    &-wrapper {
      @apply flex flex-row items-center;
    }
  }
  &-number {
    @apply text-h2 font-bold;
  }
}
</style>
