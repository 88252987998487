<script setup>
// Required Package
import { computed, onUpdated, ref, onMounted, watch, onUnmounted } from 'vue'
import { ColorPicker } from 'vue-color-kit'
// Store
import store from '@/store'
// Base component
import BaseImgFileInput from "@/components/Base/Fields/ImageField"
import Overflow from '@/components/Base/Menu/Overflow'
// Custom component
import Banner from '@/components/Branding/Banner'
// Style
import 'vue-color-kit/dist/vue-color-kit.css'
import { debounce } from 'lodash'
// Utils
import { processStatuses } from '@/utils'
import { onBeforeRouteLeave } from 'vue-router'

defineProps({
  nextStep: {
    type: Function,
    required: true
  }
})

const imageBackup = ref('')
const isEventLogoRemoved = ref(false)

const eventSettingError = computed(() => {
  return store.getters.eventSettingError ?? {}
})

const data = computed(() => {
  return store.getters.getBannerData
})

const bannerSelection = computed({
  get() {
    return store.getters.getSelectedBannerID
  },
  set(value) {
    store.dispatch('selectBanner', value)
  }
})

const ebEventStatus = computed(() => {
  return store.getters.ebEventStatus
})

const bannerSelectBg = computed({
  get() {
    return store.getters.getCustomBanner?.backgroundImage
  },
  set(value) {
    store.dispatch('setCustomBannerImage', value)
  }
})

const onBannerImageSelected = (value) => {
  store.dispatch('setCustomBannerImage', value)
  bannerSelection.value = 'upload'
}

const eventSettings = computed(() => {
  return store.getters.eventSettings
})

const event = computed(() => {
  return store.getters.event
})

const brandingStatus = computed(() => {
  return store.getters.getFileUploadStatus === processStatuses.LOADING || store.getters.eventSettingsStatus === processStatuses.SAVING
})

const isStaff = computed(() => {
  return store.getters.isStaff
})

const brandLogoImageErrorMessage = computed(() => {
  if (store.getters.getFileUploadError) {
    if(store.getters.getFileUploadError.data.message === 'failed to get file \'attachment\'') {
      return 'Please select brand logo'
    } else {
      return store.getters.getFileUploadError.data.message
    }
  }
  return ''
})

// Debounce the upload function to avoid multiple triggers
const debouncedUpload = debounce(() => {
  if (typeof eventSettings.value.image === 'object' && eventSettings.value.image !== null) {
    store.dispatch('uploadFile', eventSettings.value.image).then(() => {
      imageBackup.value = eventSettings.value.image
      eventSettings.value.image = store.getters.getUploadedFile
    })
  }
}, 1500)

onUpdated( () => {
  debouncedUpload()
})

const bannerSetsStatus = computed(() => {
  return store.getters.getBannerSetStatus
})

const deleteIcon = () => {
  isEventLogoRemoved.value = true
  eventSettings.value.image = ""
}

const changePhotoClick = () => {
  isEventLogoRemoved.value = true
  document.getElementById('logo').click()
}
/// Color Picker
const colorPrimaryPlaceholder = ref('#8400F0')
const suckerCanvas = ref(null)
const suckerArea = ref([])
const showPrimary = ref(false)
const showSecondary = ref(false)

const primaryChangeColor = (color) => {
  const { r,g,b,a} = color.rgba
  color.rgba = `rgba(${r}, ${g}, ${b}, ${a})`
  eventSettings.value.primaryColor = color.hex
}

const secondaryChangeColor = (color) => {
  const { r,g,b,a} = color.rgba
  color.rgba = `rgba(${r}, ${g}, ${b}, ${a})`
  eventSettings.value.secondaryColor = color.hex
}

const toggleColorPicker = (color) => {
  if (brandingStatus.value) {
    return
  }
  if (color === 'primary') {
    showPrimary.value = !showPrimary.value
    colorPrimaryPlaceholder.value = eventSettings.value?.primaryColor
  } else if (color === 'secondary') {
    showSecondary.value = !showSecondary.value
    colorPrimaryPlaceholder.value = eventSettings.value?.secondaryColor
  } else {
    showPrimary.value = false
    showSecondary.value = false
  }
}

const acceptedFileTypes = computed(() => {
  return ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml']
})

const clientEventTheme = computed(() => {
  return store.getters.getEventTheme
})

const beforeUnloadHandler = async (event) => {
  if (store.getters.isEventSettingsUpdated || store.getters.isBannerSelectionUpdated || store.getters.isCustomBannerImageUpdated) {
    event.preventDefault()
    // Allow the page to be unloaded
    event.returnValue = true
  }
}

// Need to check theme colour change
watch (() => clientEventTheme.value, (theme) => {
  const root = document.querySelector('#banner-inner')
  // Set new colour
  if (theme) {
    root.style.setProperty('--primary', theme.primary)
    root.style.setProperty('--secondary', theme.secondary)
  }
})

watch(() => store.getters.ebEventStatus, (newValue, oldValue) => {
  if (newValue === processStatuses.LOADED && oldValue === processStatuses.LOADING) {
    store.dispatch('fetchBannerSets')
  }
})

onBeforeRouteLeave((to, from, next) => {
  if(store.getters.isEventSettingsUpdated || store.getters.isBannerSelectionUpdated || store.getters.isCustomBannerImageUpdated){
    const payload = {
      confirmAction: () => {
        store.dispatch('ResetModal')
        next()
      },
      cancelAction: () => {
        store.dispatch('ResetModal')
      }
    }
    store.dispatch('ToggleModalUnsavedAlert', payload)
  } else {
    next()
  }
})

onMounted(() => {
  if (clientEventTheme.value) {
    store.dispatch('getEvent', event.value.id)
  }
  if (bannerSetsStatus.value === processStatuses.IDLE && ebEventStatus.value === processStatuses.LOADED) {
    store.dispatch('fetchBannerSets')
  }
  addEventListener("beforeunload", beforeUnloadHandler)
})

onUnmounted(() => {
  store.dispatch('resetBannerStore')
  // Remove beforeunload event listener when the component is unmounted
  removeEventListener("beforeunload", beforeUnloadHandler)
})
</script>

<template>
  <!-- Choose Colour & Branding Logo -->
  <div v-if="showPrimary || showSecondary"
       class="w-full h-full absolute top-0 left-0 z-10"
       @click="toggleColorPicker('close')" />
  <div class="flex flex-col w-full mb-28">
    <div class="branding-box mt-8">
      <div class="w-100">
        <h3 class="font-semibold text-lg py-2 capitalize sm:text-left">
          Choose your brand color
        </h3>
      </div>
      <div class="flex flex-col sm:flex-row mt-5">
        <div class="flex flex-col w-50 mr-6">
          <div class="branding-box-label relative mb-2">
            <p class="inputLabel">
              Showcase Logo
            </p>
            <p class="text-xs py-1">
              Try to upload images with a square aspect ratio for better user experience.
            </p>
          </div>
          <span class="flex flex-col items-center w-full justify-center">
            <span v-show="typeof eventSettings.image !== 'string' || eventSettings.image === ''" class="relative">
              <base-img-file-input id="logo"
                                  v-model="eventSettings.image"
                                  :src="eventSettings.image"
                                  name="Logo"
                                  aria-label="Logo image file attach"
                                  :disabled="brandingStatus"
                                  :image-has-changed="true"
                                  :image-has-removed="isEventLogoRemoved"
                                  :img-file-error="brandLogoImageErrorMessage"
                                  :is-brand-logo="true"
                                  :accepted-file-types="acceptedFileTypes">
              <template #upload-content>
                <label for="logo"/>
              </template>
              </base-img-file-input>
            </span>
            <div v-if="![undefined, '', null].includes(eventSettings.image) && typeof eventSettings.image === 'string'"
                 class="branding-box-update t-3 h-10rem l-0 w-10rem">
              <img class="branding-box-update-img w-40 h-40 z-10 mb-12"
                   :src="eventSettings.image"
                   alt="brand image">
              <span class="branding-box-header">
                <overflow position="center">
                  <template #menu-items>
                    <ul class="branding-box-menu-list">
                      <li class="branding-box-menu-item"
                          @click.prevent="changePhotoClick">
                        <i class="fa-thin fa-pencil mr-2" />Change Photo
                      </li>
                      <li class="branding-box-menu-item"
                          @click.prevent="() => deleteIcon()">
                        <i class="fa-thin fa-trash-can mr-2" />Delete
                      </li>
                    </ul>
                    </template>
                </overflow>
              </span>
            </div>
          </span>
        </div>
        <div class="relative top-3">
          <div v-if="eventSettings.primaryColor !== undefined" class="branding-box-colorInputContainer">
            <label class="inputLabel" for="primaryColorPicker">
              Primary Color
            </label>
            <div class="branding-box-colorInputs" :class="{'branding-box-colorInputs_primaryActive' : showPrimary}"
                 @click="toggleColorPicker('primary')">
              <div class="branding-box-colorPickerButton"
                   :style="{ 'background-color': eventSettings.primaryColor}">
              </div>
              <div class="text-left w-full">
                <p class="branding-box-colorPickerText">{{ eventSettings.primaryColor }}</p>
              </div>
            </div>
          </div>
          <color-picker
              :key="colorPrimaryPlaceholder"
              class="branding-box-colorPicker"
              :class="{'branding-box-colorPicker_active' : showPrimary}"
              theme="light"
              :color="colorPrimaryPlaceholder"
              :sucker-hide="true"
              :sucker-canvas="suckerCanvas"
              :sucker-area="suckerArea"
              :disabled="brandingStatus"
              @change-color="primaryChangeColor"/>
          <div v-if="eventSettings.secondaryColor !== undefined" class="branding-box-colorInputContainer">
            <label class="inputLabel" for="secondaryColorPicker">
              Secondary Color
            </label>
            <div class="branding-box-colorInputs" :class="{'branding-box-colorInputs_secondaryActive' : showSecondary}"
                 @click="toggleColorPicker('secondary')">
              <div class="branding-box-colorPickerButton bg-emerald-300"
                   :style="{ 'background-color': eventSettings.secondaryColor}">
              </div>
              <div class="text-left w-full">
                <p class="branding-box-colorPickerText">{{ eventSettings.secondaryColor }}</p>
              </div>
            </div>
            <p class="text-p-xs max-w-[15rem] py-2">
              We recommend not using white for better user experience
            </p>
          </div>
          <color-picker
              :key="colorPrimaryPlaceholder"
              class="branding-box-colorPicker"
              :class="{'branding-box-colorPicker_active' : showSecondary}"
              theme="light"
              :color="colorPrimaryPlaceholder"
              :sucker-hide="true"
              :sucker-canvas="suckerCanvas"
              :sucker-area="suckerArea"
              :disabled="brandingStatus"
              @change-color="secondaryChangeColor"/>
        </div>
      </div>
    </div>
    <div class="error">{{eventSettingError['server'] ?? ''}}</div>
    <!-- Choose Banner  -->
    <div class="banner">
      <div class="w-100">
        <h3 class="text-h3 py-2 capitalize font-medium">
          Choose your Banner
        </h3>
      </div>
      <div id="banner-inner"
           class="banner-inner">
        <div v-for="item in data"
              :key="item.id">
          <div v-if="item.name !== 'empty'" class="py-4">
            <label :for="item.name" class="capitalize ml-1 text-p-xl font-medium cursor-pointer">
              <banner :name="item.name"
                      :event-name="event.name"
                      :is-empty="item.name === 'empty'"
                      :is-upload="item.name === 'upload'"
                      :logo-img="eventSettings.image"
                      :description="item.description"
                      :background-img="item.backgroundImage"
                      :select-bg="bannerSelectBg"
                      :select-model="bannerSelection"
                      :disabled="brandingStatus || item.comingSoon || (item.isStaff && !isStaff)"
                      :error="brandLogoImageErrorMessage"
                      :theme="item.theme"
                      :select-name="item.name"
                      @on-image-selected="onBannerImageSelected"/>
              <input :id="item.name"
                     v-model="bannerSelection"
                     name="banner"
                     type="radio"
                     class="accent-product-400 cursor-pointer"
                     :disabled="item.comingSoon || (item.isStaff && !isStaff)"
                     :value="item.id ?? item.name" />
              {{item.name}} {{(item.comingSoon || (item.isStaff && !isStaff)) ? '(Coming Soon)' : ''}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.branding {
  &-box {
    @apply bg-background-100 border-blacks-100 max-w-[80rem] w-full p-7 border rounded-2xl shadow-xl;
    &-colorInputContainer {
      @apply py-2 flex flex-col;
    }
    &-colorPicker {
      @apply hidden h-[15.375rem] absolute overflow-hidden top-[16rem] sm:top-[0%] right-[calc(50%-13.625rem/2)] sm:right-[-100%] z-30 w-[13.625rem];
      &_active {
        @apply block;
      }
    }
    &-colorInputs {
      @apply flex flex-row items-center bg-white p-2 border cursor-pointer w-[15rem] rounded-md;
      &_primaryActive {
        @apply border-gray-600;
      }
      &_secondaryActive {
        @apply border-gray-600;
      }
    }
    &-colorPickerButton {
      @apply w-7 h-5 m-1 mr-4 bg-purple rounded-sm;
    }
    &-colorPickerText {
      @apply border-0 focus-visible:outline-none cursor-pointer;
    }
    &-update {
      @apply max-w-[300px] mx-auto h-[160px] relative;
      &-img {
        @apply h-[160px] max-w-[300px] object-cover rounded-lg;
      }
    }
    &-label {
      @apply -top-4;
    }
    &-header {
      @apply text-xs absolute top-0 right-0;
    }
    &-menu { // Project Card Header Menu
      &-list {
        @apply text-lg md:text-xs flex flex-col items-start;
      }
      &-item {
        @apply py-3 ml-6;
        &:hover {
          @apply text-purple cursor-pointer;
        }
      }
    }
  }
}

.banner {
  @apply max-w-[80rem] w-full p-7 border rounded-2xl shadow-xl mt-8;
  &-inner {
    @apply grid grid-cols-1 md:grid-cols-2 w-full gap-4 py-2;
  }
}
.comingSoon{
  @apply text-primary font-bold pt-4 -mb-2;
}
</style>
