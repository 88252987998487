<script setup>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { onMounted, onUnmounted, ref } from 'vue'

// General Purpose Video Player
const props = defineProps({
  autoplay: {
    type: Boolean,
    default() {
      return false;
    }
  },
  control: {
    type: Boolean,
    default() {
      return true;
    }
  },
  sources: {
    type: Array,
    default() {
      return [];
    }
  },
  thumbnail: {
    type: String,
    default() {
      return '';
    }
  }
})

const videoPlayer = ref(null)

onMounted(() => {
  videojs.addLanguage('en', {
    "No compatible source was found for this media.": "Video processing failed. Please try a different video.",
    "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Video is processing. Feel free to check back in a bit."
  })

  videojs(videoPlayer.value, {
    autoplay: props.autoplay,
    controls: props.control,
    sources: props.sources,
    poster: props.thumbnail,
  })
})

onUnmounted(() => {
  if (videoPlayer.value) {
    videojs(videoPlayer.value).dispose()
  }
})
</script>

<template>
  <video ref="videoPlayer"
          class="video-js"></video>
</template>
