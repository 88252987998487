<script setup>
import { computed, onMounted } from 'vue'
import store from '@/store'
import BaseInput from '@/components/Base/Fields/InputField'
import BaseButton from '@/components/Base/Button/BaseButton'
import BasicForm from '@/components/Base/Forms/BasicForm'

onMounted(() => {
  store.dispatch('fetchForms')
})

const isFormLoading = computed(() => {
  return store.getters.getFormLoadingStatus === 'loading'
})

// Later use
// const login = () => {
//   store.dispatch('ToggleLoginModal')
// }

const formItems = computed(() => {
  return store.getters.getFormItems
})
</script>

<template>
  <div class="container">
    <basic-form class-name="form"
                :submit-action="() => console.log('hello')">
      <template v-if="isFormLoading">
        Loading
      </template>
      <template v-else>
        <span class="form-partners">
          <img src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/navbars%2Fpbwordmark.png?alt=media&token=071ad253-caa6-4a82-98be-cda5c76378dc" alt="projectboard"/>
          <i class="fa-solid fa-xmark px-2.5"></i>
          <img class="form-avatar" src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Fprofile%2Fblank-profile-picture.png?alt=media&token=66376c74-95e4-4e5b-940a-8f4ac3bcb38c" alt="team avatar" />
        </span>
        <h1 class="form-header">
          Welcome to ProjectBoard! You have been invited to participate in an event. Please full out the form below to access your project template.
        </h1>
        <span v-for="item in formItems" :key="item.id"
              class="form-questions">
          <base-input v-model="item.context"
                      :model-value="item.context"
                      :label-text="item.labels.en"
                      input-name="email"
                      :input-id="item.labels.en"
                      class-name="w-full"
                      :input-place-holder="item.labels.en"/>
          </span>
      </template>
      <span class="form-footer">
          <p class="form-footer-agreement">By clicking Sign Up, you are agreeing to our
            <span class="form-footer-toc">Terms of Use</span>
          </p>
          <base-button id="signup"
                        name="signup"
                        aria-label="signup"
                        text="sign up"
                        functions=""
                        class-name="form-footer-signup"/>
        </span>
      <span v-if="!store.getters.isLoggedIn"
            class="text-sm py-4">Already an User? <span class="form-footer-login"
                                                        @click="() => console.log('hello')">Log In</span>
      </span>
    </basic-form>
  </div>
</template>

<style lang="scss" scoped>
.container {
  @apply bg-gradient-to-t from-form-purple to-form-purple-dark py-12 px-4 my-4 max-w-[96rem] flex items-center justify-center rounded-lg;
}
.form {
  @apply bg-white rounded-md p-3 flex flex-col items-center;
  &-avatar {
    @apply w-8 h-8 rounded-full
  }
  &-partners {
    @apply flex flex-row items-center justify-around pt-4;
  }
  &-header {
    @apply px-10 py-4 max-w-[30rem] text-center w-full;
  }
  &-questions {
    @apply flex flex-col w-full py-2 px-4;
    &-label {
      @apply text-gray-500 text-xs pb-2 tracking-wide;
    }
  }
  &-footer {
    @apply flex flex-row items-center py-4;
    &-agreement {
      @apply text-xs mr-2;
    }
    &-toc {
      @apply text-purple font-semibold;
    }
    &-signup {
      @apply capitalize rounded-lg text-white bg-purple border-none px-4 py-2;
    }
    &-login {
      @apply text-purple font-bold cursor-pointer;
    }
  }
}
</style>
