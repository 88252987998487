<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// Store
import store from '@/store'
import { pbAuthBase } from 'pb-components'
import { config } from '@/config'

const route = useRoute()
const router = useRouter()

const token = ref(route.query.token)

const ssoLogin = async () => {
  if (token.value) {
    try {
      await store.dispatch('exchangeForAccessToken', {'token': token.value})
      const { query } = route

      if (query?.returnUrl) {
        await router.push(query.returnUrl)
      } else {
        await router.push({name: 'Home'})
      }
    } catch (e) {
      console.error(e, store.getters.ssoLoginError)
    }
  }
}

const ssoLoginStatus = computed(() => {
  return store.getters.ssoLoginStatus
})

const goBackToLogin = () => {
  let loginUrl = config.AUTH_URL + '/selfserve/login'
  if (route.query.returnUrl) {
    loginUrl = loginUrl + '?returnUrl=' + encodeURIComponent(route.query.returnUrl)
  }
  window.location.href = loginUrl
}

onMounted(async () => {
  await ssoLogin()
})
</script>

<template>
  <pb-auth-base :status="ssoLoginStatus"
                :error-action="goBackToLogin">
  </pb-auth-base>
</template>

<script lang="scss">
</script>
