<script setup>
import ModalBase from "@/components/Modals/ModalBase"
import BaseButton from '@/components/Base/Button/BaseButton';
import { computed } from 'vue';
import store from '@/store';

const cancel = () => {
  store.dispatch('ToggleModalEventBegin')
}

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const publishEvent = () => {
  let localItemsArray = {}
  const localItems = localStorage.getItem('eventPopupFlag')
  if(localItems !== null) {
    const eventTitle = "event" + event.value.id
    localItemsArray = JSON.parse(localItems)
    localItemsArray[eventTitle] = "Published"
    localStorage.setItem('eventPopupFlag', JSON.stringify(localItemsArray))
  }
  // localStorage.setItem('goliveEventId-' + event.value.id, 'Published')
  store.dispatch('publishEvent', event.value.id)
  store.dispatch('ToggleModalEventBegin')
  store.dispatch('ToggleModalEventLive')
}
</script>

<template>
  <modal-base modal-class="bg-white modal-day" :is-close="true">
    <template #modal-title>
      <div class="modal-day-title">
        <img class="modal-day-title-img" src="https://firebasestorage.googleapis.com/v0/b/test-d9796.appspot.com/o/selfserve%2Fcalendar-star-duotone%201.png?alt=media&token=b5c51406-66da-4650-bdc4-4e73ff8aa392"/>
        <h1 class="modal-day-title-header">Your 30 day is about to begin!</h1>
      </div>
    </template>
    <template #modal-content>
      <div class="modal-day-content">
        <p class="modal-day-content-para">Your 30 day free event period will begin once you click on Go live Now. You can always extend the event time, <a class="modal-day-content-para-link" href="#">Click here</a> for extension plans.</p>
      </div>
    </template>
    <template #modal-button>
      <div class="modal-day-buttons">
        <base-button
            id="go-live"
            name="go-live"
            text="Yes, Go Live Now"
            aria-label="go Button"
            class-name="modal-day-buttons-live"
            :functions="publishEvent"/>
        <base-button id="go-cancel"
                     name="go-cancel"
                     class-name="modal-day-buttons-time"
                     btn-type="text"
                     :functions="cancel"
                     text="I need more time" />
      </div>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal-day {
  @apply md:h-auto overflow-y-scroll max-w-lg p-7 rounded-b-none md:rounded-2xl;
  &-title {
    &-header {
      @apply text-h4 mb-4 w-full text-center font-medium;
    }
    &-img {
      @apply w-20 mx-auto mb-5;
    }
  }
  &-content {
    &-para {
      @apply max-w-[23rem] mb-8 text-p-md text-center mx-auto md:leading-6 leading-5 text-blacks-300;
      &-link {
        @apply text-purple-dark underline;
      }
    }
  }
  &-buttons {
    @apply md:flex justify-center md:flex-row-reverse px-1;
    &-live {
      @apply md:max-w-[52rem] md:mx-2 capitalize w-full md:mb-0 mb-3 h-11;
    }
    &-time {
      @apply text-product-500 border border-product-500 px-3 py-1.5 rounded-lg capitalize w-full md:mb-0 mb-3 h-11;
    }
  }
}
</style>
