<script setup>
import BaseInput from '@/components/Base/Fields/InputField'
import BaseButton from '@/components/Base/Button/BaseButton'
import ModalLayout from '@/components/Modals/ModalAuthBase'
import { useRouter, useRoute } from 'vue-router'
import store from '@/store'
import {computed, ref} from 'vue'

const props = defineProps({
  password: {
    type: String,
    required: true
  }
})

const username = computed(() => {
  return store.getters.usernameOrEmail
})

const loginError = computed(() => {
  return store.getters.loginError
})

const password = ref(props.password)

const route = useRoute()
const router = useRouter()
const login = () => {
  const payload = {
    username: username.value,
    password: password.value
  }
  store.dispatch('login', payload).then(() => {
    store.dispatch('ToggleLoginModal')
    if (route.query.returnUrl) {
      router.push(route.query.returnUrl)
    } else {
      router.push('/events')
    }
  })
}
</script>

<template>
  <modal-layout>
    <template #modal-title>
      Hello, {{username}}
    </template>
    <template #modal-body>
      <span class="password">
        <base-input v-model="password"
                    :model-value="password"
                    input-name="password"
                    input-id="password"
                    input-type="password"
                    class-name="text-black text-center"
                    input-place-holder="Enter your password"
                    input-aria-label="sign in email or username"
                    :errors="loginError"
                    @handle-enter="login"/>
      </span>
    </template>
    <template #modal-button>
      <span class="w-full text-center py-4">
      <base-button id="next"
                    name="next"
                    text="login"
                    class-name="btn btn-primary"
                    :functions="login" />
    </span>
    </template>
    <template #modal-footer>
      <span v-if="1 == 2" class="tryAgain">
      Forget Your Password? <br /> <span class="tryAgain_text">
      Reset Password | Email Login Link
      </span>
    </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.password {
  @apply m-4 px-10;

  &_text {
    @apply text-center mb-12;
  }
}
.tryAgain {
  @apply text-center text-xs mb-12;
&_text {
   @apply text-purple cursor-pointer;
 }
}
</style>
