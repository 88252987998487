<script setup>
// Required Packages
import { computed } from 'vue'
// Base Components
import Tooltip from '@/components/Base/Tooltip'
import BaseImgFileInput from '@/components/Base/Fields/ImageField'
import SvgBanner from '@/components/Base/Svg'

const props = defineProps({
  logoImg: {
    type: String
  },
  eventName: {
    type: String,
    default: 'Demo event'
  },
  backgroundImg: {
    type: String
  },
  bannerImg: {
    type: String
  },
  className: {
    type: String
  },
  isEmpty: {
    type: Boolean,
    default: false
  },
  isUpload: {
    type: Boolean,
    default: false
  },
  selectModel: {
    type: String,
    default: 'default'
  },
  selectName: {
    type: String,
    default: 'default'
  },
  selectBg: {
    type: [String, File],
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  functions: {
    type: Function
  },
  status: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ''
  },
  theme: {
    type: String,
    default: 'light'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: 'celebrate'
  }
})

const emit = defineEmits(['onImageSelected'])

const isDark = computed(() => {
  return props.theme.toLowerCase() === 'dark'
})

const bannerBgImg = computed({
  get: () => props.selectBg,
  set: (value) => {
    emit('onImageSelected', value)
  }
})
</script>

<template>
  <div class="custom-banner-card">
    <!--  SVG Banner  -->
    <svg-banner :banner-name="name.toLowerCase()"/>
    <!-- Regular Banner  -->
    <div class="custom-banner"
          :class="`${className ? className : !isEmpty && !isUpload ? 'custom-banner-base' : ''}`">
      <!--  Regular Banner  -->
      <span v-if="!isEmpty && !isUpload"
            class="custom-banner-container"
            :class="{'custom-banner-container-noBg' : !backgroundImg}">
        <slot name="logo">
          <div :id="eventName"
               class="avatar"
               :class="`${logoImg ? 'custom-banner-container-logo' : 'custom-banner-container-logo-empty'}`"
               :style="{ backgroundImage: `url(${logoImg ? logoImg : '/images/logo/projectboard.png'})`}"/>
        </slot>
        <slot name="event-name">
          <tooltip :input="eventName"
                   class-name="custom-banner-event-name-container">
            <template #element>
              <h5 class="custom-banner-container-text"
                  :class="{
                    'custom-banner-container-text-noBg' : !backgroundImg,
                    'custom-banner-container-text-dark' : isDark
                  }">
                <span class="line-clamp-2 ">
                  {{ eventName }}
                </span>
              </h5>
            </template>
            <template #content>
              {{ eventName }}
            </template>
          </tooltip>
        </slot>
      </span>
    </div>
    <!-- Upload Banner -->
    <span v-if="isUpload" class="custom-banner custom-banner-upload">
      <slot name="upload">
        <base-img-file-input id="custom-banner"
                            v-model="bannerBgImg"
                            name="custom-banner"
                            :src="bannerBgImg"
                            :disabled="disabled"
                            :image-has-changed="true"
                            :is-banner="true"
                            :img-file-error="error">
          <template #upload>
            <span class="custom-banner-upload-again">
              <img src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/selfserve%2Fbanners%2Fimage%20upload.png?alt=media&token=cc166d8c-2cae-4b0e-8aa5-4af56098fcf4"
                   alt="upload"/>
              <h6 class="custom-banner-upload-again-text">
                Change Photo
              </h6>
            </span>
          </template>
          <template #content>
            <span class="custom-banner-content">
              <img src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/selfserve%2Fbanners%2Fupload%20files.png?alt=media&token=57d17d94-675f-43ea-a7c2-fe76cd094928"
                   class="custom-banner-content-img"
                   alt="upload" />
              <h3 class="custom-banner-content-text">
                Upload your own banner
              </h3>
              <p class="custom-banner-content-text-support">
                Supported formats: JPEG, PNG & GIF
              </p>
            </span>
          </template>
        </base-img-file-input>
      </slot>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.custom-banner {
  @apply w-full bg-center bg-cover bg-no-repeat z-[1];
  // Card CSS
  &-card {
    @apply flex flex-col w-full relative overflow-hidden rounded-2xl;
  }
  &-base {
    @apply h-[10rem] border-2 border-greys-200 bg-transparent rounded-2xl;
  }
  &-container {
    @apply w-full flex flex-col h-full p-4;
    &-noBg {
      @apply justify-center items-center;
    }
    &-logo {
      @apply w-12 h-12 rounded-full border-2 border-greys-200 overflow-hidden mb-2 object-cover bg-cover bg-center;
      &-empty {
        @apply object-cover max-w-[10rem] pb-2 flex flex-col justify-center items-center w-[10rem] h-[1.2rem] bg-cover bg-center my-2;
      }
    }
    &-text {
      @apply font-medium text-h5 flex flex-col capitalize;
      &-noBg {
        @apply text-center;
      }
      &-dark {
        @apply text-white;
      }
    }
  }
  // Content
  &-content {
    @apply flex flex-col w-full h-full justify-center items-center;
    &-img {
      @apply w-auto h-10;
    }
    &-text {
      @apply font-medium text-product-400;
      &-support {
        @apply text-p-md text-blacks-300;
      }
    }
  }
  // Upload
  &-upload {
    @apply bg-product-100/[0.3] rounded-2xl h-[10rem] border-dashed border-2 border-product-400/[0.3] flex flex-col justify-center items-center;
    &-again {
      @apply w-full h-full flex flex-col justify-center items-center;
      &-text {
        @apply text-h6 text-white;
      }
    }
  }
  // Radio Button CSS
  &-input {
    @apply my-1 w-4 h-4 relative inset-0.5;
    &-container {
      @apply w-full mt-2;
    }
  }
  &-event-name {
    &-container {
      @apply w-full max-w-[16rem] cursor-pointer;
    }
  }
}
</style>
