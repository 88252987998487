<script setup>
// Required Components
import { computed } from 'vue'
// Store
import store from '@/store'
// Base Component
import ToastMessage from '@/components/Base/Toast'

const toasts = computed(() => {
  return store.getters.toast || []
})
</script>

<template>
  <div class="toast-container">
    <toast-message v-for="(toast, index) in toasts"
                   :key="index"
                   :toast-type="toast.type"
                   :index="index"
                   :toast-message="toast.message" />
  </div>
</template>

<style lang="scss" scoped>
.toast-container {
  @apply fixed bottom-20 right-5 h-auto w-[22rem] z-[2];
}
</style>
