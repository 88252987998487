<script setup>
// Required Packages
import { computed, onMounted, ref, watch } from 'vue'
import store from '@/store'
// Custom Component
import 'vue3-easy-data-table/dist/style.css'
import ListCard from '@/components/Submissions/ListCard'
import TagSelector from '@/components/Base/Tags/TagSelector'
import { config } from '@/config'
import { submissionStatusType } from '@/utils/submission-status'
import DashboardTable from '@/components/Base/Table/Dashboard'
import BaseButton from '@/components/Base/Button/BaseButton'
import { processStatuses, projectStatuses } from '@/utils'
import Loading from '@/components/Base/Loading'

const projectSubmissionsCounts = computed(() => {
  return store.getters.getProjectSubmissionsCounts
})

const projectSubmissionsCountStatus = computed(() => {
  return store.getters.getProjectSubmissionsCountStatus
})

const totalProjects = computed(() => {
  const { approved, declined, draft, submitted } = projectSubmissionsCounts.value
  return approved + declined + draft + submitted || 0
})

const projectCounts = computed(() => {
  return [
    {
      status: 'all',
      text: 'all',
      number: totalProjects.value
    },
    {
      status: submissionStatusType.DRAFT,
      text: 'draft',
      number: projectSubmissionsCounts.value.draft
    },
    {
      status: submissionStatusType.SUBMITTED,
      text: 'submitted',
      number: projectSubmissionsCounts.value.submitted
    },
    {
      status: submissionStatusType.APPROVED,
      text: 'approved',
      number: projectSubmissionsCounts.value.approved,
      total: totalProjects.value
    },
    {
      status: submissionStatusType.DECLINED,
      text: 'declined',
      number: projectSubmissionsCounts.value.declined
    }
  ]
})

const headers = [
  { text: "STATUS", value: "submissionStatus", sortable: true },
  { text: "PROJECT", value: "projectLink", sortable: true },
  { text: "TEAM", value: "team", searchable: true },
  { text: "ACTIONS", value: "actions", sortable: false }
]

const fetchProjects = () => {
  store.dispatch('getProjectSubmissions', { eventId: eventId.value })
}

const fetchProjectCounts = () => {
  store.dispatch('getProjectSubmissionsCounts', { eventId: eventId.value }).then(() => {
  })
}

const submissionStatusCode = ref('all')
const submissionStatusText = ref('all')

const filterOptions = computed(() => {
  if (submissionStatusCode.value !== 'all') {
    return [{
      field: 'submissionStatus',
      comparison: '=',
      criteria: submissionStatusCode.value,
    }]
  }
  return []
})

const selectSubmissionStatusFilter = async (submissionStatusCodeString, submissionStatusTextString) => {
  await store.dispatch('setProjectSubmissionFilterBy', submissionStatusCodeString)
  submissionStatusCode.value = submissionStatusCodeString
  submissionStatusText.value = submissionStatusTextString
}

const eventId = computed(() => {
  return store.getters.ebEventId
})

watch([eventId], () => { fetchProjects(), fetchProjectCounts() }, { deep: true })
watch([filterOptions], () => { fetchProjects(), fetchProjectCounts() }, { deep: true })

const items = computed(() => {
  return store.getters.getProjectSubmissions || []
})

const projectSubmissionStatus = computed(() => {
  return store.getters.getProjectSubmissionStatus
})

const eventIsPublished = computed(() => {
  return store.getters.eventSettings.status === 'Published'
})

onMounted(async () => {
  if (eventId.value) {
    await fetchProjects()
    await fetchProjectCounts()
  }
})

const sort = (sortBy = 'status') => {
  store.dispatch('toggleProjectSubmissionDesc')
  store.dispatch('setProjectSubmissionSortBy', sortBy)
}

const generateProjectLink = (id) => {
  const encodedLink = encodeURIComponent(`/${store.getters.ebEventSlug}/project/${id}`)
  // To avoid non-logged user to be redirected to 404
  // Redirect to login page by default, event site will handle the redirection for us.
  return `${config.AUTH_URL}/${store.getters.ebEventSlug}/login?returnUrl=${encodedLink}`
}

const resendProjectStatusEmail = (projectId) => {
  store.dispatch('ToggleModalGeneralConfirm', {
    title: 'Are You Sure You Want To Notify Student?',
    description: 'As a reminder, if student has been notified previously, they will receive an email again.',
    confirmButton: 'Yes, Notify Anyway',
    cancelButton: 'No',
    confirmAction: () => {
      store.dispatch('resentProjectSubmissionsStatusEmail', projectId).then(() => {
        store.dispatch('triggerToast', { message: 'Project status email sent', type: 'success' })
      }).catch(() => {
        store.dispatch('triggerToast', { message: 'Failed to send project status email', type: 'error' })
      }).finally(() => {
        store.dispatch('ResetModal')
      })
    },
    cancelAction: () => {
      store.dispatch('ResetModal')
    }
  })
}

const projectResendStatus = (projectId) => {
  return store.getters.getParticipantReNotifyStatus(projectId)
}

const isParticipantsNotified = computed(() => {
  return store.getters.getParticipantsNotified
})
</script>

<template>
  <div class="plist" :class="{'plist-withPeople' : totalProjects !== 0}">
    <ul v-if="projectSubmissionsCounts" class="plist-list">
      <list-card v-for="projectCount in projectCounts"
                 :key="projectCount.text"
                 :project="projectCount"
                 :is-loading="projectSubmissionsCountStatus === processStatuses.LOADING"
                 loading-style="pulse"
                 :class="{'active' : submissionStatusText === projectCount.text }"
                 @click="selectSubmissionStatusFilter(projectCount.status, projectCount.text)" />
    </ul>
    <div v-if="projectSubmissionStatus === processStatuses.LOADING"
         class="flex align-middle justify-center items-center mt-8">
      <loading />
    </div>
    <template v-else-if="projectSubmissionStatus === processStatuses.LOADED">
      <span v-if="totalProjects === 0" class="plist-noPeople">
        <img src="/images/icons/add-group.png"
             alt="add group"
             class="w-32 h-32"/>
        <p v-if="eventIsPublished" class="plist-noPeople_text">
          You don't have any participants yet! <br/>
          To add participants, share the event URL with them.
        </p>
        <p v-else class="plist-noPeople_text">
          You don't have any participants yet! <br/>
          Please make sure your event is live to add participants.
        </p>
      </span>
      <dashboard-table v-else :sort-function="sort" :headers="headers" :items="items">
        <template #content-column="{ item }">
          <td class="list-table-status list-table-spacing">
            <tag-selector :key="item.id"
                          :text="item.submissionStatus"
                          :project-id="item.id"
                          :event-id="eventId"
                          class="status-button"/>
          </td>
          <td class="list-table-spacing">
            <a target="_blank"
               :href="generateProjectLink(item.id)"
               class="project-link">
              {{ item.title }}
            </a>
          </td>
          <td class="list-table-spacing">
            <div class="name-wrapper">
                {{item.team}}
            </div>
          </td>
          <td>
            <base-button text="Resend"
                         :is-loading="projectResendStatus(item.id) === processStatuses.LOADING"
                         :disabled="!isParticipantsNotified || projectResendStatus(item.id) === processStatuses.LOADING || [projectStatuses.DRAFT, projectStatuses.SUBMITTED].includes(item.submissionStatus)"
                         :functions="() => resendProjectStatusEmail(item.id)"/>
          </td>
        </template>
      </dashboard-table>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.list {
  &-table {
    &-status {
      @apply min-w-[9rem];
    }
    &-spacing {
      @apply pr-3;
    }
  }
}
:deep .vue3-easy-data-table__header {
  @apply z-[1];
}
:deep .vue3-easy-data-table__main {
  @apply overflow-visible;
}
.plist {
  @apply border rounded-xl border-greys-200 my-6 bg-greys-50 max-w-[83.5rem] p-4 flex flex-col h-fit;
  &-list {
    @apply w-full flex gap-5 flex-wrap py-6 justify-start;
  }
  &-noPeople {
    @apply flex flex-col py-4 w-full justify-center items-center h-full;
    &_text {
      @apply py-6 text-center max-w-[24.125rem] text-p-md;
    }
  }
  &-withPeople {
    @apply border-none bg-transparent p-0;
  }
}
.name-wrapper {
  @apply flex flex-row items-center;
}
.avatar {
  @apply inline-block w-[2rem] h-[2rem] rounded-full object-cover;
}
.avatar-wrapper {
  @apply flex justify-items-center items-center shrink-0 mr-1;
}
.project-link {
  @apply text-product-400 underline;
}
.page-button {
  @apply px-2 py-[3px] mx-1 bg-greys-100 border rounded-md border-greys-200;
  &:disabled {
    @apply bg-greys-50 text-greys-400 cursor-not-allowed
  }
}
</style>
