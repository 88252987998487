import axios from 'axios'
import { config } from '@/config'

class VersionService {
    getVersion() {
        return axios.
            get(`${config.API_URL}/version`)
    }
}

export default new VersionService()
