import { processStatuses } from '@/utils'
import PlanService from '@/services/plan'

export default {
  state: () => ({
    plans: [],
    plan_modal: false,
    plan_loading_status: processStatuses.IDLE
  }),
  getters: {
    getPlans: state => state.plan_loading_status === processStatuses.LOADED ? state.plans : [],
    getPlanStatus: state => state.plan_loading_status,
    getPlanModal: state => state.plan_modal,
    getPlanByName: state => (name) => {
      return state.plan_loading_status === processStatuses.LOADED ?
        state.plans.find(m => m.name === name) : null
    }
  },
  mutations: {
    TOGGLE_PLAN_MODAL (state) {
      state.plan_modal = !state.plan_modal
    },
    PLANS_LOADING (state) {
      state.plan_loading_status = processStatuses.LOADING
    },
    PLANS_LOADED (state, payload) {
      state.plans = payload.plans
      state.plan_loading_status = processStatuses.LOADED
    },
    PLANS_ERROR (state) {
      state.plan_loading_status = processStatuses.ERROR
    },
  },
  actions: {
    toggle_plan_modal ({ commit }) {
      commit('TOGGLE_PLAN_MODAL')
    },
    fetchPlans ({ commit }) {
      commit('PLANS_LOADING')
      PlanService.getPlans().then((response) => {
        commit('PLANS_LOADED', response.data)
      }).catch((err) => {
        commit('PLANS_ERROR', err)
      })
    }
  }
}
