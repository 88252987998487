import axios from 'axios'
import { config } from '@/config'

class ResourceService {
  getResource({ resourceId, include, isTemplate }) {
    return axios.get(`${config.API_URL}/resource/${resourceId}`, {
      params: {
        include: include,
        isTemplate: isTemplate
      }
    })
  }

  getResources({ eventId, limit, offset }) {
    return axios.get(`${config.API_URL}/resources?event_id=${eventId}`, {
      params: {
        limit: limit,
        offset: offset
      }
    })
  }

  createResource(data) {
    return axios.post(`${config.API_URL}/resources`, data)
  }

  updateResource(resourceId, data, updateMask) {
    return axios.patch(`${config.API_URL}/resource/${resourceId}`, data, updateMask ? {
      params: {
        "updateMask": updateMask
      }
    } : null)
  }

  deleteResource({ resourceId }) {
    return axios.delete(`${config.API_URL}/resource/${resourceId}`)
  }

}

export default new ResourceService()
