import axios from 'axios'
import store from '@/store'
import router from '@/router'

const authHeader = 'Authorization'

export const errorInterceptor = () => {
    axios.interceptors.response.use(null, (error) => {
        const { response, config } = error
        if (!response) {
            // network error
            console.error(error)
            return
        }

        if (response.status === 401 && !config._retry) {
            config._retry = true
            // did the request fire before we had token?
            if (store.getters.isLoggedIn && !config.headers[authHeader] && store.getters.authToken) {
                config.headers[authHeader] = `Bearer ${store.getters.authToken}`
                return axios(config)
            }

            // handle access token expiry
            if (response.data && response.data.message && response.data.message.includes("token is expired")) {
                // Start a fresh axios config to send to refresh API
                const refreshConfig = axios.defaults
                refreshConfig._retry = true
                return store.dispatch('refresh', refreshConfig).then(() => {
                    // Update the original config's auth token and replay
                    config.headers[authHeader] = `Bearer ${store.getters.authToken}`
                    return axios(config)
                }, () => {
                    // if fail to get the new refresh token log user out
                    store.dispatch('logout').then(() => {
                        router.push({ name: "Login" }).then(() => {
                            return Promise.reject(response)
                        })
                    })
                })
            }

            // if it gets here, log user out
            if (config.url === '/api-v2/users') {
                store.dispatch('logout').then(() => {
                    router.push({ name: "Login" }).then(() => {
                        return Promise.reject(response)
                    })
                })
            }
        }

        return Promise.reject(response)
    })
}
