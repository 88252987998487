import SponsorService from '@/services/sponsor'
import { processStatuses } from '@/utils'
import { cloneObject } from '@/utils/clone'
import FileService from '@/services/file'

const defaultSponsorsState = () => {
    return {
      // Sponsors Managements
      sponsors: {},
      originalSponsors: {},
      sponsorsStatus: processStatuses.IDLE,
      sponsorsError: null,
      sponsorsErrorList: [],
      // Sponsor Images Upload
      sponsorImagesStatus: {},
      sponsorImageUploadStatus: processStatuses.IDLE,
      // Filter
      filterSponsors: '',
      isDescending: false,
    }
}

export default {
  state: defaultSponsorsState(),
  getters: {
    // Admin Sponsors Management
    sponsors: state => {
      return Object.values(state.sponsors).sort(
          (a, b) => {
            // Get the type
            const hasFilter = state.filterSponsors.length > 0 ? state.filterSponsors : 'priority'
            // Check if it is empty string
            const isEmpty = a[hasFilter] === '' || b[hasFilter] === ''
            const isNewRecord = typeof a['id'] === 'string' || typeof b['id'] === 'string'
            // Check if it is string type
            const isString = typeof a[hasFilter] === 'string' || typeof b[hasFilter] === 'string'
            // Descending and ascending logic
            if (state.isDescending) {
              return (isEmpty || isNewRecord) ? -1 :
                  isString ? (b[hasFilter].toLowerCase()).localeCompare(
                      (a[hasFilter].toLowerCase())) : b[hasFilter] - a[hasFilter]
            } else {
              return (isEmpty || isNewRecord) ? -1 :
                  isString ? (a[hasFilter].toLowerCase()).localeCompare(
                      (b[hasFilter].toLowerCase())) : a[hasFilter] - b[hasFilter]
            }
          })
    },
    allEventSponsors: state => Object.values(state.sponsors),
    originalSponsors: state => Object.values(state.originalSponsors),
    sponsorsStatus: state => state.sponsorsStatus,
    sponsorsError: state => state.sponsorsError,
    sponsorsErrorList: state => state.sponsorsErrorList,
    isSponsorsUpdated: state => {
      if (Object.keys(state.sponsors).length !== Object.keys(state.originalSponsors).length) {
        return true
      }

      for (const sponsorId in state.sponsors) {
        if (!(sponsorId in state.originalSponsors)) {
          return true
        }
        const fieldsToCheck = ['url', 'image', 'priority']
        if (fieldsToCheck.some(field => state.sponsors[sponsorId][field] !== state.originalSponsors[sponsorId][field])) {
          return true
        }
      }

      return false
    },
    getModifiedSponsors: state => {
      if (state.sponsorsStatus === processStatuses.IDLE) {
        return { newSponsors: [], updatedSponsors: [], deletedSponsorIds: [] }
      }

      // Group sponsors into New and Existing items depends on the ID
      // e.g. New record will be assigned a UUID instead of an integer ID
      const { newSponsors, existingSponsors } = Object.values(state.sponsors).reduce(({ newSponsors, existingSponsors }, sponsor) => {
        if (Number.isInteger(sponsor.id)) {
          existingSponsors[sponsor.id] = sponsor
        } else {
          newSponsors[sponsor.id] = sponsor
        }
        return { newSponsors, existingSponsors }
      }, { newSponsors: {}, existingSponsors: {} })

      // Check if any fields got updated from the existing sponsors
      const updatedSponsors = {}
      const fieldsToCheck = ['url', 'description', 'image']
      for (const sponsorId in existingSponsors) {
        if (fieldsToCheck.some(field => existingSponsors[sponsorId][field] !== state.originalSponsors[sponsorId][field])) {
            updatedSponsors[sponsorId] = existingSponsors[sponsorId]
        }
      }

      // check deleted sponsors
      const originalSponsorIds = Object.keys(state.originalSponsors)
      const deletedSponsorsIds = originalSponsorIds.filter(id => !(id in state.sponsors))

      return { newSponsors: Object.values(newSponsors), updatedSponsors: Object.values(updatedSponsors), deletedSponsorIds: deletedSponsorsIds }
    },
    sponsorImageUploadStatus: state => state.sponsorImageUploadStatus,
    // Filter
    filterSponsors: state => state.filterSponsors,
  },
  mutations: {
    SPONSORS_LOADING(state) {
      state.sponsorsStatus = processStatuses.LOADING
    },
    SPONSORS_LOADED(state, sponsors) {
      state.sponsors = sponsors.reduce((acc, sponsor) => {
        acc[sponsor.id] = sponsor
        return acc
      }, {})
      state.originalSponsors = cloneObject(state.sponsors)
      state.sponsorsStatus = processStatuses.LOADED
    },
    SPONSORS_LOAD_ERROR(state, err) {
      state.sponsorsError = err
      state.sponsorsStatus = processStatuses.ERROR
    },
    SPONSORS_SAVING(state) {
        state.sponsorsStatus = processStatuses.SAVING
    },
    ADD_SPONSOR(state, newSponsor) {
      state.sponsors[newSponsor.id] = newSponsor
    },
    REMOVE_SPONSOR(state, id) {
      delete state.sponsors[id]
    },
    UPDATE_SPONSOR(state, { id, field, value }) {
      if (id in state.sponsors) {
        state.sponsors[id][field] = value
      }
    },
    SPONSOR_IMAGE_UPLOADING(state, sponsorId) {
      state.sponsorImagesStatus[sponsorId] = processStatuses.LOADING
    },
    SPONSOR_IMAGE_UPLOADED(state, { sponsorId, link }) {
      state.sponsors[sponsorId].image = link
      state.sponsorImagesStatus[sponsorId] = processStatuses.LOADED
    },
    SPONSOR_IMAGE_UPLOAD_ERROR(state, { sponsorId, err }) {
      state.sponsorImagesStatus[sponsorId] = processStatuses.ERROR
      console.debug(err)
    },
    SPONSOR_IMAGES_UPLOADING(state) {
      state.sponsorImageUploadStatus = processStatuses.SAVING
    },
    SPONSOR_IMAGES_UPLOADED(state) {
      state.sponsorImageUploadStatus = processStatuses.LOADED
    },
    SET_FILTER_TYPE (state, filter) {
      state.filterSponsors = filter
    },
    TOGGLE_SPONSORS_DESC(state) {
      state.isDescending = !state.isDescending
    },
    RESET_SPONSORS_STATE(state) {
      Object.assign(state, defaultSponsorsState())
    },
    SET_SPONSORS_ERROR_LIST(state, errorList) {
      state.sponsorsErrorList = errorList
    }
  },
  actions: {
    // Admin Sponsor Management
    fetchEventSponsors({commit, rootGetters}) {
      // Fetch Event Categories only if the event builder is loaded
      // i.e. User land on the event builder page.
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      commit('SPONSORS_LOADING')
      return new Promise((resolve, reject) => {
        SponsorService.getSponsors(rootGetters.ebEventId).then((response) => {
          commit('SPONSORS_LOADED', response.data.sponsors)
          resolve()
        }).catch(err => {
          commit('SPONSORS_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    addEventSponsor({ commit }, newSponsor) {
      commit('ADD_SPONSOR', newSponsor)
    },
    removeEventSponsor({ commit }, id) {
      commit('REMOVE_SPONSOR', id)
    },
    updateEventSponsor({ commit }, { id, field, value }) {
      commit('UPDATE_SPONSOR', { id, field, value })
    },
    async uploadSponsorImages({ commit, getters, rootGetters }) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      const sponsorsToUpload = getters.sponsors.filter(m => m.image && m.image instanceof File)

      if (sponsorsToUpload.length > 0) {
        commit('SPONSOR_IMAGES_UPLOADING')
        await Promise.all(sponsorsToUpload.map(sponsor => {
          commit('SPONSOR_IMAGE_UPLOADING', sponsor.id)
          return new Promise((resolve, reject) => {
            FileService.upload({
              file: sponsor.image,
              directory: `selfserve/${rootGetters.ebEventId}/sponsors`
            }).then(response => {
              commit('SPONSOR_IMAGE_UPLOADED', { sponsorId: sponsor.id, link: response.data.link })
              resolve()
            }).catch(err => {
              commit('SPONSOR_IMAGE_UPLOAD_ERROR', { sponsorId: sponsor.id, err })
              reject(err)
            })
          })
        }))
        commit('SPONSOR_IMAGES_UPLOADED')
      }
    },
    async saveEventSponsors({commit, getters, rootGetters }) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      const { newSponsors, updatedSponsors, deletedSponsorIds } = getters.getModifiedSponsors

      commit('SPONSORS_SAVING')
      if (deletedSponsorIds.length > 0) {
        try {
          await SponsorService.deleteSponsors(rootGetters.ebEventId, deletedSponsorIds)
        } catch (err) {
          commit('SPONSORS_LOAD_ERROR', err)
          throw err
        }
      }

      if (updatedSponsors.length > 0) {
        const updateTasks = updatedSponsors.map(sponsor => {
          return SponsorService.updateSponsor(sponsor.id, {
            name: sponsor.name,
            image: sponsor.image,
            priority: sponsor.priority,
            url: sponsor.url
          })
        })

        try {
            await Promise.all(updateTasks)
        } catch (err) {
          commit('SPONSORS_LOAD_ERROR', err)
          throw err
        }
      }

      if (newSponsors.length > 0) {
        // Bulk creation will return the full list of sponsors for the current event
        const newSponsorsToCreate = newSponsors.map(sponsor => {
            return {
              name: sponsor.name,
              image: sponsor.image,
              priority: sponsor.priority,
              url: sponsor.url
            }
        })
        try {
          const response = await SponsorService.createSponsors(rootGetters.ebEventId, newSponsorsToCreate)
          commit('SPONSORS_LOADED', response.data.sponsors)
        } catch (err) {
          commit('SPONSORS_LOAD_ERROR', err)
          throw err
        }
      } else {
        try {
          // Fetch again to get the latest sponsors
            const response = await SponsorService.getSponsors(rootGetters.ebEventId)
            commit('SPONSORS_LOADED', response.data.sponsors)
        } catch (err) {
            commit('SPONSORS_LOAD_ERROR', err)
            throw err
        }
      }

    },
    resetSponsorsState({ commit }) {
      commit('RESET_SPONSORS_STATE')
    },
    setSponsorFilterBy({ commit }, filter) {
      commit('SET_FILTER_TYPE', filter)
    },
    toggleSponsorsDesc({ commit }) {
      commit('TOGGLE_SPONSORS_DESC')
    },
    setSponsorsErrorList({commit}, errorList) {
      commit('SET_SPONSORS_ERROR_LIST', errorList)
    }
  }
}
