import OrganizationService from "@/services/organization"
import { processStatuses } from '@/utils'
import { eventPlans, eventUpgradeMapping } from '@/utils/eventPlans'
import { organizationFeatureCodes } from '@/utils/organizationFeatures'

export default {
    state: () => ({
        // Organization Management: change name, email, logo, subscription, etc.
        newOrganization: {},
        newOrganizationError: {},
        organizationName: '',
        organizationStatus: processStatuses.IDLE,
        organizationError: null,
        organizationSubscription: {},
        remainingEvents: eventPlans.EventPlans.reduce((acc, plan) => {
            if (plan !== eventPlans.FREE) {
                acc[plan] = 0
            }
            return acc
        }, {}),
        organizationFeatures: [],
    }),
    getters: {
        newOrganization: state => state.newOrganization,
        newOrganizationError: state => state.newOrganizationError,
        organizationName: state => state.organizationName,
        getOrganizationStatus: state => state.organizationStatus,
        getOrganizationSubscription: state => state.organizationSubscription,
        getRemainingEvents: state => planName => { return state.remainingEvents[planName] }, // It returns remaining events count of given plan name
        getAllRemainingPaidEvents: state => state.remainingEvents,
        getAllRemainingUpgradableEvents: state => currentPlan => {
            const upgradablePlans = eventUpgradeMapping[currentPlan]
            const upgradeableRemaining = {}
            for (const [planName, remaining] of Object.entries(state.remainingEvents)) {
                if (upgradablePlans.includes(planName)) {
                    upgradeableRemaining[planName] = remaining
                }
            }
            return upgradeableRemaining
        },
        hasRemainingPaidEvents: state => currentPlan => {
            switch (currentPlan) {
                case eventPlans.STARTER:
                    return state.remainingEvents[eventPlans.ESSENTIALS] && state.remainingEvents[eventPlans.ESSENTIALS] > 0
                case eventPlans.ESSENTIALS:
                    return state.remainingEvents[eventPlans.PROFESSIONAL] && state.remainingEvents[eventPlans.PROFESSIONAL] > 0
                case eventPlans.PROFESSIONAL:
                    return false;
                case eventPlans.FREE:
                    return Object.values(state.remainingEvents).some(m => m > 0)
            }

            return false
        },
        hasRemainingPremiumEvents: state => currentPlan => {
            if (currentPlan !== eventPlans.ESSENTIALS) {
                return state.remainingEvents[eventPlans.ESSENTIALS] && state.remainingEvents[eventPlans.ESSENTIALS] > 0
            }
            return false
        },
        hasRemainingProEvents: state => currentPlan => {
            if (currentPlan !== eventPlans.PROFESSIONAL) {
                return state.remainingEvents[eventPlans.PROFESSIONAL] && state.remainingEvents[eventPlans.PROFESSIONAL] > 0
            }
            return false
        },
        hasRemainingEvents: state => {
            return Object.values(state.remainingEvents).some(m => m > 0)
        },
        hasSubscription: state => (planName) => {
            return state.organizationStatus === processStatuses.LOADED && state.newOrganization?.organizationSubscriptions?.some(m => m.plan?.name === planName)
        },
        getAvailableOrganizationSubscription: state => planName => {
            if (state.organizationStatus !== processStatuses.LOADED) {
                return null
            }
            return state.newOrganization?.organizationSubscriptions?.find(m => {
                return m.plan?.name === planName && m.planSubscriptionDetails?.length === 0
            })
        },
        getOrganizationFeatures: state => state.organizationFeatures,
        getOrganizationFeature: state => (featureName) => {
            if (!organizationFeatureCodes.OrganizationFeatureCodes.includes(featureName)) {
                return null
            }
            if (state.organizationStatus === processStatuses.LOADED) {
                const organizationFeatures = state.organizationFeatures.filter(f => f.feature.featureGroup?.code === featureName)
                    .sort((a, b) => b.feature.priority - a.feature.priority)
                return organizationFeatures.length > 0 ? organizationFeatures[0].feature : null
            }
            return null
        }
    },
    mutations: {
        SET_ORGANIZATION_NAME (state, organizationName) {
            state.organizationName = organizationName
        },
        NEW_ORGANIZATION_UPDATE (state, organizationName) {
            state.organizationName = organizationName
            state.newOrganization = { name: organizationName }
            state.newOrganizationError = null
        },
        RESET_NEW_ORGANIZATION (state) {
            state.newOrganizationError = {}
        },
        CURRENT_ORGANIZATION (state, organization) {
            state.newOrganization = organization
            state.organizationFeatures = organization.organizationFeatures
            state.organizationStatus = processStatuses.LOADED
            state.newOrganizationError = null
        },
        ORGANIZATION_LOADING (state) {
            state.organizationStatus = processStatuses.LOADING
        },
        ORGANIZATION_ERROR (state, error) {
            state.organizationStatus = processStatuses.ERROR
            state.newOrganizationError = error
        },
        SET_ORGANIZATION_SUBSCRIPTION (state, subscription) {
            state.organizationSubscription = subscription
        },
        RESET_ORGANIZATION_SUBSCRIPTION (state) {
            state.organizationSubscription = {}
        },
        SET_REMAINING_EVENTS (state, {planName, remainingEvents}) {
            state.remainingEvents[planName] = remainingEvents
        },
        RESET_REMAINING_EVENTS (state) {
            state.remainingEvents = eventPlans.EventPlans.reduce((acc, plan) => {
                if (plan !== eventPlans.FREE) {
                    acc[plan] = 0
                }
                return acc
            }, {})
        }
    },
    actions: {
        setNewOrganization({ commit, getters }, { organizationName }) {
            return new Promise((resolve, reject) => {
                let hasError = false
                if (!organizationName || organizationName == '') {
                    hasError = true
                }

                if (!hasError) {
                    if (organizationName === getters.newOrganization?.name) {
                        commit('SET_ORGANIZATION_NAME', organizationName)
                    } else {
                        commit('NEW_ORGANIZATION_UPDATE', organizationName)
                    }
                    resolve()
                } else {
                    reject()
                }
            })
        },
        fetchOrganization({ commit, getters, rootGetters }) {
            const activeOrganization = rootGetters.getActiveOrganization
            if (rootGetters.getUserOrganizationStatus !== processStatuses.LOADED && activeOrganization) {
                console.debug('User organization is not loaded or active organization is not set, skipping organization load')
                return
            }
            if (getters.getOrganizationStatus === processStatuses.LOADING) {
                console.debug('Organization is currently loading, skipping organization load')
                return
            }
            commit('ORGANIZATION_LOADING')
            return new Promise((resolve, reject) => {
                OrganizationService.getOrganization({ idOrSlug: activeOrganization.id }).then((response) => {
                    response.data.organization.organizationSubscriptions.find((subscription) => {
                        commit('SET_TOP_SUBSCRIPTION_NAME', subscription.plan.name)
                    })
                    commit('CURRENT_ORGANIZATION', response.data.organization)
                    resolve(response.data.organization)
                }).catch((err) => {
                    commit('ORGANIZATION_ERROR', err)
                    commit('NEW_EVENT_ERROR', { key: 'server', value: err.data.message })
                    reject(err)
                })
            })
        },
        updateOrganization({ commit, state }, { id, slug, userId, updateMask }) {
            commit('NEW_EVENT_SAVING')
            return new Promise((resolve, reject) => {
                OrganizationService.updateOrganization({
                    organizationId: id,
                    organizationName: state.organizationName,
                    slug: slug,
                    userId: userId
                }, updateMask).then((response) => {
                    commit('CURRENT_ORGANIZATION', response.data.organization)
                    resolve(response.data.organization)
                }).catch((err) => {
                    commit('NEW_EVENT_ERROR', { key: 'server', value: err.data.message })
                    reject(err)
                })
            })
        },
        resetNewOrganization({ commit }) {
            commit('RESET_NEW_ORGANIZATION')
        },
        resetRemainingEvents({ commit }) {
            commit('RESET_REMAINING_EVENTS')
        },
        getOrganizationSubscription({ commit }, organizationId ) {
            commit('RESET_ORGANIZATION_SUBSCRIPTION')
            return new Promise((resolve, reject) => {
                OrganizationService.getOrganizationSubscription({ organizationId }).then((response) => {
                    commit('SET_ORGANIZATION_SUBSCRIPTION', response.data)
                    resolve(response.data)
                }).catch((err) => {
                    commit('ORGANIZATION_ERROR', err)
                    reject(err)
                })
            })
        },
        setRemainingEvents({ commit, getters }) {
            if (getters.newOrganization) {
                const organizationCapacity = getters.newOrganization?.capacity
                for (const [plan, capacity] of Object.entries(organizationCapacity)) {
                    const numberOfEvents = getters.getNumberOfSubscriptionsByPlanName(plan)
                    commit('SET_REMAINING_EVENTS', {planName: plan, remainingEvents: Math.max(capacity - numberOfEvents, 0)})
                }
            }
        }
    }
}
