import VersionService from "@/services/version"

export default {
    state:() => ({
        version: null,
        versionError: null
    }),
    getters: {
        version: state => state.version
    },
    mutations: {
        VERSION_LOADED(state, payload) {
            state.version = payload
        },
        VERSION_LOAD_ERROR(state, error) {
            state.version = null
            state.versionError = error
        }
    },
    actions: {
        fetchVersion({ commit, getters }) {
            if (getters.isLoggedIn && getters.isStaff) {
                return new Promise((resolve, reject) => {
                    VersionService.getVersion().then(response => {
                        commit('VERSION_LOADED', response.data)
                        resolve()
                    }).catch(err => {
                        commit('VERSION_LOAD_ERROR', err)
                        reject()
                    })
                })
            }
        }
    }
}
