<script setup>
import {computed, onMounted, nextTick} from 'vue'
import store from '@/store'
import feVersion from '@/version/version.json'

import { Version } from 'version'
import 'version/dist/style.css'

const backendVersion = computed(() => {
  return store.getters.version
})

const frontendVersion = computed(() => {
  return {
    "FrontendSelfServe": [{
      version: feVersion,
    }]
  }
})

onMounted(() => {
  store.dispatch('fetchVersion')
  console.log("feVersion", frontendVersion)
  nextTick(() => {
    console.log("beVersion", backendVersion)
  })
})
</script>

<template>
    <version :backend-version="backendVersion" :frontend-version="frontendVersion" class="px-6 py-4" />
</template>

