// import axios from 'axios'
import ProjectService from "@/services/project";
import EventTemplateService from "@/services/event-template";
import { processStatuses } from "@/utils"
export default {
  state: () => ({
    // Master Template list view For user to select / clone template
    selectedMasterTemplateId: null,
    selectedMasterTemplate: {},
    masterTemplates: [],
    masterTemplatesError: {},
    masterTemplatesStatus: processStatuses.IDLE,
    // Existing / previously saved template
    template: {},
    templateStatus: processStatuses.IDLE,
    templateError: null,
    // Cloning Project States
    templateClonedProject: {},
    templateCloneStatus: processStatuses.IDLE,
    templateCloneError: null,
    // Save Cloned project as event template
    eventTemplate: {},
    eventTemplateSavingStatus: processStatuses.IDLE,
    eventTemplateSavingError: null,
    // Delete event template
    eventTemplateDeletingStatus: processStatuses.IDLE,
    eventTemplateDeletingError: null,
    // Update event template status to Completed or Draft
    eventTemplateUpdatingStatus: processStatuses.IDLE,
    eventTemplateUpdatingError: null
  }),
  getters: {
    selectedMasterTemplateId: state => state.selectedMasterTemplateId,
    masterTemplates: state => state.masterTemplates,
    selectedMasterTemplate: state => state.selectedMasterTemplate,
    masterTemplatesErrors: state => state.masterTemplatesError,
    masterTemplatesLoading: state => state.masterTemplatesStatus === processStatuses.LOADING,
    masterTemplatesStatus : state => state.masterTemplatesStatus,
    templateCloneStatus: state => state.templateCloneStatus,
    template: state => state.template,
    templateStatus: state => state.templateStatus,
    clonedProjectId: state => state.templateClonedProject.id,
    eventTemplateUpdatingStatus: state => state.eventTemplateUpdatingStatus
  },
  mutations: {
    SET_MASTER_TEMPLATE_ID (state, selected) {
      state.selectedMasterTemplateId = selected
    },
    SET_TEMPLATE (state, data) {
      state.template = data
    },
    MASTER_TEMPLATES_ERROR (state, error) {
      state.masterTemplatesStatus = processStatuses.ERROR
      state.masterTemplatesError = error
    },
    MASTER_TEMPLATES_LOADING (state) {
      state.masterTemplatesStatus = processStatuses.LOADING
    },
    MASTER_TEMPLATES_LOADED (state, templates) {
      state.masterTemplatesStatus = processStatuses.LOADED
      state.masterTemplates = templates
    },
    TEMPLATE_LOADING (state) {
      state.templateStatus = processStatuses.LOADING
    },
    TEMPLATE_LOADED (state, template) {
      state.templateStatus = processStatuses.LOADED
      state.template = template
      state.templateError = null
    },
    TEMPLATE_ERROR (state, err) {
      state.templateStatus = processStatuses.ERROR
      state.templateError = err
    },
    TEMPLATE_CLONING (state) {
      state.templateCloneStatus = processStatuses.LOADING
      state.templateCloneError = null
    },
    TEMPLATE_CLONED (state, project) {
      state.templateClonedProject = project
      state.templateCloneStatus = processStatuses.LOADED
      state.templateCloneError = null
    },
    TEMPLATE_CLONE_ERROR (state, error) {
      state.templateCloneError = error
      state.templateCloneStatus = processStatuses.ERROR
    },
    EVENT_TEMPLATE_SAVING (state) {
      state.eventTemplateSavingStatus = processStatuses.LOADING
      state.eventTemplateSavingError = null
    },
    EVENT_TEMPLATE_SAVED (state, eventTemplate) {
      state.eventTemplate = eventTemplate
      state.eventTemplateSavingStatus = processStatuses.LOADED
      state.eventTemplateSavingError = null
    },
    EVENT_TEMPLATE_SAVE_ERROR (state, error) {
      state.eventTemplateSavingStatus = processStatuses.ERROR
      state.eventTemplateSavingError = error
    },
    EVENT_TEMPLATE_DELETING (state) {
      state.eventTemplateDeletingStatus = processStatuses.LOADING
    },
    EVENT_TEMPLATE_DELETED (state) {
      state.eventTemplateDeletingStatus = processStatuses.LOADED
      state.templateCloneStatus = processStatuses.IDLE
      state.eventTemplate = null
    },
    EVENT_TEMPLATE_DELETE_ERROR (state, err) {
      state.eventTemplateDeletingStatus = processStatuses.ERROR
      state.eventTemplateDeletingError = err
    },
    EVENT_TEMPLATE_UPDATING (state) {
      state.eventTemplateUpdatingStatus = processStatuses.SAVING
    },
    EVENT_TEMPLATE_UPDATED (state, eventTemplate) {
      state.eventTemplateUpdatingStatus = processStatuses.LOADED
      state.eventTemplate = eventTemplate
      state.eventTemplateUpdatingError = null
    },
    EVENT_TEMPLATE_UPDATE_ERROR (state, err) {
      state.eventTemplateUpdatingStatus = processStatuses.ERROR
      state.eventTemplateUpdatingError = err
    }

  },
  actions: {
    setSelectedMasterTemplateID({ commit }, selected) {
      commit('SET_MASTER_TEMPLATE_ID', selected)
    },
    setTemplate({commit}, template) {
      commit('SET_TEMPLATE', template)
    },
    getTemplate({ commit }, templateId) {
      commit('TEMPLATE_LOADING')
      return new Promise((resolve, reject) => {
        ProjectService
          .getProject({
            templateID: templateId,
            include: '',
            template: true
          })
          .then((response) => {
            commit('TEMPLATE_LOADED', response.data.project)
            resolve()
          })
          .catch((err) => {
            console.error(err)
            commit('TEMPLATE_ERROR', err)
            reject(err)
          })
      })
    },
    getMasterTemplates({ commit }) {
      commit('MASTER_TEMPLATES_LOADING')
      return new Promise((resolve, reject) => {
        ProjectService
          .getProjects({
            pageSize: 20,
            nextPageToken: 0,
            include: '',
            isTemplate: true
          })
          .then((response) => {
            commit('MASTER_TEMPLATES_LOADED', response.data.projects)
            resolve()
          })
          .catch((err) => {
            console.error(err)
            commit('MASTER_TEMPLATES_ERROR', err)
            reject(err)
          })
      })
    },
    saveClonedTemplateAsEventTemplate({ commit, getters, rootGetters }) {
      commit('EVENT_TEMPLATE_SAVING')
      return new Promise((resolve, reject) => {
        EventTemplateService.createEventTemplate({ status: "Draft", projectId: getters.clonedProjectId, eventId: rootGetters.ebEventId })
            .then((response) => {
              commit('EVENT_TEMPLATE_SAVED', response.data)
              resolve()
            })
            .catch((err) => {
              console.error(err)
              commit('EVENT_TEMPLATE_SAVE_ERROR', err)
              reject(err)
            })

      })
    },
    cloneTemplate ({commit}, { projectId, eventId }) {
      commit('TEMPLATE_CLONING', true)
      return new Promise((resolve, reject) => {
        ProjectService.cloneProject({ projectId: projectId, eventId: eventId, isTemplate: true }).then((response) => {
          commit('TEMPLATE_CLONED', response.data.project)
          resolve(response.data.project)
        }).catch((err) => {
          console.error(err)
          commit('TEMPLATE_CLONE_ERROR', err)
          reject(err)
        })
      })
    },
    deleteEventTemplate ({commit}, eventTemplateId) {
      commit('EVENT_TEMPLATE_DELETING')
      return new Promise((resolve, reject) => {
        EventTemplateService.deleteEventTemplate({ id: eventTemplateId }).then(() => {
          commit('EVENT_TEMPLATE_DELETED')
          resolve()
        }).catch((err) => {
          console.error(err)
          commit('EVENT_TEMPLATE_DELETE_ERROR', err)
          reject(err)
        })
      })
    },
    updateEventTemplate ({commit}, { eventTemplateId, status }) {
      commit('EVENT_TEMPLATE_UPDATING')
      return new Promise((resolve, reject) => {
        EventTemplateService.updateEventTemplate({ id: eventTemplateId, status: status }).then((response) => {
          commit('EVENT_TEMPLATE_UPDATED', response.data)
          resolve(response.data)
        }).catch((err) => {
          console.error(err)
          commit('EVENT_TEMPLATE_UPDATE_ERROR', err)
          reject(err)
        })
      })
    }
  }
}
