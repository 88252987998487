import axios from 'axios'
import { config } from '@/config'

class UserService {
  getUser({ username, include }) {
    return axios.get(`${config.API_URL}/user`, {
      params: {
        userName: username,
        include: include
      }
    })
  }

  getUsers({ roles, eventId }, include = 'organization') {
    return axios.get(`${config.API_URL}/users`, {
        params: {
          pageSize: 50,
          nextPageToken: 0,
          roles: roles,
          include: include,
          eventId: eventId
        }
    })
  }
}

export default new UserService()
