import CategoryService from '@/services/category'
import FileService from '@/services/file'
import { processStatuses } from '@/utils'
import { cloneObject } from '@/utils/clone'

const defaultCategoryState = () => {
    return {
      categories: {},
      originalCategories: {},
      categoriesStatus: processStatuses.IDLE,
      categoryImagesStatus: {},
      categoryUploadStatus: processStatuses.IDLE,
      categoriesError: null,
      categoriesErrorList: [],
      categoryLibrary: [],
      categoryLibraryStatus: processStatuses.IDLE,
      categoryLibraryError: null,
      selectedCategoryFromLibrary: {},
      filterCategories: 'name',
      isDescending: false
    }
}

export default {
  state: defaultCategoryState(),
  getters: {
    categories: state => {
      return Object.values(state.categories).sort(
          (a, b) => {
            // Get the type
            const hasFilter = state.filterCategories.length > 0 ? state.filterCategories : 'priority'
            // Check if it is empty string
            const isEmpty = a[hasFilter] === '' || b[hasFilter] === ''
            const isNewRecord = typeof a['id'] === 'string' || typeof b['id'] === 'string'
            // Check if it is string type
            const isString = typeof a[hasFilter] === 'string' || typeof b[hasFilter] === 'string'
            // Descending and ascending logic
            if (state.isDescending) {
              return (isEmpty || isNewRecord) ? -1 :
                  isString ? (b[hasFilter].toLowerCase()).localeCompare(
                      (a[hasFilter].toLowerCase())) : b[hasFilter] - a[hasFilter]
            } else {
              return (isEmpty || isNewRecord) ? -1 :
                  isString ? (a[hasFilter].toLowerCase()).localeCompare(
                      (b[hasFilter].toLowerCase())) : a[hasFilter] - b[hasFilter]
            }
        })
    },
    categoriesErrorList: state => {
      return state.categoriesErrorList
    },
    filterCategories: state => {
      return state.filterCategories
    },
    originalCategories: state => {
      if (state.categoriesStatus !== processStatuses.LOADED) {
        return []
      }
      return Object.values(state.originalCategories)
    },
    categoriesStatus: state => state.categoriesStatus,
    categoriesUploadStatus: state => state.categoryUploadStatus,
    categoryLibrary: state => state.categoryLibrary,
    selectedCategoryFromLibrary: state => state.selectedCategoryFromLibrary,
    categoryImagesStatus: state => (categoryId) => {
      if (categoryId in state.categoryImagesStatus) {
        return state.categoryImagesStatus[categoryId]
      }
      return null
    },
    isCategoriesPageReadOnly: (state, getters, rootState, rootGetters) => {
      // Categories page is read-only if categories are being saved
      if (getters.categoriesUploadStatus === processStatuses.SAVING || getters.categoriesStatus === processStatuses.SAVING) {
        return true
      }

      // If the user is not a PB staff, always allow editing
      if (rootGetters.isStaff) {
        return false
      }

      // If the event is published for non PB staffs
      return rootGetters.eventSettings.status === 'Published'
    },
    isCategoriesUpdated: state => {
      if (Object.keys(state.categories).length !== Object.keys(state.originalCategories).length) {
        return true
      }

      for (const category_id in state.categories) {
        if (!(category_id in state.originalCategories)) {
          return true
        }
        const fieldsToCheck = ['name', 'image', 'priority', 'color']
        if (fieldsToCheck.some(field => state.categories[category_id][field] !== state.originalCategories[category_id][field])) {
          return true
        }
      }

      return false
    },
    getModifiedCategories: state => {
      if (state.categoriesStatus === processStatuses.IDLE) {
          return { newCategories: [], updatedCategories: [], deletedCategoryIds: [] }
      }

      // Group categories into New and Existing items depends on the ID
      // e.g. New record will be assigned a UUID instead of an integer ID
      const { newCategories, existingCategories } = Object.values(state.categories).reduce(({ newCategories, existingCategories }, category) => {
        if (Number.isInteger(category.id)) {
          existingCategories[category.id] = category
        } else {
          newCategories[category.id] = category
        }

        return { newCategories, existingCategories }
      }, { newCategories: {}, existingCategories: {} })

      // Check if any fields got updated from the existing categories
      const updatedCategories = {}
      const fieldsToCheck = ['name', 'image', 'priority', 'color']
      for (const categoryId in existingCategories) {
        if (fieldsToCheck.some(field => existingCategories[categoryId][field] !== state.originalCategories[categoryId][field])) {
          updatedCategories[categoryId] = existingCategories[categoryId]
        }
      }

      // Check deleted categories
      const originalCategoryIds = Object.keys(state.originalCategories)
      const deletedCategoryIds = originalCategoryIds.filter(id => !(id in state.categories))

      return { newCategories: Object.values(newCategories), updatedCategories: Object.values(updatedCategories), deletedCategoryIds }
    },
    numberOfSelectedCategoriesFromLibrary: state => (parentCategoryId) => {
      if (parentCategoryId) {
        return Object.values(state.selectedCategoryFromLibrary).filter(category => category.parentId === parentCategoryId).length
      }
      return Object.values(state.selectedCategoryFromLibrary).length

    }
  },
  mutations: {
    // Loading Categories of user
    CATEGORIES_LOADED(state, categories) {
      // convert array to object
      const categoriesMap = categories.reduce((obj, item) => {
          obj[item.id] = item
          return obj
      }, {})

      state.categories = categoriesMap
      state.originalCategories = cloneObject(categoriesMap)
      state.categoriesStatus = processStatuses.LOADED
    },
    CATEGORIES_LOADING(state) {
      state.categoriesStatus = processStatuses.LOADING
    },
    CATEGORIES_SAVING(state) {
      state.categoriesStatus = processStatuses.SAVING
    },
    CATEGORIES_SAVED(state) {
      state.originalCategories = cloneObject(state.categories)
      state.categoriesStatus = processStatuses.LOADED
    },
    CATEGORIES_LOAD_ERROR(state, error) {
      state.categoriesError = error
      state.categoriesStatus = processStatuses.ERROR
    },
    ADD_CATEGORY (state, newCategory) {
      state.categories[newCategory.id] = newCategory
    },
    REMOVE_CATEGORY (state, id) {
      delete state.categories[id]
    },
    UPDATE_CATEGORY (state, { id, field, value }) {
      if (id in state.categories) {
        state.categories[id][field] = value
      }
    },
    // Loading Category Library
    CATEGORY_LIBRARY_LOADED(state, categories) {
      state.categoryLibrary = categories
      state.categoryLibraryStatus = processStatuses.LOADED
    },
    CATEGORY_LIBRARY_LOADING(state) {
      state.categoryLibraryStatus = processStatuses.LOADING
    },
    CATEGORY_LIBRARY_LOAD_ERROR(state, error) {
      state.categoryLibraryError = error
      state.categoryLibraryStatus = processStatuses.ERROR
    },
    SELECT_CATEGORY_LIBRARY_ITEM(state, category) {
      state.selectedCategoryFromLibrary[category.id] = category
    },
    UNSELECT_CATEGORY_LIBRARY_ITEM(state, category) {
        delete state.selectedCategoryFromLibrary[category.id]
    },
    RESET_SELECTED_CATEGORY_LIBRARY_ITEM(state) {
        state.selectedCategoryFromLibrary = {}
    },
    CATEGORY_IMAGE_UPLOADING(state, categoryId) {
      state.categoryImagesStatus[categoryId] = processStatuses.SAVING
    },
    CATEGORY_IMAGE_UPLOADED(state, { categoryId, link}) {
      state.categoryImagesStatus[categoryId] = processStatuses.LOADED
      state.categories[categoryId].image = link
    },
    CATEGORY_IMAGE_UPLOAD_ERROR(state, { categoryId, error }) {
      state.categoryImagesStatus[categoryId] = processStatuses.ERROR
      console.debug(error)
    },
    CATEGORY_IMAGES_UPLOADING(state) {
      state.categoryUploadStatus = processStatuses.SAVING
    },
    CATEGORY_IMAGES_UPLOADED(state) {
      state.categoryUploadStatus = processStatuses.LOADED
    },
    RESET_CATEGORY_STATE(state) {
      Object.assign(state, defaultCategoryState())
    },
    SET_FILTER_TYPE (state, filter) {
      state.filterCategories = filter
    },
    TOGGLE_DESC (state) {
      state.isDescending = !state.isDescending
    },
    SET_CATEGORIES_ERROR_LIST (state, errorList) {
      state.categoriesErrorList = errorList
    }
  },
  actions: {
    fetchEventCategories({commit, rootGetters}) {
      // Fetch Event Categories only if the event builder is loaded
      // i.e. User land on the event builder page.
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      commit('CATEGORIES_LOADING')
      return new Promise((resolve, reject) => {
        CategoryService.getCategories(rootGetters.ebEventId).then((response) => {
          commit('CATEGORIES_LOADED', response.data.categories)
          resolve()
        }).catch(err => {
          commit('CATEGORIES_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    addEventCategory ({ commit }, newCategory) {
      commit('ADD_CATEGORY', newCategory)
    },
    removeEventCategory ({ commit }, id) {
      commit('REMOVE_CATEGORY', id)
    },
    updateEventCategory ({ commit }, { id, field, value }) {
      commit('UPDATE_CATEGORY', { id, field, value })
    },
    fetchCategoryLibrary({commit}) {
      commit('CATEGORY_LIBRARY_LOADING')
      return new Promise((resolve, reject) => {
        CategoryService.getCategories().then((response) => {
          commit('CATEGORY_LIBRARY_LOADED', response.data.categories)
          resolve()
        }).catch(err => {
          commit('CATEGORY_LIBRARY_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    selectCategoryFromLibrary({commit}, category) {
      commit('SELECT_CATEGORY_LIBRARY_ITEM', category)
    },
    unselectCategoryFromLibrary({commit}, category) {
      commit('UNSELECT_CATEGORY_LIBRARY_ITEM', category)
    },
    saveSelectedCategoriesFromLibrary({commit, getters, rootGetters}) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      const selectedCategories = Object.values(getters.selectedCategoryFromLibrary)
      if (!selectedCategories || selectedCategories.length === 0) {
        return
      }
      commit('CATEGORIES_LOADING')
      // Get primary Color
      const primaryColor = rootGetters.getEventTheme?.primary ?? "#8400f0"
      const newCategories = selectedCategories.map(category => {
        return {
          name: category.name,
          image: category.image,
          priority: category.priority,
          color: primaryColor
        }
      })
      return new Promise((resolve, reject) => {
        CategoryService.createCategories(rootGetters.ebEventId, newCategories).then((response) => {
          commit('CATEGORIES_LOADED', response.data.categories)
          commit('RESET_SELECTED_CATEGORY_LIBRARY_ITEM')
          resolve()
        }).catch(err => {
          commit('CATEGORIES_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    resetSelectedCategoriesFromLibrary({commit}) {
      commit('RESET_SELECTED_CATEGORY_LIBRARY_ITEM')
    },
    async uploadCategoryImages({commit, getters, rootGetters}) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      const categoryImagesToUpload = getters.categories.filter(m => m.image && m.image instanceof File)

      if (categoryImagesToUpload.length > 0) {
        commit('CATEGORY_IMAGES_UPLOADING')
        await Promise.all(categoryImagesToUpload.map(category => {
          commit('CATEGORY_IMAGE_UPLOADING', category.id)
          return new Promise((resolve, reject) => {
            FileService.upload({
              file: category.image,
              directory: `selfserve/${rootGetters.ebEventId}/categories`
            }).then((response) => {
              commit('CATEGORY_IMAGE_UPLOADED', { categoryId: category.id, link: response.data.link })
              resolve()
            }).catch(err => {
              commit('CATEGORY_IMAGE_UPLOAD_ERROR', { categoryId: category.id, error: err })
              reject(err)
            })
          })
        }))
        commit('CATEGORY_IMAGES_UPLOADED')
      }
    },
    async saveCategories({commit, getters, rootGetters}) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      const { newCategories, updatedCategories, deletedCategoryIds } = getters.getModifiedCategories
      if (newCategories.length === 0 && updatedCategories.length === 0 && deletedCategoryIds.length === 0) {
        return
      }

      commit('CATEGORIES_SAVING')
      if (updatedCategories.length > 0) {
        const updateTasks = updatedCategories.map(category => {
          return CategoryService.updateCategory(category.id, category)
        })

        try {
          await Promise.all(updateTasks)
        } catch (err) {
          commit('CATEGORIES_LOAD_ERROR', err)
          throw err
        }
      }

      if (deletedCategoryIds.length > 0) {
        try {
          await CategoryService.deleteCategories(rootGetters.ebEventId, deletedCategoryIds)
        } catch (err) {
            commit('CATEGORIES_LOAD_ERROR', err)
            throw err
        }
      }

      if (newCategories.length > 0) {
        // Bulk creation will return the full list of categories
        const newCategoriesToCreate = newCategories.map(category => {
            return {
                name: category.name,
                image: category.image,
                priority: category.priority,
                color: category.color
            }
        })
        try {
          const response = await CategoryService.createCategories(rootGetters.ebEventId, newCategoriesToCreate)
          commit('CATEGORIES_LOADED', response.data.categories)
        } catch (err) {
          commit('CATEGORIES_LOAD_ERROR', err)
          throw err
        }
      } else {
        try {
          // Fetch again to get the latest categories
          const response = await CategoryService.getCategories(rootGetters.ebEventId)
          commit('CATEGORIES_LOADED', response.data.categories)
        } catch (err) {
          commit('CATEGORIES_LOAD_ERROR', err)
          throw err
        }
      }
    },
    resetCategoryState({commit}) {
      commit('RESET_CATEGORY_STATE')
    },
    setFilterType({commit}, filter) {
      commit('SET_FILTER_TYPE', filter)
    },
    toggleDesc({commit}) {
      commit('TOGGLE_DESC')
    },
    setCategoriesErrorList({commit}, errorList) {
      commit('SET_CATEGORIES_ERROR_LIST', errorList)
    }
  }
}
