import router from '@/router'
import store from '@/store'

export const redirectToRoute = (routeName) => {
  return router.push({ name: routeName})
}

export const handleBackButton = () =>  {
  window.onpopstate = function() {
    store.dispatch('ResetModal')
  }
}
