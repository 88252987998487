<script setup>
// Required Packages
import { computed, watch, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
// Store
import store from '@/store'
// Base Components
import Loader from '@/components/Base/Loader'
import BaseButton from '@/components/Base/Button/BaseButton'
// Config
import { config } from '@/config'
// Utils
import {
  processStatuses,
  eventStatuses,
  allSectionsCompleted
} from '@/utils'
import day from 'dayjs'
import utc from 'dayjs/plugin/utc'

day.extend(utc)

const route = useRoute()
let copied = ref(false)


const isEventPublished = computed(() => {
  return event.value.eventSettings?.status === eventStatuses.LIVE
})

const isPreviewLinkReady = computed(() => {
  return store.getters.ebEventStatus === processStatuses.LOADED && store.getters.getEventPreviewStatus === processStatuses.LOADED
})

const isPreview = computed (() => {
  return store.getters.timelinePreview
})

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const eventLoadingStatus = computed(() => {
  return store.getters.ebEventStatus
})

// Use scoped slots to pass event Status down to the template
const eventStatus = computed(() => {
  // Get event status from store
  const status = event.value.eventSettings ? event.value.eventSettings.status : 'Draft'
  switch (status) {
  case "Published":
    return "Live"
  default:
    return status
  }
})

const eventPublishStatus = computed(() => {
  return store.getters.getEventPublishStatus
})

const completionValue = computed(() => {
  return store.getters.getConfigCompletion
})

const isGolive = computed(() => {
  return allSectionsCompleted(completionValue.value) && (!isEventPublished.value || eventPublishStatus.value === processStatuses.SAVING)
})

const previewUrl = computed(() => {
  return store.getters.getEventPreviewUrl
})

const publishEvent = () => {
  store.dispatch('ToggleModalEventLaunch')
}

const copyToClipboard = (code) => {
  navigator.clipboard.writeText(code)
  copied.value = true
}

const isShowcaseBuilder = computed(() => {
  return route.name !== 'Participants'
})

const formatEBNames = (name) => {
  switch (name) {
  case 'Branding':
    return 'Design & Branding'
  case 'ProjectTemplates':
    return 'Project Templates'
  case 'CreateAwards':
    return 'Create Awards'
  case 'GiveAwards':
    return 'Give Awards'
  default:
    return name
  }
}

onMounted(() => {
  if (store.getters.getEventPreviewStatus === processStatuses.IDLE) {
    // Generate preview token etc..
    store.dispatch('previewEvent')
  }
})

watch(() => store.getters.ebEventStatus, (newValue) => {
  if (newValue === processStatuses.LOADED) {
    // Generate preview token etc..
    store.dispatch('previewEvent')
  }
})
</script>

<template>
  <div class="nav">
    <span class="nav-builder">
      <span class="nav-builder_text">
        <slot name="event-name">
          <!-- Default Name if slots not provided -->
          <span class="nav-builder_text-mobile">
            {{event.name}} /
          </span>
          <span v-show="isShowcaseBuilder"
                class="nav-builder_showcase">
            Showcase Builder /
          </span>
          <span class="nav-builder_ebtext">
            {{ formatEBNames(route.name) }}
          </span>
        </slot>
      </span>
      <span class="nav-builder-options">
        <slot
          :disabled="!isPreview"
          name="event-preview-button"
        >
          <base-button v-if="isEventPublished"
                       id="shareUrl"
                       name="shareUrl"
                       aria-label="shareUrl"
                       :functions="() => copyToClipboard(`${config.EVENT_URL}/${event.slug}`)"
                       btn-type="text"
                       text="Share Url">
             <template #contents>
                <span class="nav-builder-copy">
                  <i v-if="!copied" class="fa-sm fa-regular fa-copy"></i><span v-if="!copied"> Share Url</span>
                  <i v-if="copied" class="fa-regular fa-check"></i><span v-if="copied"> Copied</span>
                </span>
              </template>
          </base-button>
          <!-- Default Preview button if no content is provided -->
          <a class="nav-builder-options_active"
             target="_blank"
             :class="!isPreview ? 'nav-builder-options_notActive': ''"
             :href="isEventPublished ? `${config.EVENT_URL}/${event.slug}` : previewUrl">
            <span class="font-medium text-h5 flex flex-row items-center justify-center">
              <span class="mr-1 mt-0.5">
                <loader v-if="!isPreviewLinkReady" />
                <i v-else
                   class="fa-light fa-eye fa-xs"
                ></i>
              </span> {{isEventPublished ? 'View' : 'Preview'}} Event
            </span>
          </a>
        </slot>
        <slot name="event-status"
              v-bind="{ eventStatus: eventStatus }">
          <!-- Default Status if no content is provided -->
          <div v-if="eventLoadingStatus === processStatuses.LOADED"
              class="nav-builder-options_hidden"
               :class="`nav-builder-options_notActive status-${eventStatus}`">
            <div class="mr-1">
              <i class="fa-solid fa-circle fa-xs" />
            </div>
            {{eventStatus}}
          </div>
        </slot>
        <span>
          <slot v-if="!isEventPublished"
                name="event-publish-button">
            <!-- Default Publish button if no content is provided -->
            <base-button
              id="published"
              name="published"
              btn-type="success"
              class-name="nav-button-live"
              aria-label="published"
              :functions="publishEvent"
              :disabled="!isGolive"
              :is-loading="eventPublishStatus === processStatuses.SAVING"
              text="Launch Event"
              :tooltip="!isGolive ? 'Once you have completed all of the required sections (General, Design & Branding, Resources, Project Templates & Event Timelines), click here to make your event live!' : ''"
            />
          </slot>
        </span>
      </span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.nav {
  @apply bg-white border-b-2 border-gray-300 w-full flex flex-row fixed md:mt-[2.45rem] mt-[6.5rem] left-0 h-[4rem] z-[2];
  &-template {
    @apply hidden md:inline-flex;
  }
  &-menu {
    @apply hidden w-[20.5rem] md:inline-flex;
    &-btnContainer {
      @apply w-full flex flex-col text-purple items-center justify-center pb-3 md:border-r-[2rem] border-r-white;
      @apply md:ml-0.5 hidden md:inline-flex;
      &_link {
        @apply flex flex-row;
      }
      &_icon {
        @apply mt-[1px] mr-2;
      }
    }
  }
  &-builder {
    @apply w-full flex flex-row justify-between py-2;
    &-copy {
      @apply normal-case text-p-md font-medium;
    }
    &_text {
      @apply md:pl-[21rem] flex justify-center items-center text-p-sm text-blacks-300 px-2 md:px-0 mt-2;
      &-mobile {
        @apply hidden md:inline-flex;
      }
    }
    &_showcase {
      @apply ml-1 hidden md:inline-block text-blacks-500 text-p-sm;
    }
    &_ebtext {
      @apply text-blacks-500 font-medium md:ml-1 ml-4 hidden md:inline-flex;
    }
    &-options {
      @apply flex flex-row justify-between items-center px-2 md:w-auto w-full mt-2;
      &_active {
        @apply text-sm text-purple py-2 flex flex-row px-2 cursor-pointer md:mr-4;
      }
      &_notActive {
        @apply text-sm md:text-xs py-1 flex flex-row px-2 md:mr-4;
      }
      &_hidden {
        @apply hidden md:inline-flex uppercase rounded flex flex-row justify-center items-center;
      }
      &-btn {
        @apply text-white bg-purple text-sm h-8 mx-2;
      }
    }
  }
  &-button {
    &-live {
      @apply w-[9.25rem] h-[2.75rem] capitalize;
    }
  }
  .status {
    &-Live {
      @apply bg-green-100;
      i {
        @apply text-green-400;
      }
    }
    &-grey {
    }
    &-Draft {
      @apply bg-amber-100;
      i {
        @apply text-amber-400;
      }
    }
  }
}
</style>
