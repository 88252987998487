import FormService from "@/services/form"

export default {
  state: () => ({
    forms: [],
    status: 'idle',
    error: null
  }),
  getters: {
    getFormItems: state => state.forms,
    getFormLoadingStatus: state => state.status,
    getFormError: state => state.error
  },
  mutations: {
    FORM_LOADED(state, payload) {
      state.forms = payload
      state.status = 'loaded'
    },
    FORM_LOADING(state) {
      state.status = 'loaded'
    },
    FORM_ERROR(state, error) {
      state.error = error
    }
  },
  actions: {
    fetchForms({commit}) {
        commit('FORM_LOADING')
        return new Promise((resolve, reject) => {
          FormService.getForms().then((response) => {
            commit('FORM_LOADED', response.data.forms)
            resolve()
          }).catch((err) => {
            commit('FORM_ERROR', err)
            reject(err)
          })
        })
      }
  }
}
