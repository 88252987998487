<script setup>
import CardSlot from '@/components/Base/Cards/CardSlot'
import BaseButton from '@/components/Base/Button/BaseButton'
import { defineProps } from 'vue'
import store from '@/store'

defineProps({
  card: {
    type: Object,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  },
})

const toggleUpgrade = (plan) => {
  if (plan !== 'custom') {
    store.dispatch('ToggleModalUpgradePayment', { selectedPlan: plan })
  } else {
    const mailtoLink = 'mailto:projectboardplus@projectboard.zohodesk.com'
    window.location.href = mailtoLink
  }
}
</script>

<template>
  <card-slot v-if="!isLoading"
             class-name="card">
    <template #header>
      <div class="card-header">
        <div class="card-header-container">
          <h3 class="card-header-container-top">
            {{ card.topText }}
          </h3>
        </div>
        <div class="card-header-container">
          <h1 class="card-header-container-middle">
            {{ card.name }}
          </h1>
        </div>
        <div class="card-header-container">
          <h4 class="card-header-container-bottom">
            <span v-html="card.subText" />
          </h4>
        </div>
      </div>
    </template>
    <template #content>
      <div class="card-content">
        <span class="flex flex-row items-center">
          <base-button text="upgrade"
                       class-name="text-p-sm ml-4 mb-2"
                       btn-type="secondary"
                       :functions="() => toggleUpgrade(card.plan.toLowerCase())">
            <template #contents>
              <span class="card-header-upgradeBtn">
                {{ card.buttonText }}
              </span>
            </template>
          </base-button>
        </span>
      </div>
    </template>
  </card-slot>
  <card-slot v-else
             class-name="card">
    <template #header>
      <div class="card-header">
        <div class="card-header-container">
          <h3 class="card-header-container-top">
          </h3>
        </div>
        <div class="card-header-container">
          <h1 class="card-header-container-middle">
          </h1>
        </div>
        <div class="card-header-container">
          <h4 class="card-header-container-bottom">
          </h4>
        </div>
      </div>
    </template>
    <template #content>
      <div class="card-content cursor-pointer">
      <span class="card-content-icon">
        <i class="" />
      </span>
      </div>
    </template>
  </card-slot>
</template>

<style scoped lang="scss">
.card {
  @apply md:w-[22rem] md:min-w-0 min-w-full border rounded-2xl border-greys-200 h-full pb-3;
  &-header {
    @apply p-4 bg-white rounded-t-2xl flex flex-col relative h-[7.9rem];
    &-container {
      @apply w-full flex flex-col justify-between relative;
      &-draft {
        @apply flex flex-col;
      }
      &-top {
        @apply w-full h-[1.5rem] text-h4 font-medium;
      }
      &-middle {
        @apply w-full h-[1.5rem] pt-3 pl-0 text-h2 font-bold;
      }
      &-bottom {
        @apply animate-[pulse-bg_2s_infinite] md:mb-6 text-h6 pt-6 pl-0;
      }
    }
    &-upgradeBtn {
      @apply flex flex-col justify-center items-center p-2;
    }
  }
}
</style>
