<script setup>
/*
  * General Purpose Confirmation Modal
  *
  */

import { computed, onMounted } from 'vue'
import store from '@/store'
import ModalLayout from '@/components/Modals/ModalBase'
import BaseButton from '@/components/Base/Button/BaseButton'
import { handleBackButton } from '@/utils'

const confirmData = computed(() => {
  return store.getters.modalData
})

const confirmAction = () => {
  store.dispatch('ConfirmAction')
}

const cancelAction = () => {
  store.dispatch('CancelAction')
}

onMounted(() => {
  handleBackButton()
})
</script>

<template>
  <modal-layout modal-class="bg-white modal-confirm" :is-close="true">
    <template #modal-title>
      <span class="modal-confirm-header">
        <h1 class="modal-confirm-title">
          {{ confirmData?.data?.title }}
        </h1>
      </span>
    </template>
    <template #modal-subContent>
      <span class="modal-confirm-body">
        <p class="modal-confirm-content"
           v-html="confirmData?.data?.description" />
      </span>
    </template>

    <!-- Back to Events & Submit Buttons -->
    <template #modal-button>
      <span class="modal-confirm-btn-container">
          <base-button :functions="cancelAction"
                       :text="confirmData?.data?.cancelButton"
                       class-name="modal-confirm-btn modal-confirm-btn-back"
                        btn-type="secondary"/>
          <base-button :functions="confirmAction"
                       :text="confirmData?.data?.confirmButton"
                       class-name="modal-confirm-btn modal-confirm-btn-approve"
                        btn-type="primary"/>
        </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.modal {
  &-confirm {
    @apply md:h-auto overflow-y-auto max-w-lg md:p-7 p-4 rounded-b-none md:rounded-2xl;
    &-header {
      @apply flex flex-col w-full justify-center items-center;
    }
    &-title {
      @apply normal-case font-medium text-h2 text-center text-blacks-500 pb-3;
    }
    &-body {
      @apply flex flex-col justify-center items-center;
    }
    &-content {
      @apply text-blacks-300 text-p-md text-center;
    }
    &-btn {
      @apply w-[12rem] h-[3rem] mx-1 normal-case;
      &-container {
        @apply w-full flex flex-row justify-center mt-8;
      }
      &-back {
        @apply text-primary border-primary font-medium hover:border-transparent hover:bg-white hover:text-product-500;
      }
      &-approve {
        @apply bg-other-green text-white font-medium hover:opacity-80;
      }
      &-delete {
        @apply bg-other-red text-white font-medium hover:opacity-80;
      }
    }
  }
}
</style>
