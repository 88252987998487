import axios from 'axios'
import { config } from '@/config'

class EventTemplateService {
    getEventTemplates({ eventId, status }) {
        return axios.get(`${config.API_URL}/partners/events/templates`, {
                params: {
                    eventId: eventId,
                    status: status
                }
            })
    }
    createEventTemplate({ status, projectId, eventId }) {
        return axios.post(`${config.API_URL}/partners/events/template`, {
            "status": status,
            "projectId": projectId,
            "eventId": eventId
        })
    }
    deleteEventTemplate({ id }) {
        return axios.delete(`${config.API_URL}/partners/events/template/${id}`)
    }

    updateEventTemplate({ id, status }) {
        return axios.patch(`${config.API_URL}/partners/events/template/${id}`, {
            "status": status
        })
    }

}

export default new EventTemplateService()
