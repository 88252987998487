import axios from 'axios'
import { config } from '@/config'

class PostService {
  getPosts({ projectId, limit, include, nextPageToken }) {
    return axios.get(`${config.API_URL}/posts`, {
      params: {
        projectId : projectId,
        limit: limit,
        includes: include,
        nextPageToken: nextPageToken
      }
    })
  }
  getPost({ postId, includes = "attachments" }) {
    return axios.get(`${config.API_URL}/posts/${postId}`, {
      params: {
        includes: includes
      }
    })
  }
  updatePost(postId, post, updateMask = "instructions,body") {
    return axios.patch(`${config.API_URL}/posts/${postId}`, post, {
      params: {
        "updateMask": updateMask
      }
    })
  }
  updatePostFeatures(postId, post) {
    return this.updatePost(postId, post, "post_features")
  }
  updatePostPriority(postId, post) {
    return this.updatePost(postId, post, "priority")
  }
  createPost(projectId, post) {
    return axios.post(`${config.API_URL}/projects/${projectId}/post`, post)
  }
  deletePost(postId) {
    return axios.delete(`${config.API_URL}/posts/${postId}`)
  }
}

export default new PostService()
