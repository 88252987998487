const FREE = 'free'
const STARTER = 'starter'
const ESSENTIALS = 'essentials'
const PROFESSIONAL = 'professional'

const EventPlans = [FREE, STARTER, ESSENTIALS, PROFESSIONAL]

export const eventUpgradeMapping = {
    [FREE]: [STARTER, ESSENTIALS, PROFESSIONAL],
    [STARTER]: [ESSENTIALS, PROFESSIONAL],
    [ESSENTIALS]: [PROFESSIONAL],
    [PROFESSIONAL]: []
}

export const eventPlans = {
    FREE,
    STARTER,
    ESSENTIALS,
    PROFESSIONAL,
    EventPlans
}
