import PostService from "@/services/post"
import { processStatuses } from "@/utils"

export default {
  state: () => ({
    posts: [],
    postsErrors: {},
    postsStatus: processStatuses.IDLE,
    post_id: ''
  }),
  getters: {
    posts: state => state.posts,
    postsErrors: state => state.postsErrors,
    postsStatus: state => state.postsStatus,
    isPostsLoaded: state => state.postsStatus === processStatuses.LOADED,
    postID: state => state.post_id
  },
  mutations: {
    POSTS_LOADING (state) {
      state.postsStatus = processStatuses.LOADING
    },
    POSTS_LOADED (state, posts) {
      state.posts = posts
      state.postsStatus = processStatuses.LOADED
    },
    POSTS_LOAD_ERROR (state, err) {
      state.postsStatus = processStatuses.ERROR
      state.postsErrors = err
    }
  },
  actions: {
    getPosts ({commit}, projectId) {
      commit('POSTS_LOADING', true)
      return new Promise((resolve, reject) => {
        //TODO needs to implementation of pagination
        PostService.getPosts({projectId : projectId,limit: 10, include:"", nextPageToken: 0}).then((response) => {
          commit('POSTS_LOADED', response.data.posts)
          resolve()
        }).catch((err) => {
          console.error(err)
          commit('POSTS_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
  },
}
