import axios from 'axios'

export default {
  state: () => ({
    faq_questions: [],
    faq_questions_errors: null,
  }),
  getters: {
    faqQuestions: state => state.faq_questions
  },
  mutations: {
    SET_FAQ_QUESTIONS (state, data) {
      state.faq_questions = data
    },
    SET_FAQ_ERROR(state, error) {
      state.faq_questions_errors = error
    }
  },
  actions: {
    async getFaqQuestions ({ commit }) {
      try {
        const result = await axios.get('/faq/faq.json')
        if (result.status === 200) {
          const { data } = result
          commit('SET_FAQ_QUESTIONS', data)
        }
      } catch (err) {
        commit('SET_FAQ_ERROR', err)
      }
    }
  }
}
