import axios from 'axios'
import { config } from '@/config'

class OrganizationService {
    getOrganization({ idOrSlug }) {
        return axios.get(`${config.API_URL}/organizations/${idOrSlug}`, {
            params: {
                include: "features, subscriptions"
            }
        })
    }

    getOrganizationsByUserId({ userId }) {
        return axios.get(`${config.API_URL}/organizations`, {
            params: {
                userId: userId,
                include: "features, subscriptions"
            }
        })
    }

    updateOrganization({ organizationId, organizationName, slug, logo, email }, updateMask = "name,slug,logo,email") {
        return axios.patch(`${config.API_URL}/organizations/${organizationId}`, {
            "name": organizationName,
            "slug": slug,
            "logo": logo,
            "email": email
        }, {
            params: {
                "updateMask": updateMask
            }
        })
    }

    getOrganizationSubscription({ organizationId }) {
        return axios.post(`${config.API_URL}/get-organization-subscriptions`, {
            "organizationId": organizationId
        })
    }

    getOrganizationPaidEventCount({ organizationId }) {
        return axios.post(`${config.API_URL}/get-organization-paid-event-count`, {
            "organizationId": organizationId
        })
    }
}

export default new OrganizationService()
