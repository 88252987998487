<script setup>
// Required Component
import { ref, computed } from 'vue'
// Base Component
import BaseButton from '@/components/Base/Button/BaseButton'
import BaseInput from '@/components/Base/Fields/InputField'
// Store
import store from '@/store'

defineProps({
  includedList: Array,
  paidPlans: Object,
  modalType: String,
  isPlanLoading: Boolean,
  isPlanError: Boolean,
  cancelPlan: Function,
  createCheckoutSessionAndRedirect: Function,
  checkoutSessionStatus: String,
  processStatuses: Object,
  config: Object,
  getIncludedInfo: Function,
  isPlan: {
    type: Boolean,
    default: false
  },
  isPopularPlan: {
    type: Boolean,
    default: false
  }
})

const checkEducator = ref(false)

const eventIdToUpdate = computed(() => {
  // If event ID is not provided, default to 0, as a result,
  // purchase will be made to organization level
  return store.getters.modalData?.data?.eventId ?? 0
})

const canApplyEduDiscount = computed(() => {
  return false
})
</script>

<template>
  <div class="modal-plan-content">
    <div v-if="!isPlanLoading && !isPlanError"
         class="modal-plan-info">
      <div v-if="isPopularPlan"
           class="modal-plan-popular">
        <p class="mt-1">Most Popular</p>
      </div>
      <span v-if="!isPlan"
            class="modal-plan-info-title">
        <img v-for="included in getIncludedInfo(includedList, paidPlans?.name)"
             :key="included.name"
             :src="included.icon"
             :alt="included.name"
             class="modal-plan-info-image">
        {{paidPlans?.name}}
      </span>
      <h3 v-for="included in getIncludedInfo(includedList, paidPlans?.name)"
          :key="included.name"
          class="modal-plan-info-description">
        {{included.description}}
      </h3>
      <h4 class="modal-plan-info-cost">
        <span class="modal-plan-info-cost-text">
          US$
        </span>
          {{ paidPlans?.price }} <span class="modal-plan-info-cost-text mt-2">
          / Showcase
        </span>
      </h4>
      <p v-if="canApplyEduDiscount" class="modal-plan-info-discount">
        ${{Math.ceil(paidPlans?.price / 2)}} Education Discount (Middle School and High School)
      </p>
    </div>
    <div v-if="isPlanLoading"
         class="modal-plan-included">
      LOADING...
    </div>
    <div v-else-if="isPlanError"
         class="modal-plan-included">
      Fail load plans. Please refresh the page and try again.
    </div>
    <div v-else
         class="modal-plan-included">
      <ul v-for="included in getIncludedInfo(includedList, paidPlans?.name)"
          :key="included.name"
          class="modal-plan-included-list">
        <li v-for="item in included.items"
            :key="item"
            class="modal-plan-included-item">
          <span class="modal-plan-included-item-text">
            {{item}}
          </span>
        </li>
      </ul>
      <a :href="config.PRICING_URL"
         class="modal-plan-included-features"
         target="_blank">
        See All Features
      </a>
    </div>
  </div>
  <div class="modal-plan-footer">
    <base-input v-if="canApplyEduDiscount"
                :model-value="checkEducator"
                :checked="checkEducator"
                input-type="checkbox"
                class-name="modal-plan-footer-checkbox"
                :input-id="`plan-educator_${paidPlans?.name}`"
                :input-name="`plan-educator_${paidPlans?.name}`"
                label-text="Are you an educator?"
                :label-name="`plan-educator_${paidPlans?.name}`"
                @change="checkEducator = !checkEducator"/>
    <span class="modal-plan-footer-btn-container">
          <base-button v-if="isPlan"
                       :functions="cancelPlan"
                       class-name="modal-plan-footer-btn modal-plan-footer-btn-cancel"
                       btn-type="text"
                       text="Cancel"/>
          <base-button :functions="() => createCheckoutSessionAndRedirect(paidPlans?.id, checkEducator, eventIdToUpdate)"
                       :disabled="checkoutSessionStatus === processStatuses.LOADING"
                       :class-name="`modal-plan-footer-btn ${isPlan ? '' : 'modal-plan-footer-btn-notPlan'}`"
                       text="choose plan" />
        </span>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  &-plan {
    &-info {
      @apply flex flex-col w-full;
      &-image {
        @apply mr-2;
      }
      &-title {
        @apply flex flex-row font-normal text-h2 capitalize pb-4 items-center;
      }
      &-description {
        @apply text-blacks-300 py-2 text-p-xl;
      }
      &-cost {
        @apply text-h1 font-medium pt-2 w-full flex flex-row gap-0.5 items-center;
        &-text {
          @apply text-p-xs font-normal;
        }
      }
      &-discount {
        @apply text-product-400 font-italic pb-4;
      }
    }

    &-included {
      @apply flex flex-col w-full py-6 justify-center;
      &-text {
        @apply font-medium text-xl pb-1;
      }

      &-list {
        @apply flex flex-col w-full;
      }

      &-item {
        @apply flex flex-row py-1 items-center text-p-md text-blacks-300;
        &-circle {
          @apply w-[1.625rem] h-[1.625rem] rounded-full bg-other-green flex justify-center items-center mr-2;
        }

        &-text {
          @apply text-p-xl;
        }
      }

      &-features {
        @apply font-medium text-product-400 mt-2;
      }
    }

    &-footer {
      @apply flex flex-col w-full;
      &-checkbox {
        @apply mt-2;
      }

      &-btn {
        @apply capitalize px-4 py-2;
        &-notPlan {
          @apply w-full;
        }
        &-container {
          @apply flex flex-row justify-end w-full mt-4;
        }

        &-cancel {
          @apply text-blacks-500;
        }
      }
    }

    &-popular {
      @apply w-[11rem] rotate-45 fixed block text-center text-white text-p-sm h-0 ml-[7.7rem] mt-[0.75rem] font-light;
      @apply border-b-[30px] border-b-primary border-r-[30px] border-r-transparent;
      @apply border-l-[30px] border-l-primary border-r-[30px] border-l-transparent;
    }
  }
  &-upgrade {
    @apply bg-white;
  }
}
// Styling the checkbox
:deep(.field-input) {
  @apply w-5 h-5;
}
// Styling for the label
:deep(.field-label-notInputs) {
  @apply font-normal text-p-xl ml-3 normal-case;
}
</style>
