<script setup>
import { computed } from 'vue'
import store from '@/store'

const previewData = computed(() => {
  return store.getters.modalData.data
})

const close = () => {
  store.dispatch('ResetEventModal')
  store.dispatch('ToggleModal')
  store.dispatch('ToggleOverlay')
  store.dispatch('ResetModal')
}
</script>

<template>
  <div class="receipt-modal-content">
    <div v-if="previewData.receiptUrl !== '' || previewData.receiptUrl !== null">
      <iframe class="receipt-modal-content-iframe" :src="previewData.receiptUrl" width="100%" height="100%"></iframe>
    </div>
    <div v-else class="receipt-modal-content-inner">
      <div class="w-full center text-h1 text-background-400">
        <p>No preview available.</p>
      </div>
    </div>
  </div>
  <div class="receipt-modal-buttons">
    <button class="receipt-modal-buttons-close" @click="close"><span class="icon"><i class="fal fa-times" aria-label="close"></i></span></button>
  </div>
</template>


<style lang="scss" scoped>
.receipt-modal {
  &-content {
    @apply md:w-auto h-screen rounded-xl;
    &-iframe {
      @apply h-screen md:w-[90rem] pt-10 pb-10 w-[15rem] rounded-xl;
    }
    &-inner {
      @apply flex items-center absolute inset-x-0 top-10 bottom-10 w-auto text-center h-auto;
    }
  }
  &-buttons {
    @apply absolute -right-14 top-5 flex text-white text-p-md justify-end z-10;
    &-close{
      @apply text-h1;
    }
  }
}
</style>
