import { createStore }from 'vuex'
import createPlugin from 'logrocket-vuex'
import LogRocket from 'logrocket'
import menu from '@/store/modules/menu'
import modal from '@/store/modules/modal'
import navbar from '@/store/modules/navbar'
import user from '@/store/modules/user'
import event from '@/store/modules/event'
import support from '@/store/modules/support'
import plan from '@/store/modules/plan'
import notification from '@/store/modules/notification'
import auth from '@/store/modules/auth'
import resource from '@/store/modules/resource'
import teamSettings from '@/store/modules/teamsettings'
import timeline from '@/store/modules/timeline'
import form from '@/store/modules/form'
import organization from '@/store/modules/organization'
import file from '@/store/modules/file'
import subscription from '@/store/modules/subscription'
import version from '@/store/modules/version'
import template from '@/store/modules/template'
import project from '@/store/modules/project'
import eventBuilder from '@/store/modules/event-builder'
import comments from '@/store/modules/comments'
import post from '@/store/modules/post'
import participant from '@/store/modules/participant'
import banner from '@/store/modules/banners'
import toast from '@/store/modules/toast'
import payment from '@/store/modules/payment'
import transaction from '@/store/modules/transaction'
import categories from '@/store/modules/categories'
import awards from '@/store/modules/awards'
import projectAwards from '@/store/modules/project-awards'
import organizationUser from '@/store/modules/organization-user'
import organizations from '@/store/modules/organizations'
import sponsors from '@/store/modules/sponsors'
import projectSubmissions from '@/store/modules/project-submissions'
import iframe from '@/store/modules/iframe'

const logrocketPlugin = createPlugin(LogRocket)

const store = createStore({
  modules: {
    menu,
    modal,
    navbar,
    user,
    event,
    support,
    plan,
    notification,
    auth,
    teamSettings,
    resource,
    timeline,
    form,
    organization,
    organizations,
    file,
    subscription,
    version,
    template,
    project,
    eventBuilder,
    comments,
    post,
    participant,
    banner,
    toast,
    payment,
    transaction,
    categories,
    awards,
    projectAwards,
    organizationUser,
    sponsors,
    projectSubmissions,
    iframe
  },
  plugins: [logrocketPlugin]
})

export default store
