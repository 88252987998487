<script setup>
// Required components
import { onMounted, computed } from 'vue'
// Store
import store from '@/store'
// Base page
import DashboardPageBase from './DashboardPageBase'
// Custom component
import SupportItem from '@/components/Support/SupportItem'

const faqQuestions = computed(() => {
  return store.getters.faqQuestions
})

onMounted(() => {
  store.dispatch('getFaqQuestions')
})
</script>

<template>
  <dashboard-page-base>
    <template #title>
        Help & Support
    </template>
    <template #subContent>
      <h6 class="support-subContent">
        Below are a few helpful FAQ's to aid your event creation, design and management.
      </h6>
    </template>
    <template #content>
      <ul class="support-questions">
        <li v-for="data in faqQuestions"
            :key="data.title"
            class="mt-8">
          <h3 class="support-questions-title">
            {{data.title}}
          </h3>
          <support-item v-for="item in data.info"
                        :key="item.title"
                        :title="item.title"
                        :bullets="item.bullets"
                        :notes="item.notes"
                        :projects="item.projects"
                        :content="item.content" />
        </li>
      </ul>
    </template>
  </dashboard-page-base>
</template>

<style lang="scss" scoped>
.support {
  &-subContent {
    @apply text-h6 text-blacks-300 my-2;
  }
  &-questions {
    @apply p-2 md:max-w-[45rem] w-full;
    &-title {
      @apply text-h3 pb-6 font-bold;
    }
  }
}
</style>

