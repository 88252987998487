<script setup>
import { ref, onMounted } from 'vue'
import BaseButton from '@/components/Base/Button/BaseButton'

const props = defineProps({
  color: {
    type: String,
    default: '#8400F0'
  },
  theme: {
    type: String,
    default: 'light'
  },
  setColor: {
    type: Function,
    default: () => {}
  },
  id: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const previousColour = props.color
const pureColor = ref(props.color);
const gradientColor = ref("linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%)")

const colorPickerState = ref(false)

const toggleColorPicker = () => {
  colorPickerState.value = !colorPickerState.value
}

const closeColorPicker = () => {
  colorPickerState.value = false
}

const cancel = () => {
  // Set back to original
  pureColor.value = previousColour.toUpperCase()
  props.setColor(pureColor.value, props.id)
  colorPickerState.value = false
}

const save = () => {
  // Set back to original
  props.setColor(pureColor.value, props.id)
  colorPickerState.value = false
}

onMounted(() => {
  colorPickerState.value = false
})
</script>

<template>
  <div class="color-picker">
    <div v-show="colorPickerState && !disabled"
         class="color-picker-overlay"
         @click.prevent="closeColorPicker"/>
    <div class="color-picker-container"
         :class="disabled ? 'disabled' : ''"
         @click.prevent="toggleColorPicker">
     <div class="color-picker-container-hex"
          :style="{backgroundColor: `${color}`}"/>
     {{color?.toUpperCase()}}
    </div>
    <div v-show="colorPickerState && !disabled"
         class="color-picker-modal">
      <color-picker v-model:pure-color="pureColor"
                    v-model:gradient-color="gradientColor"
                    format="hex"
                    is-widget
                    :on-change="setColor(pureColor, id)"
                    picker-type="chrome"
                    :disable-history="true"/>
      <div class="arrow-right" />
      <div v-show="colorPickerState && !disabled"
            class="color-picker-button-container">
        <base-button id="cancelColorPicker"
                     name="cancelColorPicker"
                     btn-type="text"
                     class-name="color-picker-button"
                     text="Cancel"
                     :functions="cancel"/>
        <base-button id="applyColorPicker"
                     name="appleColorPicker"
                     class-name="color-picker-button"
                     :functions="save"
                     text="Apply" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.color-picker {
  @apply py-3 relative;
  &-overlay {
    @apply h-screen w-screen bg-transparent fixed left-0 top-0 z-[4];
  }
  &-container {
    @apply flex flex-row cursor-pointer border border-blacks-200 w-[7.5rem] items-center;
    @apply px-4 py-2 rounded-lg text-p-sm;
    &-hex {
      @apply h-4 w-4 mr-2 border rounded border-transparent overflow-hidden;
    }
    &.disabled {
      @apply border-none;
    }
  }
  &-modal {
    @apply absolute z-10 -ml-[20rem] -mt-36 bg-white border rounded-md shadow;
  }
  &-button {
    @apply capitalize px-4 py-2;
    &-container {
      @apply w-full flex justify-end py-3 -mt-3 px-2;
    }
  }
}

.arrow-right {
  @apply absolute w-4 h-4 bg-white shadow -rotate-45 ml-[16.7rem] z-[1] -mt-[8rem];
}

// Overrides
:deep(.vc-colorpicker) {
  @apply shadow-none #{!important};
}
</style>
