// Config
import { config } from '@/config'
// Firebase
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebase = {
  apiKey: config.FIREBASE_API_KEY,
  projectId: config.FIREBASE_PROJECT_ID,
  authDomain: `${config.FIREBASE_PROJECT_ID}.firebaseapp.com`,
}

const app = initializeApp(firebase)

const auth = getAuth()

export { app, auth }
