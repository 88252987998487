<script setup>
import EventPageBase from '@/pages/EventBuilder/EventPageBase'
import ParticipantList from '@/components/Participants/List'
import ParticipantAddOn from '@/components/Participants/AddOn'
import store from '@/store'
import { computed } from 'vue'
import BaseButton from '@/components/Base/Button/BaseButton'

const eventIsPublished = computed(() => {
  return store.getters.eventSettings.status === 'Published'
})
</script>

<template>
  <event-page-base :is-visible="false">
    <template #title>
      Participants
    </template>
    <template v-if="eventIsPublished" #description>
      Now that the event is live, invite participants to complete the template. Once they begin editting their project, their information will appear below to access and manage.
    </template>
    <template v-else #description>
      Once your event is published, you will be able to invite participants by sharing the event URL with them - they can join directly from there. Once they begin editing their projects, their information will appear below to access and manage.
    </template>
    <template #rightContainer>
      <base-button :functions="() => store.dispatch('ToggleModalInviteParticipants')"
                   text="+ Invite participants"/>
    </template>
    <template #content>
      <participant-list />
      <participant-add-on />
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
</style>
