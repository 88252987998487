const SUBMITTED = 'PROJECT_STATUSES_SUBMITTED'
const APPROVED = 'PROJECT_STATUSES_APPROVED'
const DECLINED = 'PROJECT_STATUSES_DECLINED'
const DRAFT = 'PROJECT_STATUSES_DRAFT'

const Statuses = [SUBMITTED, APPROVED, DECLINED, DRAFT]

export const submissionStatusType = {
  SUBMITTED,
  APPROVED,
  DECLINED,
  DRAFT,
  Statuses
}
