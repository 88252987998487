<script setup>
// Required Packages
import { computed, ref, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// Store
import store from '@/store'
// Base Components
import {pbButton, pbInputField, pbAvatar} from 'pb-components'
// Custom Components
import ModalLayout from '@/components/Modals/ModalAuthBase'
import AuthBase from '@/pages/Auth/AuthBase'

const props = defineProps({
  password: {
    type: String,
    requires: true
  },
  passwordConfirm: {
    type: String,
    requires: true
  }
})

const route = useRoute()
const router = useRouter()

const user = reactive({
  password:  ref(props.password) || '',
  passwordConfirm: ref(props.passwordConfirm) || ''
})

const token = ref(route.query.token)
const passwordChanged = ref(false)

const changePasswordErrorMessage = computed(() => store.getters.changePasswordError)
const changePasswordOtherErrorMessage = computed(() => store.getters.passwordValidationError)
const changePasswordValidationErrorMessage = computed(() => {
  return store.getters.changePasswordValidationError
})

const changePasswordValidateError = (type) => {
  let temp = ''
  Object.keys(changePasswordValidationErrorMessage.value).filter(item =>{
    if (item === type) {
      if (item === 'password' || item === 'passwordConfirm') {
        if (Object.keys(changePasswordValidationErrorMessage.value[type])?.length > 0) {
          temp = changePasswordValidationErrorMessage.value[type]
        } else {
          temp = ''
        }
      } else {
        // Give back other error messages
        temp = changePasswordValidationErrorMessage.value[type]
      }
    }
  })
  return temp
}

const passwordValidationError = computed ( () => {
  let ValidatePass = store.getters.changePasswordValidationError
  return Object.keys(ValidatePass).filter((key) => key.includes('password')).reduce((cur, key) => {
    return Object.assign(cur, { [key]: ValidatePass[key] })
  }, {});
})

const changePassword = async () => {
  let payload = { newPassword: user.password, passwordConfirm: user.passwordConfirm, token: token.value }

  await store.dispatch('changePassword', payload)

  if (store.getters.changePasswordStatus === 'loaded') {
    passwordChanged.value = true
  }
}

const redirectToLogin = () => {
  //get the returnUrl from the url
  const { query } = route
  if (query?.returnUrl) {
    router.push('/login?returnUrl=' + query?.returnUrl)
  } else {
    router.push('/login')
  }
}

onMounted(() => {
  store.dispatch('ChangeModalType', 'reset-password')
})
</script>

<template>
  <auth-base auth-submit-function="changePassword"
             :reset-or-verify="true">
    <template #form>
        <span v-if="!passwordChanged">
          <modal-layout>
            <template #modal-title>
              <pb-avatar
                  class-name="forget-password-avatar"
                  image="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/selfserve%2Fimages%2Ftabler-icon-circle-key-filled.png?alt=media&token=f6006fe8-abb8-4a5f-9ec6-557222de7f12"/>
              Set New Password
            </template>
            <template #modal-subtitle>
              Please set your new password
            </template>
            <template #modal-body>
              <span class="forget-password">
                <pb-input-field v-model="user.password"
                            label-text="Password"
                            input-type="password"
                            input-id="password"
                            input-aria-label="password"
                            input-place-holder="Type Password"
                            class-name="w-full py-2"
                            :errors="changePasswordValidateError('password')"
                            :model-value="user.password || ''" />
                <pb-input-field v-model="user.passwordConfirm"
                            label-text="Confirm Password"
                            input-type="password"
                            input-id="passwordConfirm"
                            input-aria-label="passwordConfirm"
                            input-place-holder="Re-type Password"
                            class-name="w-full py-2"
                            :errors="passwordValidationError['passwordConfirm']"
                            :model-value="user.passwordConfirm || ''" />
                <div class="error" >
                  {{ changePasswordErrorMessage }}
                </div>
                <div v-for="message in changePasswordOtherErrorMessage" :key="message"
                     class="error">
                  {{message}}
                </div>
              </span>
            </template>
            <template #modal-button>
              <span class="forget-password-footer">
                <pb-button id="resetPassword"
                             name="resetPassword"
                             text="Reset Password"
                             class-name="forget-password-btn"
                             :functions="changePassword" />
              </span>
              </template>
          </modal-layout>
        </span>
        <span v-else>
          <modal-layout>
            <template #modal-title>
               <pb-avatar
                   class-name="forget-password-verify"
                   image="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/selfserve%2Fimages%2Ftabler-icon-discount-check-filled.png?alt=media&token=8f40298d-df2a-4111-9538-14bb807042c4"/>
              Password Reset
            </template>
            <template #modal-subtitle>
              Your password has been successfully reset.<br>
              Click below to login into your account
            </template>
            <template #modal-button>
              <span class="forget-password-footer">
                <pb-button id="login"
                             name="login"
                             text="Login"
                             class-name="forget-password-btn-login"
                             :functions="redirectToLogin" />
              </span>
              </template>
          </modal-layout>
        </span>
    </template>
  </auth-base>
</template>

<style lang="scss" scoped>
.forget-password {
  @apply md:px-24 w-full px-10 relative;
  &-avatar {
    @apply w-14 h-14 bg-violet-200 p-2 border-none mt-12 mb-4;
  }
  &-verify {
    @apply w-28 h-28 border-none mt-12 mb-4;
  }
  &-btn {
    @apply w-full py-4 capitalize text-p-md font-normal;
    &-login {
      @apply w-[18rem] py-4 capitalize text-p-xl font-medium;
    }
  }
  &-footer {
    @apply pt-4 pb-12 w-full text-center px-10 md:px-[6.5rem];
  }
}
// Auth Overrides
.modal :deep(.modal-header_title) {
  @apply w-full flex flex-col justify-center items-center pb-0;
}

.modal :deep(.modal-header_subtitle) {
  @apply -mb-7;
}

.authPage {
  @apply bg-none;
}
.authPage :deep(.authPage-formContainer) {
  @apply w-full;
}
// Field Overrides
.field :deep(.field-input-password-eye) {
  @apply mt-6;
}

.error {
  @apply text-p-sm font-normal;
}
</style>
