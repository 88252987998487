<script setup>
import { ref } from 'vue'
// Store
import store from '@/store'
// Modal
import ModalLayout from '@/components/Modals/ModalAuthBase'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'
import Avatar from '@/components/Base/Avatar'
// Countdown timer
const timer = ref(false) // Add Time later
const countDown = ref(15)

const resetPassword = async() => {
  timer.value = true
  countDown.value = 15
  try {
    let payload = { usernameOrEmail: store.getters.resetPasswordUsernameOrEmail }
    await store.dispatch('resetPassword', payload)
    if (!store.getters.resetPasswordError) {
      const intervalId = setInterval(() => {
        if (countDown.value === 0) {
          timer.value = false
          clearInterval(intervalId)
        }
        countDown.value--
      }, 1000)
    }
  } catch(err) {
    console.error(err)
  }
}

const toggleModal = () => {
  store.dispatch('ToggleModal')
  store.dispatch('ToggleOverlay')
  store.dispatch('ResetModal')
}
</script>

<template>
  <div class="resend-header">
    <img src="/images/logo/projectboard.png"
         alt="login logo"
         class="resend-header-logo" />
  </div>
  <modal-layout>
    <template #modal-title>
      <avatar
          class-name="resend-avatar"
          image="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/selfserve%2Fimages%2Ftabler-icon-circle-key-filled-email.png?alt=media&token=f427998f-b2ae-4395-83d9-d0eb584339db"/>
      Check Your Email
    </template>
    <template #modal-subtitle>
        We have sent a password reset link to <br> {{ store.getters.resetPasswordUsernameOrEmail }}
    </template>
    <template #modal-button>
      <span class="w-full text-center py-4">
        <base-button id="confirmationSent"
                     name="confirmationSent"
                     class-name="resend-btn"
                     text="Okay"
                     :functions="toggleModal" />
      </span>
      <p v-if="timer" class="resend-query">
        Didn't receive the email? Resend link in <span class="resend-countDown">{{ countDown }}s...</span>
      </p>
      <p v-else class="resend-query">
        Didn't receive the email? <span class="resend-query-link"
                                        @click="resetPassword">
          Click To Resend
      </span>
      </p>
      <span class="resend-login"
            @click="toggleModal">
        <i class="fa-regular fa-chevron-left"/> Back to Login
      </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.resend {
  @apply flex flex-col w-full justify-center md:px-[7.4rem];
  &-header {
    @apply md:inline-flex w-full justify-center items-center hidden;
    &-logo {
      @apply w-auto h-[2rem] mb-10;
    }
  }
  &-avatar {
    @apply w-14 h-14 bg-violet-200 p-2 border-none mb-4 md:mb-0;
  }
  &-login {
    @apply text-center text-primary cursor-pointer text-p-sm font-medium pb-8 pt-2;
    &:hover {
      @apply text-secondary;
    }
  }
  &-input {
    &-label {
      @apply text-p-sm text-blacks-500 ml-3;
    }
  }
  &-btn {
    @apply capitalize w-full md:w-[24rem] py-2 font-normal;
  }
  &-query {
    @apply text-p-md text-center text-greys-400 pb-2;
    &-link {
      @apply text-primary font-medium cursor-pointer;
    }
  }
  &-countDown {
    @apply text-other-green;
  }
}
.modal-check-reset-password-email :deep(.modal-header_title) {
  @apply flex flex-col justify-center items-center;
}
</style>
