// Used to indicate resource types
// Current support only IMAGE, FILE, LINK and VIDEO

const IMAGE = 'TYPE_IMAGE_RESOURCE'
const FILE = 'TYPE_FILE_RESOURCE'
const EMBEDDED_VIDEO = 'TYPE_EMBEDDED_VIDEO_RESOURCE'
const LINK = 'TYPE_LINK_RESOURCE'
const VIDEO = 'TYPE_VIDEO_RESOURCE'

const ResourceTypes = [IMAGE, FILE, EMBEDDED_VIDEO, LINK, VIDEO]
const ResourceTypeToInteger = {
    'TYPE_IMAGE_RESOURCE': 0,
    'TYPE_FILE_RESOURCE': 1,
    'TYPE_EMBEDDED_VIDEO_RESOURCE': 2,
    'TYPE_LINK_RESOURCE': 3,
    'TYPE_VIDEO_RESOURCE': 4,
}

export const resourceTypes = {
    IMAGE,
    FILE,
    EMBEDDED_VIDEO,
    LINK,
    VIDEO,
    ResourceTypes,
    ResourceTypeToInteger
}
