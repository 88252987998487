<script setup>
import ModalBase from "@/components/Modals/ModalBase"
import BaseButton from '@/components/Base/Button/BaseButton';
import store from '@/store';

const submit = () => {
  store.dispatch('ToggleModalEventDraft')
}
</script>

<template>
  <modal-base modal-class="bg-white modal-eventDraft" :is-close="true">
    <template #modal-title>
      <div class="modal-eventDraft-title">
        <h1 class="modal-eventDraft-title-header">Need More Time? No Problem!</h1>
      </div>
    </template>
    <template #modal-content>
      <div class="modal-eventDraft-content">
        <p class="modal-eventDraft-content-para">Once you’re ready, just click the Green
          <span class="modal-eventDraft-content-para-span">LAUNCH EVENT</span> button on the top right.</p>
      </div>
    </template>
    <template #modal-button>
      <div class="modal-eventDraft-buttons">
          <base-button
            id="go-live"
            name="go-live"
            text="Got It"
            aria-label="go Button"
            class-name="modal-eventDraft-buttons-live"
            :functions="submit"/>
        </div>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal-eventDraft {
  @apply md:h-auto max-w-lg pb-7 rounded-b-none md:rounded-2xl overflow-y-auto p-5;
  &-title {
    @apply p-2;
    &-header {
      @apply md:text-h2 mb-3 w-full text-center font-medium pt-7;
    }
  }
  &-content {
    &-para {
      @apply max-w-[23rem] px-3 text-p-xl text-center mx-auto md:leading-6 leading-5 text-blacks-300;
      &-span {
        @apply text-other-green font-medium md:text-h5;
      }
    }
  }
  &-buttons {
    @apply px-5 md:flex-row-reverse mt-8 text-center;
    &-live {
      @apply md:max-w-[12rem] md:mx-2 capitalize w-full md:mb-0 mb-3 h-11;
    }
  }
}
</style>
