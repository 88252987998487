import axios from 'axios'
import { config } from '@/config'

class AttachmentService {
  getAttachments({ postId, pageSize = 10, nextPageToken = 0 }) {
    return axios.get(`${config.API_URL}/projects/posts/${postId}/attachments`, {
      params: {
        pageSize: pageSize,
        nextPageToken: nextPageToken
      }
    })
  }
  getAttachment({ attachmentId, includes }) {
    return axios.get(`${config.API_URL}/attachments/${attachmentId}`, {
      params: {
        includes: includes
      }
    })
  }
  updateAttachment(attachmentId, attachment, updateMask = "priority,url,description,type") {
    return axios.patch(`${config.API_URL}/attachments/${attachmentId}`, attachment, {
      params: {
        "updateMask": updateMask
      }
    })
  }
  createAttachment(attachment) {
    return axios.post(`${config.API_URL}/attachment`, attachment)
  }
  deleteAttachment(attachmentId) {
    return axios.delete(`${config.API_URL}/attachments/${attachmentId}`)
  }
  copyAttachment(attachmentId) {
    return axios.post(`${config.API_URL}/attachments/${attachmentId}`)
  }
}

export default new AttachmentService()
