import { eventCompletionValues, eventFeatureCodes, IsSectionCompleted } from '@/utils'
export default {
  state: {
    sidebar_type: '',
    sidebar_items: [],
    event_builder_items: [],
    completion: {}
  },
  getters: {
    sidebarType: state => state.sidebar_type,
    sidebarItems: state => state.sidebar_items,
    eventBuilderItems: state => state.event_builder_items,
    IsEventSectionCompleted: state => (name) => state.completion ? state.completion[name] : false,
    completion: state => state.completion
  },
  mutations: {
    SET_SIDEBAR_TYPE (state, type) {
      state.sidebar_type = type
    },
    GET_SIDEBAR_ITEMS (state) {
      // Fill in back end call
      state.sidebar_items = [
        {
          name: 'Events',
          title: 'showcases',
          icon: 'calendar',
        },
        {
          name: 'Payments',
          title: 'payments',
          icon: 'credit-card',
        },
        {
          name: 'Users',
          title: 'admins',
          icon: 'user'
        },
        {
          name: 'Settings',
          title: 'settings',
          icon: 'gear'
        },
        {
          name: 'Analytics',
          title: 'analytics',
          icon: 'chart-simple'
        },
        {
          name: 'Support',
          title: 'help & support',
          icon: 'circle-question'
        }
      ]
    },
    GET_EVENT_BUILDER_ITEMS (state) {
      state.event_builder_items = [
        {
          name: 'EventBuilder',
          display: 'Showcase Builder',
          collapse: false,
          items: [
            {
              name: 'General',
              display: 'General',
              eventCompletionFlag: eventCompletionValues.event_config_completion_general.value,
              order: 1,
              isConfigured: false,
              isLocked: false
            },
            {
              name: 'Branding',
              display: 'Design & Branding',
              eventCompletionFlag: eventCompletionValues.event_config_completion_branding.value,
              order: 2,
              isConfigured: false,
              isLocked: false
            },
            {
              name: 'ProjectTemplates',
              display: 'Project Templates',
              eventCompletionFlag: eventCompletionValues.event_config_completion_template.value,
              order: 3,
              isConfigured: false,
              isLocked: false
            },
            {
              name: 'Timeline',
              display: 'Timeline',
              eventCompletionFlag: eventCompletionValues.event_config_completion_timeline.value,
              order: 4,
              isConfigured: false,
              isLocked: false
            },
            {
              name: 'Resources',
              display: 'Resources (Optional)',
              eventCompletionFlag: eventCompletionValues.event_config_completion_resources.value,
              order: 5,
              isConfigured: false,
              isLocked: false
            },
            {
              name: 'Categories',
              display: 'Categories (Optional)',
              eventCompletionFlag: eventCompletionValues.event_config_completion_categories.value,
              order: 6,
              isConfigured: false,
              isLocked: false,
              featureFlag: eventFeatureCodes.ENABLE_CATEGORIES
            },
            {
              name: 'Sponsors',
              display: 'Sponsors (Optional)',
              eventCompletionFlag: eventCompletionValues.event_config_completion_sponsors.value,
              order: 7,
              isConfigured: false,
              isLocked: false,
              featureFlag: eventFeatureCodes.ENABLE_SPONSOR
            }
          ]
        },
        {
          name: 'PeopleProjects',
          display: 'People & Projects',
          collapse: false,
          items: [
            {
              name: 'Participants',
              display: 'Participants',
              eventCompletionFlag: 0,
              isLocked: false
            },
            {
              name: 'Submissions',
              display: 'Project Submissions',
              eventCompletionFlag: 0,
              isLocked: false
            }
          ]
        },
        {
          name: 'Awards',
          display: 'Awards',
          collapse: false,
          items: [
            {
              name: 'CreateAwards',
              display: 'Create Awards',
              eventCompletionFlag: 0,
              isLocked: false,
              featureFlag: eventFeatureCodes.ENABLE_AWARDS
            },
            {
              name: 'GiveAwards',
              display: 'Give Awards',
              eventCompletionFlag: 0,
              isLocked: false,
              featureFlag: eventFeatureCodes.ENABLE_AWARDS
            }
          ]
        }
      ]

      if (state.event_builder_items[0]?.items?.length > 0) {
        state.completion = state.event_builder_items[0].items.reduce((acc, item) => {
          acc[item.name] = false
          return acc
        }, {})
      } else {
        console.error("No items available to populate completion")
      }
    },
    SET_COLLAPSE_MENU (state, menuName) {
      if (state.event_builder_items) {
        state.event_builder_items.filter(menu => {
          if (menu.name && menu.name === menuName) {
            menu.collapse = !menu.collapse
          }
        })
      }
    },
    SET_EVENT_COMPLETION (state, { name, value }) {
      if (!state.completion) {
        state.completion = {} // Initialize as an empty object if undefined
      }
      state.completion[name] = value
    },
    RESET_COMPLETION (state) {
      state.completion = {}
    }
  },
  actions: {
    SetSidebarType ({ commit }, type) {
      commit ('SET_SIDEBAR_TYPE', type)
    },
    SetCollapseMenu ({ commit }, menuName) {
      commit('SET_COLLAPSE_MENU', menuName)
    },
    GetSideBarItems ({ commit }) {
      commit ('GET_SIDEBAR_ITEMS')
    },
    async GetEventBuilderItems ({ commit, rootGetters }) {
      commit ("GET_EVENT_BUILDER_ITEMS", rootGetters?.getConfigCompletion)
    },
    SetEventCompletion({ commit, rootGetters }) {
      const completionValue = rootGetters.getConfigCompletion
      // General
      commit('SET_EVENT_COMPLETION', {
        name: 'General',
        value: IsSectionCompleted(eventCompletionValues.event_config_completion_general.value, completionValue)
      })
      // Branding
      commit('SET_EVENT_COMPLETION', {
        name: 'Branding',
        value: IsSectionCompleted(eventCompletionValues.event_config_completion_branding.value, completionValue)
      })
      // Project Templates
      commit('SET_EVENT_COMPLETION', {
        name: 'ProjectTemplates',
        value: IsSectionCompleted(eventCompletionValues.event_config_completion_template.value, completionValue)
      })
      // Timeline
      commit('SET_EVENT_COMPLETION', {
        name: 'Timeline',
        value: IsSectionCompleted(eventCompletionValues.event_config_completion_timeline.value, completionValue)
      })
      // Resources
      commit('SET_EVENT_COMPLETION', {
        name: 'Resources',
        value: IsSectionCompleted(eventCompletionValues.event_config_completion_resources.value, completionValue)
      })
      // Categories
      commit('SET_EVENT_COMPLETION', {
        name: 'Categories',
        value: IsSectionCompleted(eventCompletionValues.event_config_completion_categories.value, completionValue)
      })
      // Sponsors
      commit('SET_EVENT_COMPLETION', {
        name: 'Sponsors',
        value: IsSectionCompleted(eventCompletionValues.event_config_completion_sponsors.value, completionValue)
      })

    },
    ResetCompletion ({ commit }) {
      commit('RESET_COMPLETION')
    }
  }
}
