<script setup>
import { getDecodedRoles } from '@/utils'

import AdminPanel from '@/components/Layout/Panels/Admin'

defineProps({
  panelType: {
    type: String,
    default: 'admin'
  },
  adminRolePermissions: {
    type: Array,
    default: () => ['ss_user', 'super_admin', 'ss_event_participant']
  }
})

const getPermissions = (permissions) => {
  if (permissions) return getDecodedRoles().some(role => permissions?.includes(role))
}
</script>

<template>
  <admin-panel v-if="panelType === 'admin' && getPermissions(adminRolePermissions)"/>
</template>

<style lang="scss" scoped>
</style>
