import axios from 'axios'
import { config } from '@/config'

class SponsorService {
    getSponsors(eventId) {
      const params = {
        'pagination.pageSize': 100,
        'pagination.nextPageToken': 0,
        eventId: eventId
        // projectId: projectId
      }
      return axios
          .get(`${config.API_URL}/sponsors`, {
              params: params
          })
    }
    getSponsor(sponsorId) {
        return axios
            .get(`${config.API_URL}/sponsors/${sponsorId}`)
    }
    updateSponsor(sponsorId, { image, priority, url }, updateMask = "image,priority,url") {
      const body = {
        "sponsor": {
          "image": image,
          "priority": priority,
          "url": url
        },
        "updateMask": updateMask
      }

      return axios
          .patch(`${config.API_URL}/sponsors/${sponsorId}`, body)
    }
    createSponsors(eventId, sponsors) {
      const body = {
        "sponsors": sponsors,
        "eventId": eventId
      }

      return axios
          .post(`${config.API_URL}/sponsors`, body)
    }
    deleteSponsors(eventId, sponsorIds) {
        return axios
            .delete(`${config.API_URL}/sponsors`, {
                data: {
                    "sponsorIds": sponsorIds,
                    "eventId": eventId
                }
            })
    }
}

export default new SponsorService()
