<script setup>
import store from "@/store"
import { computed, onMounted, ref } from "vue"
import { useDropzone } from "vue3-dropzone"
// Modals
import ModalBase from "@/components/Modals/ModalBase"
import BaseButton from "@/components/Base/Button/BaseButton"
import BaseImgFileInput from "@/components/Base/Fields/ImageField"
import Overflow from '@/components/Base/Menu/Overflow'
import InputField from '@/components/Base/Fields/InputField'
import TextareaField from '@/components/Base/Fields/TextareaField'
import {
  handleBackButton,
  urlRegex,
  bitwiseOperators,
  processStatuses,
  acceptedFileFormats,
  isFirebaseStorageURL,
  generateAttachment,
  createLinkAttachment
} from '@/utils'

const maxCharTitle = 50
const maxCharDesc = 250

const defaultResourceBanner = "https://firebasestorage.googleapis.com/v0/b/test-d9796.appspot.com/o/selfserve%2Fresources%20empty%20state.png?alt=media&token=ed963548-13c6-4d43-9c23-a75b3b96082f"
const resourceLinkIcon = "https://firebasestorage.googleapis.com/v0/b/test-d9796.appspot.com/o/selfserve%2FLink%20For%20Resource.png?alt=media&token=7410764b-ce34-498d-b2ba-6c5452d5c86b"
const isCoverRemoved = ref(false)

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const resource = computed(() => {
  return store.getters.getResource
})

const resourceError = computed(() => {
  return store.getters.getResourceError
})

const resourceUploadStatus = computed(() => {
  return store.getters.getResourceUploadStatus === processStatuses.SAVING
})

const resourceValidateError = (type) => {
  let errMsg = ""
  if (resourceError.value != null) {
    Object.keys(resourceError.value).filter((item) => {
      if (item === type) {
        errMsg = resourceError.value[type]
      }
    })
  }
  return errMsg
}

const cancel = () => {
  fetchResources()
  store.dispatch("ToggleModalResource")

}

const create = () => {
  store.dispatch("resetResource")
  if(!isValid.value){
    resource.value.resourceLink = ""
  }
  const isFirebaseUrl = isFirebaseStorageURL(resource.value.resourceLink)
  const payload = {
    externalId: event.value.id,
    resourceLink: resource.value.resourceLink,
    fullTitle: resource.value.fullTitle,
    description: resource.value.description,
    resourceBanner: resource.value.resourceBanner ?? (isFirebaseUrl ? defaultResourceBanner : resourceLinkIcon),
    type: resourceType.value.type,
  }
  checkValidation(payload)
  if (Object.keys(store.getters.getResourceError).length === 0) {
    store.dispatch("ToggleModalResource")
    store.dispatch("createResource", payload).then(() => {
      fetchResources()
      store.dispatch('updateEventConfigs', { eventId: event.value.id, configKey: "event_config_completion", configValue: "4", isConfigValueBitMask: true, bitwiseOperation: bitwiseOperators.COMBINE }).then(() => {
        store.dispatch('getEventConfigs', { eventId: event.value.id})
      }).catch((err) => {
        console.error(err)
      })
    })
  }
}

const update = () => {
  store.dispatch("resetResource")
  if(!isValid.value){
    resource.value.resourceLink = ""
  }
  const payload = {
    externalId: event.value.id,
    resourceLink: resource.value.resourceLink,
    fullTitle: resource.value.fullTitle,
    description: resource.value.description,
    resourceBanner: resource.value.resourceBanner ? resource.value.resourceBanner : defaultResourceBanner,
    type: resourceType.value.type,
  }
  checkValidation(payload)
  if (Object.keys(store.getters.getResourceError).length === 0) {
    store.dispatch("ToggleModalResource")
    store.dispatch("editResource", { resourceId: resource.value.id, data: payload }).then(() => {
      fetchResources()
    })
  }
}

let resourceLinkUrl = ref(resource.value.resourceLink)
let resourceType = ref("")
let isValid = ref(true)

const saveResourceLink = () => {
  isValid.value = resource.value.resourceLink?.match(urlRegex)

  if(isValid.value) {
    resourceLinkUrl.value = resource.value.resourceLink
    // create link resourec type
    resourceType.value = createLinkAttachment(resource.value.resourceLink)
  }
}

const checkValidation = (formData) => {
  Object.keys(formData).filter((item) => {
    if (!formData[item] && item === "fullTitle") {
      const formItemPayload = {
        key: item,
        errMsg: "Please enter your title"
      }
      store.getters.getResourceError[formItemPayload.key] =
        formItemPayload.errMsg
    }
    if (!formData[item] && item === "resourceLink") {
      const formItemPayload = {
        key: item,
        errMsg: "Please upload your resource"
      }
      store.getters.getResourceError[formItemPayload.key] =
        formItemPayload.errMsg
    }
  })
}

const updateResourceBanner = async (uploadFile) => {
  const file = await store.dispatch("uploadResource", {
    uploadFile: uploadFile,
  })
  if (file.link) {
    resource.value.resourceBanner = file.link
  }
}

const updateResource = async (uploadFile) => {
  const fileType = uploadFile.type
  if (!acceptedFileFormats.acceptedAllFilesFormats.includes(fileType)) {
    await store.dispatch("setUploadResourceFileError", "Please upload JPEG, PNG, GIF, MP4, PDF, PPT, DOC, AVI file" )
  } else {
    const file = await store.dispatch("uploadResource", {
      uploadFile: uploadFile,
    })
    if (file.link) {
      await store.dispatch("setUploadResourceFileError", "").then(() => {
      })
      resourceLinkUrl.value = resource.value.resourceLink = file.link
    }
    // check file type
    resourceType.value = generateAttachment(uploadFile)
  }
}

const fetchResources = () => {
  if (event.value.id > 0) {
    store.dispatch("fetchResources", { eventId: event.value.id })
  }
}

// Dropzone
const { getRootProps, getInputProps, isDragActive } = useDropzone({
  onDrop,
})

function onDrop(acceptFile) {
  updateResource(acceptFile[0])
}

function changePhotoClick() {
  isCoverRemoved.value = true
  document.getElementById('resource_cover').click()
}

function deleteResourceBanner() {
  isCoverRemoved.value = true
  store.dispatch('removeResourceCover')
}

function deleteResourceLink() {
  resourceLinkUrl.value = ""
  resource.value.resourceLink = ""
}
const URLToggle = ref(false)
const switchToUrl = () => {
  URLToggle.value = !URLToggle.value
  isValid.value = true
}

const resourceName = computed(() => {
  const decodedUrl = decodeURIComponent(resource.value.resourceLink).split("?")[0].split("/") // Split the URL by '/' and decode it
  // Check if the decodedUrl array exists and has a valid domain
  if (decodedUrl && decodedUrl.length >= 3) {
    const domain = decodedUrl[2] // Get the domain (third element in the split URL)
    // Check if the domain is 'firebasestorage.googleapis.com'
    if (domain === 'firebasestorage.googleapis.com') {
      // Extract the filename, removing the timestamp and underscores
      return decodedUrl[decodedUrl.length - 1].split("_").slice(1).join("_")
    }
  }
  // If decodedUrl is null or the domain is different, return the original resource link
  return resource.value.resourceLink
})

onMounted(() => {
  handleBackButton()
})
</script>

<template>
  <modal-base modal-class="bg-white modal-resources">
    <template #modal-title>
      <h1 class="support-header">{{ (resource.id === undefined || resource.id === '') ? 'Add' : 'Edit' }} Resource</h1>
    </template>
    <template #modal-content>
      <div>
        <div class="resources_div">
          <div v-show="resource.resourceBanner === undefined || resource.resourceBanner === '' || resource.resourceBanner === null" class="cover-img">
            <base-img-file-input id="resource_cover"
                                  v-model="resource.resourceBanner"
                                  :src="resource.resourceBanner"
                                  name="Resource"
                                  aria-label="Add resource cover image"
                                  :image-has-changed="true"
                                  :image-has-removed="isCoverRemoved"
                                  :accepted-file-types="acceptedFileFormats.acceptedImageFilesFormats"
                                  :img-uploading="resourceUploadStatus"
                                  @update:model-value="updateResourceBanner">
              <template #upload-content>
                <label for="resource_cover"/>
              </template>
            </base-img-file-input>
            <p class="ml-4">Add a cover image for your resource</p>
          </div>
          <div v-if="![undefined, '', null].includes(resource.resourceBanner)" class="cover-img-update">
            <img v-if="resource.resourceBanner && resource.resourceBanner.length" :src="resource.resourceBanner">
              <span class="resource-card-header">
                <span class="resource-card-header-status"
                      :class="{
                        'status-green': eventTemplate?.status === 'Published',
                        'status-black': eventTemplate?.status === 'Draft'
                      }">
                  <span class="card-header_text">
                    {{ eventTemplate?.status }}
                  </span>
                </span>
                <overflow position="center">
                  <template #menu-items>
                    <ul class="resource-card-menu-list">
                  <li class="resource-card-menu-item"
                      @click.prevent="() => changePhotoClick()">
                    <i class="fa-thin fa-pencil mr-2" />Change Photo
                  </li>
                  <li class="resource-card-menu-item"
                      @click.prevent="() => deleteResourceBanner()">
                    <i class="fa-thin fa-trash-can mr-2" />Delete
                  </li>
                </ul>
                </template>
              </overflow>
            </span>
          </div>
        </div>
        <div class="resources_div">
          <div class="resources_field">
            <input-field v-model="resource.fullTitle"
                          label-text="Enter Your Title"
                          input-name="block_headline"
                          input-id="block_headline"
                          class-name="field-input-base"
                          input-aria-label="block_headline"
                          input-place-holder="Your Title"
                          :model-value="resource.fullTitle"
                          :input-model="resource.fullTitle"
                          :input-max-length="maxCharTitle"
                          :errors="resourceValidateError('fullTitle')"/>
            <span v-if="resource && resource.fullTitle && resource.fullTitle.length" class="block text-right pt-1 text-p-xs font-lighter">{{ maxCharTitle - (resource.fullTitle?.length ?? 0) }}/{{ maxCharTitle }}</span>
          </div>
        </div>
        <div class="resources_div">
          <label for="text">Enter Your Description</label>
          <div class="resources_field">
            <textarea-field v-model="resource.description"
                            :model-value="resource.description"
                            :max-length="maxCharDesc"
                            :input-resize="true"
                            input-place-holder="Describe your resources in few words"
                            class-name="resources_text border rounded-lg border-greys-200 p-2"/>
            <span v-if="resource && resource.description && resource.description.length" class="block text-right text-p-xs font-lighter">{{ maxCharDesc - (resource.description?.length ?? 0) }}/{{ maxCharDesc }}</span>
          </div>
        </div>
        <div class="resources_div">
          <label>Upload Your Resource</label>
          <div v-if="resourceLinkUrl === undefined || resourceLinkUrl === ''" class="dropzone">
            <div class="dropzone-inner" v-bind="getRootProps()">
              <div
                class="dropzone-large isDragActive"
                :class="{ isDragActive }"
              >
                <span v-if="!URLToggle" class="dropzone-large-drop">
                  <input v-bind="getInputProps()" />
                  <span class="text-4xl py-4">
                    <img class="w-12" src="https://firebasestorage.googleapis.com/v0/b/test-d9796.appspot.com/o/selfserve%2Fupload%20files.png?alt=media&token=ef40d23c-1d31-4c66-91f2-d4b2aaf50aa9">
                  </span>
                  <p class="dropzone-large-text">
                    Drag & drop files or <span class="dropzone-large-text-span cursor-pointer" @click.prevent="() => switchToUrl()">Add URL</span>
                  </p>
                  <p class="dropzone-large-formats">
                    Supported formats:  <span class="dropzone-large-formats-cap">  JPEG, PNG, GIF, MP4, PDF, PPT, DOC, AVI </span>
                  </p>
                  <p class="dropzone-small-restrict">
                    Please note that you can only add 1 resource at a time.
                  </p>
                </span>
                <div v-else class="add-url-box">
                    <i class="add-url-box-close fa fa-times dropzone-large-icon" @click.stop="() => switchToUrl()"></i>
                    <label class="font-medium add-url-box-label">Add URL</label>
                    <p class="pb-3">Paste Link Here</p>
                    <div class="add-url-link">
                      <div class="add-url-link-left">
                        <input-field id="block_resourceLink"
                                v-model="resource.resourceLink"
                                :model-value="resource.resourceLink"
                                name="block_headline"
                                input-type="text"
                                place-holder="https://youtube.com/videos"
                                class-name="resources_text field-input-base"
                                aria-label="block_headline"
                        />
                        <div v-if="!isValid" class="error add-url-link-left">Please enter valid link</div>
                      </div>
                      <base-button id="save-resource-link"
                                  name="save-resource-link"
                                  text="Add"
                                  aria-label="Add Button"
                                  class-name="add-url-link-btn"
                                  :functions="saveResourceLink"/>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="link-name">
              <div class="link-name-inner">
                  <span class="link-name-text">{{resourceName}}</span><span class="fa-thin fa-check link-name-resource"></span>
                  <i class="fa-thin fa-trash-can mr-2 link-name-delete" @click.prevent="() => deleteResourceLink()" />
              </div>
            <!-- Uploaded resource display HERE -->
          </div>
        </div>
        <div class="error">{{ resourceError.resourceLink ? resourceError.resourceLink : resourceError.value }}</div>
        <div class="error">{{ resourceError["server"] ?? "" }}</div>
        <span class="max-w-[80rem] flex w-full md:justify-end justify-center items-end pt-5 px-4">
          <base-button id="resource-cancel"
                        name="resource-cancel"
                        class-name=""
                        btn-type="text"
                        :functions="cancel"
                        text="Cancel" />
          <base-button v-if="resource.id === undefined || resource.id === ''"
                        id="resource-save"
                        name="resource-save"
                        text="Save"
                        aria-label="Save Button"
                        :is-loading="resourceUploadStatus"
                        :disabled="resourceUploadStatus"
                        :functions="create"/>
          <base-button v-else
                        id="resource-update"
                        name="resource-update"
                        text="Save"
                        aria-label="Update Button"
                        :is-loading="resourceUploadStatus"
                        :disabled="resourceUploadStatus"
                        :functions="update"/>
        </span>
      </div>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.support {
  &-header {
    @apply font-medium text-h3 tracking-tight text-center text-left md:pt-4 py-2;
  }
  &-form {
    @apply px-4 md:px-12;
    &-items {
      @apply py-3;
    }
    &-labels {
      @apply text-xs text-gray-500;
    }
    &-footer {
      @apply w-full flex flex-row items-center text-gray-500 pt-4 pb-12 justify-between;
    }
  }
}

.cancelBtn {
  @apply border-transparent text-product-500;
}
.submitBtn {
  @apply border-transparent bg-purple text-white rounded;
}
.modal {
  @apply top-auto sm:bottom-auto bottom-0 translate-y-0 sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2;
  &-main {
    @apply max-w-[30rem] p-5 pt-3 rounded-3xl md:rounded-b-3xl inline-block;
  }
}
.resources {
  &_div {
    @apply w-full mb-5 md:px-4 relative;
    label {
      @apply text-p-md;
    }
  }
  label {
    @apply block w-full mb-1 font-medium text-[0.625rem] leading-[0.875rem] text-grey-700;
  }
}
.hr {
  &_line {
    @apply border-gray-300 mb-5;
  }
}
.cancel {
  &-btn {
    @apply text-product-500 capitalize;
  }
}

.dropzone {
  @apply w-full flex flex-col md:flex-row justify-between;
  &-large {
    @apply relative border-dashed h-[15rem] w-full rounded-lg border flex flex-col justify-center items-center bg-product-100 border-product-500;
    &-icon {
      @apply text-product-500;
    }
    &-drop {
      @apply w-full flex flex-col items-center;
    }
    &-text {
      @apply max-w-[18rem] text-center text-xl;
    }
    &-container {
      @apply w-full h-full flex items-center justify-center bg-gray-200;
      &-embed {
        @apply h-full w-full;
      }
      &-video {
        @apply h-full w-full;
      }
    }
    &-img {
      @apply object-contain h-full;
    }
    &-text {
      @apply font-medium mb-3;
      &-span {
        @apply text-product-500;
      }
    }
    &-formats {
      @apply text-center font-medium capitalize;
      &-cap {
        @apply text-p-xs;
      }
    }
  }
  &-small {
    @apply bg-gray-200 h-[3rem] w-[5rem] rounded-lg border flex justify-center items-center md:mb-5 relative cursor-pointer;
    &-file {
      @apply flex items-center justify-center text-2xl w-full;
    }
    &-container {
      @apply flex md:inline-block flex-row pt-4 md:pt-0 overflow-x-auto gap-x-4 md:gap-x-0 md:overflow-visible;
    }
    &-img {
      @apply flex items-center justify-center h-full;
      &-image {
        @apply object-contain h-full;
      }
    }
    &_active {
      @apply border-black text-black;
    }
    &_inActive {
      @apply border-gray-400 text-gray-400;
    }
    &-delete {
      @apply absolute md:-top-4 md:-right-4 text-white md:text-3xl bg-purple-500 rounded-full cursor-pointer md:w-9 md:h-9 flex items-center justify-center -top-2 -right-2 w-6 h-6;
      &:hover {
        @apply text-product-500 border-2 border-product-500 bg-white
      }
    }
    &-preview {
      @apply w-full h-full;
    }
    &-restrict {
      @apply text-center text-gray-500 text-p-sm;
    }
  }
  &-inner {
    @apply w-full;
  }
}
.cover {
  &-img {
    @apply flex items-center mb-5;
    &-update {
      @apply max-w-[18rem] mx-auto h-[9rem] relative;
      img {
        @apply h-[9rem] w-full object-cover rounded-lg;
      }
    }
  }
}

.resource {
  &-card {
    &-header {
      @apply text-xs absolute top-0 left-0 w-full flex flex-row justify-between;
      &_title {
        @apply truncate max-w-[12rem];
      }
      &-status {
        @apply flex flex-row;
      }
    }
    &_title {
      @apply capitalize font-semibold;
    }
    &_btn {
      @apply uppercase text-white text-sm rounded px-4 py-2;
    }
    &-list {
      @apply rounded-lg flex flex-wrap grow gap-4 py-6;
    }
    &-menu { // Project Card Header Menu
      &-list {
        @apply text-lg md:text-xs flex flex-col items-start;
      }
      &-item {
        @apply py-3 ml-6 cursor-pointer hover:text-product-500;
      }
    }
  }
}
.link {
  &-name {
    &-inner {
      @apply border border-grey-600 px-3 py-0 h-[2.3rem] text-xs rounded-lg leading-9 relative pr-10 flex items-center;
    }
    &-text {
      @apply line-clamp-1 mr-3;
    }
    &-resource {
      @apply font-bold;
      &::before {
        @apply text-other-green;
      }
    }
    &-delete {
      @apply absolute top-0 right-1 cursor-pointer leading-8;
    }
  }
}
.add {
  &-url {
    &-box {
      @apply w-full px-5;
      &-close {
        @apply absolute top-3.5 right-3.5 cursor-pointer text-gray-700;
      }
      &-label {
        @apply hidden;
      }
    }
    &-link {
      @apply flex items-start;
      &-left {
        @apply w-full;
        &-error {
          @apply text-left font-medium text-p-sm;
        }
      }
      &-btn {
        @apply h-10 w-20 m-2;
      }
      label {
        @apply hidden;
      }
    }
  }
}
.resource-modal :deep(.modal) {
  @apply overflow-y-auto;
}
:deep(.field-input-error-text) {
  @apply text-left;
}
:deep(.field-input) {
  @apply w-full;
}
</style>
