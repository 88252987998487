const urlExpression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const urlRegex = new RegExp(urlExpression)

// A utility function that checks if a date is valid.
function isValidDate(dateString) {
    if (dateString.toString() === "Wed Dec 31 1969 19:00:00 GMT-0500 (Eastern Standard Time)") {
        return false
    } else {
        const date = new Date(dateString)
        return date instanceof Date && !isNaN(date)
    }
}

export {
    urlRegex,
    isValidDate
}
