<script setup>
// Required Packages
import { computed } from 'vue'
import ModalLayout from '@/components/Modals/ModalBase'
import BaseButton from '@/components/Base/Button/BaseButton'
import "vue-select/dist/vue-select.css"
import store from '@/store'
import { config } from '@/config'

const zFairUrl = computed(() => {
  const params = {
    'ex-org-id': store.getters.getActiveOrganization.id,
    'email': store.getters.user.email,
    'return-to': window.location.href
  }
  return `${config.ZFAIR_URL}?${new URLSearchParams(params).toString()}`
})

const closeModal = () => {
  store.dispatch('CloseModal')
}


const connectToZFairs = () => {
  window.open(zFairUrl.value, '_blank')
}
</script>

<template>
  <modal-layout modal-class="modal-integration">
    <template #modal-title>
      <h4 class="modal-integration-title">
        Import Participants From Your zFairs Account
      </h4>
    </template>
    <template #modal-content>
      <div class="modal-integration-container">
        <div class="modal-integration-logo-container">
          <img class="m-auto mt-4" src="/images/zfair_to_pb.png"/>
        </div>
        <p class="pt-4 font-medium">By clicking 'Import' below, you authorize ProjectBoard to retrieve the following data from your zFairs account:</p>
        <div class="modal-integration-allows">
          <div class="modal-integration-allows-item"><i class="modal-integration-allows-item-icon fa-solid fa-check"></i> Participant Details</div>
          <div class="modal-integration-allows-item"><i class="modal-integration-allows-item-icon fa-solid fa-check"></i> Organizational Information</div>
        </div>
      </div>
    </template>
    <template #modal-button>
      <span class="modal-integration-btn-container">
        <base-button :functions="closeModal"
                      class-name="modal-integration-btn"
                      btn-type="text"
                      text="">
          <template #contents>
            <span class="capitalize font-light">
              Cancel
            </span>
          </template>
        </base-button>
        <base-button :functions="connectToZFairs"
                      class-name="modal-integration-btn"
                      text="">
          <template #contents>
            <span class="capitalize font-light">
              Allow Access
            </span>
          </template>
        </base-button>
      </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.modal {
  &-integration {
    @apply md:h-auto overflow-y-scroll max-w-[32rem] md:p-6 p-4 rounded-b-none md:rounded-2xl bg-white;
    &-container {
      @apply px-3;
    }
    &-logo {
      &-container {
        @apply py-4;
      }
    }
    &-content {
      @apply text-blacks-300 text-p-md text-center;
    }
    &-members {
      @apply pr-10 md:min-h-[10rem] md:max-h-[30rem] overflow-y-auto;
    }
    &-title {
      @apply text-h4 font-medium text-blacks-500 pt-2 pb-2;
    }
    &-subTitle {
      @apply text-p-md text-blacks-300 text-center px-7;
    }
    &-invited {
      @apply text-p-xs capitalize text-blacks-500 pt-5 pb-2 font-semibold;
    }
    &-invitees {
      @apply flex flex-col pb-4;
    }
    &-invitee {
      @apply flex flex-row py-2 items-center justify-between;
    }
    &-underline {
      @apply text-greys-300 w-[100%] h-0.5 pb-2;
    }
    &-btn {
      @apply w-auto h-[2.5rem] mx-2 normal-case p-4;
      &-container {
        @apply flex flex-row justify-end;
      }
    }
    &-allows {
      @apply flex flex-col pt-4 min-h-[10rem];
      &-item {
        @apply pt-2;
        &-icon {
          @apply pr-2 text-primary text-[1.5rem];
        }
      }
    }
    &-consent {
      @apply text-sm py-4;
    }
  }
}
</style>
