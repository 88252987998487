<script setup>
// Required Components
import { computed, ref } from 'vue'
// Store
import store from '@/store'
// Custom Components
import ModalLayout from '@/components/Modals/ModalAuthBase'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'
import BaseInput from '@/components/Base/Fields/InputField'
import Avatar from '@/components/Base/Avatar'

const props = defineProps({
  usernameOrEmail: {
    type: String,
    requires: true
  }
})

const isLoading = ref(false)
const usernameOrEmail = ref(props.usernameOrEmail)
const resetPasswordErrorMessage = computed(() => {
  return store.getters.resetPasswordError
})

const resetPassword = () => {
  let payload = { usernameOrEmail: usernameOrEmail.value }
  isLoading.value = true
  store.dispatch('resetPassword', payload).then(() => {
    if (store.getters.resetPasswordStatus === 'loaded') {
      store.dispatch('ChangeModalType', 'check-reset-password-email')
    }
    isLoading.value = false
  }).catch(() => {
    isLoading.value = false
  })
}

const toggleModal = () => {
  store.dispatch('ToggleModal')
  store.dispatch('ToggleOverlay')
  store.dispatch('ResetModal')
}
</script>

<template>
  <div class="forget-password-header">
    <img src="/images/logo/projectboard.png"
         alt="login logo"
         class="forget-password-header-logo" />
  </div>
  <modal-layout>
    <template #modal-title>
      <avatar
          class-name="forget-password-avatar"
          image="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/selfserve%2Fimages%2Ftabler-icon-circle-key-filled.png?alt=media&token=f6006fe8-abb8-4a5f-9ec6-557222de7f12"/>
      Forgot Password?
    </template>
    <template #modal-subtitle>
        No Worries, We’ll send you reset instructions
    </template>
    <template #modal-body>
      <span class="forget-password">
        <label for="usernameOrEmail"
               class="forget-password-input-label">
          Email/Username
        </label>
        <base-input v-model="usernameOrEmail"
                    input-id="usernameOrEmail"
                    class-name="w-full py-2 max-w-[24rem]"
                    input-name="usernameOrEmail"
                    input-type="text"
                    place-holder="Enter your Username or Email"
                    :model-value="usernameOrEmail"
                    :errors="resetPasswordErrorMessage"
                    input-aria-label="username or email of account"
                    @handle-enter="resetPassword" />
      </span>
    </template>
    <template #modal-button>
      <span class="w-full text-center py-4">
        <base-button id="resetPassword"
                     class-name="forget-password-btn"
                     name="resetPassword"
                     text="Reset Password"
                     :disabled="isLoading"
                     :is-loading="isLoading"
                     :functions="resetPassword" />
      </span>
      <span class="forget-password-login"
            @click="toggleModal">
        <i class="fa-regular fa-chevron-left"/> Back to Login
      </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.forget-password {
    @apply flex flex-col w-full justify-center md:px-[7.4rem];
  &-avatar {
    @apply w-14 h-14 bg-violet-200 p-2 border-none mb-4 md:mb-0;
  }
  &-header {
    @apply w-full justify-center items-center hidden md:inline-flex;
    &-logo {
      @apply w-auto h-[2rem] mb-10;
    }
  }
  &-login {
    @apply text-center text-primary cursor-pointer text-p-sm font-medium pb-8 pt-2;
    &:hover {
      @apply text-secondary;
    }
  }
  &-input {
    &-label {
      @apply text-p-sm text-blacks-500 ml-3;
    }
  }
  &-btn {
    @apply capitalize w-full md:w-[24rem] py-2 font-normal;
  }
}

.error {
  @apply text-p-sm font-normal;
}

.modal-forget-password :deep(.modal-header_title) {
  @apply flex flex-col justify-center items-center;
}
</style>
