<script setup>
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import store from '@/store'
import ModalBase from '@/components/Modals/ModalBase'

// Modals
const isModal = computed(() => {
  return store.getters.modal
})
const firstText = ref('')

// Progress bar
const progressBar = computed(() => {
  return store.getters.progressBar
})

const user = computed(() => {
  const { userName, firstName, lastName, fullName } = store.getters.user
  if (fullName) return fullName
  else if (firstName && lastName) return `${firstName} ${lastName}`
  else if (userName) return userName
  return 'User'
})

onBeforeMount(async () => {
  await store.dispatch('GetEvents')
  firstText.value = store.getters.events?.length === 0 ? 'first' : ''
})

onMounted( () => {
  if (!isModal.value) store.dispatch('ToggleOverlay')
})
</script>

<template>
  <modal-base>
    <template #modal-title>
      <h1 class="modal-hero-text">
        Hello, {{ user }}!
        <br/>Create your {{ firstText || '' }} Showcase
      </h1>
    </template>
    <template #modal-content>
      <span class="progress">
        <span class="progress-container">
          <div class="progress-bar mr-2"
               :class="{'progress-bar-active' : progressBar >= 0 }"/>
          <div class="progress-bar mx-2"
               :class="{
                'progress-bar-active' : progressBar >= 1,
                'progress-bar-inActive' : progressBar < 1
                }"/>
        </span>
        <span class="progress-content">
          <slot name="modal-body" />
        </span>
      </span>
      <slot name="modal-button"></slot>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal {
  &-main {
    @apply border rounded-xl flex flex-col bg-gray-100 w-screen md:h-auto md:w-[60rem];
  }
  &-hero {
     @apply w-full p-4;
  &-icon {
     @apply absolute right-2 p-1 cursor-pointer;
   }
  &-text {
     @apply text-center font-bold mt-16 text-4xl;
   }
  }
}
.header {
  @apply w-full text-center;
}

.button-container {
  @apply w-full text-right py-4 px-16 md:px-40;
  &-feature {
    @apply w-full text-right py-4 px-16;
  }
}

.nextBtn {
  @apply bg-purple text-white uppercase rounded-md text-sm;
}
.skipBtn {
  @apply text-purple uppercase rounded-md text-sm mr-4 border-2 border-gray-300;
}

.progress {
  @apply flex flex-col w-full justify-center items-center;
  &-container {
    @apply flex w-2/3 py-6 justify-between;
  }
  &-content {
    @apply flex w-2/3 py-2 justify-between;
  }
  &-bar {
    @apply h-3 w-1/2 border border-2 rounded-xl;
    &-active {
      @apply bg-green-600;
    }
    &-inActive {
    @apply bg-gray-300 border-gray-300;
    }
  }
}
</style>
