<script setup>
import store from '@/store'
import BaseInput from '@/components/Base/Fields/InputField'
import BaseButton from '@/components/Base/Button/BaseButton'
import ModalLayout from '@/components/Modals/ModalAuthBase'
import {ref, computed} from "vue"

const props = defineProps({
  usernameOrEmail: {
    type: String,
    required: true
  },
})

const usernameOrEmail = ref(props.usernameOrEmail)
// Temporary to hide 3rd login
const isMvp = true

const signIn = () => {
  let payload = {}
  if (usernameOrEmail.value.includes('@')) {
    payload = {
      email: usernameOrEmail.value
    }
  } else {
    payload = {
      username: usernameOrEmail.value
    }
  }
  store.dispatch('validateUsernameOrEmail', payload).then(() => {
    if (!store.getters.isUserVerified) {
      store.dispatch('ChangeModalType', 'verify')
    } else {
      store.dispatch('ChangeModalType', 'password')
    }
  })
}

const usernameOrEmailValidationErrorMessage = computed(() => {
  return store.getters.usernameOrEmailError
})
</script>

<template>
  <modal-layout>
    <template #modal-title>Log in</template>

    <template #modal-body>
      <span class="login" >
        <base-input v-model="usernameOrEmail"
                    input-type="text"
                    input-name="usernameOrEmail"
                    input-id="usernameOrEmail"
                    class-name="text-center w-full"
                    input-aria-label="sign in email or username"
                    input-place-holder="Username or Email"
                    @handle-enter="signIn"/>

        <div class="error">{{usernameOrEmailValidationErrorMessage}}</div>
      </span>
      <span class="social">
        <ul v-if="!isMvp" class="social-container">
          <li>
            <span class="social-avatar">
            <i class="fa-brands fa-apple text-white"></i>
            </span>
          </li>
          <li>
            <span class="social-avatar">
            <i class="fa-brands fa-google text-white"></i>
            </span>
          </li>
          <li>
            <span class="social-avatar">
            <i class="fa-solid fa-phone text-white"></i>
            </span>
          </li>
        </ul>
        <h5 class="register">
          New User? <span class="register_text"
                          @click="() => store.dispatch('ChangeModalType', 'register')">Sign up</span>
        </h5>
      </span>
    </template>

    <template #modal-button>
        <span class="w-full text-center py-4">
          <base-button id="next"
                       name="next"
                       text="Next"
                       class-name="btn btn-primary"
                       :functions="signIn" />
        </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.login {
  @apply m-4 px-10;
}
.social {
  @apply w-full text-center;
  &-container {
    @apply flex flex-row w-full justify-around px-36 py-2;
  }
  &-avatar {
    @apply inline-block h-7 w-7 rounded-full bg-purple flex justify-center items-center;
  }
}
.register {
  @apply py-2 font-bold text-sm;
  &_text {
    @apply text-purple cursor-pointer;
  }
}
</style>
