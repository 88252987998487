<script setup>
// Ref
import { ref } from 'vue'
// Props
const props = defineProps({
  inputType: {
    type: String,
    default: 'text'
  },
  inputMaxLength: Number,
  inputAriaLabel: String,
  inputPlaceHolder: String,
  inputId: String,
  inputName: String,
  modelValue: {
    type: [String, Boolean, Number],
    required: true
  },
  labelName: {
    type: String
  },
  labelId: {
    type: String
  },
  labelFor: {
    type: String
  },
  labelText: {
    type: String
  },
  tabIndex: {
    type: Number,
    default: 0
  },
  className: String,
  errors: String,
  disabled: {
    type: Boolean,
    default: false
  },
  checked: {
    type: Boolean,
    default: false
  }
})
// Checks
const isSearch = (props.inputId === 'Search' || props.inputName === 'Search' || props.inputPlaceHolder === 'Search')
const isPassword = props.inputType === 'password'
const isNotInputs = (props.inputType !== 'text' && props.inputType !== 'password' && props.inputType !== 'email')
// Sync with input
const currentType = ref(props.inputType)
defineEmits(['update:modelValue'])
// Password Toggle
const showPassword = ref(false)
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value
  if (showPassword.value) {
    currentType.value = 'text'
  } else {
    currentType.value = 'password'
  }
}
</script>

<template>
  <span :class="{
          'field' : !isNotInputs,
          'field-notInputs' : isNotInputs
        }">
    <slot name="field-label">
      <label v-if="!isSearch"
             :id="labelId"
             :qa="labelId ? `qa-${labelId}-label` : null"
             :class="{
               'field-label' : !isNotInputs,
               'field-label-notInputs' : isNotInputs
             }"
             :aria-label="labelName"
             :for="labelName">
        {{labelText}}
      </label>
    </slot>
    <i v-if="isSearch"
       class="fa-solid fa-magnifying-glass field-search-icon" />
    <input :id="inputId"
           class="field-input"
           :class="`${className && className.length > 0 ? className : 'field-input-base'}
           ${ errors ? 'field-input-error' : 'field-input-border'}
           ${ currentType === 'password' && modelValue.length > 0 ? 'field-input-password' : ''}
           ${ isSearch ? 'field-search' : 'field-regular'}
           ${ inputType === 'number' ? 'field-input-number' : 'field-input-text'}`"
           :type="currentType"
           :disabled="disabled"
           :name="inputName"
           :value="modelValue"
           :tabindex="tabIndex"
           :aria-label="inputAriaLabel"
           :placeholder="inputPlaceHolder"
           :maxlength="inputMaxLength"
           :checked="checked"
           :qa="`qa-${inputId}`"
           @input="$emit('update:modelValue', $event.target.value)"/>
    <span v-if="isPassword"
                 text=""
                 class="field-input-password-eye"
                 @click.prevent="toggleShowPassword">
        <img v-if="!showPassword"
             class="w-4 h-auto mt-1"
             src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Feye-close-vector.png?alt=media&token=8ebe4791-733c-4cbe-8eb4-e8ce6987ecae"
             alt="not show password"/>
        <i v-else
           class="fa-regular fa-eye text-primary" />
    </span>
    <span v-if="!isSearch"
          class="field-input-error-text paragraph-xs">
      {{errors}}
    </span>
  </span>
</template>

<style lang="scss" scoped>
.field {
  @apply flex flex-col relative;
  &-notInputs {
    @apply flex flex-row-reverse items-center justify-end accent-primary capitalize;
  }
  &-label {
    @apply mb-1.5 ml-0.5 text-p-md;
    &-notInputs {
      @apply mt-0.5 ml-2 text-p-xl font-medium;
    }
  }
  &-regular {
    @apply px-3.5;
  }
  &-search {
    @apply pl-7 pr-3.5 bg-greys-100 w-full md:max-w-[13.75rem] py-2;
    &:enabled {
      &:hover {
        @apply bg-background-400;
      }
      &:focus {
        @apply bg-background-400;
      }
    }
    &:disabled {
      @apply cursor-not-allowed;
    }
    &-icon {
      @apply absolute bottom-[1.4rem] left-[1rem] text-greys-300 text-opacity-60;
    }
  }
  &-input {
    @apply rounded-lg border py-2;
    &-base {
      @apply py-2 w-full;
    }
    &-number {
      @apply text-center font-bold text-primary text-access;
    }
    &-text {
      @apply text-blacks-500;
    }
    &-base {
      @apply md:w-[21.875rem];
    }
    &-password {
      @apply tracking-[.5em] pr-9;
      &-eye {
        @apply absolute right-0 py-5 px-5 flex justify-center items-center cursor-pointer;
      }
    }
    &-border {
      @apply border-greys-200;
    }
    &-error {
      @apply border-other-red;
      &-text {
        @apply text-other-red text-center py-1 font-medium text-p-sm;
      }
    }
    // Disabled Styles
    &:disabled {
      @apply bg-greys-100;
    }
    // Enabled Styles
    &:enabled {
      &:focus {
        @apply outline-primary outline outline-1;
      }
      &:hover {
        @apply border-primary;
      }
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}


  .org-form {
    .field {
      &-input {
        @apply w-full;
      }
    }
  }
</style>
