<script setup>
// Store
// Modal
import store from '@/store'
import ModalLayout from '@/components/Modals/ModalBase'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'

const toggleInviteParticipantsPreviewModal = () => {
  store.dispatch('ToggleModalInviteParticipantsPreview')
  store.dispatch('ToggleModalInviteParticipants')
}
</script>

<template>
  <modal-layout modal-class="bg-white modal-participantsPreview" :is-close="true">
    <template #modal-title>
      <span class="flex flex-row">
        <base-button
          class-name="modal-participantsPreview-close-btn"
          btn-type="text"
          :functions="toggleInviteParticipantsPreviewModal">
          <template #contents>
            <i class="fa-solid fa-angle-left text-blacks-300"></i>
          </template>
        </base-button>
        <h1 class=" w-full modal-participantsPreview-title">Email Preview</h1>
      </span>
      <hr class="h-[1px] w-full bg-[#c9c9c9]" />
    </template>
    <template #modal-subContent>
      <span class="mt-4 flex flex-col">
        <div>
          <span class="font-medium">From: </span> ProjectBoard
        </div>
        <div>
          <span class="font-medium">Subject: </span> You're Invited To Join <span class="font-bold">(Showcase Name)</span>
        </div>
      </span>
    </template>
    <template #modal-content>
      <div class="modal-participantsPreview-content">
        <p class="justify-center items-center">
          You’ve been invited by <span class="font-bold">(First Name Last Name) </span>
          to participate in <span class="font-bold">(Showcase Name)</span>.
        </p>
        <br/>
        <p class="justify-center items-center">
          Click below to access the event details and start your project.
        </p>
        <div class="modal-participantsPreview-btn" >
          <base-button id="confirmationSent"
                       name="confirmationSent"
                       class-name="modal-participantsPreview-btn-container"
                       text="Start Your Project"
                       btn-type="primary"/>
        </div>
        <div class="modal-participantsPreview-footer">
          <img src="/images/logo/projectboard.png"
               alt="login logo"
               class="modal-participantsPreview-footer-logo" />
        </div>
      </div>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.modal {
  &-participantsPreview {
    @apply md:h-auto overflow-y-scroll max-w-md md:p-7 p-4 rounded-b-none md:rounded-2xl;
    &-content {
      @apply text-p-md text-center rounded-lg border-2 pt-14 pl-10 pr-10 pb-6 text-p-sm mt-6;
    }
    &-title {
      @apply text-h4 font-medium text-blacks-500 text-center pb-4;
    }
    &-hr {
      @apply w-full h-0.5 bg-greys-300;
    }
    &-btn {
      @apply w-full h-[2.5rem];
      &-container {
        @apply p-3 mt-8;
      }
    }
    &-footer {
      @apply flex w-full justify-center items-center pt-10 mt-10;
      &-logo {
        @apply w-auto h-[1rem] mb-1 mt-6;
      }
    }
  }
}
</style>
