import tracking from '@/utils/tracking'

export const trackingIntercepter = (to) => {
    const utmParams = {
        utmSource: to.query.utm_source || '',
        utmMedium: to.query.utm_medium || '',
        utmCampaign: to.query.utm_campaign || '',
        utmTerm: to.query.utm_term || '',
        utmContent: to.query.utm_content || ''
    }

    if (Object.keys(utmParams).some(k => utmParams[k])) {
        tracking.setUtmParams(utmParams)
    }
}
