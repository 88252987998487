<script setup>
// Required Packages
import { computed, onMounted, ref, watch } from 'vue'
// Store
import store from '@/store'
// Base Components
import ModalBase from '@/components/Modals/ModalBase'
// Custom Components
import BaseButton from '@/components/Base/Button/BaseButton'
import BaseInput from '@/components/Base/Fields/InputField'
import { isFirebaseStorageURL, processStatuses } from '@/utils'
import Avatar from '@/components/Base/Avatar'
import { Icons } from 'icons'

const eventAwards = computed(() => {
  return store.getters.allEventAwards
})

const eventAwardsStatus = computed(() => {
  return store.getters.awardsStatus
})

const activeProjectId = computed(() => {
  return store.getters.modalData.data.projectId
})

const activeProjectAwards = computed(() => {
  return store.getters.getProjectAwardsByProjectId(activeProjectId.value)
})

const selectedProjectAwardIds = ref([])

const save = () => {
  store.dispatch('replaceAwardsToProject', { projectId: activeProjectId.value, awardsIds: selectedProjectAwardIds.value }).then(() => {
    store.dispatch('ResetModal')
  })
}

const cancel = () => {
  store.dispatch('ResetModal')
}

const select = (e, item) => {
  if (e.target.checked) {
    selectedProjectAwardIds.value.push(item.id)
  } else {
    selectedProjectAwardIds.value = selectedProjectAwardIds.value?.filter((awardId) => awardId !== item.id) ?? []
  }
}

watch(activeProjectAwards, (newVal) => {
  selectedProjectAwardIds.value = newVal?.map(award => award.id) ?? []
})

onMounted(() => {
  // Fetch all event awards
  if (eventAwardsStatus.value === processStatuses.IDLE) {
    store.dispatch('fetchEventAwards')
  }

  // Project awards suppose to be fetched in the parent page, in case it doesn't fetch yet (by checking status), fetch it here
  if (store.getters.projectAwardsStatus === processStatuses.IDLE) {
    store.dispatch('fetchProjectAwards')
  }
  // Check if the awards has been selected
  if (store.getters.projectAwardsStatus === processStatuses.LOADED) {
    if (activeProjectAwards.value?.length > 0) {
      activeProjectAwards.value?.map(award => {
        selectedProjectAwardIds.value.push(award.id)
      })
    }
  }
})
</script>

<template>
  <modal-base modal-class="modal-assign-awards">
    <template #modal-title>
      <h3 class="modal-assign-awards-title">
        Assign Awards
      </h3>
    </template>
    <template #modal-subContent>
      <hr />
    </template>
    <template #modal-content>
      <div v-if="eventAwardsStatus === processStatuses.LOADED"
          class="modal-assign-awards-body">
          <ul>
            <li v-for="item in eventAwards"
                :key="item"
                class="modal-assign-awards-item">
              <label class="modal-assign-awards-item-label"
                     :for="item.id">
                <avatar v-if="item.image !== ''"
                        :is-large="false"
                        class-name="avatar-medium">
                  <template #profile>
                    <img v-if="isFirebaseStorageURL(item.image)"
                         :src="item.image"
                         :alt="item.name"
                         class="h-10 rounded-full" />
                    <icons v-else-if="((typeof item.image) === 'string' && item.image?.length > 0)"
                           :name="item.image"
                           :enable-color="true"
                           :invert-color="false"
                           type="cycle"
                           size="xl"/>
                  </template>
                </avatar>
                <span>{{item.name}}</span>
              </label>
              <base-input :checked="selectedProjectAwardIds.includes(item.id)"
                          :model-value="item.id"
                          :input-id="`${item.id}`"
                          :input-name="`${item.id}`"
                          input-type="checkbox"
                          class-name="modal-assign-awards-item-input"
                          @change="(e) => select(e, item)" />
            </li>
          </ul>
      </div>
    </template>
    <template #modal-button>
      <span class="modal-assign-awards-button-wrapper">
        <base-button id="cancelAssignAwards"
                     name="cancelAssignAwards"
                     :functions="cancel"
                     class-name="modal-assign-awards-button"
                     text="Cancel"
                     btn-type="text"/>
        <base-button id="chooseAwards"
                     name="chooseAwards"
                     text="Choose"
                     :disabled="eventAwardsStatus === processStatuses.LOADING"
                     class-name="modal-assign-awards-button"
                     :functions="save"/>
      </span>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal-assign-awards {
  @apply bg-white max-w-[32.625rem] max-h-[46.875rem] px-6 py-4;
  &-title {
    @apply pb-4 pt-2 text-left font-medium text-h2;
  }
  &-description {
    @apply text-center text-p-sm text-[#151515] md:px-8 px-2;
  }
  &-body {
    @apply mb-6 overflow-y-auto;
  }
  &-accordian {
    @apply p-6 w-full flex flex-col overflow-hidden;
    &:hover {
      @apply overflow-y-auto;
    }
  }
  &-button {
    @apply capitalize text-p-sm px-4 py-2;
    &-wrapper {
      @apply w-full flex flex-row justify-end;
    }
  }
  &-item {
    @apply flex justify-between mt-4;
    &-label {
      @apply flex flex-row items-center space-x-2;
    }
    &-input {
      @apply cursor-pointer flex items-center justify-center flex-row w-full;
    }
  }
}

// Checkbox Override for the arrows
input[type=checkbox] {
  background-color: transparent;
  appearance: none;;
  width: 15px;;
  height: 15px;
  border: 2px solid black;
  border-radius: 2px;
}

input[type=checkbox]:checked {
  appearance: none;
  outline: none;
  padding: 0;
  content: none;
  border: 2px solid #8400F0;
}

input[type=checkbox]:checked::before {
  color: #8400F0;
  transition: 120ms transform ease-in-out;
  content: "\00A0\2713\00A0";
  font-weight: bolder;
  font-size: 12px;
}
</style>
