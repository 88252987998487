import UserService from "@/services/user"
import { processStatuses } from '@/utils'

export default {
  state: () => ({
    user: null,
    users: [],
    userStatus: processStatuses.IDLE,
    usersStatus: processStatuses.IDLE,
    userError: null
  }),
  getters: {
    user: state => state.user,
    displayName: state => state.user ? `${state.user.firstName} ${state.user.lastName} (@${state.user.userName})` : '',
    userNameInitial: state => {
      if (state.user) {
        const fullNameInitial = `${state.user.firstName.charAt(0)}${state.user.lastName.charAt(0)}`
        if (fullNameInitial && fullNameInitial !== '') {
          return fullNameInitial
        }
        return state.user.userName.substring(0, 2)
      }
      return ''
    },
    userIsLoading: state => state.userStatus === processStatuses.LOADING,
    userIsLoaded: state => state.userStatus === processStatuses.LOADED && state.user,
    userStatus: state => state.userStatus,
    users: state => state.users
  },
  mutations: {
    USER_LOADED(state, payload) {
      state.user = payload
      state.userStatus = processStatuses.LOADED
    },
    USER_LOADING(state) {
      state.userStatus = processStatuses.LOADING
    },
    USER_LOAD_ERROR(state, error) {
      state.userStatus = processStatuses.ERROR
      state.userError = error
    }
  },
  actions: {
    fetchCurrentUser({commit, getters, rootGetters}) {
      if (rootGetters.isLoggedIn && !getters.userIsLoading) {
        commit('USER_LOADING')
        commit('ORGANIZATIONS_LOADING', null, {root: true})
        return new Promise((resolve, reject) => {
          UserService.getUser({username: rootGetters.username, include: 'organization'}).then((response) => {
            commit('USER_LOADED', response.data.user)
            commit('ORGANIZATIONS_LOADED', { organizations: response.data.user.organizations, userId: response.data.user.id }, {root: true})
            resolve()
          }).catch((err) => {
            commit('USER_LOAD_ERROR', err)
            commit('ORGANIZATIONS_ERROR', err, {root: true})
            reject(err)
          })
        })
      }
    },
    getUsers({commit}, payload) {
      return new Promise((resolve, reject) => {
        UserService.getUsers(payload).then((response) => {
          commit('USER_ADMIN_LOADED', response.data)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    }
  }
}
