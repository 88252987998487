<script setup>
import { computed } from 'vue'
import store from '@/store'
import EventCard from './EventCard'
import { processStatuses } from "@/utils"

const isEventsStatusLoading = computed(() => {
  return store.getters.eventsStatus === processStatuses.LOADING
})

const events = computed(() => {
  return store.getters.events.length > 0 ? store.getters.events : (store.getters.eventsStatus === processStatuses.LOADING ? [{}, {}, {}, {}] : [])
})

const getSubscription = (eventId) => {
  const subscriptions = store.getters.subscriptionPlans.filter(subscription => subscription.eventId === eventId)
  if (subscriptions.length === 0) {
    return {}
  }
  let eventSubscription = subscriptions[0]
  subscriptions.forEach(subscription => {
    if (subscription?.plan?.id > eventSubscription?.plan?.id) {
      eventSubscription = subscription
    }
  })
  return eventSubscription
}
</script>

<template>
  <ul class="list" :class="{'skeleton': isEventsStatusLoading}">
    <event-card v-for="cardItem in events"
                :key="cardItem.id"
                :card="cardItem"
                :logo="cardItem?.eventSettings?.image"
                :subscription="getSubscription(cardItem?.id)"
                :is-loading="isEventsStatusLoading"/>
  </ul>
</template>

<style lang="scss" scoped>
.list {
  @apply mt-6 flex grow flex-wrap gap-4;
}
</style>
