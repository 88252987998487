<script setup>
// Required Components
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// Store
import store from '@/store'
// Base Components
import CardSlot from '@/components/Base/Cards/CardSlot'
import Overflow from '@/components/Base/Menu/Overflow'
import BaseButton from '@/components/Base/Button/BaseButton'
import Tag from '@/components/Base/Tags'
import TagLabel from '@/components/Base/Tags/Labels'
// Utils
import { getDecodedRoles, eventPlans } from '@/utils'
import Tooltip from '@/components/Lib/Tooltip';

const props = defineProps({
  card: {
    type: Object,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  subscription: {
    type: Object,
    required: true
  },
  logo: {
    type: String,
    default: ''
  }
})

const router = useRouter()
const route = useRoute()

const user = computed(() => {
  return store.getters.user
})
const feedback = computed(() => {
  return store.getters.feedback
})

const isEvents = computed(() => {
  return route.name === 'Events'
})

const cancelEventOnClick = () => {
  const payload = {
    eventId: props.card.id,
    title: 'Are you sure you want to cancel your event?',
    description: ' If you decide to cancel your event, our support team will connect with you shortly. Note, any project templates already started by a participant, will not be deleted.',
    eventCancelFeedback: 'Tell us why you want to cancel',
    eventTeamResponseTime: 'Response times from our team may vary, but we will try our hardest to get back to you within 24 hours.',
    confirmButton: 'Submit',
    cancelButton: 'Back to Events',
    confirmAction: () => {
      store.dispatch('submitEventFeedback', { eventId: props.card?.id, userId: user.value.id, feedback: feedback.value }).then(() => {
        if (userRoles.value.includes('super_admin')) {
          store.dispatch('deleteEvent', { eventId: props.card?.id }).then(() => {
            store.dispatch('GetEvents')
          })
        }
        store.dispatch('ResetModal')
        store.dispatch('ToggleModalEventCancelled')
        store.dispatch('resetFeedbackValidationError')
      })
    },
    cancelAction: () => {
      store.dispatch('ResetModal')
      store.dispatch('resetFeedbackValidationError')
    },
    type: 'confirmEvent'
  }
  store.dispatch('ToggleModalEventCancelConfirmation', payload)
  store.dispatch('resetFeedbackValidationError')
}

const deleteEventOnClick = () => {
  const payload = {
    eventId: props.card.id,
    title: 'Are you sure you want to Delete your event?',
    description: 'After deleting the event, This action is irreversible and cannot be undone.',
    confirmButton: 'Yes, Delete Event',
    cancelButton: 'Cancel',
    confirmAction: () => {
      store.dispatch('deleteEvent', { eventId: props.card?.id }).then(() => {
        store.dispatch('GetEvents')
      })
      store.dispatch('ResetModal')
    },
    cancelAction: () => {
      store.dispatch('ResetModal')
    },
    type: 'deleteEvent'
  }
  store.dispatch('ToggleModalEventDeleteConfirmation', payload)
}

const cardMenuItems = [
  {
    name: props.card.eventSettings?.status === 'Published' ? 'Cancel Showcase' : 'Delete',
    icon: 'fa-light fa-times',
    onMenuItemClick: props.card.eventSettings?.status === 'Published' ? cancelEventOnClick : deleteEventOnClick
  }
]

const getEventStatus = (eventSettings) => {
  if (eventSettings) return eventSettings?.status === 'Published' ? 'Live' : eventSettings.status
  return 'draft'
}

const toggleEventBuilder = async (event) => {
  if (event) {
    await store.dispatch('setEventBuilder', event.id)
    await router.push({ name: 'General', params: { 'slug': event.slug } })
  }

  if (event != null) {
    await store.dispatch('getEventConfigs', { eventId: event.id})
  }
}

const userRoles = computed(() => {
  return getDecodedRoles() || []
})

const toggleUpgrade = (eventId) => {
  if (store.getters.hasRemainingPaidEvents(props.subscription?.plan?.name)) {
    store.dispatch('ToggleModalUpgradeEvent', { eventId: eventId })
  } else {
    // If we are upgrading from Basic to Premium, give 250 discount
    let discount = null
    if (props.subscription?.plan?.name === 'basic') {
      discount = {
        name: 'premium',
        amount: -500
      }
    }

    store.dispatch('ToggleModalUpgradePayment', {
      eventId: eventId,
      currentPlan: props.subscription?.plan?.name,
      discount: discount
    })
  }
}
</script>

<template>
  <card-slot v-if="!isLoading"
             class-name="card">
    <template #header>
      <div class="card-header">
        <div v-show="!logo"
             class="card-header-image"/>
        <img v-show="logo?.length > 0"
             :src="logo"
             class="card-header-image"
             alt="default-logo"/>
        <div class="card-header-info">
          <tag :id="`card-${getEventStatus(card.eventSettings)}`"
               :name="`card-${getEventStatus(card.eventSettings)}`"
               :text="getEventStatus(card.eventSettings)" />
          <tooltip direction="left"
                   :input="card.name"
                   :max-length="50">
            <template #element>
              <h3 class="card-header_title">
                {{ card.name }}
              </h3>
            </template>
            <template #content>
              {{card.name}}
            </template>
          </tooltip>
        </div>
        <div class="absolute right-0">
          <overflow :menu-items="cardMenuItems"
                  position="left" />
        </div>
      </div>
    </template>
    <template #content>
      <div class="px-4">
      <hr class="h-[1px] w-full bg-[#c9c9c9]"/>
      </div>
      <div class="card-content"
           :class="{'cursor-pointer' : !isEvents}"
           @click.prevent="!isEvents ? () => toggleEventBuilder(card) : null">
        <span class="flex flex-row items-center">
          <tag-label :text="`${ subscription?.plan?.name || 'free'}`"
                     class-name="py-2 px-12 font-medium capitalize text-p-md text-product-dark" />
          <base-button text="upgrade"
                       class-name="text-p-sm ml-4 mb-2"
                       btn-type="text"
                       :disabled="subscription?.plan?.name === eventPlans.PROFESSIONAL"
                       :functions="() => toggleUpgrade(card.id)">
            <template #contents>
              <span class="card-header-upgradeBtn">
                Upgrade<i class="fa-solid fa-chevron-right fa-xs ml-1"></i>
              </span>
            </template>
          </base-button>
        </span>
        <span class="card-content-icon"
            :class="{'cursor-pointer' : isEvents}"
            @click.prevent="() => toggleEventBuilder(card)">
          <i class="fa-solid fa-circle-arrow-right fa-2xl" />
        </span>
      </div>
    </template>
  </card-slot>
  <card-slot v-else
             class-name="card">
    <template #header>
      <div class="card-header">
        <div class="card-header-container">
          <span class="card-header-draft">
            <i class="fa-xl mr-2"/>
            <span class="card-header_text">
            </span>
          </span>
        </div>
        <h3 class="card-header_title"></h3>
      </div>
    </template>
    <template #content>
      <div class="card-content cursor-pointer">
      <span class="card-content-icon">
        <i class="" />
      </span>
      </div>
    </template>
  </card-slot>
</template>

<style lang="scss" scoped>
.card {
  @apply md:w-[25rem] md:min-w-0 min-w-full border rounded-2xl border-greys-200 h-full;
  &-header {
    @apply p-4 bg-white rounded-t-2xl flex flex-row relative h-[7.9rem];
    &-image {
      @apply w-[88px] h-[88px] bg-[#ededed] rounded-xl mr-4 border overflow-hidden;
    }
    &-info {
      @apply w-2/3;
    }
    &-container {
      @apply w-full flex flex-row justify-between relative;
    }
    &-plan {
      @apply flex flex-row;
    }
    &-draft {
      @apply flex flex-row;
    }
    &_text {
      @apply text-xs;
    }
    &_title {
      @apply text-h5 pt-4 font-medium line-clamp-2 text-blacks-500;
    }
    &-upgradeBtn {
      @apply capitalize flex flex-row justify-center items-center pt-2;
    }
  }
  &-content {
    @apply rounded-b-2xl flex justify-between items-center flex-row w-full py-5 px-4;
    &-icon {
      @apply mr-2 bg-white rounded-full;
    }
  }
  &-menu {
    @apply absolute right-0 z-10 mt-6 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
  }
}
.skeleton {
  .card {
    &-content {
      @apply h-36 rounded-b-2xl flex justify-end items-end animate-[pulse-bg_2s_infinite] bg-background-500 bg-no-repeat bg-gradient-to-r from-background-500 via-blacks-100 to-background-500;
    }
    &-header {
      &_title {
        @apply animate-[pulse-bg_2s_infinite] w-full h-[1.5rem] bg-background-500 bg-no-repeat bg-gradient-to-r from-background-500 via-blacks-100 to-background-500;
      }
      &-container {
        @apply animate-[pulse-bg_2s_infinite] w-[4rem] h-[1rem] bg-background-500 bg-no-repeat bg-gradient-to-r from-background-500 via-blacks-100 to-background-500 mb-3;
      }
    }
  }
}
@keyframes pulse-bg {
  0% {
    @apply [background-position-x:-29rem];
  }

  100% {
    @apply [background-position-x:29rem];
  }
}

:deep(.tag) {
  @apply max-w-fit py-2;
}
</style>
