<script setup>
// Required Components
import { computed, reactive, onMounted, watch, onUnmounted } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
// Store
import store from '@/store'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'
import LinkButton from '@/components/Base/Button/LinkButton'
// Custom Components
import EventPageBase from '@/pages/EventBuilder/EventPageBase'
import CardEventBuilder from '@/components/Base/Cards/EventBuilder'
import ProjectAwardsTable from '@/components/Awards/ProjectAwardsTable'

import { eventFeatureCodes, processStatuses } from '@/utils'
import { config } from '@/config'

const giveAwards = reactive({
  title: "No Approved Projects Yet",
  description: "Once you've approve projects in the Project Submissions Page, they will show up here, and will be eligible for awards"
})

const router = useRouter()

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const awardFeatureEnabled = computed(() => {
  const feature = store.getters.getEventFeature(eventFeatureCodes.ENABLE_AWARDS)
  return feature?.value === 1
})

const projectAwards = computed(() => {
  return store.getters.projectAwards
})

const projectAwardsStatus = computed(() => {
  return store.getters.projectAwardsStatus
})

const isSectionReady = computed(() => {
  if (store.getters.ebEventStatus !== processStatuses.LOADED) {
    return false
  } else {
    if (awardFeatureEnabled.value) {
      return store.getters.projectAwardsStatus === processStatuses.LOADED
    } else {
      return true
    }
  }
})

const isProjectAwardsUpdated = computed(() => {
  return store.getters.isProjectAwardsUpdated
})

const saveProjectAwards = () => {
  store.dispatch('saveProjectAwards')
}

const prev = () => {
  router.push({ name: 'CreateAwards', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

onMounted(() => {
  if (awardFeatureEnabled.value) {
    store.dispatch('fetchProjectAwards')

    // Fetch all event awards if not already loaded
    if (store.getters.awardsStatus === processStatuses.IDLE) {
      store.dispatch('fetchEventAwards')
    }
  }

  addEventListener("beforeunload", beforeUnloadHandler)
})

onUnmounted(() => {
  store.dispatch('resetProjectAwardsState')
  // Remove beforeunload event listener when the component is unmounted
  removeEventListener("beforeunload", beforeUnloadHandler)
})

watch(() => store.getters.ebEventStatus, (status) => {
  if (status === processStatuses.LOADED && projectAwardsStatus.value === processStatuses.IDLE && awardFeatureEnabled.value) {
    store.dispatch('fetchProjectAwards')

    if (store.getters.awardsStatus === processStatuses.IDLE) {
      store.dispatch('fetchEventAwards')
    }
  }
})

const beforeUnloadHandler = async (event) => {
  if (store.getters.isProjectAwardsUpdated) {
    event.preventDefault()
    // Allow the page to be unloaded
    event.returnValue = true
  }
}

onUnmounted(() => {
  store.dispatch('resetAwardsState')
  // Remove beforeunload event listener when the component is unmounted
  removeEventListener("beforeunload", beforeUnloadHandler)
})

onBeforeRouteLeave((to, from, next) => {
  if(store.getters.isProjectAwardsUpdated){
    const payload = {
      confirmAction: () => {
        store.dispatch('ResetModal')
        next()
      },
      cancelAction: () => {
        store.dispatch('ResetModal')
      }
    }
    store.dispatch('ToggleModalUnsavedAlert', payload)
  } else {
    next()
  }
})

const triggerUpgradeModal = () => {
  const subscriptionDetails = store.getters.getEventSubscriptionDetails
  if (store.getters.hasRemainingPaidEvents(subscriptionDetails?.plan?.name)) {
    store.dispatch('ToggleModalUpgradeEvent', { eventId: store.getters.ebEventId })
  } else {
    store.dispatch('ToggleModalUpgradePayment', {
      eventId: store.getters.ebEventId,
      currentPlan: subscriptionDetails?.plan?.name
    })
  }
}

const notifyAndGiveAwards = () => {
  store.dispatch('ToggleModalGeneralConfirm', {
    title: 'Are You Sure You Want To Give Awards?',
    description: 'As a reminder, once you give awards, all participants will be notified, awards will be placed on the projects, and <strong>you cannot change your selections</strong>.',
    confirmButton: 'Yes, Give Awards',
    cancelButton: 'No, I Need More Time',
    confirmAction: () => {
      store.dispatch('publishAwards').then(() => {
        store.dispatch('ResetModal')
      })
    },
    cancelAction: () => {
      store.dispatch('ResetModal')
    }
  })
}

const isAwardsPublished = computed(() => {
  return store.getters.getAwardsPublished
})

const eventIsPublished = computed(() => {
  return store.getters.eventSettings.status === 'Published'
})

const awardPublishingStatus = computed(() => {
  return store.getters.getAwardsPublishingStatus
})

const notifyButtonDisabled = computed(() => {
  return !eventIsPublished.value ||
    isAwardsPublished.value ||
    awardPublishingStatus.value === processStatuses.SAVING ||
    !awardFeatureEnabled.value || !projectAwards.value || projectAwards.value.length === 0 ||
    store.getters.isProjectAwardsUpdated
})

const eventSiteProjectUrl = computed(() => {
  return `${config.EVENT_URL}/${store.getters.ebEventSlug}/projects`
})
</script>

<template>
  <event-page-base :is-published="isAwardsPublished">
    <template #title>
      Give Awards
    </template>
    <template #description>
      Here you can assign awards to projects. When you're ready to notify participants of your selections, click "Notify And Give Awards". The award will be placed on the project, and will be visible for all to see!
    </template>
    <template #error-message>
        This section is no longer editable as the awards have already been given. Please email our
        <a class="underline" href="mailto:projectboardplus@projectboard.zohodesk.com"> support</a>
        for further questions.
    </template>
    <template #rightContainer>
      <base-button v-if="!isAwardsPublished"
                   :functions="notifyAndGiveAwards"
                   :is-loading="awardPublishingStatus === processStatuses.SAVING"
                   :disabled="notifyButtonDisabled"
                   text="Notify And Give Awards"
      />
      <link-button v-else :href="eventSiteProjectUrl"
                   target="_blank"
                   text="View Awards On Site"/>
    </template>
    <template #content>
      <div v-if="!isSectionReady">Loading</div>
      <template v-else>
        <card-event-builder v-if="!projectAwards || projectAwards.length === 0"
                            image="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Fawards%2Fgive_award.png?alt=media&token=8e7c6051-b19e-46a0-a215-698b0c69d387"
                            :title="giveAwards.title"
                            :content="giveAwards.description"
                            :is-create="!awardFeatureEnabled"
                            :functions="triggerUpgradeModal"
                            btn-text="Upgrade">
          <template #content>
            Once you've approve projects in the
            <router-link :to="{name: 'Submissions', params: {slug: event.slug}}"
                         class="underline font-bold">
              Project Submissions
            </router-link>
            Page, they will show up here, and will be eligible for awards
          </template>
        </card-event-builder>
        <project-awards-table v-else />
      </template>
    </template>
    <template #bottom-nav-container>
      <base-button
          id="bottom-nav-prev"
          name="bottom-nav-prev"
          class-name="bottom-nav-button"
          btn-type="text"
          text="back"
          :functions="prev">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-left" />
            </span>
            Back
          </span>
        </template>
      </base-button>
      <base-button text="Update"
                   :disabled="!isProjectAwardsUpdated || projectAwardsStatus !== processStatuses.LOADED || !awardFeatureEnabled"
                   :is-loading="false"
                   aria-label="Update Button"
                   class-name="give-awards-button"
                   :functions="saveProjectAwards" />
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.give-awards {
  &-button {
    @apply capitalize px-6 py-2 mt-1;
  }
}
</style>
