<template>
  <login-account v-if="typeOfModal === 'login'"
                 :username-or-email="usernameOrEmail" />
  <register-account v-else-if="typeOfModal === 'register'"
                    :email="email"
                    :username="username"
                    :password="password"
                    :password-confirm="passwordConfirm"
                    :first-check-box="firstCheckBox"
                    :second-check-box="secondCheckBox" />
  <password-account v-else-if="typeOfModal === 'password'"
                    :password="password" />
  <verification-account v-else-if="typeOfModal === 'verify'"
                        :verification-code="verificationCode" />
  <logout-account v-else-if="typeOfModal === 'logout'" />
  <forget-password v-else-if="typeOfModal === 'forget-password'" />
  <check-reset-password-email v-else-if="typeOfModal === 'check-reset-password-email'" />
</template>

<script setup>
import { computed, ref } from 'vue'
import store from '@/store'
// Components
import LoginAccount from './LoginAccount'
import RegisterAccount from './RegisterAccount'
import PasswordAccount from './PasswordAccount'
import LogoutAccount from './LogoutAccount'
import VerificationAccount from './VerificationAccount'
import ForgetPassword from '@/components/Modals/User/ForgotPassword';
import CheckResetPasswordEmail from '@/components/Modals/User/CheckResetPasswordEmail';
// Login
const usernameOrEmail = ref ('')
// Event
const email = ref('')
const username = ref('')
const password = ref('')
const passwordConfirm = ref('')
const firstCheckBox = ref(false)
const secondCheckBox = ref(false)
const verificationCode = ref('')
// Modal logic
const typeOfModal = computed(() => {
  return store.getters.typeOfModal
})
</script>

<style lang="scss" scoped>
.modal {
  @apply border rounded-xl flex flex-col bg-gray-100 w-screen;
  &-header {
    @apply w-full p-4;
    &_close {
      @apply absolute right-2 p-1 cursor-pointer;
    }
    &_title {
      @apply text-center font-bold text-2xl;
    }
  }
  &-login, &-register, &-password, &-logout, &-forget-password, &-check-reset-password-email {
    @apply md:w-[30rem];
  }
  &-verify {
    @apply md:w-[38rem];
  }
  &-profile {
    @apply md:w-[36rem];
  }
}
.nextBtn {
  @apply bg-purple text-white uppercase rounded-md text-sm;
}
.skipBtn {
  @apply text-purple uppercase rounded-md text-sm mr-4 border-2 border-gray-300;
}
.tryAgain {
  @apply text-center text-xs mb-12;
  &_text {
    @apply text-purple cursor-pointer;
  }
}
</style>
