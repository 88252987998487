import axios from 'axios'
import { processStatuses } from '@/utils'

export default {
  state: () => ({
    comments: {},
    comments_errors: null,
    status: processStatuses.IDLE
  }),
  getters: {
    comments: state => state.comments,
    commentsErrors: state => state.comments_errors,
    commentsLoading: state => state.status === processStatuses.LOADED
  },
  mutations: {
    SET_COMMENTS_LOADING (state) {
      state.status = processStatuses.LOADING
    },
    SET_COMMENTS_LOADED (state, comments) {
      state.status = processStatuses.LOADED
      state.comments = comments
    },
    SET_COMMENTS_ERRORS (state, errors) {
      state.status = processStatuses.ERROR
      state.comments_errors = errors
    },
    SET_COMMENT_POST (state, data) {
      const newPostMap = new Map()
      // API CALL AND CHECK FOR 200 then rest of the code
      // NEED TO RETURN KEY
      newPostMap.set(`${Math.random()}_NEED_KEY`, data)
      // Append the new map item to the first
      state.comments = new Map([...newPostMap, ...state.comments])
      state.status = processStatuses.LOADED
    },
    POST_REPLY_COMMENT (state, payload) {
      const { parent_id } = payload
      const replies = state.comments.get(`${parent_id}`)
      state.comments.get(`${parent_id}`)['replies'] = [payload, ...replies['replies']]
    },
    EDIT_COMMENT (state, payload) {
      const { comment, parent_id, id } = payload
      if (parent_id === 0) {
        if (state.comments.has(id)){
         state.comments.get(id).comment = comment
          state.comments.set(`${id}`, {
            ...state.comments.get(`${id}`), comment: comment
          })
        } else {
          state.comments_errors = 'Unable to save comments'
        }
      } else {
        const replies = state.comments.get(`${parent_id}`)
        state.comments.get(`${parent_id}`)['replies'] = replies['replies'].map(reply => {
          if (reply && reply.id === id) {
            return reply.comment = comment
          }
        })
      }
    },
    DELETE_COMMENT (state, ids) {
      const { parent_id, id } = ids
      // Rehydrate the store
      if (parent_id === 0) {
        if (state.comments.has(id)) {
          // DO API CALL HERE AND CHECK FOR 200 then rest of the code
          state.comments.delete(id)
        } else {
          // SET API FAILURE HERE
          state.comments_errors = 'No comment found'
        }
      } else {
        // DO API CALL HERE AND CHECK FOR 200 then rest of the code
        const replies = state.comments.get(`${parent_id}`)
        state.comments.get(`${parent_id}`)['replies'] = replies['replies'].filter((reply) => {
          return reply.id !== id
        })
      }
    },
  },
  actions: {
    async getComments({commit}) {
      commit('SET_COMMENTS_LOADING')
      try {
        const result = await axios.get('/comments.json')
        if (result.status === 200) {
          const { data: { comments } } = result
          // Creating map or dictionary
          // Declare map
          const map = new Map()
          // Iterate objects
          Object.entries(comments).forEach((key) => {
            // Looking through the keys
            Object.values(key).forEach(value => {
              if( value['id']){
                // Set id from parent id and nest replies
                map.set(value['id'], value)
              }
            })
          })
          commit('SET_COMMENTS_LOADED', map)
        }
      } catch(err) {
        commit('SET_COMMENTS_ERRORS', err)
      }
    },
    async postComment({commit}, data) {
      commit('SET_COMMENTS_LOADING', true)
      try {
        // Do your post request
        commit('SET_COMMENT_POST', data)
      } catch(err) {
        commit('SET_COMMENTS_ERRORS', err)
      }
      commit('SET_COMMENTS_LOADING', false)
    },
    async postReplyComment({commit}, payload) {
      commit('SET_COMMENTS_LOADING')
      try {
        commit('POST_REPLY_COMMENT', payload)
      } catch(err) {
        commit('SET_COMMENTS_ERRORS', err)
      }
    },
    async editComment ({ commit }, payload) {
      commit('SET_COMMENTS_LOADING')
      try {
        // Do your post request
        commit('EDIT_COMMENT', payload)
      } catch(err) {
        commit('SET_COMMENTS_ERRORS', err)
      }
    },
    async deleteComment ({ commit }, ids) {
      commit('SET_COMMENTS_LOADING')
      try {
        // Do your post request
        commit('DELETE_COMMENT', ids)
      } catch(err) {
        commit('SET_COMMENTS_ERRORS', err)
      }
    }
  }
}
