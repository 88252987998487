<script setup>
// Required Components
import { ref } from 'vue'
// Utils
import { isTruncated } from '@/utils'

defineProps({
  direction: {
    type: String,
    default: 'left' // left or right
  },
  position: {
    type: String,
    default: 'bottom' // bottom or top
  },
  className: String,
  input: undefined // Any type
})

const onHover = ref(false)

const showElement = () => {
  onHover.value = true
}

const hideElement = () => {
  onHover.value = false
}
</script>

<template>
  <span v-touch:tap="onHover ? hideElement : showElement"
        v-touch:release="hideElement"
        v-touch:drag="hideElement"
        class="tooltip"
        :class="className"
        @mouseenter="showElement"
        @mouseleave="hideElement">
    <slot name="element"/>
    <span v-if="onHover && isTruncated(input)"
          :class="`tooltip-content tooltip-content-${direction} tooltip-content-${position} ${className ? className : 'tooltip-content-base'}`">
      <span :class="`arrow arrow-${position} arrow-${direction} ${position === 'bottom' ? 'arrow-base' : 'arrow-base-down'}`"/>
      <slot name="content"/>
    </span>
  </span>
</template>

<style lang="scss" scoped>
// Tooltip CSS
.tooltip {
  @apply relative cursor-default my-2;
  &-content {
    @apply absolute rounded bg-[#313033] text-white z-[1] text-p-md px-4 py-2 font-medium break-all;
    &-base {
      @apply md:max-w-[15rem];
    }
    &-right {
      @apply right-0;
    }
    &-bottom {
      @apply mt-3;
    }
    &-top {
      @apply -top-24;
    }
  }
}
// Arrow CSS
.arrow {
  @apply absolute;
  &-bottom {
    @apply -top-2;
  }
  &-top {
    @apply -bottom-2;
  }
  &-base {
    @apply w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-[#313033];
    &-down {
      @apply w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-[#313033];
    }
  }
  &-left {
    @apply ml-4;
  }
  &-right {
    @apply mr-8 right-0;
  }
}
</style>
