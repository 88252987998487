// Used to indicate API call status for loading icon or error message
// e.g. Fetch data from API:
// 1. (page load) idle ->
// 2. (API initiating) loading ->
// 3. (API return data) loaded or (API return error) error

const IDLE = 'idle'
const LOADING = 'loading'
const SAVING = 'saving'
const LOADED = 'loaded'
const ERROR = 'error'

const Statuses = [IDLE, LOADING, SAVING, LOADED, ERROR]

export const processStatuses = {
    IDLE,
    LOADING,
    SAVING,
    LOADED,
    ERROR,
    Statuses
}
