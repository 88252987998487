<script setup>
// Required Components
import { computed, watchEffect, watch, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// Store
import store from '@/store'
// Custom Lib Components
import Avatar from '@/components/Base/Avatar'
// Custom Components
import Dashboard from '@/components/Layout/Sidebar/Dashboard'
import EventBuilder from '@/components/Layout/Sidebar/EventBuilder'
import BaseInput from '@/components/Base/Fields/InputField'
import Tooltip from '@/components/Lib/Tooltip'
// Utils
import { isTruncated } from '@/utils'
import Panels from '@/components/Layout/Panels'

defineProps({
  sidebarBtn: {
    type: Boolean,
    default: false
  }
})

// Search
const search = ref('')

// Dashboard Sidebar Items
const sideBarItems = ref( [
  {
    name: 'Events',
    title: 'showcases',
    icon: 'calendar',
  },
  {
    name: 'Payments',
    title: 'payments',
    icon: 'credit-card',
  },
  {
    name: 'Users',
    title: 'admins',
    icon: 'user'
  },
  {
    name: 'Settings',
    title: 'settings',
    icon: 'gear'
  },
  {
    name: 'Analytics',
    title: 'analytics',
    icon: 'chart-simple'
  },
  {
    name: 'Support',
    title: 'help & support',
    icon: 'circle-question'
  }
]
)

const user = computed(() => {
  return store.getters.user
})

// Get route
const route = useRoute()
const router = useRouter()
// Completed value for event builder
const completionValue = computed(() => {
  return store.getters.getConfigCompletion
})

// Mobile Menu Mode
const mobileMenu = computed(() => {
  return store.getters.menu
})

// Sidebar Toggle
const toggleSidebar = () => {
  if (!isEventBuilder.value) {
    store.dispatch('ToggleSideMenu')
  }
}

// Check for event builder
const isEventBuilder = computed(() => {
  return route.path.includes('event-builder')
})

// Event Builder
const eventBuilderItems = computed(() => {
  return store.getters.eventBuilderItems
})

// Get sidebar items
const getSideBarItems = async () => {
  await store.dispatch('GetSideBarItems')
  store.dispatch('GetEventBuilderItems').then(() => {
    store.dispatch('SetEventCompletion')
  })
}

const organization = computed(() => {
  return store.getters.getActiveOrganization
})

const userOrganizations = computed(() => {
  return store.getters.getUserOrganizations
})

const isUserOrganizationOwner = (org) => {
  return org.userId === user.value.id
}

const showOrganizationSwitcher = ref(false)

const toggleOrganizationSwitcher = () => {
  showOrganizationSwitcher.value = !showOrganizationSwitcher.value
}

const hideSidebar = computed(() => {
  return !!route.meta?.hideSidebar
})

watchEffect(() => {
  if (isEventBuilder.value ) {
    store.dispatch('SetStateSideMenu', false)
  }
})

watch(() => completionValue.value, (newValue) => {
  if (newValue) {
    store.dispatch('SetEventCompletion')
  }
})

onMounted(async () => {
  await store.dispatch('fetchCurrentUser')
  if (user.value != null) {
    await store.dispatch('fetchOrganization', { slug: user.value.userName, userId: user.value.id })
  }
  await getSideBarItems()
})

const switchOrganization = (orgId) => {
  if (orgId !== store.getters.getActiveOrganization?.id) {
    store.dispatch('setActiveOrganization', orgId)
    showOrganizationSwitcher.value = false
    // Refresh the page
    router.go(0)
  }
}
</script>

<template>
<aside v-show="!hideSidebar"
       class="sidebar"
       :class="{
          'md:px-3 w-full' : !isEventBuilder,
          'md:w-[18rem] w-full' : !sidebarBtn,
          'md:w-[5rem] w-full' : sidebarBtn,
          'hidden md:block' : !mobileMenu,
          'block top-[4rem]' : mobileMenu,
          'mt-[2.8rem]': isEventBuilder && mobileMenu
        }">
  <base-input v-if="mobileMenu && !isEventBuilder"
               label-text="Search"
               input-name="Search"
               input-id="Search"
               class-name="w-full"
               input-aria-label="Search button"
               input-place-holder="Search"
               :disabled="true"
               :model-value="search"
               :input-model="search" />
  <avatar v-if="!isEventBuilder"
          class-name="sidebar-btn"
          :class="{
            'left-[16.8rem]': !sidebarBtn,
            'left-[3.7rem]': sidebarBtn
          }"
          @click.prevent="toggleSidebar">
    <template #profile>
      <img src="/images/icons/icon-sidebar.png"
           alt="sidebar logo"/>
    </template>
  </avatar>
  <div v-if="!isEventBuilder"
        class="sidebar-logo-container"
        :class="{
          'pl-7': !sidebarBtn,
          'pl-3': sidebarBtn
        }">
    <div v-if="!organization"
         class="sidebar-loading">
      <div class="sidebar-loading-avatar" />
      <div class="sidebar-loading-name" />
    </div>
    <avatar v-else
            class="sidebar-logo">
      <template #profile>
        <img v-if="organization?.logo"
             :src="organization?.logo"
             alt="team logo"
             class="sidebar-logo-image"/>
      </template>
    </avatar>
    <tooltip direction="left"
             :input="organization?.name"
             :max-length="25"
             :tooltip-disabled="showOrganizationSwitcher"
             @click="toggleOrganizationSwitcher">
      <template #element>
        <h5 v-if="!sidebarBtn" class="sidebar-name mr-8" :class="{ 'card-description-truncated' : isTruncated(organization?.name) }">
          {{organization?.name}}
        </h5>
      </template>
      <template #content>
        {{organization?.name}}
      </template>
      <template v-if="userOrganizations?.length > 1" #icon>
        <div class="sidebar-org-button"
             :class="{'sidebar-org-button-open' : !sidebarBtn}">
          <i class="fa-solid"
             :class="showOrganizationSwitcher ? 'fa-chevron-up' : 'fa-chevron-down'"/>
        </div>
      </template>
    </tooltip>
    <div v-if="userOrganizations?.length > 1"
         v-show="showOrganizationSwitcher"
         class="sidebar-org-container"
         :class=" {'sidebar-org-container-open' : !sidebarBtn }"
         role="menu"
         tabindex="-1">
      <ul role="none"
           class="sidebar-org-list">
        <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <li v-for="org in userOrganizations"
             :key="`${org.id}_${org.name}`"
             class="sidebar-org-list-item"
              :class="{
               'sidebar-org-list-item-inActive' : org.id === store.getters.getActiveOrganization?.id,
               'cursor-default': org.id === store.getters.getActiveOrganization?.id,
               'cursor-pointer': org.id !== store.getters.getActiveOrganization?.id
              }"
             role="menuitem"
             tabindex="-1"
             @click="() => switchOrganization(org.id)">
              {{org.name}}
            <span v-if="isUserOrganizationOwner(org)"
                  class="">
              <i class='fa-light fa-crown'></i>
            </span>
          </li>
      </ul>
    </div>
  </div>
  <span v-else>
    <router-link :to="{name: 'Events'}">
      <span class="sidebar-back-container">
        <i class="fa-solid fa-chevron-left"></i>
        <p class="sidebar-back-text">
          Back to Admin Dashboard
        </p>
      </span>
    </router-link>
  </span>
  <event-builder v-if="isEventBuilder"
                 :event-builder-items="eventBuilderItems"
                 :user="user"/>
  <dashboard v-else
             :is-mobile-menu="mobileMenu"
             :is-mobile-toggle="sidebarBtn"
             :side-bar-items="sideBarItems"/>
</aside>
<panels />
</template>

<style lang="scss" scoped>
.sidebar {
  @apply h-screen py-3 border-r fixed z-[4] md:bg-background-500 bg-background-400 px-4 md:px-0 transition-all ease-in-out duration-300;
  &-loading {
    @apply animate-pulse flex flex-row items-center;
    &-avatar {
      @apply rounded-full bg-product-400/20  h-9 w-9;
    }
    &-name {
      @apply h-3.5 bg-product-400/20 rounded w-[8rem] ml-2;
    }
  }
  &-logo {
    @apply w-[2rem] h-[2rem] rounded-full overflow-hidden;
    &-container {
      @apply flex flex-row items-center pt-2 pb-4 relative;
    }
    &-image {
      @apply w-full h-full bg-white;
    }
    & :deep + .tooltip {
      @apply w-[calc(100%-2rem)];
    }
  }
  &-name {
    @apply text-h5 font-medium ml-4 line-clamp-1;
  }
  &-back {
    &-container {
      @apply flex-row text-product-400 items-center py-4 px-7 hidden md:inline-flex;
    }
    &-text {
      @apply text-p-sm ml-3 font-medium;
    }
  }
  &-btn {
    @apply w-10 h-10 ring-greys-200 fixed bg-white top-[50%] cursor-pointer hidden md:inline-flex shadow-lg;
    &:hover {
      @apply bg-product-400/[.3];
    }
  }
  &-org {
    &-button {
      @apply pr-3 absolute top-0;
      &-open {
        @apply right-4;
      }
    }
    &-container {
      @apply absolute z-10 top-12 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden;
      &-open {
        @apply right-0;
      }
    }
    &-list {
      @apply overflow-y-auto max-h-[18rem];
      &-item {
        @apply text-gray-700 block px-4 py-2 text-p-md flex flex-row justify-between;
        &-inActive {
          @apply bg-grey-100 text-grey-800;
        }
      }
    }
  }
}
</style>
