<script setup>
import store from '@/store'
import DashboardPageBase from './DashboardPageBase'
import { computed, onMounted, watch } from 'vue'
import BaseButton from '@/components/Base/Button/BaseButton'
import DashboardTable from '@/components/Base/Table/Dashboard'
import BaseTag from '@/components/Base/Tags'

import AddOnsList from '@/components/Users/AddOnsList'
import Overflow from '@/components/Base/Menu/Overflow'
import { processStatuses } from '@/utils'
import { organizationFeatureCodes } from '@/utils/organizationFeatures'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

const headers = [
  { text: "First Name", value: "firstName", sortable: true },
  { text: "Last Name", value: "lastName", sortable: true },
  { text: "Email", value: "email" },
  { text: "Role", value: "role" },
  { text: "Status", value: "status" },
  { text: "", value: "options" },
]

const organization = computed(() => {
  return store.getters.getActiveOrganization
})

const usersData = computed(() => {
  return store.getters.combinedUsers
})

const isOrgOwnerAdmin = computed(() => {
  const userRole = store.getters.getOrganizationUserRole(store.getters.user.id)
  return ['Owner', 'Admin'].includes(userRole)
})

const organizationOwner = computed(() => {
  return store.getters.getOrganizationOwner
})

const organizationFeatureData = computed(() => {
  return store.getters.getOrganizationFeature(organizationFeatureCodes.NUMBER_OF_ADMIN_SEATS)
})

const totalAdminsList = computed(() => {
  if (organizationFeatureData?.value && organizationFeatureData?.value?.value !== undefined) {
    return usersData?.value.length >= organizationFeatureData.value.value
  } else {
    return true
  }
})

const transferOwnership = (orgUser) => {
  if (orgUser && organization?.value?.name) {
    const orgUserPayload = {
      organizationUserRole: 'ss_organization_owner',
      id: orgUser.id,
    }
    const payload = {
      userId: orgUser.id,
      title: 'Make this person the owner?',
      description: 'The new owner will be notified and could remove you.',
      confirmButton: 'Yes, Transfer',
      cancelButton: 'No, Go Back',
      confirmAction: () => {
        store.dispatch('updateOrganizationUser', orgUserPayload)
        // update the current user role to admin
        const currentUserPayload = {
          organizationUserRole: 'ss_organization_admin',
          id: organizationOwner.value?.organizationUsers?.id,
        }
        store.dispatch('updateOrganizationUser', currentUserPayload)
        store.dispatch('ResetModal')
      },
      cancelAction: () => {
        store.dispatch('ResetModal')
      },
      type: 'transferOwnership',
    }
    store.dispatch('ToggleModalTransferOwnership', payload)
  } else {
    const payload = {
      title: 'Organization Name Setup Required for Ownership Transfer',
      description: 'You haven\'t configured your organization\'s name yet. Click the \'Go to Settings\' button to configure it. After completion, return to the Admins page to finalize the ownership transfer.',
      confirmButton: 'Go to Settings',
      cancelButton: 'Cancel',
      confirmAction: () => {
        // Navigate to setting page and add return URL to admin page
        router.push({ name: 'Settings', query: { returnUrl: 'Users' } })
        store.dispatch('ResetModal')
      },
      cancelAction: () => {
        store.dispatch('ResetModal')
      },
      type: 'transferOwnership',
    }
    store.dispatch('ToggleModalTransferOwnership', payload)
  }
}

const removeAccess = (userData) => {
  if (userData.id > 0) {
    const payload = {
      userId: userData.id,
      title: 'Are You Sure You Want To Remove This Admin?',
      description: 'They will lose access to this organization, and will have to be invited again.',
      confirmButton: 'Yes, Remove',
      cancelButton: 'No, Go Back',
      confirmAction: () => {
        if (userData.status === 'Active') {
          store.dispatch('deleteOrganizationUser', { id : userData.organizationUsers.id })
          if (userData?.userInvitations?.id) {
            store.dispatch('deleteInviteUser', { id: userData.userInvitations?.id })
          }
        } else {
          store.dispatch('deleteInviteUser', { id: userData.userInvitationsId })
        }
        store.dispatch('ResetModal')
      },
      cancelAction: () => {
        store.dispatch('ResetModal')
      },
      type: 'removeAccess',
    }
    store.dispatch('ToggleModalRemoveAccess', payload)
  }
}

const resendInvite = (email) => {
  const payload = {
    email: email,
    organizationId: organization.value?.id,
  }
  store.dispatch('reInviteUser', payload).then(() => {
    store.dispatch('triggerToast', { message: 'Invite Send', type: 'success' })
  })
}

const getOrgUser = () => {
  store.dispatch('getOrganizationUsers')
  let payload = {
    organizationId: organization.value?.id,
    pageSize: 100,
    nextPageToken: 0,
  }
  store.dispatch('fetchExistingInvitedUsers', payload)
}

const sort = (sortBy = 'firstName') => {
  store.dispatch('toggleUserDataDesc')
  store.dispatch('setUserSortBy', sortBy)
}

watch(() => store.getters.getUserOrganizationStatus, (state) => {
  if (state === processStatuses.LOADED) {
    getOrgUser()
  }
})

onMounted(() => {
  const { query } = route
  if (query?.organizationId) {
    store.dispatch('setActiveOrganization', query.organizationId)
    router.push({ name: 'Users' }).then(() => {
      // Refresh the page
      router.go(0)
    })
  }
  if(store.getters.getUserOrganizationStatus === processStatuses.LOADED) {
    getOrgUser()
  }

})
</script>

<template>
  <dashboard-page-base>
    <template #title>
      admins
    </template>

    <template #rightContainer>
      <base-button
        text="+ Invite Admins"
        :functions="() => store.dispatch('ToggleModalInviteAdmin')"
        :disabled="totalAdminsList"
        class-name="users-header-btn"
        btn-type="primary"
      />
    </template>
    <template #subContent>
      <p class="users-header-subTitle">
        Add and manage admin for your organization
      </p>
    </template>
    <template #content>
      <div class="users-table">
        <dashboard-table :sort-function="sort" :items="usersData" :headers="headers" >
          <template #content-column="{ item }">
            <td>{{ item.firstName || '--' }}</td>
            <td>{{ item.lastName || '--' }}</td>
            <td>{{item.email}}</td>
            <td><span v-html="item.role === 'Owner' ? 'Admin &nbsp;&nbsp; <i class=\'fa-light fa-crown\'></i>' : 'Admin'"></span></td>
            <td>
            <base-tag
                v-if="item !== ''"
                :text="item.status"
                class="users-table-tags"
            />
            </td>
          </template>
          <template #action-column="{ item }">
            <div v-if="item.role !== 'Owner' && isOrgOwnerAdmin" class="transactions-table-download">
              <overflow class="users-table-btn" position="left">
                <template #menu-items>
                  <ul class="users-table-menu-list">
                    <li v-if="item.status === 'Active' && isOrgOwnerAdmin" class="users-table-menu-list-item" @click.prevent="transferOwnership(item.organizationUsers)">
                      Transfer Ownership
                    </li>
                    <li v-if="isOrgOwnerAdmin" class="users-table-menu-list-item" @click.prevent="removeAccess(item)">
                      Remove Access
                    </li>
                    <li v-if="item.status !== 'Active'" class="users-table-menu-list-item" @click.prevent="resendInvite(item.email)">
                      Resend Invite {{item.role}}
                    </li>
                  </ul>
                </template>
              </overflow>
            </div>
          </template>
        </dashboard-table>
      </div>
      <div class="users-addOns">
        <add-ons-list />
      </div>
    </template>

  </dashboard-page-base>
</template>

<style lang="scss" scoped>
.users {
  // Header
  &-header {
    &-subTitle {
      @apply text-p-xs text-blacks-300 pt-2;
    }
    &-btn {
      @apply capitalize py-2 px-8 text-p-md font-medium rounded-md;
    }
  }
// Table styles

  &-table {
    @apply mt-10;
    &-title {
      @apply text-h2 font-medium pt-8 pb-6 pl-2;
    }
    &-tags {
      @apply max-w-[6.2rem] h-[1.8rem];
    }
    &-btn {
      @apply ml-6;
    }
    &-menu { // Project Card Header Menu
      &-list {
        @apply text-p-xs flex flex-col items-start;
        &-item {
          @apply px-5 py-3 cursor-pointer hover:text-primary w-full;
        }
      }
    }
  }

  &-addOns {
    @apply md:pb-0 pb-28 md:ml-2 md:pt-6;
  }
}

// Styling for the table
// Styling for the table
.customize-table {
  @apply md:max-w-[60rem] w-full h-[23rem] pt-[-5rem] overflow-y-auto;
  --easy-table-border: 1px solid transparent;
  --easy-table-body-row-background-color: transparent;
  --easy-table-header-background-color: transparent;
  --easy-table-footer-background-color: transparent;
  --easy-table-body-row-height: 4rem;
}

// Easy Table CSS V-deep overrides
:deep(.header-text) {
  @apply text-p-md font-normal text-blacks-300;
}

// Styling the TRs
:deep(.direction-left) {
  @apply text-p-md text-blacks-500;
}

// Adding the missing border
.customize-table :deep(table) {
  @apply border-b text-blacks-100;
}

// Remove the border from the footer
//.customize-table :deep(.vue3-easy-data-table__footer) {
////@apply border-transparent my-[1rem];
//  @apply hidden;
//}
</style>

