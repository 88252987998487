import FileService from "@/services/file"
import { processStatuses } from '@/utils';

export default {
  state: {
    file: null,
    status: processStatuses.IDLE,
    error: null
  },
  getters: {
    getUploadedFile: state => state.status === processStatuses.LOADED ? state.file : '',
    getFileUploadStatus: state => state.status,
    getFileUploadError: state => state.error
  },
  mutations: {
    SET_FILE (state, data) {
      state.file = data
      state.error = null
    },
    SET_FILE_UPLOAD_STATUS (state) {
      state.status = processStatuses.LOADING
    },
    SET_FILE_UPLOADED_STATUS (state) {
      state.status = processStatuses.LOADED
    },
    SET_FILE_ERROR (state, error) {
      if (error.data == null){
        error.data = {}
        error.data.message = 'failed to get file \'attachment\''
      }
      state.status = processStatuses.ERROR
      state.error = error
    },
    RESET_FILE_UPLOAD (state) {
      state.file = null
      state.status = processStatuses.IDLE
      state.error = null
    }
  },
  actions: {
    async uploadFile({ commit }, file) {
      commit('SET_FILE_UPLOAD_STATUS')
      try {
        const resp = await FileService.upload({ file: file })
        if (resp.status === 200) {
          commit('SET_FILE', resp.data.link)
          commit('SET_FILE_UPLOADED_STATUS')
        }
      } catch (err) {
        commit('SET_FILE', null)
        commit('SET_FILE_ERROR', err)
      }
    },
    resetFileUpload({ commit }) {
      commit('RESET_FILE_UPLOAD')
    },
    fileNotSelectedMessage ({commit}) {
      let err = {}
      commit('SET_FILE_ERROR', err)
    }
  }
}
