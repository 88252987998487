<script setup>
// Required Component
import { ref } from 'vue'
// Router
import { useRouter } from 'vue-router'
// Custom Layout
import ErrorLayout from '@/pages/NotFound/ErrorLayout'
// Custom Components
import BaseButton from '@/components/Base/Button/BaseButton'
import BaseInput from '@/components/Base/Fields/InputField'
// Router
const router = useRouter()
// Variable
const userInput = ref('')
// Request Email
const goBugParents = () => {
  return
}
// Redirect Login
const goLogin = () => {
  router.push('/login')
}
</script>

<template>
  <error-layout :is-user="true">
    <template #error-logo>
      <img src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/selfserve%2Fimages%2FerrorUser.png?alt=media&token=4ebf6815-3f9d-46b1-af6d-f04eed14796d"
           alt="pageError"
           class="error-page-image"/>
    </template>
    <template #error-header>
      <span class="">
        Oops! It looks like your parent or guardian hasn't approved your account yet.
      </span>
    </template>
    <template #error-content>
      To unlock your access to ProjectBoard, please ask them to check their email (or their junk mail!) to approve your account.
      <br/>
      <br/>
      Need to resend approve email? Enter your parent's email below
    </template>
    <template #error-button>
      <span class="error-page-button-container">
        <span class="error-page-button-container-request">
          <base-input input-id="guardianEmail"
                      input-name="guardianEmail"
                      input-aria-label="guardian email"
                      input-place-holder="Please enter your parent's email ID"
                      :model-value="userInput" />
          <base-button id="errorPageButton"
                       name="errorPageButton"
                       btn-type="secondary"
                       aria-label="error page button"
                       class-name="error-page-button-request"
                       :functions="goBugParents"
                       text="Send Request"/>
        </span>
      <span class="error-page-or">Or</span>
      <base-button id="errorPageButton"
                   name="errorPageButton"
                   aria-label="error page button"
                   class-name="error-page-button"
                   :functions="goLogin"
                   text="Try Logging in again"/>
      </span>
    </template>

  </error-layout>
</template>

<style lang="scss" scoped>
.error-page{
  &-image {
    @apply pb-9;
  }
  &-or {
    @apply py-4 text-h5 text-blacks-500 font-medium;
  }
  &-button {
    @apply capitalize text-h5 font-medium py-2 px-7;
    &-container {
      @apply w-full flex flex-col justify-center md:items-center;
      &-request {
        @apply w-full flex md:flex-row flex-col items-center;
      }
    }
    &-request {
      @apply capitalize text-h5 font-medium md:h-[2.4rem] md:ml-4 px-4 w-full py-2;
    }
  }
}

:deep(.field) {
  @apply w-full;
}
</style>
