<script setup>
// Props
import { submissionStatusType } from '@/utils/submission-status'
import Loader from '@/components/Base/Loader'

defineProps({
  project: {
    type: Object,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  loadingStyle: {
    type: String,
    default: 'pulse'
  }
})
// Get status
const statusType = (status) => {
  switch (status) {
  case submissionStatusType.DECLINED:
    return 'Declined Projects'
  case submissionStatusType.SUBMITTED:
    return 'Projects Submitted'
  case submissionStatusType.APPROVED:
    return 'Approved Projects'
  case 'all':
    return 'All Projects'
  case 'participants':
    return 'Participants'
  default:
    return 'Draft Projects'
  }
}
</script>

<template>
  <div class="listCard">
    <template v-if="(loadingStyle === 'pulse' && !isLoading) || loadingStyle === 'spinner'">
      <span class="listCard-status-wrapper">
        <span v-if="!['all', 'participants'].includes(project.status)" :class="`listCard-dot listCard-dot-${project.text}`"/>
        <p class="listCard-status">
          {{statusType(project.status)}}
        </p>
      </span>
      <h2 v-if="!isLoading" class="listCard-number">
        {{project.number}}
      </h2>
      <div v-else
           class="listCard-loader">
        <loader size="lg" />
      </div>
    </template>
    <template v-else-if="loadingStyle === 'pulse' && isLoading">
      <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-white rounded col-span-2"></div>
              <div class="h-2 bg-white rounded col-span-1"></div>
            </div>
            <div class="h-2 bg-white rounded"></div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.listCard {
  @apply rounded-xl bg-greys-100 w-[5rem] h-[4.4rem] sm:w-[12.875rem] sm:h-[5.625rem] py-1 sm:py-4 px-2 sm:px-6 flex flex-col justify-center sm:justify-start cursor-pointer;
  &.active {
    @apply outline-product-400 outline outline-2 bg-product-100;
  }
  &-dot {
    @apply sm:w-3 sm:h-3 sm:rounded-full sm:mr-1.5;
    &-draft {
      @apply bg-greys-400;
    }
    &-declined {
      @apply bg-other-red;
    }
    &-submitted {
      @apply bg-other-blue;
    }
    &-approved {
      @apply bg-other-green;
    }
  }
  &-status {
    @apply text-xs sm:text-p-md text-blacks-500 font-semibold;
    &-wrapper {
      @apply flex flex-row items-center;
    }
  }
  &-number {
    @apply text-h2 font-bold;
  }
  &-loader {
    @apply flex items-center h-14;
  }
}
</style>
