<script setup>
// Required Components
import { computed, ref, watch, onMounted, onUnmounted } from 'vue'
// Store
import store from '@/store'
// Custom Components
import Overlay from '@/components/Layout/Overlay'
// Base Component
import ToastContainer from '@/components/Toast'
import { pbAutoLogin } from 'pb-components'
import router from '@/router'
import { config } from '@/config'
import { useRoute } from 'vue-router'

// Check user logged in
const isLoggedIn = computed(() => {
  return store.getters.isLoggedIn
})

const route = useRoute()

// Logic to handle when device is landscape
const windowWidth = ref(window.innerWidth)
const handleResize = () => {
  windowWidth.value = window.innerWidth
  if (windowWidth.value > 576) {
    store.dispatch('ToggleCloseMenu')
  }
}

const isEventBuilder = computed(() => {
  return window.location.pathname.includes('event-builder') || false
})

const isMobileMenu = computed(() => {
  return store.getters.menu
})

// No Scrolling when mobile menu is active
watch(() => isMobileMenu.value, (value) => {
  if (value) {
    document.body.classList.add('scroll-lock')
  } else {
    document.body.classList.remove('scroll-lock')
  }
})

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})

const onLogin = (redirectUrl) => {
  router.push(redirectUrl)
}

const onLoginStatusChange = (data) => {
  if (isLoggedIn.value) {
    // Log user out if they has logged out from auth site
    if (!data.isLoggedIn) {
      store.dispatch('logout')
    } else if (data.username !== store.getters.username) {
      // log out locally (not from the auth site)
      store.dispatch('logout', false)
      // Redirect to auth site to auto login again with a different user
      const authLoginUrl = config.AUTH_URL + '/selfserve/login'
      window.location.href = authLoginUrl
    }
  }
}

const onError = (error) => {
  console.error('Error from auto login iframe:', error)
}

const authUrl = computed(() => {
  return config.AUTH_URL + '/selfserve/login'
})
</script>

<template>
  <div class="background">
    <overlay />
    <span v-if="isLoggedIn"
          class="content"
          :class="{'content-dashboard' : !isEventBuilder}">
      <slot name="sidebar"/>
      <span class="content-main">
        <slot name="navigation"/>
        <slot name="content" />
      </span>
    </span>
    <toast-container />
    <pb-auto-login
      :key="route.path"
      :auth-url="authUrl"
      :silent-login="!isLoggedIn"
      @on-login="onLogin" 
      @on-login-status-change="onLoginStatusChange" 
      @on-error="onError" />
  </div>
</template>

<style lang="scss" scoped>
.background {
  @apply min-h-screen flex flex-row w-full relative;
}

.content {
  @apply w-full;
    &-dashboard {
    @apply bg-white;
  }
  &-main {
    @apply flex flex-col w-full;
  }
}
</style>
