<script setup>
// Required Component
import { ref, reactive, onMounted, computed } from 'vue'
// Store
import store from '@/store'
// Custom components
import ModalBase from '@/components/Modals/ModalBase'
// Base components
import BaseButton from '@/components/Base/Button/BaseButton'
import { processStatuses, eventPlans } from '@/utils'
import { useRouter } from 'vue-router'

const organizationProperties = reactive({
  isEventsStatusLoaded: false
})

const selectedPlan = computed(() => {
  return store.getters.modalData?.data?.selectedPlan
})

const selectedEventType = ref(selectedPlan.value || 'premium')

const eventId = computed(() => {
  return store.getters.modalData?.data?.eventId
})

const hasPlansSubscription = (planName) => {
  return store.getters.hasSubscription(planName)
}

const router = useRouter()

const upgradeEvent = (type='free') => {
  const plan = store.getters.getPlanByName(type)
  const activeOrganizationSubscription = store.getters.getAvailableOrganizationSubscription(type)
  if (plan && eventSubscriptionDetail.value.id && plan.name !== eventSubscriptionDetail.value.plan.name && activeOrganizationSubscription?.id) {
    store.dispatch('updatePlanSubscriptionDetails', { planId: plan.id, subscriptionPlanId: eventSubscriptionDetail.value.id, organizationSubscriptionId: activeOrganizationSubscription.id }).then(() => {
      // Force reload page
      router.go(0)
    })
  }
}

const closeUpgradeEventModal = () => {
  store.dispatch('ResetModal')
}

const eventSubscriptionDetail = computed(() => {
  // Get current event subscription plan
  // In Event Builder page event subscription details will be loaded
  let subscriptionPlan = store.getters.getEventSubscriptionDetails
  if (!subscriptionPlan || !subscriptionPlan.id) {
    // In Events listing page, we need to get the subscription plan from Plans store
    subscriptionPlan = store.getters.getSubscriptionByEventId(eventId.value)
  }

  return subscriptionPlan
})


const setOrganizationProperties = () => {
  organizationProperties.isEventsStatusLoaded = store.getters.eventsStatus === processStatuses.LOADED
  for (const [planName, value] of Object.entries(remainingPaidEvents.value)) {
    if (value > 0) {
      selectedEventType.value = planName
      break
    }
  }
}

const upgradeButtonLoading = computed(() => {
  return store.getters.getPlanStatus === processStatuses.LOADING ||
    store.getters.getEventSubscriptionDetailsStatus === processStatuses.LOADING
})

const upgradeButtonDisabled = computed(() => {
  return upgradeButtonLoading.value || !store.getters.hasRemainingPaidEvents
})

const remainingPaidEvents = computed(() => {
  return store.getters.getAllRemainingUpgradableEvents(eventSubscriptionDetail?.value?.plan?.name)
})

onMounted(() => {
  setOrganizationProperties()
  if (store.getters.getPlanStatus === processStatuses.IDLE) {
    store.dispatch('fetchPlans')
  }

  // In event builder page, fetch the subscription detail
  if (store.getters.getEventSubscriptionDetailsStatus === processStatuses.IDLE) {
    store.dispatch('fetchEventSubscriptionDetails')
  }
})
</script>

<template>
  <modal-base :is-close="true"
              modal-class="upgrade-event">
    <template #modal-title>
      <h2 class="upgrade-event-title">
        Upgrade Event
      </h2>
    </template>
    <template #modal-subContent>
      <p class="upgrade-event-subTitle">
        <template v-if="hasPlansSubscription(eventPlans.STARTER) || hasPlansSubscription(eventPlans.ESSENTIALS) || hasPlansSubscription(eventPlans.PROFESSIONAL)">
          You have starter, essentials or pro plans available to you. Please choose an event type to upgrade.
        </template>
      </p>
    </template>
    <template #modal-content>
      <span class="upgrade-event-radio-container">
        <span v-for="[planName, numberRemaining] of Object.entries(remainingPaidEvents)"
              :key="`upgrade_plan_modal_${planName}`"
              class="upgrade-event-radio-item"
              :class="{
                'upgrade-event-radio-item-enabled' : numberRemaining > 0,
                'upgrade-event-radio-item-disabled' : numberRemaining === 0
              }">
          <input :id="`${planName}-create`"
                 v-model="selectedEventType"
                 class="upgrade-event-radio" :disabled="numberRemaining === 0" type="radio" :name="`${planName}-create`" :value="planName">
          <label class="upgrade-event-radio-text"
                 :for="`${planName}-create`">
            {{ planName }} ({{numberRemaining}} Remaining)
          </label>
        </span>
      </span>
    </template>
    <template #modal-button>
      <span class="upgrade-event-btn-container">
        <base-button id="closeUpgradeEventModal"
                     :functions="closeUpgradeEventModal"
                     name="closeUpgradeEventModal"
                     class-name="upgrade-event-btn upgrade-event-btn-cancel"
                     btn-type="text"
                     text="Cancel" />
        <base-button id="createEventModal"
                   :functions="() => upgradeEvent(selectedEventType)"
                   :is-loading="upgradeButtonLoading"
                   :disabled="upgradeButtonDisabled"
                   name="upgradeEventModal"
                   class-name="upgrade-event-btn"
                   text="Upgrade Event" />
      </span>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.upgrade-event {
  @apply bg-white max-w-[30.5rem] py-4 px-8;
  &-title {
    @apply text-h2 font-medium text-center;
  }
  &-subTitle {
    @apply text-p-md text-center text-blacks-300 py-2;
  }
  &-radio {
    @apply accent-product-400;
    &-container {
      @apply py-6 flex flex-col w-full px-4;
    }
    &-item {
      @apply py-2;
      &-enabled {
        @apply cursor-pointer;
      }
      &-disabled {
        @apply cursor-default text-gray-500;
      }
    }
    &-text {
      @apply ml-4 text-p-xl font-normal;
    }
  }
  &-btn {
    @apply capitalize px-4 py-2 text-p-xl font-medium;
    &-cancel {
      @apply text-blacks-500;
      &:enabled {
        &:focus {
          @apply outline-none;
        }
      }
    }
    &-container {
      @apply flex flex-row w-full justify-end pb-4;
    }
  }
}
</style>
