import router from '@/router'
import store from '@/store'

export const logout = () => {
  store.dispatch('logout').then(() => {
    store.dispatch('ToggleModal')
    store.dispatch('ToggleOverlay')
    router.push('/login')
  })
}
