<script setup>
// Required components
import { onMounted, computed, ref, watch } from 'vue'
// Store
import store from '@/store'
// Base page
import DashboardPageBase from './DashboardPageBase'
import Dropdown from '@/components/Base/Fields/Dropdown'
import loader from '@/components/Base/Loader'
import { processStatuses } from '@/utils'

import { config } from '@/config'

const events = computed(() => {
  return store.getters.events
})

const selectedEventSlug = ref(null)

const analyticsUrl = computed(() => {
  // Looker Studio Query Parameter should follow the format:
  /*
  const includeEventSlugStatement = "include%EE%80%800%EE%80%80IN%EE%80%80" + selectedEventSlug.value?.slug
  // df77, df60, df61, df62 are the Looker Studio dashboard filters
  const params = {
    "df77": includeEventSlugStatement,
    "df60": includeEventSlugStatement,
    "df61": includeEventSlugStatement,
    "df62": includeEventSlugStatement,
  }
  const paramsAsString = JSON.stringify(params)
  const encodedParams = encodeURIComponent(paramsAsString)
  // Save the output of the following statement to env variable VITE_ANALYTICS_URL
  Sample URL: https://lookerstudio.google.com/u/0/reporting/0f6f2dd3-8916-4c6c-b469-f0472bfbd90a/page/kIV1C?params=${encodedParams}
  */

  return config.ANALYTICS_URL.replaceAll('{{event_slug}}', selectedEventSlug.value?.slug)
})

const eventsStatus = computed(() => {
  return store.getters.eventsStatus
})

const initializeAnalyticsEvents = () => {
  store.dispatch('GetEvents').then(() => {
    if (events.value.length > 0) {
      selectedEventSlug.value = events.value[0]
    }
  })
}

onMounted(() => {
  initializeAnalyticsEvents()
})

watch(() => store.getters.getUserOrganizationStatus, () => {
  if (eventsStatus.value !== processStatuses.LOADED) {
    initializeAnalyticsEvents()
  }
})
</script>

<template>
  <dashboard-page-base>
    <template #title>
        Showcase Analytics
    </template>
    <template #subContent>
      <h6 class="analytics-subtitle">
        Here you can see an overview of your participant and visitor data.
      </h6>
      <div v-if="eventsStatus === processStatuses.LOADED"
           class="analytics-event-selector">
        <label class="analytics-event-selector-label">Event:</label>
        <dropdown v-if="selectedEventSlug"
                  v-model="selectedEventSlug"
                  class="analytics-event-selector-dropdown"
                  :options="events"></dropdown>
      </div>
    </template>
    <template #content>
      <div v-if="eventsStatus === processStatuses.LOADING"
           class="analytics-loader">
        <loader size="xl" />
      </div>
      <div v-else-if="selectedEventSlug"
           class="analytics-content">
        <iframe class="analytics-content-iframe"
                :src="analyticsUrl"></iframe>
      </div>
    </template>
  </dashboard-page-base>
</template>

<style lang="scss" scoped>
.analytics {
  &-subtitle {
    @apply text-h6 text-blacks-300 my-2;
  }
  &-event-selector {
    @apply flex align-middle content-center items-center mb-6;
    &-label {
      @apply font-bold pr-2;
    }
    &-dropdown {
      @apply md:max-w-7xl;
    }
  }
  &-content {
    @apply p-2 md:mx-2 h-[80vh] w-full bg-white rounded-2xl drop-shadow-md border-grey-300 border-t max-w-[64rem];
    &-title {
      @apply text-h3 pb-6 font-bold;
    }
    &-iframe {
      @apply h-full w-full;
    }
  }
  &-loader {
    @apply p-2 md:mx-2 w-full;
  }
}
</style>

