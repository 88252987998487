import AuthService from "@/services/auth"
import PasswordStrength from "@fnando/password_strength"
import { checkUsernameValidation, checkPasswordValidation, processStatuses } from '@/utils'
import { config } from '@/config'
import { sanitizeAuthToken } from '@/utils'

const initialState = () => ({
  status: 'idle',
  loggedIn: false,
  loginError: null,
  userNameEmailError: null,
  passwordError: null,
  loginServerError: null,
  authTokens: null,
  usernameOrEmail: null,
  usernameOrEmailIsValid: false,
  usernameOrEmailStatus: 'idle',
  usernameOrEmailError: null,
  userSettings: {},
  registerValidationError: {},
  passwordValidationError: {},
  checkVerify: null,
  isUserVerified: false,
  email: null,
  verificationCodeEmailOrUserName: null,
  verificationCodeSendStatus: 'idle',
  verificationCodeSendError: null,
  resetPasswordUsernameOrEmail: null,
  resetPasswordStatus: 'idle',
  resetPasswordError: null,
  changePasswordStatus: 'idle',
  changePasswordValidationError: {},
  changePasswordError: null,
  thirdPartyLoginStatus: processStatuses.IDLE,
  thirdPartyLoginError: null,
  ssoLoginStatus: processStatuses.IDLE,
  ssoLoginError: null
})

export default {
  state: () => {
    const authTokens = JSON.parse(localStorage.getItem('auth'));
    return authTokens ? { ...initialState(), loggedIn: true, authTokens: authTokens } : initialState()
  },
  getters: {
    authToken: state => state.authTokens ? state.authTokens.authorization : null,
    refreshToken: state => state.authTokens ? state.authTokens['refresh-token'] : null,
    username: state => state.authTokens ? state.authTokens.username : null,
    email: state => state.authTokens ? state.authTokens.email : null,
    loginError: state => state.loginError,
    passwordError: state => state.passwordError,
    userNameEmailError: state => state.userNameEmailError,
    loginServerError: state => state.loginServerError,
    isLoggedIn: state => state.loggedIn,
    usernameOrEmail: state => state.usernameOrEmail,
    usernameOrEmailError: state => state.usernameOrEmailError,
    registerValidationError: state => state.registerValidationError,
    passwordValidationError: state => state.passwordValidationError,
    checkVerify: state => state.checkVerify,
    isUserVerified: state => state.isUserVerified,
    verificationCodeEmailOrUserName: state => state.verificationCodeEmailOrUserName,
    verificationCodeSendStatus: state => state.verificationCodeSendStatus,
    verificationCodeSendError: state => state.verificationCodeSendError,
    isStaff: state => state.authTokens ? state.authTokens.isStaff : false,
    authLoading: state => state.status,
    resetPasswordUsernameOrEmail: state => state.resetPasswordUsernameOrEmail,
    resetPasswordStatus: state => state.resetPasswordStatus,
    resetPasswordError: state => state.resetPasswordError,
    changePasswordStatus: state => state.changePasswordStatus,
    changePasswordValidationError: state => state.changePasswordValidationError,
    changePasswordError: state => state.changePasswordError,
    thirdPartyLoginStatus: state => state.thirdPartyLoginStatus,
    thirdPartyLoginError: state => state.thirdPartyLoginError,
    ssoLoginStatus: state => state.ssoLoginStatus,
    ssoLoginError: state => state.ssoLoginError
  },
  mutations: {
    LOGIN_PROCESSING (state) {
      state.status = 'loading'
      state.userNameEmailError = ''
      state.passwordError = ''
    },
    LOGIN_DONE (state, payload) {
      state.status = 'loaded'
      state.loggedIn = true
      state.authTokens = payload
      state.loginError = null
    },
    LOGIN_ERROR (state, error) {
      state.status = 'error'
      state.loggedIn = false
      state.loginError = error
      state.loginServerError = error
    },
    USERNAME_EMAIL_PASSWORD_ERROR (state, error) {
      state.status = 'error'
      state.loggedIn = false
      state.userNameEmailError = ' '
      state.passwordError = ' '
      state.loginServerError = error
    },
    LOGOUT (state) {
      const initState = initialState()
      Object.keys(initState).forEach(key => { state[key] = initState[key] })
    },
    USERNAME_OR_EMAIL_VALIDATING (state) {
      state.usernameOrEmailStatus = 'loading'
    },
    USERNAME_OR_EMAIL_VALIDATED (state, payload) {
      state.usernameOrEmailStatus = 'loaded'
      state.userSettings = payload.userSettings
      state.usernameOrEmail = payload.username ?? payload.email
      state.isUserVerified = payload.isVerified
      state.usernameOrEmailError = null
    },
    USERNAME_OR_EMAIL_VALIDATE_ERROR (state, err) {
      state.usernameOrEmailStatus = 'error'
      state.usernameOrEmailError = err
    },
    TOKEN_REFRESHING (state) {
      state.tokenRefreshingStatus = 'loading'
    },
    TOKEN_REFRESHED (state) {
      state.tokenRefreshingStatus = 'loaded'
    },
    TOKEN_REFRESH_ERROR (state) {
      state.tokenRefreshingStatus = 'error'
    },
    REGISTER_VALIDATE_ERROR (state, err) {
      state.registerValidationError[err.key] = err.errMsg
    },
    PASSWORD_VALIDATE_ERROR (state, err) {
      state.passwordValidationError[err.key] = err.errMsg
    },
    REGISTER_VALIDATE_RESET (state) {
      state.registerValidationError = {}
      state.passwordValidationError = {}
      state.loginError = null
      state.usernameOrEmailError = null
    },
    PASSWORD_VALIDATED (state){
      state.registerValidationError.password = {}
      state.registerValidationError.passwordConfirm = {}
      state.changePasswordValidationError.password = {}
      state.changePasswordValidationError.passwordConfirm = {}
    },
    REGISTER_ERROR (state, error) {
      state.registerValidationError[error.key] = error.errMsg
    },
    REGISTER_DONE (state) {
      state.registerValidationError = {}
    },
    VERIFY_DONE (state, payload) {
      state.isUserVerified = true
      state.checkVerify = payload
    },
    VERIFY_ERROR (state, error) {
      state.isUserVerified = false
      state.checkVerify = error
    },
    RESET_VALIDATION_ERRORS (state) {
      state.loginError = null
      state.usernameOrEmailError = null
      state.registerValidationError = {}
      state.passwordValidationError = {}
      state.changePasswordValidationError = {}
      state.changePasswordError = null
      state.resetPasswordError = null
    },
    VERIFICATION_CODE_EMAIL_OR_USERNAME_INIT (state, emailOrUsername) {
      state.verificationCodeEmailOrUserName = emailOrUsername
      state.verificationCodeSendStatus = 'idle'
      state.verificationCodeSendError = null
    },
    VERIFICATION_CODE_EMAIL_SENDING (state) {
      state.verificationCodeSendStatus = 'loading'
      state.verificationCodeSendError = null
    },
    VERIFICATION_CODE_EMAIL_SENT (state) {
      state.verificationCodeSendStatus = 'loaded'
      state.verificationCodeSendError = null
    },
    VERIFICATION_CODE_EMAIL_SEND_ERROR (state, err) {
      state.verificationCodeSendStatus = 'error'
      state.verificationCodeSendError = err
    },
    RESET_PASSWORD_INIT (state, usernameOrEmail) {
      state.resetPasswordUsernameOrEmail = usernameOrEmail
      state.resetPasswordStatus = 'idle'
      state.resetPasswordError = null
    },
    RESET_PASSWORD_PROCESSING (state) {
      state.resetPasswordStatus = 'loading'
    },
    RESET_PASSWORD_DONE (state) {
      state.resetPasswordStatus = 'loaded'
      state.resetPasswordError = null
    },
    RESET_PASSWORD_ERROR (state, error) {
      state.resetPasswordStatus = 'error'
      state.resetPasswordError = error
    },
    CHANGE_PASSWORD_PROCESSING (state) {
      this.commit('CHANGE_PASSWORD_VALIDATE_RESET')
      state.changePasswordStatus = 'loading'
    },
    CHANGE_PASSWORD_VALIDATE_ERROR (state, err) {
      state.changePasswordValidationError[err.key] = err.errMsg
      state.changePasswordStatus = 'error'
    },
    CHANGE_PASSWORD_VALIDATE_RESET (state) {
      state.changePasswordValidationError = {}
      state.passwordValidationError = {}
      state.loginError = null
      state.usernameOrEmailError = null
    },
    CHANGE_PASSWORD_DONE (state) {
      state.changePasswordStatus = 'loaded'
      state.changePasswordError = null
    },
    CHANGE_PASSWORD_ERROR (state, error) {
      state.changePasswordStatus = 'error'
      state.changePasswordError = error
    },
    THIRD_PARTY_LOGIN_PROCESSING (state) {
      state.thirdPartyLoginStatus = processStatuses.LOADING
      state.thirdPartyLoginError = null
    },
    THIRD_PARTY_LOGIN_DONE (state) {
      state.thirdPartyLoginStatus = processStatuses.LOADED
    },
    THIRD_PARTY_LOGIN_ERROR (state, error) {
      state.thirdPartyLoginStatus = processStatuses.ERROR
      state.thirdPartyLoginError = error
    },
    SSO_LOGIN_PROCESSING (state) {
      state.ssoLoginStatus = processStatuses.LOADING
      state.ssoLoginError = null
    },
    SSO_LOGIN_DONE (state) {
      state.ssoLoginStatus = processStatuses.LOADED
    },
    SSO_LOGIN_ERROR (state, error) {
      state.ssoLoginStatus = processStatuses.ERROR
      state.ssoLoginError = error
    }
  },
  actions: {
    login ({ commit }, { usernameOrEmail, password }) {
      commit('LOGIN_PROCESSING')
      const usernameValidationErrors = checkUsernameValidation(usernameOrEmail)
      const passwordValidationErrors = checkPasswordValidation(password)
      if (usernameValidationErrors && passwordValidationErrors) {
        commit('USERNAME_EMAIL_PASSWORD_ERROR', usernameValidationErrors)
        return
      }

      if (passwordValidationErrors) {
        commit('USERNAME_EMAIL_PASSWORD_ERROR', passwordValidationErrors)
        return
      }
      return new Promise((resolve, reject) => {
        AuthService.login({usernameOrEmail, password}).then((response) => {
          handleLoginResponse(commit, resolve, reject, response)
        }).catch((err) => {
          commit('LOGIN_ERROR', err.data?.message)
          reject(err)
        })
      })
    },
    logout ({ commit }, redirectAfterLogout = true) {
      AuthService.logout()
      commit('LOGOUT')

      if (redirectAfterLogout) {
        //ToDo: this might not be the best practice, for Self Serve after logout we always redirect to the login page
        const url = config.AUTH_URL + '/selfserve/logout'
        window.location.href = url
      }
    },
    refresh ({ commit, getters }, config) {
      commit('TOKEN_REFRESHING')
      return new Promise((resolve, reject) => {
        AuthService.refresh({ username: getters.username, refreshToken: getters.refreshToken }, config).then((response) => {
          const authPayload = {
            'authorization': sanitizeAuthToken(response.headers.authorization),
            'refresh-token': response.headers['refresh-token'],
            'username': response.data.user.userName
          }
          localStorage.setItem('auth', JSON.stringify(authPayload));
          commit('LOGIN_DONE', authPayload)
          commit('USER_LOADED', response.data.user, {root: true})

          commit('TOKEN_REFRESHED', response.data.refreshToken)
          resolve()
        }).catch((err) => {
          commit('TOKEN_REFRESH_ERROR',err.data.message)
          reject(err)
        })
      })
    },
    firebaseLogin: ({commit}, { idToken, signupSource }) => {
      commit('LOGIN_PROCESSING')
      return new Promise((resolve, reject) => {
        AuthService.firebaseLogin({ idToken, signupSource }).then(response => {
          handleLoginResponse(commit, resolve, reject, response)
        }).catch(err => {
          commit('LOGIN_ERROR', err.data?.message)
          reject(err)
        })
      })
    },
    makeLogin: ({commit}, response) => {
      commit('LOGIN_PROCESSING')
      return new Promise((resolve, reject) => {
        // assign the username to the userName key to match the expectation of the handleLoginResponse function.
        response.data.user.userName = response.data.user.user_name
        handleLoginResponse(commit, resolve, reject, response)
      })
    },
    verifyAccount ({ commit }, { usernameOrEmail, verificationCode }) {
      return new Promise((resolve, reject) => {
        AuthService.VerifyAccount({usernameOrEmail, verificationCode}).then((response) => {
          commit('VERIFY_DONE', response)
          const authPayload = {
            'authorization': sanitizeAuthToken(response.headers.authorization),
            'refresh-token': response.headers['refresh-token'],
            'username': response.data.user.userName,
            'isStaff': response.data.user.isEngStaff
          }
          localStorage.setItem('auth', JSON.stringify(authPayload));
          commit('LOGIN_DONE', authPayload)
          commit('USER_LOADED', response.data.user, {root: true})
          resolve()
        }).catch((err) => {
          commit('VERIFY_ERROR', err.data.message)
          reject(err)
        })
      })
    },
    validateSignupAccount ({ commit }, { email, username, password, passwordConfirm, firstName, lastName }) {
      commit('REGISTER_VALIDATE_RESET', '')
      let validateStatus = false
      let passwordValidate = false
      let emailStatus = false
      let usernameStatus = false
      let firstNameStatus = false
      let lastNameStatus = false
      return new Promise((resolve, reject) => {
        if (!firstName) {
          const payload = { 'key': 'firstName', 'errMsg': 'Please enter a first name' }
          commit('REGISTER_VALIDATE_ERROR', payload)
          reject()
        } else {
          firstNameStatus = true
        }
        if (!lastName) {
          const payload = { 'key': 'lastName', 'errMsg': 'Please enter a last name' }
          commit('REGISTER_VALIDATE_ERROR', payload)
          reject()
        } else {
          lastNameStatus = true
        }
        if (!email) {
          const payload = { 'key': 'email', 'errMsg': 'Please input an email address' }
          commit('REGISTER_VALIDATE_ERROR', payload)
          reject()
        } else if (email.indexOf('@') < 1 || email.lastIndexOf('.') < email.indexOf('@') + 2 || email.lastIndexOf('.') + 2 >= email.length) {
          const payload = { 'key': 'email', 'errMsg': 'Please enter valid email' }
          commit('REGISTER_VALIDATE_ERROR', payload)
          reject()
        } else {
          emailStatus = true
        }
        if (!username) {
          const payload = { 'key': 'username', 'errMsg': 'Please choose an username' }
          commit('REGISTER_VALIDATE_ERROR', payload)
          reject()
        } else if (username && (username.length < 4 || username.length > 20)) {
          const payload = { 'key': 'username', 'errMsg': 'Usernames must be between 4 and 20 characters in length' }
          commit('REGISTER_VALIDATE_ERROR', payload)
          reject()
        } else {
          usernameStatus = true
        }
        if (!password) {
          const payload = { 'key': 'password', 'errMsg': 'Please choose a password' }
          commit('REGISTER_VALIDATE_ERROR', payload)
          reject()
        }
        if (!passwordConfirm) {
          const payload = { 'key': 'passwordConfirm', 'errMsg': 'Please confirmed your password' }
          commit('REGISTER_VALIDATE_ERROR', payload)
          reject()
        }
        passwordValidate = validatePassword(commit, password)
        if (passwordValidate) {
          if (!passwordConfirm) {
            const payload = { 'key': 'passwordConfirm', 'errMsg': 'Please enter a confirm password' }
            commit('REGISTER_VALIDATE_ERROR', payload)
            reject()
          } else if (password !== passwordConfirm) {
            const payload = { 'key': 'passwordConfirm', 'errMsg': 'Passwords do not match! Please try again' }
            commit('REGISTER_VALIDATE_ERROR', payload)
            reject()
          } else {
            validateStatus = true
            commit('PASSWORD_VALIDATED')
          }
        } else {
          reject()
        }

        if (emailStatus && usernameStatus && passwordValidate && validateStatus && firstNameStatus && lastNameStatus) {
          commit('REGISTER_VALIDATE_RESET', '')
          const user = {
            email: email,
            username: username,
            password: password,
            firstName: firstName,
            lastName: lastName
          }
          AuthService.register(user).then((response) => {
            commit('REGISTER_DONE')
            commit('VERIFICATION_CODE_EMAIL_OR_USERNAME_INIT', response.data.user.email)
            resolve()
          }).catch((err) => {
            const payload = { 'key': 'registerError', 'errMsg': err.data.message }
            commit('REGISTER_ERROR', payload)
            reject(err)
          })
        }
      })
    },
    validateUsernameOrEmail ({ commit }, { username, email }) {
      commit('USERNAME_OR_EMAIL_VALIDATING')
      return new Promise((resolve, reject) => {
        if (!username && !email) {
          commit('USERNAME_OR_EMAIL_VALIDATE_ERROR', 'Please enter a username or email')
          reject()
        } else {
          AuthService.validateUserNameOrEmail({username, email}).then((response) => {
            commit('USERNAME_OR_EMAIL_VALIDATED', {
              username: response.data.user.userName,
              isVerified: response.data.isVerified,
              userSettings: response.data.user.userSettings
            })
            commit('VERIFICATION_CODE_EMAIL_OR_USERNAME_INIT', response.data.user.email)
            resolve(response)
          }).catch((err) => {
            commit('USERNAME_OR_EMAIL_VALIDATE_ERROR', err.data.message)
            reject(err)
          })
        }
      })
    },
    ResetValidationError ({commit}) {
      commit('RESET_VALIDATION_ERRORS')
    },
    sendVerificationCode ({ commit }, { usernameOrEmail }) {
      commit('VERIFICATION_CODE_EMAIL_SENDING')
      return new Promise((resolve, reject) => {
        if (!usernameOrEmail) {
          commit('VERIFICATION_CODE_EMAIL_SEND_ERROR', 'Invalid username or email')
          reject()
        } else {
          AuthService.sendVerificationCode({usernameOrEmail}).then(() => {
            commit('VERIFICATION_CODE_EMAIL_SENT')
            resolve()
          }).catch((err) => {
            commit('VERIFICATION_CODE_EMAIL_SEND_ERROR', err.data.message)
            reject(err)
          })
        }
      })
    },
    resetPassword ({ commit }, { usernameOrEmail }) {
      commit('RESET_PASSWORD_INIT', usernameOrEmail)
      commit('RESET_PASSWORD_PROCESSING')
      return new Promise((resolve, reject) => {
        AuthService.resetPassword({usernameOrEmail}).then(() => {
          commit('RESET_PASSWORD_DONE')
          resolve()
        }).catch((err) => {
          commit('RESET_PASSWORD_ERROR', err.data.message)
          reject(err)
        })
      })
    },
    changePassword ({ commit }, { newPassword, passwordConfirm, token }) {
      commit('CHANGE_PASSWORD_PROCESSING')
      let newPasswordValidated = false
      let passwordConfirmValidated = false
      return new Promise((resolve, reject) => {
        if (!newPassword) {
          const payload = { 'key': 'password', 'errMsg': 'Please enter a new password' }
          commit('CHANGE_PASSWORD_VALIDATE_ERROR', payload)
          reject()
        }
        newPasswordValidated = validatePassword(commit, newPassword)
        if (newPasswordValidated) {
          if (!passwordConfirm) {
            const payload = { 'key': 'passwordConfirm', 'errMsg': 'Please enter a confirm password' }
            commit('CHANGE_PASSWORD_VALIDATE_ERROR', payload)
            reject()
          } else if (newPassword !== passwordConfirm) {
            const payload = { 'key': 'passwordConfirm', 'errMsg': 'Passwords do not match! Please try again' }
            commit('CHANGE_PASSWORD_VALIDATE_ERROR', payload)
            reject()
          } else {
            passwordConfirmValidated = true
            commit('PASSWORD_VALIDATED')
          }
        } else {
          commit('CHANGE_PASSWORD_VALIDATE_ERROR', 'Invalid password')
          reject()
        }

        if (passwordConfirmValidated && newPasswordValidated) {
          commit('CHANGE_PASSWORD_VALIDATE_RESET')
          AuthService.changePassword({ newPassword, token }).then(() => {
            commit('CHANGE_PASSWORD_DONE')
            resolve()
          }).catch((err) => {
            commit('CHANGE_PASSWORD_ERROR', err.data.message)
            reject(err)
          })
        }
      })
    },
    thirdPartyLogin({ commit, getters }, { token }) {
      if (getters.thirdPartyLoginStatus === processStatuses.LOADING) {
        console.error('Third party login already in progress')
        return
      }

      if (!token) {
        commit('THIRD_PARTY_LOGIN_ERROR', 'Invalid token')
        return
      }
      commit('THIRD_PARTY_LOGIN_PROCESSING')
      return new Promise((resolve, reject) => {
        AuthService.thirdPartySso({ token }).then((response) => {
          const authPayload = {
            'authorization': sanitizeAuthToken(response.headers.authorization),
            'refresh-token': response.headers['refresh-token'],
            'username': response.data.user.userName,
            'isStaff': response.data.user.isEngStaff
          }

          localStorage.setItem('auth', JSON.stringify(authPayload));
          commit('THIRD_PARTY_LOGIN_DONE')
          commit('LOGIN_DONE', authPayload)
          commit('USER_LOADED', response.data.user, {root: true})
          resolve()
        }).catch((err) => {
          commit('THIRD_PARTY_LOGIN_ERROR', err.data.message)
          reject(err)
        })
      })

    },
    exchangeForAccessToken ({ commit }, { token }) {
      commit('SSO_LOGIN_PROCESSING')
      return new Promise((resolve, reject) => {
        AuthService.exchangeForAccessToken({token}).then((response) => {
          commit('SSO_LOGIN_DONE')
          const authPayload = handleLoginResponse(commit, resolve, reject, response)
          resolve(authPayload)
        }).catch((err) => {
          commit('SSO_LOGIN_ERROR', err.data.message)
          reject(err)
        })
      })
    },
  }
}

function validatePassword(commit, password) {
  const uppercase = /[A-Z]/
  const lowercase = /[a-z]/
  const number = /[0-9]/
  const strength = PasswordStrength.test('', password)

  // if (!password) {
  //   const payload = { 'key': 'passwordLength', 'errMsg': "Password should contain at least 6 characters" }
  //   commit('PASSWORD_VALIDATE_ERROR', payload)
  //   return false
  // }

  if (password && (password.length < 6 || !uppercase.test(password) || !lowercase.test(password) || !number.test(password) || strength.status === 'weak' || strength.status === 'invalid' || strength.score === 0)) {
    if (!number.test(password)) {
      const payload = { 'key': 'passwordNumber', 'errMsg': "Password should contain at least 1 number" }
      commit('PASSWORD_VALIDATE_ERROR', payload)
    }
    if (!uppercase.test(password)) {
      const payload = { 'key': 'passwordUpper', 'errMsg': "Password contain at least 1 uppercase character" }
      commit('PASSWORD_VALIDATE_ERROR', payload)
    }
    if (!lowercase.test(password)) {
      const payload = { 'key': 'passwordLower', 'errMsg': "Password should contain at least 1 lowercase character" }
      commit('PASSWORD_VALIDATE_ERROR', payload)
    }
    if (password.length < 6) {
      const payload = { 'key': 'passwordLength', 'errMsg': "Password should contain at least 6 characters" }
      commit('PASSWORD_VALIDATE_ERROR', payload)
    }
    if (strength.status === 'weak' || strength.status === 'invalid' && strength.score < 0) {
      const payload = { 'key': 'passwordBasic', 'errMsg': "Avoid basic passwords (ex. Password123)" }
      commit('PASSWORD_VALIDATE_ERROR', payload)
    }
  } else if (password.length > 6 && uppercase.test(password) && lowercase.test(password) && number.test(password)) {
    commit('PASSWORD_VALIDATED')
    return true
  }

  return false
}

function handleLoginResponse(commit, resolve, reject, response) {
  if (!response.data.verified) {
    commit('USERNAME_OR_EMAIL_VALIDATED', {
      username: response.data.user.userName,
      isVerified: response.data.verified,
      userSettings: response.data.user.userSettings
    })
    commit('VERIFICATION_CODE_EMAIL_OR_USERNAME_INIT', response.data.user.email)
    commit('LOGIN_ERROR', 'Account is not verified')
    reject('Account is not verified')
  } else {
    const authPayload = {
      'authorization': sanitizeAuthToken(response.headers.authorization),
      'refresh-token': response.headers['refresh-token'],
      'username': response.data.user.userName,
      'isStaff': response.data.user.isEngStaff
    }

    localStorage.setItem('auth', JSON.stringify(authPayload))
    commit('LOGIN_DONE', authPayload)
    commit('USER_LOADED', response.data.user, {root: true})
    resolve(authPayload)
  }
}
