import axios from 'axios'
import { config } from '@/config'
import { parseParams } from '@/utils'

class BannerService {
    getBannerSets({ eventId }) {
        return axios.get(`${config.API_URL}/banner-sets`, {
            params: {
                eventId: eventId
            }
        })
    }

    createBannerSets({ eventId, name, bannerId }) {
        return axios.post(`${config.API_URL}/banner-sets`, {
            "name": name,
            "eventId": eventId,
            "bannersInSets": [
                {
                    "bannerId": bannerId,
                    "sortOrder": 0
                }
            ]
        })
    }

    updateBannerSets({ banner_set_id, name, banners }, updateMask = "name,banners_in_sets") {
        // Banners structure
        // [{ "bannerId", "sortOrder" }]
        // We are using sortOrder to determine the selected banner: 0 is selected, 1 is not selected
        return axios.patch(`${config.API_URL}/banner-sets/${banner_set_id}`, {
            "name": name,
            "bannersInSets": banners
        }, {
            params: {
                "updateMask": updateMask
            }
        })
    }

    deleteBannerSets({ banner_set_id }) {
        return axios.delete(`${config.API_URL}/banner-sets/${banner_set_id}`)
    }

    deleteBannersInSets({ banner_set_id, banner_ids }) {
        return axios.delete(`${config.API_URL}/banner-sets/${banner_set_id}/banners`, {
            params: {
                "bannerIds": banner_ids
            },
            paramsSerializer: (params) => parseParams(params),
        })
    }

    createBanner({ name, title, description, backgroundImage }) {
        return axios.post(`${config.API_URL}/banners`, {
            "name": name,
            "title": title,
            "description": description,
            "backgroundImage": backgroundImage
        })
    }

    updateBanner({ id, name, title, description, backgroundImage}) {
        return axios.patch(`${config.API_URL}/banners/${id}`, {
            "name": name,
            "title": title,
            "description": description,
            "backgroundImage": backgroundImage,
        },{
            params: {
                "updateMask": "background_image"
            }
        })
    }
}

export default new BannerService()
