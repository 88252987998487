<script setup>
import {computed, onMounted, watch, reactive} from 'vue'
import store from '@/store'
// Custom components
import DashboardPageBase from './DashboardPageBase'
import BaseInput from '@/components/Base/Fields/InputField'
import BaseButton from '@/components/Base/Button/BaseButton'
import BasicImgFileInput from "@/components/Base/Fields/ImageField"
import BasicForm from '@/components/Base/Forms/BasicForm'
import { processStatuses } from '@/utils'

import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

const maxCharName = 80
const event = reactive({
  teamLogo: '',
  teamName: '',
  email: '',
  url: '',
})
const saveTeam = () => {
  const { query } = route
  const payload = {
    teamLogo: event.teamLogo,
    teamName: event.teamName,
    email: event.email
  }
  store.dispatch('validateTeamSettings', payload).then((response) => {
    payload.teamLogo = response
    payload.organization = store.getters.newOrganization
    store.dispatch('updateTeamSettings', payload).then((res) => {
      event.teamLogo = res.data.organization.logo
      event.teamName = res.data.organization.name
      event.email = res.data.organization.email
      if (query?.returnUrl) {
        router.push(query.returnUrl)
      } else {
        store.dispatch('triggerToast',{ message: 'Your settings has been successfully saved.', type: 'success'})
      }
    }).catch(error => {
      console.error(error)
    })
  })
}
const fileUploadStatus = computed(() => {
  return store.getters.teamLogoUploadStatus
})

const savingStatus = computed(() => {
  return store.getters.teamDataStatus
})

const isTeamSaving = computed(() => {
  return fileUploadStatus.value === processStatuses.SAVING || savingStatus.value === processStatuses.SAVING
})

const getPartner = (user) => {
  store.dispatch('fetchOrganization', {slug: user.userName, userId: user.id}).then((partner) => {
    event.teamLogo = partner.logo
    event.teamName = partner.name
    event.email = partner.email
  }).catch(error => {
    console.error(error)
  })
}
watch(() => store.getters.user, (user, prevUser) => {
  if (!prevUser && user) {
    getPartner(user)
  }
})
onMounted(() => {
  store.dispatch('resetTeamSettingsError')
  if(store.getters.user){
    getPartner(store.getters.user)
  }
})

const teamFormValidationErrorMessage = computed(() => {
  return store.getters.teamFormValidationError
})
const teamValidateError = (type) => {
  let temp = ''
  Object.keys(teamFormValidationErrorMessage.value).filter(item =>{
    if (item === type) {
      temp = teamFormValidationErrorMessage.value[type]
    }
  })
  return temp
}
</script>

<template>
  <dashboard-page-base>
    <template #title>
      Settings
    </template>
    <template #content>
      <div class="team">
        <basic-form class-name="team-form"
                    :submit-action="saveTeam">
          <label class="team-form_label">Organization Logo * </label>
          <span class="flex flex-col relative mt-5 justify-left items-left">
            <span class="z-20 relative">
              <basic-img-file-input id="logo"
                                    v-model="event.teamLogo"
                                    name="Logo"
                                    class-name="logo bg-white"
                                    :disabled="isTeamSaving"
                                    :image-has-changed="true"
                                    :img-file-error="teamValidateError('teamLogo')"/>
            </span>
            <div class="absolute t-0 h-10rem l-0 w-10rem ">
              <img v-if="event.teamLogo && event.teamLogo.length"
                   class="w-40 h-40 z-10 rounded-full"
                   :src="event.teamLogo">
            </div>
          </span>
          <span class="team-form-section">
            <label class="team-form_label">
              Organization Name * (This is used for any external event communications)
            </label>
            <base-input v-model="event.teamName"
                        input-id="teamName"
                        :model-value="event.teamName"
                        input-type="text"
                        input-aria-label="team name"
                        input-name="teamName"
                        class-name="w-full p-2 -ml-2"
                        :disabled="isTeamSaving"
                        :input-max-length="maxCharName"
                        :errors="teamValidateError('teamName')"/>
          </span>
          <span v-if="event.teamName && event.teamName.length" class="w-full flex flex-row justify-end text-xs text-purple-500">
          Characters left: {{maxCharName - event.teamName.length}}
          </span>
          <span class="team-form-section">
            <label class="team-form_label">
              Primary Email * (This is where you'll receive all notifications for your events)
            </label>
            <base-input v-model="event.email"
                        input-id="email"
                        :model-value="event.email"
                        input-type="text"
                        input-name="email"
                        :input-place-holder="event.email"
                        :disabled="isTeamSaving"
                        class-name="w-full p-2 -ml-2"
                        :errors="teamValidateError('email')"/>
         </span>
          <span v-if="false"
                class="team-form-section">
            <label class="team-form_label">
              Team Website (If applicable)
            </label>
            <base-input v-model="event.url"
                        input-id="url"
                        :model-value="event.url"
                        input-type="text"
                        input-name="url"
                        class-name="w-full p-2 -ml-2"
                        :input-place-holder="event.url"
                        :disabled="isTeamSaving"
                        :errors="teamValidateError('url')"/>
          </span>
          <div class="error" >{{ teamFormValidationErrorMessage.teamError }}</div>
          <span class="flex flex-col w-full justify-end items-end pt-8 pr-4">
            <base-button text="Save"
                         aria-label="Save Button"
                         :disabled="isTeamSaving"
                         :is-loading="isTeamSaving"
                         :functions="saveTeam" />
          </span>
        </basic-form>
      </div>
    </template>
  </dashboard-page-base>
</template>

<style lang="scss" scoped>
.team {
  @apply flex items-center p-4;
  &-form {
    @apply bg-white rounded-2xl p-2 drop-shadow-md my-8 px-16 py-8 w-full max-w-[50rem];
    &-section {
      @apply flex flex-col w-full py-4;
    }
    &_label {
      @apply text-gray-500 text-sm pb-4 tracking-wide;
    }
    &_input {
      @apply w-full p-2;
    }
  }
}
</style>
