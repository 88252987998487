<script setup>
// Required Package
import { computed, reactive, onMounted, watch } from 'vue'
// Store
import store from '@/store'

const props = defineProps({
  bannerName: {
    type: String,
    default: 'celebrate'
  }
})

// User Primary Colour
const primaryColour = computed(() => {
  return store.getters.eventSettings.primaryColor
})
// User Secondary Colour
const secondaryColour = computed(() => {
  return store.getters.eventSettings.secondaryColor
})

// Setting the banner defaults
const bannerSettings = reactive({
  banners: store.getters.getBanners,
  svgStr: ''
})

// Get the SVG file and convert to string
const svgFileToString = (path) => {
  const {backgroundImage, defaultPrimaryColor, defaultSecondaryColor} = path
  fetch(backgroundImage)
    .then((response) => response.text())
    .then((data) => {
      // Replace all primary with users
      defaultPrimaryColor.split(',').map(colour => {
        data = data.replaceAll(`fill="${colour}"`, ` class="fillPrimary"`)
        data = data.replaceAll(`stroke="${colour}"`, `class="strokePrimary"`)
      })
      // Replace all secondary with users
      defaultSecondaryColor.split(',').map(colour => {
        data = data.replaceAll(`fill="${colour}"`, ` class="fillSecondary"`)
        data = data.replaceAll(`stroke="${colour}"`, `class="strokeSecondary"`)
      })
      bannerSettings.svgStr = data
    }).catch((error) => {
      console.error(error)
    })
}
// Get the SVG banner
const getSvgBanner = (name = 'celebrate') => {
  Object.keys(bannerSettings.banners).map((key) => {
    if (bannerSettings.banners[key].name.toLowerCase() === name) {
      svgFileToString(bannerSettings.banners[key])
    }
  })
}
// Check for colour changes
watch (() => {
  primaryColour.value
  secondaryColour.value
}, function() {
  getSvgBanner(props.bannerName)
}, { deep: true })

onMounted(() => {
  getSvgBanner(props.bannerName)
})
</script>

<template>
  <!-- Celebrate SVG Start -->
  <div class="svg-container">
    <span class="svg-banner"
          v-html="bannerSettings.svgStr"/>
  </div>
</template>

<style lang="scss" scoped>
.svg {
  &-container {
    @apply absolute left-0 md:left-auto min-w-[40rem] w-full h-[9rem] flex justify-center items-center mt-2;
  }
  &-banner{
    @apply w-full h-auto flex justify-center items-center;
  }
}
// Deeps Colours for SVG
:deep(.fillPrimary) {
  fill: var(--primary);
}
:deep(.strokePrimary) {
  stroke: var(--primary);
}
:deep(.fillSecondary) {
  fill: var(--secondary);
}
:deep(.strokeSecondary) {
  stroke: var(--secondary);
}
</style>

