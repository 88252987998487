export const RoleEnum = {
  1: 'Super Admin',
  2: 'User',
  3: 'Owner',
  4: 'Admin',
  5: 'Editor',
  9: 'Owner', // organization owner
  10: 'Admin', // organization admin
}

export const mapRoleIdToRoleName = (roleId) => {
  return RoleEnum[roleId] || '-'
}


const SUPER_ADMIN = 'super_admin'
const SS_USER = 'ss_user'
const SS_EVENT_OWNER = 'ss_event_owner'
const SS_EVENT_ADMIN = 'ss_event_admin'
const SS_EVENT_EDITOR = 'ss_event_editor'
const SS_EVENT_VIEWER = 'ss_event_viewer'
const SS_EVENT_PARTICIPANT = 'ss_event_participant'
const SS_EVENT_VISITOR = 'ss_event_visitor'

export const userRoles = {
  SUPER_ADMIN,
  SS_USER,
  SS_EVENT_OWNER,
  SS_EVENT_ADMIN,
  SS_EVENT_EDITOR,
  SS_EVENT_VIEWER,
  SS_EVENT_PARTICIPANT,
  SS_EVENT_VISITOR,
}

export const roleToEnum = {
  'ss_event_owner': 0,
  'ss_event_admin': 1,
  'ss_event_participant': 7
}


