import { createApp } from 'vue'
// Routes
import router from './router'
// Store
import store from './store'
// Touch
import Vue3TouchEvents from 'vue3-touch-events'
// Import app
import App from './App.vue'
// Config
import { config } from '@/config'
// Vue 3 Colour Picker
import Vue3ColorPicker from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'
// Tailwind CSS configure
import './styles/tailwind.css'
// Fontawesome
import '@fortawesome/fontawesome-pro/css/all.css'
// LogRocket
import LogRocket from 'logrocket'

import { jwtInterceptor, errorInterceptor } from '@/utils'

jwtInterceptor()
errorInterceptor()

// LogRocket
if (config.LOGROCKET) {
  LogRocket.init(config.LOGROCKET)
}

// Set GA Parameters
document.cookie = `gtm_ga_id=${config.GA_ID}; SameSite=None; Secure;`

// Added router, store & fontawesome for app
createApp(App)
  .use(router)
  .use(store)
  .use(Vue3TouchEvents)
  .use(Vue3ColorPicker)
  .mount('#app')
