<script setup>
import { computed } from 'vue'
import { FocusTrap } from 'focus-trap-vue'
import store from '@/store'
// Modal logic
const typeOfModal = computed(() => {
  return store.getters.typeOfModal
})
const modalState = computed(() => {
  return store.getters.modal
})
const toggleModal = () => {
  store.dispatch('ToggleModal')
  store.dispatch('ToggleOverlay')
  store.dispatch('ResetModal')
  if (!modalState.value) {
    store.dispatch('ResetValidationError')
  }
}
</script>

<template>
  <focus-trap v-model:active="modalState">
    <main class="modal"
          :class="`modal-${typeOfModal}`">
        <span class="modal-header">
          <i v-if="store.getters.isLoggedIn || typeOfModal === 'register'"
             class="fa-light fa-xmark fa-xl modal-header_close"
             @click="toggleModal" />
          <h1 class="modal-header_title">
            <slot name="modal-title"></slot>
          </h1>
          <p class="modal-header_subtitle">
            <slot name="modal-subtitle"></slot>
          </p>
        </span>
        <slot name="modal-body"></slot>
        <slot name="modal-button"></slot>
        <slot name="modal-footer"></slot>
    </main>
  </focus-trap>
</template>

<style lang="scss" scoped>
.modal {
  @apply border rounded-xl flex flex-col bg-gray-100 w-screen;
  &-header {
    @apply w-full p-4 mb-4;
    &_close {
      @apply absolute right-2 p-1 cursor-pointer;
    }
    &_title {
      @apply text-center font-bold text-h1 pb-4;
    }
    &_subtitle {
      @apply text-center text-sm font-normal text-greys-300;
    }
  }
  &-reset-password {
    @apply bg-white md:bg-gray-100 border-0 md:border flex flex-col justify-center md:w-[40rem] md:h-[30rem] #{!important};
  }
  &-check-reset-password-email {
    @apply px-4 bg-white md:bg-gray-100 border-0 md:border flex flex-col justify-center md:h-[30rem] md:w-[35rem] #{!important};
  }
  &-forget-password {
    @apply px-4 bg-white md:bg-gray-100 border-0 md:border flex flex-col justify-center md:w-[40rem] md:h-[30rem] #{!important};
  }
  &-login, &-password, &-register, &-logout {
    @apply md:w-[30rem];
  }
  &-verify {
    @apply md:w-[38rem];
  }
  &-profile {
    @apply md:w-[36rem];
  }
}
.nextBtn {
  @apply bg-purple text-white uppercase rounded-md text-sm;
}
.skipBtn {
  @apply text-purple uppercase rounded-md text-sm mr-4 border-2 border-gray-300;
}
.tryAgain {
  @apply text-center text-xs mb-12;
  &_text {
    @apply text-purple cursor-pointer;
  }
}
</style>
