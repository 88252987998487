<script setup>
import EventPageBase from '@/pages/EventBuilder/EventPageBase'
import SubmissionsList from '@/components/Submissions/List'
import store from '@/store'
import { computed } from 'vue'
import { processStatuses } from '@/utils'
import BaseButton from '@/components/Base/Button/BaseButton'

const eventIsPublished = computed(() => {
  return store.getters.eventSettings.status === 'Published'
})

const participantNotifyStatus = computed(() => {
  return store.getters.getParticipantNotifyStatus
})

const notifyParticipantsButtonDisabled = computed(() => {
  return participantNotifyStatus.value === processStatuses.LOADING || isParticipantsNotified.value || totalProjects.value === 0
})

const isParticipantsNotified = computed(() => {
  return store.getters.getParticipantsNotified
})

const projectSubmissionsCounts = computed(() => {
  return store.getters.getProjectSubmissionsCounts
})

const totalProjects = computed(() => {
  const { approved, declined, draft, submitted } = projectSubmissionsCounts.value
  return approved + declined + draft + submitted || 0
})

const notifyParticipants = () => {
  store.dispatch('ToggleModalGeneralConfirm', {
    title: 'Are You Sure You Want To Send Decisions?',
    description: 'All participants that have approved or declined projects <span class="underline"">will be</span> notified. ' +
      '<br><br> Any participants with projects that are Draft or Submitted, <span class="underline"">will not</span> be notified. ' +
      '<br><br><strong>Decisions are final, and you cannot change project statuses later</strong>.',
    confirmButton: 'Yes, Notify Now',
    cancelButton: 'No, I Need More Time',
    confirmAction: () => {
      store.dispatch('sendProjectSubmissionsStatusEmail').then(() => {
        store.dispatch('triggerToast',{ message: 'All participants notified', type: 'success'})
        store.dispatch('ResetModal')
      }).catch(() => {
        store.dispatch('triggerToast',{ message: 'Failed to notify participants', type: 'error'})
        store.dispatch('ResetModal')
      })
    },
    cancelAction: () => {
      store.dispatch('ResetModal')
    }
  })
}
</script>

<template>
  <event-page-base :is-visible="false">
    <template #title>
      Project Submissions
    </template>
    <template v-if="eventIsPublished" #description>
      Now that the event is live, invite participants to complete the template. Once they begin editing their project, their information will appear below to access and manage.
    </template>
    <template v-else #description>
      Once your event is published, you will be able to invite participants by sharing the event URL with them - they can join directly from there. Once they begin editing their projects, their information will appear below to access and manage.
    </template>
    <template #rightContainer>
      <base-button :functions="notifyParticipants"
                   :is-loading="participantNotifyStatus === processStatuses.LOADING"
                   :disabled="notifyParticipantsButtonDisabled"
                   text="Send Decision"/>
    </template>
    <template #content>
      <submissions-list />
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
</style>
