<script setup>
import ModalBase from "@/components/Modals/ModalBase"
import BaseButton from '@/components/Base/Button/BaseButton';
import store from '@/store';
import { ref } from 'vue';
const done = () => {
  store.dispatch('ToggleModalEventCancelled')
}
const headerImage = ref('https://firebasestorage.googleapis.com/v0/b/test-d9796.appspot.com/o/selfserve%2Fbadge-check-solid%201.png?alt=media&token=38bf12d8-54c9-419d-be3c-08129e776328')
</script>

<template>
  <modal-base modal-class="bg-white modal-eventCancel" :is-close="true">
    <template #modal-title>
      <div class="modal-eventCancel-title">
        <img class="modal-eventCancel-title-img" :src="headerImage"/>
        <h1 class="modal-eventCancel-title-header">Your request has been submitted!</h1>
      </div>
    </template>
    <template #modal-content>
      <div class="modal-eventCancel-content">
        <p class="modal-eventCancel-content-para">Response times from our team may vary, but we will try our hardest to get back to you within 24 hours.</p>
      </div>
    </template>
    <template #modal-button>
      <div class="modal-eventCancel-button">
        <base-button id="event-cancel-btn"
                     name="event-cancel-btn"
                     class-name="btn btn-primary modal-eventCancel-button-done"
                     btn-type="text"
                     :functions="done"
                     text="Done" />
      </div>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal-eventCancel {
  @apply text-center text-h4 font-medium md:px-5 px-2 md:h-auto overflow-y-auto max-w-md p-7 rounded-b-none md:rounded-2xl;
  &-title {
    &-header {
      @apply w-full text-center text-h4 mb-4 font-medium md:px-5 px-2;
    }
    &-img {
      @apply w-24 mx-auto mb-5;
    }
  }
  &-content {
    &-para {
      @apply text-p-md text-center mx-auto md:leading-6 leading-5 text-blacks-300 mb-3;
    }
  }
  &-button {
    &-done {
      @apply w-full text-white p-2 mt-3;
    }
  }
}
</style>
