const UTM_KEY = 'utm_params'
const tracking = {
  getUtmParams: () => {
    return JSON.parse(localStorage.getItem(UTM_KEY))
  },
  setUtmParams: (value) => {
    localStorage.setItem(UTM_KEY, JSON.stringify(value))
  },
  clearUtmPrams: () => {
    localStorage.removeItem(UTM_KEY)
  }
}

export default tracking
