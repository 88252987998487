<script setup>
// Required Components
import { computed } from 'vue'
// Store
import store from '@/store'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'
import EbTable from '@/components/Base/Table/EventBuilder'
import Avatar from '@/components/Base/Avatar'
import { Icons } from 'icons'
import { processStatuses, isFirebaseStorageURL, eventFeatureCodes } from '@/utils'
import { config } from '@/config'

const headers = [
  {
    text: 'Project Title', value: "title", sortable: true
  },
  {
    text: 'Assign Awards', value: "awards", sortable: true, class: "min-w-[14rem]"
  }
]

const items = computed(() => {
  return store.getters.projectAwards
})

const awardFeatureEnabled = computed(() => {
  const feature = store.getters.getEventFeature(eventFeatureCodes.ENABLE_AWARDS)
  return feature?.value === 1
})

const awardsReadOnly = computed(() => {
  // Awards read only when
  // 1. saving
  // 2. loading
  // 3. Awards feature is disabled
  // 4. Awards are published
  // 5. Event awards is not loaded or empty
  return store.getters.projectAwardsStatus === processStatuses.SAVING ||
    store.getters.projectAwardsStatus === processStatuses.LOADING ||
    !awardFeatureEnabled.value || store.getters.getAwardsPublished ||
    store.getters.awardsStatus !== processStatuses.LOADED || !store.getters.awards || store.getters.awards.length === 0

})

const toggleAssignAwardModal = (projectId) => {
  store.dispatch('ToggleAssignAwardsModal', {
    data: {
      projectId: projectId
    },
    confirmAction: (projectId, awardIds) => {
      // Need an update award action
      store.dispatch('replaceAwardsToProject', { projectId: projectId, awardIds: awardIds })
    }
  })
}

const removeAwardFromProject = (projectId, awardId) => {
  store.dispatch('removeAwardFromProject', { projectId, awardId })
}

const sort = (sortBy = 'title') => {
  store.dispatch('setProjectAwardSortBy', sortBy)
  store.dispatch('toggleProjectAwardsDesc')
}

const generateProjectLink = (id) => {
  const encodedLink = encodeURIComponent(`/${store.getters.ebEventSlug}/project/${id}`)
  // To avoid non-logged user to be redirected to 404
  // Redirect to login page by default, event site will handle the redirection for us.
  return `${config.EVENT_URL}/${store.getters.ebEventSlug}/login?returnUrl=${encodedLink}`
}
</script>

<template>
  <div class="overflow-x-auto">
    <eb-table input-place-holder="New Award"
              :read-only="awardsReadOnly"
              :items="items"
              :invert-color="false"
              :headers="headers"
              :sort-function="sort" >
      <template #content-name="{ item }">
        <a :href="generateProjectLink(item.id)"
            class="text-purple-600 underline line-clamp-1 md:w-[90%]">{{item.title}}</a>
      </template>
      <template #content-column="{ item }">
        <base-button v-if="!item.awards || item.awards.length === 0"
                     id="assignAwardsButton"
                     :functions="() => toggleAssignAwardModal(item.id)"
                     text="Assign Awards"
                     class-name="table-button table-button-assign"
                     btn-type="tertiary"
                     :disabled="awardsReadOnly" />
        <template v-else>
          <div class="w-full flex flex-col">
            <div v-for="award in item.awards"
                 :key="`${item.id}_${award.id}`"
                 class="flex items-center space-x-2">
              <avatar v-if="award.image !== ''"
                      :is-large="false"
                      class-name="avatar-medium">
                <template #profile>
                  <img v-if="isFirebaseStorageURL(award.image)"
                       :src="award.image"
                       :alt="award.name"
                       class="h-10 rounded-full" />
                  <icons v-else-if="((typeof award.image) === 'string' && award.image?.length > 0)"
                         :name="award.image"
                         :enable-color="true"
                         :invert-color="false"
                         type="cycle"
                         size="xl"/>
                </template>
              </avatar>
              <span class="max-w-[18rem] line-clamp-1">{{award.name}}</span>
            </div>
            <base-button v-if="!awardsReadOnly"
                         id="assignAwardsButton"
                         :functions="() => toggleAssignAwardModal(item.id)"
                         text="Assign Awards"
                         class-name="table-button table-button-assign"
                         btn-type="tertiary"
                         :disabled="awardsReadOnly" />
          </div>
        </template>
      </template>
      <template #action-column="{ item }">
        <div :class="{'w-full flex flex-col' : item.awards && item.awards.length > 0}">
          <div v-if="item.awards && item.awards.length > 0"
               class="w-full flex flex-col">
            <base-button v-for="award in item.awards"
                         :key="`${item.id}_${award.id}`"
                         :functions="() => removeAwardFromProject(item.id, award.id)"
                         text="delete icon"
                         :disabled="awardsReadOnly"
                         class-name="table-button table-button-delete"
                         btn-type="text">
              <template #contents>
                <i class="fa-regular fa-trash-can fa-lg"></i>
              </template>
            </base-button>
          </div>
          <div v-if="!awardsReadOnly"
               class="min-h-[3rem]"/>
        </div>
      </template>
    </eb-table>
  </div>
</template>

<style lang="scss" scoped>
.table{
  // awards Add
  &-add {
    @apply pt-10;
  }
  &-button {
    @apply w-auto min-w-[2.5rem] text-blacks-500 max-w-[8.5rem] px-5 py-3;
    &-awards {
      @apply px-4 py-2 text-p-md text-white;
    }
    &-assign {
      @apply my-3.5;
    }
    &-delete {
      @apply h-[2.42rem];
    }
  }
}
</style>
