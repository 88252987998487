export const eventCompletionValues = {
  'event_config_completion_general' : { value: 1, optional: false},
  'event_config_completion_branding' : { value: 2, optional: false},
  'event_config_completion_resources': { value: 4, optional: true},
  'event_config_completion_template': { value: 8, optional: false},
  'event_config_completion_timeline' : { value: 16, optional: false},
  'event_config_completion_categories' : { value: 32, optional: true},
  'event_config_completion_sponsors' : { value: 64, optional: true},
}

export const IsSectionCompleted = (value, page) => {
  return value && ((value & page) > 0)
}

export const allSectionsCompleted = (completeValue) => {
  return Object.values(eventCompletionValues).filter(m => !m.optional).reduce((acc, value) => {
    return acc && ((completeValue & value.value) > 0)
  }, true);
}

