<script setup>
import { computed } from 'vue'
import store from '@/store'
import { processStatuses } from '@/utils'

import OrganizationModal from './OrganizationModal'
import BrandingModal from './BrandingModal';

const eventModalType = computed(() => {
  return store.getters.eventModalType
})
const organizatioStatus = computed(() => {
  return store.getters.getOrganizationStatus
})
</script>

<template>
  <organization-modal v-if="eventModalType === 'organization' && organizatioStatus && organizatioStatus === processStatuses.LOADED"/>
  <branding-modal v-if="eventModalType === 'branding'" />
</template>

<style lang="scss" scoped>
.modal {
  @apply border rounded-xl flex flex-col bg-gray-100 w-screen md:h-auto md:w-[60rem];
  &-hero {
    @apply w-full p-4;
    &_icon {
      @apply absolute right-2 p-1 cursor-pointer;
    }
    &_text {
      @apply text-center font-bold mt-16 text-4xl;
    }
  }
}
.header {
  @apply w-full text-center;
}

.nextBtn {
  @apply bg-purple text-white uppercase rounded-md text-sm;
}
.skipBtn {
  @apply text-purple uppercase rounded-md text-sm mr-4 border-2 border-gray-300;
}
</style>
