<script setup>
import { computed, onBeforeMount, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import store from '@/store'
import { ProjectTemplate } from 'project'
import EventPageBase from './EventPageBase'
import BaseButton from '@/components/Base/Button/BaseButton'
import PostService from "@/services/post"
import CommentService from "@/services/comment"
import FileService from '@/services/file'
import ProjectService from '@/services/project'
import AttachmentService from '@/services/attachment'
import CategoryService from '@/services/category'
import { attachmentTypes } from 'project/src/utils/attachmentTypes'
import { redirectToRoute, processStatuses, bitwiseOperators, eventStatuses, eventFeatureCodes } from "@/utils"
import dayjs from 'dayjs'
import { displayModes } from 'project/src/utils/displayMode'

const router = useRouter()
const route = useRoute()

const eventStatus = computed(() => {
  return store.getters.ebEventStatus
})

// check if event contain category module feature
const isEnableCategory = computed(() => {
  const feature = store.getters.getEventFeature(eventFeatureCodes.ENABLE_CATEGORIES)
  return feature?.value === 1
})

const eventCategory = computed(() => {
  return store.getters.categories
})

const categoriesStatus = computed(() => {
  return store.getters.categoriesStatus
})

const hasEventCategory = computed(() => {
  return eventCategory?.value?.length === 0
})

const isProjectAccessible = computed(() => {
  return eventStatus.value === processStatuses.LOADED && isProjectAndPostsLoaded.value && categoriesStatus.value === processStatuses.LOADED
})

const projectOptions = computed (() => {
  return {
    project: {
      canEdit: true,
      canEditInstruction: true,
      canEditDescription: false,
      canEditVideo: false,
      canInvite: false,
      canComment: false,
      showComments: false,
      showInstructionsOnView: true
    },
    post: {
      canEditTitle: !isEventPublished.value,
      canEditCover: !isEventPublished.value,
      canEditInstruction: !isEventPublished.value,
      canEditDescription: false,
      canReOrder: !isEventPublished.value,
      canComment: false,
      showComments: false,
      defaultView: isEventPublished.value ? displayModes.VIEW : displayModes.EDIT,
      showInstructionsOnView: true,
      showAttachmentsOnView: true,
      canCreate: true,
      canDelete: true
    },
    attachment: {
      canEdit: false
    },
    category: {
      showCategory:  true, // Category Section
      showCategoryList: hasEventCategory.value, // Category List
      showLockIcon: !isEnableCategory.value, // Lock Category
      canEditCategory: false
    }
  }
})

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const isEventPublished = computed(() => {
  return event.value.eventSettings?.status === eventStatuses.LIVE
})

const theme = computed(() => {
  return store.getters.getEventTheme
})

const backToTemplate = async () => {
  return router.push({ name: 'ProjectTemplates', params: { slug: route.params.slug }})
}

const saveAsDraft = async () => {
  const eventTemplate = await store.getters.getEventTemplate
  if (eventTemplate && eventTemplate.status !== "Draft") {
    store.dispatch('updateEventTemplate', { eventTemplateId: eventTemplate.id, status: "Draft" }).then((res) => {
      store.dispatch('eventBuilderUpdateTemplateStatus', res)
      if (res.status === "Draft") {
        store.dispatch('updateEventConfigs', { eventId: store.getters.ebEventId, configKey: "event_config_completion", configValue: "8", isConfigValueBitMask: true, bitwiseOperation: bitwiseOperators.REMOVE }).then(() => {
          store.dispatch('getEventConfigs', { eventId: store.getters.ebEventId})
        }).catch((err) => {
          console.error(err)
        })
      }
    })
  }
  return router.push({ name: 'ProjectTemplates', params: { slug: route.params.slug }})
}

// Params: ( data, oldData )
const saveProject = async (data) => {
  const payload = {
    imageUrl: data.imageUrl,
    title: data.title,
    description: data.description,
  }
  try {
    const response = await ProjectService.updateProject(data.id, payload)
    if (response && response.data && response.data.project) {
      return response.data.project
    } else {
      throw new Error('Invalid response')
    }
  } catch (error) {
    console.error('Error updating project:', error.message)
    throw new Error('Internal server error. Please try again later.')
  }
}

const projectSubmissionDeadline = computed(() => {
  return projectSubmissionDeadlineStatus.value === processStatuses.LOADED ? store.getters.projectSubmissionDeadline[0]?.endAt : null
})

const projectSubmissionDeadlineStatus = computed(() => {
  return store.getters.projectSubmissionDeadlineStatus
})

// Params (project, oldProject)
const saveProjectInstructions = async (project) => {
  const data = {
    instructionSection: project.instructionSection,
    instructionVideo: project.instructionVideo
  }
  return new Promise((resolve, reject) => {
    ProjectService.updateProjectInstructions(project.id, data).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const previewProject = () => {
  console.debug('previewing project')
}

const projectEditTrigger = () => {
  store.dispatch('projectValidateReset')
}

const projectMainToggle = () => {
  store.dispatch('setProjectMainToggle')
}
const uploadToFirestore = async (file) => {
  // To do, specify upload path to betterorganize assets in firestore
  const response = await FileService.upload({ file: file })
  return response.data.link
}

// Params: (project, oldProject)
const saveProjectVideo = async (project) => {
  const data = {
    videoUrl: project.videoUrl,
    instructionVideo: project.instructionVideo
  }
  return new Promise((resolve, reject) => {
    ProjectService.updateProjectVideoAndInstruction(project.id, data).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const sideMenuDesktop = computed(() => {
  return store.getters.sideMenu
})

const projectMainEdit = computed(() => {
  return store.getters.projectMainEdit
})

const projectCardError = computed(() => {
  return store.getters.projectsErrors
})

/*
const posts = computed(() => {
  return store.getters.posts
})*/

const project = computed(() => {
  return store.getters.activeProject
})

const onEventTemplatePublish = async () => {
  const eventTemplate = store.getters.getEventTemplate
  if (eventTemplate) {
    await store.dispatch("updateEventTemplate", { eventTemplateId: eventTemplate.id, status: "Published" }).then((res) => {
      if (res.status === "Published") {
        store.dispatch("eventBuilderUpdateTemplateStatus", res)
        store.dispatch('updateEventConfigs', { eventId: store.getters.ebEventId, configKey: "event_config_completion", configValue: "8", isConfigValueBitMask: true, bitwiseOperation: bitwiseOperators.COMBINE }).then(() => {
          store.dispatch('getEventConfigs', { eventId: store.getters.ebEventId})
        }).catch((err) => {
          console.error(err)
        })
      }
    })
    return router.push({
      name: "ProjectTemplates",
      params: {
        slug: route.params.slug
      }
    })
  } else {
    console.error('event template not found')
  }
}

const eventTemplateUpdateStatus = computed(() => {
  return store.getters.eventTemplateUpdatingStatus
})

// Project Post List and Title/Image Edit view
const fetchPosts = (projectId) => {
  return new Promise((resolve, reject) => {
    PostService.getPosts({projectId : projectId,limit: 10, include:"", nextPageToken: 0}).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const onPostsLoad = (data) => {
  console.debug("onPostsLoad", data)
}
const onPostsEdit = (data) => {
  console.debug("onPostsEdit", data)
}
const onPostsSave = async (posts) => {
  // UPDATE HERE
  const updatedPosts = []
  for (const post of posts) {
    const response = await PostService.updatePostPriority(post.id, post)
    updatedPosts.push(response.data.post)
  }
  return updatedPosts
}

const onPostSave = async (post) => {
  return new Promise((resolve, reject) => {
    PostService.updatePost(post.id, post, "instructions,body,post_features").then(response => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const onPostCreate = async (post) => {
  return new Promise((resolve, reject) => {
    PostService.createPost(project.value.id, post).then(response => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const onPostDelete = async (postId) => {
  return new Promise((resolve, reject) => {
    PostService.deletePost(postId).then(response => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const onPostsCancel = (data) => {
  console.debug("onPostsCancel", data)
}
const onPostUpdate = (data, oldData, fileOrImage) => {
  console.debug("onPostUpdate", data, oldData, fileOrImage)
}

// Post Modal
const fetchPost = async (postId) => {
  return new Promise((resolve, reject) => {
    PostService.getPost({postId: postId}).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const isProjectAndPostsLoaded = computed(() => {
  return store.getters.isPostsLoaded && store.getters.projectsLoaded
})

watch(() => store.getters.ebEventStatus, () => {
  if (store.getters.ebEventStatus === processStatuses.LOADED) {
    store.dispatch('fetchEventCategories')
  }
})

onMounted(async () => {
  await store.dispatch('setProjectID', route.params.id)
  await store.dispatch('getProject', route.params.id)
  await store.dispatch('getPosts', route.params.id)
  await store.dispatch('getComments')
  await store.dispatch('fetchProjectSubmissionDeadline')
})

onBeforeMount(async() => {
  const slug = route.params.slug
  await store.dispatch('getEvent', slug)
})

// Attachments
const fetchAttachments = async (postId) => {
  console.debug('fetchAttachments', postId)
  return await AttachmentService.getAttachments({postId: postId})
}

const onAttachmentUpdate = async (item, oldItem) => {
  console.debug('onAttachmentUpdate', item, oldItem)
  // for now it update only client side
  /*
  const data = {
    "priority": item.priority,
    "url": item.url,
    "description": item.description,
    "post_id": item.postId
  }

  return await AttachmentService.updateAttachment(item.id, data)
  */
}

const onAttachmentsSave = async (newAttachments, updatedAttachments, deletedAttachmentIds, oldAttachments) => {
  const results = oldAttachments

  // Create attachments
  for (const attachment of Object.values(newAttachments)) {
    const data = {
      "priority": attachment.priority,
      "url": attachment.url,
      "description": attachment.description,
      "post_id": attachment.postId,
      "type": attachmentTypes.AttachmentTypeToInteger[attachment.type]
    }
    const response = await AttachmentService.createAttachment(data)
    results[response.data.attachment.id] = response.data.attachment
  }

  // Update attachments
  for (const attachment of Object.values(updatedAttachments)) {
    const data = {
      "priority": attachment.priority,
      "url": attachment.url,
      "description": attachment.description,
      "post_id": attachment.postId,
      "type": attachmentTypes.AttachmentTypeToInteger[attachment.type]
    }
    const response = await AttachmentService.updateAttachment(attachment.id, data)
    results[response.data.attachment.id] = response.data.attachment
  }

  // Delete attachments
  for (const attachmentId of deletedAttachmentIds) {
    await AttachmentService.deleteAttachment(attachmentId)
    delete results[attachmentId]
  }

  return results
}

const onAttachmentCreate = (attachment) => {
  console.debug('onAttachmentCreate', attachment)
}

// Comment functions
const user = computed(() => {
  return store.getters.user
})

const comments = computed(() => {
  return store.getters.comments
})

const fetchCommentsOnPost = async(postId) => {
  return await CommentService.getCommentsByPostId(postId, "users")
}

const fetchCommentsOnProject = async(projectId) => {
  return await CommentService.getCommentsByProjectId(projectId, "users")
}

const fetchReplies = async(parentId) => {
  return await CommentService.getReplyComments(parentId, "users")
}

const fetchComment = async(commentId) => {
  return await CommentService.getComment(commentId)
}

const onCommentSave = async(projectId, payload) => {
  return await CommentService.createComment(projectId, payload)
}

const onCommentEdit = async(commentId, payload) => {
  return await CommentService.updateComment(commentId, payload)
}

const onCommentDelete = async(commentId) => {
  return await CommentService.deleteComment(commentId)
}

const onCommentLike = async(commentId) => {
  return await CommentService.likeComment(commentId)
}

const onCommentUnlike = async(commentId) => {
  return await CommentService.unlikeComment(commentId)
}

const onEventCategoryFetch = async () => {
  return new Promise((resolve, reject) => {
    CategoryService.getCategories(event.value.id).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}
</script>

<template>
  <event-page-base :is-visible="false"
                   :is-published="isEventPublished"
                   :is-centred="true">
    <template #content>
      <div v-if="isProjectAndPostsLoaded"
           class="hidden w-full flex-col items-start md:inline-flex">
        <div class="w-full md:max-w-[65rem] mt-6 -mb-4">
          <base-button id="exitEditor"
                       name="exitEditor"
                       btn-type="text"
                       class="btn-back"
                       :functions="backToTemplate"
                       text="">
            <template #contents>
              <i class="fa-regular fa-chevron-left" />
              Exit Template Editor
            </template>
          </base-button>
        </div>
      </div>
      <!--
       isProjectAndPosted is required here because project and posts loaded separately
            and this component won't re-render on second load
      -->
      <div v-if="isProjectAndPostsLoaded">
        <div class="nav-container">
          <nav class="nav"
               role="navigation">

            <span v-if="projectSubmissionDeadlineStatus === processStatuses.LOADING"
                  class="nav-left">Loading submission deadline..</span>
            <span v-if="projectSubmissionDeadlineStatus === processStatuses.LOADED && projectSubmissionDeadline"
                  class="text-sm mt-2 nav-left">
              <span class="font-semibold mr-1">Submission Deadline | </span>
             {{dayjs(new Date(projectSubmissionDeadline)).format("MMMM DD YYYY, h:mmA")}}
           </span>
            <span class="nav-left">
              <div v-if="projectSubmissionDeadlineStatus === processStatuses.LOADED && !projectSubmissionDeadline">
           Click <span class="text-product-400 cursor-pointer" @click="router.push({ name:'Timeline' })">here</span> to set the submission deadline
              </div>
            </span>

            <span class="nav-right" >
              <!-- Hide for Beta -->
                    <base-button v-if="false"
                                 id="saveDraftBtn"
                                 name="saveDraftBtn"
                                 class-name="btn-preview"
                                 text="preview template"
                                 :functions="previewProject">
                      <template #contents>
                        <i class="fa-light fa-eye fa-xs" />
                          Exit Template Editor
                      </template>
                    </base-button>

                    <span class="w-full flex flex-row md:justify-end justify-between md:relative md:w-auto">
                      <base-button id="saveDraftBtn"
                                   name="saveDraftBtn"
                                   class-name="btn-draft"
                                   btn-type="secondary"
                                   text="save as draft"
                                   :disabled="isEventPublished"
                                   :functions="saveAsDraft" />
                      <base-button id="selectBtn"
                                   name="selectBtn"
                                   class-name="btn-select"
                                   text="FINISH"
                                   :disabled="isEventPublished"
                                   :is-loading="eventTemplateUpdateStatus === processStatuses.SAVING"
                                   :functions="onEventTemplatePublish" />
                    </span>
                </span>
          </nav>
        </div>
        <project-template v-if="isProjectAccessible"
                          :client-event-theme="theme"
                          :choose-btn="backToTemplate"
                          :redirect-to-route="redirectToRoute"
                          :on-project-detail-save="saveProject"
                          :on-project-image-upload="uploadToFirestore"
                          :on-project-instructions-save="saveProjectInstructions"
                          :on-project-video-upload="uploadToFirestore"
                          :on-project-video-save="saveProjectVideo"
                          :side-menu-desktop="sideMenuDesktop"
                          :project="project"
                          :options="projectOptions"
                          :max-char-main-text-area="700"
                          :event="event"
                          :project-main-edit="projectMainEdit"
                          :project-edit-trigger="projectEditTrigger"
                          :project-main-toggle="projectMainToggle"
                          :project-card-error="projectCardError"
                          :fetch-posts="fetchPosts"
                          :fetch-post="fetchPost"
                          :on-posts-load="onPostsLoad"
                          :on-posts-edit="onPostsEdit"
                          :on-posts-save="onPostsSave"
                          :on-posts-cancel="onPostsCancel"
                          :on-post-update="onPostUpdate"
                          :on-post-image-upload="uploadToFirestore"
                          :on-post-save="onPostSave"
                          :on-post-create="onPostCreate"
                          :on-post-delete="onPostDelete"
                          :comments-data="comments"
                          :user="user"
                          :fetch-comments-on-post="fetchCommentsOnPost"
                          :fetch-comments-on-project="fetchCommentsOnProject"
                          :fetch-replies="fetchReplies"
                          :fetch-comment="fetchComment"
                          :on-comment-save="onCommentSave"
                          :on-comment-edit="onCommentEdit"
                          :on-comment-delete="onCommentDelete"
                          :on-comment-like="onCommentLike"
                          :on-comment-unlike="onCommentUnlike"
                          :fetch-attachments="fetchAttachments"
                          :on-attachment-update="onAttachmentUpdate"
                          :on-attachments-save="onAttachmentsSave"
                          :on-attachment-create="onAttachmentCreate"
                          :on-attachment-upload="uploadToFirestore"
                          :on-event-category-fetch="onEventCategoryFetch"
                          :event-categories="eventCategory"
                          :is-event-published="isEventPublished"/>
      </div>
    </template>
    <template #event-save-button>
      <!-- Dummy span to clear the default save button -->
      <!-- Is there a better way to override the default slot? -->
      <span></span>
    </template>
    <template #event-publish-button>
      <!-- Dummy span to clear the default save button -->
      <!-- Is there a better way to override the default slot? -->
      <span></span>
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.nav {
  @apply py-3 -ml-4 flex flex-row justify-between bg-white z-[1] fixed bottom-0 shadow w-full;
  @apply md:relative md:ml-0 md:py-8 md:bg-transparent md:shadow-none md:max-w-[55rem];
  &-right {
    @apply flex md:justify-end w-full sm:justify-between ml-4 px-2 md:px-0 md:ml-0 md:w-auto;
  }
  &-left {
    @apply md:inline-flex hidden;
  }
  &-container {
    @apply w-full flex flex-col;
  }
}

.btn {
  &-back {
    @apply -ml-6 text-p-md font-normal border rounded border-product-400/[.0] text-product-400 capitalize hidden md:inline-flex items-center hover:border-product-400/[1];
    i {
      @apply mr-2;
    }
  }
  &-draft {
    @apply rounded w-[8rem] text-sm capitalize py-2 hover:bg-product-400 border;
    &:enabled {
      @apply hover:text-white;
    }
    &:disabled {
      @apply border-product-400 text-product-400 hover:bg-transparent opacity-40;
    }
  }
  &-choose {
    @apply text-purple-700 text-sm border-transparent capitalize px-2 md:mr-2;
    &:hover {
      @apply border-purple-500 rounded;
    }
  }
  &-select {
    @apply text-sm bg-product-400 px-3.5 rounded md:ml-2 w-[9rem] py-2 border border-product-400;
    &:enabled {
      @apply hover:bg-transparent hover:text-product-400;
    }
    &:disabled {
      @apply border-product-100
    }
  }
}
</style>
