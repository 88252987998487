import EventService from "@/services/event"
import PlanService from "@/services/plan"
import { processStatuses } from "@/utils"
import SubscriptionService from '@/services/subscription'
import { eventFeatureCodes } from '@/utils/eventFeatures'
import { config } from '@/config'
import { cloneObject } from '@/utils/clone'

const defaultBanner = [
  {
    name: 'default'
  },
  {
    name: 'empty'
  },
  {
    name: 'upload'
  }
]

export default {
  state: () => ({
    ebEventId: null,
    ebEventSlug: null,
    status: processStatuses.IDLE,
    eventSettingsStatus: processStatuses.IDLE,
    event: {},
    eventSettings: {},
    originalEventSettings: {},
    eventSettingError: null,
    generalSettingValidationError: {},
    error: null,
    eventPublishStatus: processStatuses.IDLE,
    eventPublishError: null,
    preview: {
      status: processStatuses.IDLE,
      token: "",
      error: null,
      previewUrl: null,
      url: null
    },
    banners: [],
    eventConfigs: [],
    eventSubscriptionDetails: null,
    eventSubscriptionDetailsStatus: processStatuses.IDLE,
    eventSubscriptionDetailsError: null,
    isEventDataChanged: false,
    features: []
  }),
  getters: {
    banners: state => state.banners,
    ebEventId: state => state.ebEventId,
    ebEventSlug: state => state.ebEventSlug,
    event : state => state.event,
    eventBuilderEvent: (state) => state.event ?? {},
    eventSettings: state => state.eventSettings,
    originalEventSettings: state => state.originalEventSettings,
    hasEventTemplate: state => state.event && state.event.eventTemplates && state.event.eventTemplates.length > 0,
    getEventTemplateId: (state, getters) => getters.hasEventTemplate ? state.event.eventTemplates[0].projectId : null,
    getEventTemplate: (state, getters) => getters.hasEventTemplate ? state.event.eventTemplates[0] : null,
    isEBEventTemplateLoaded: state => state.status === processStatuses.LOADED,
    ebEventStatus: state => state.status,
    eventSettingsStatus: state => state.eventSettingsStatus,
    eventSettingError: state => state.eventSettingError,
    generalSettingValidationError: state => state.generalSettingValidationError,
    ebEventError: state => state.error,
    getEventTheme: state => {
      if (state.event) {
        return {
          'primary': state.event.eventSettings?.primaryColor,
          'secondary': state.event.eventSettings?.secondaryColor
        }
      }
    },
    getEventPublishStatus: state => state.eventPublishStatus,
    getEventPreviewToken: state => state.preview.token,
    getEventPreviewStatus: state => state.preview.status,
    getEventPreviewUrl: state => {
      return config.EVENT_URL + '/' + state.ebEventSlug + '/access?q=' + state.eventSettings.eventCode + '&preview_token=' + state.preview.token
    },
    getEventUrl: state => state.preview.url,
    getBanners: state => state.banners,
    getBannerData: state => [...defaultBanner, ...state.banners],
    getEventConfigs: state => state.eventConfigs,
    getConfigCompletion: state => {
      if (state.eventConfigs !== null && state.eventConfigs.length > 0) {
        const configValue = state.eventConfigs.find(config => config.configKey === 'event_config_completion')?.configValue
        return configValue ? parseInt(configValue, 10) : 0
      }
      return 0
    },
    getEventSubscriptionDetails: state => state.eventSubscriptionDetails,
    getEventSubscriptionDetailsStatus: state => state.eventSubscriptionDetailsStatus,
    getEventSubscriptionDetailsError: state => state.eventSubscriptionDetailsError,
    isEventDataChanged: state => state.isEventDataChanged,
    getEventFeatures: state => state.features,
    getEventFeature: state => (featureName) => {
      if (!eventFeatureCodes.EventFeatureCodes.includes(featureName)) {
        return null
      }
      if (state.status === processStatuses.LOADED) {
        const features = state.features.filter(feature => feature.featureGroup?.code === featureName)
            .sort((a, b) => b.priority - a.priority)

        return features.length > 0 ? features[0] : null
      }
      return null
    },
    isEventSettingsUpdated: state => {
      const fieldsToCheck = ['primaryColor', 'secondaryColor', 'image']
      return fieldsToCheck.some(field => state.eventSettings[field] !== state.originalEventSettings[field])
    }
  },
  mutations: {
    SET_EVENT_BUILDER (state, eventId) {
      state.ebEventId = eventId
    },
    RESET_EVENT_BUILDER (state) {
      state.ebEventId = null
    },
    EVENT_BUILDER_LOADING (state) {
      state.status = processStatuses.LOADING
    },
    EVENT_BUILDER_LOADED (state, event) {
      state.status = processStatuses.LOADED
      state.event = event
      state.eventSettings = event.eventSettings
      state.originalEventSettings = cloneObject(event.eventSettings)
      state.ebEventId = event.id
      state.ebEventSlug = event.slug
      state.banners = event.banners
      state.features = event.features
      state.eventSettingsStatus = processStatuses.LOADED
    },
    EVENT_BUILDER_ERROR (state, error) {
      state.status = processStatuses.ERROR
      state.error = error
    },
    GENERAL_SETTING_SAVED (state, event) {
      // General Settings only updates event name, slug, and eventSettings
      state.eventSettingsStatus = processStatuses.LOADED
      state.event = {
        ...state.event,
        slug: event.slug,
        name: event.name,
        eventSettings: event.eventSettings
      }
      state.eventSettings = event.eventSettings
      state.originalEventSettings = cloneObject(event.eventSettings)
      state.ebEventSlug = event.slug
    },
    GENERAL_SETTING_SAVE_ERROR (state, error) {
      state.eventSettingsStatus = processStatuses.ERROR
      state.generalSettingValidationError[error.key] = error.errMsg
    },
    EVENT_PUBLISHING (state) {
      state.eventPublishStatus = processStatuses.SAVING
    },
    EVENT_PUBLISHED (state) {
      state.eventPublishStatus = processStatuses.LOADED
      state.event.eventSettings.status = 'Published'
    },
    EVENT_PUBLISH_ERROR (state, error) {
      state.eventPublishStatus = processStatuses.ERROR
      state.eventSettingError = error
    },
    EVENT_SETTING_LOADING (state) {
      state.eventSettingsStatus = processStatuses.LOADING
    },
    EVENT_SETTING_SAVING (state) {
      state.eventSettingsStatus = processStatuses.SAVING
    },
    EVENT_SETTINGS_UPDATED (state, eventSetting) {
      state.eventSettingsStatus = processStatuses.LOADED
      state.eventSettings = eventSetting
      state.originalEventSettings = cloneObject(eventSetting)
      state.event.eventSettings = eventSetting
    },
    EVENT_SETTING_ERROR (state, error) {
      state.eventSettingsStatus = processStatuses.ERROR
      state.eventSettingError = error
    },
    RESET_EVENT_SETTING (state) {
      state.generalSettingValidationError = {}
      state.eventSettingsStatus = processStatuses.IDLE
    },
    EVENT_PREVIEW_LOADING (state) {
      state.preview.status = processStatuses.LOADING
    },
    EVENT_PREVIEW_TOKEN (state, token) {
      state.preview.token = token
      state.preview.status = processStatuses.LOADED
      state.preview.previewUrl = config.EVENT_URL + '/' + state.ebEventSlug + '/access?q=' + state.eventSettings.eventCode + '&preview_token=' + token
      state.preview.url = config.EVENT_URL + '/' + state.ebEventSlug + '/access?q=' + state.eventSettings.eventCode
    },
    EVENT_PREVIEW_ERROR (state, err) {
      state.preview.status = processStatuses.ERROR
      state.preview.error = err
    },
    RESET_PREVIEW (state) {
      state.preview = {
        status: processStatuses.IDLE,
        token: "",
        error: null,
        previewUrl: null,
        url: null
      }
    },
    EVENT_CONFIGS_RESET (state) {
      state.eventConfigs = {}
    },
    EVENT_CONFIGURED (state, config) {
      state.eventConfigs = {}
      state.eventConfigs = config
    },
    EVENT_SUBSCRIPTION_DETAIL_LOADING (state) {
      state.eventSubscriptionDetailsStatus = processStatuses.LOADING
    },
    EVENT_SUBSCRIPTION_DETAIL_LOADED (state, eventSubscriptionDetails) {
      state.eventSubscriptionDetailsStatus = processStatuses.LOADED
      state.eventSubscriptionDetails = eventSubscriptionDetails
    },
    EVENT_SUBSCRIPTION_DETAIL_ERROR (state, error) {
      state.eventSubscriptionDetailsStatus = processStatuses.ERROR
      state.eventSubscriptionDetailsError = error
    },
    SET_EVENT_DATA_CHANGED (state, isChanged) {
      state.isEventDataChanged = isChanged
    },
    RESET_EVENT_BUILDER_EVENT (state, event) {
      state.event = event
    },
    RESET_EVENT_SETTINGS (state, eventSettings) {
      state.eventSettings = eventSettings
      state.originalEventSettings = cloneObject(eventSettings)
    },
    EVENT_SETTINGS_AWARD_PUBLISHED(state) {
      state.event.eventSettings.awardsPublished = true
    },
    EVENT_BUILDER_TEMPLATE_UPDATING (state, eventTemplate) {
      state.event.eventTemplates[0]  = eventTemplate
    }
  },
  actions: {
    setEventBuilder({ commit }, eventId) {
      commit('SET_EVENT_BUILDER', eventId)
    },
    resetEventBuilder({ commit }) {
      commit('RESET_EVENT_BUILDER')
    },
    saveGeneralSettings({ commit }, payload) {
      commit('RESET_EVENT_SETTING')
      let eventName, eventDescription, eventSlug = false
      commit('EVENT_SETTING_LOADING')
      return new Promise((resolve, reject) => {
        if (!payload['name']) {
          const payload = { 'key': 'eventName', 'errMsg': 'Please enter a event name' }
          commit('GENERAL_SETTING_SAVE_ERROR', payload)
          reject()
        } else {
          eventName = true
        }
        if (!payload['description']) {
          const payload = { 'key': 'eventDescription', 'errMsg': 'Please enter a event description' }
          commit('GENERAL_SETTING_SAVE_ERROR', payload)
          reject()
        } else {
          eventDescription = true
        }
        if (!payload['slug']) {
          const payload = { 'key': 'eventUrl', 'errMsg': 'Please enter a event slug' }
          commit('GENERAL_SETTING_SAVE_ERROR', payload)
          reject()
        } else if(payload['slug'] && payload['slug'] !== '' && payload['slug'].indexOf(' ') >= 0) {
          const payload = { 'key': 'eventUrl', 'errMsg': 'Event URL should not contain space.' }
          commit('GENERAL_SETTING_SAVE_ERROR', payload)
          reject()
        } else {
          eventSlug = true
        }
        if(eventName && eventDescription && eventSlug) {
            EventService.saveGeneralSettings(payload).then((response) => {
              if (response.status === 200) {
                commit('GENERAL_SETTING_SAVED', response.data.event)
                resolve()
              } else {
                const payload = { 'key': 'serverError', 'errMsg': response.data }
                commit('GENERAL_SETTING_SAVE_ERROR', payload)
                reject()
              }
            }).catch((err) => {
              const payload = { 'key': 'serverError', 'errMsg': err.data.message }
              commit('GENERAL_SETTING_SAVE_ERROR', payload)
              reject(err)
            })
        }
      })
    },
    getEvent ({ commit }, idOrSlug) {
      commit('RESET_EVENT_SETTING')
      commit('EVENT_BUILDER_LOADING')
      commit('EVENT_TEMPLATE_UPDATING', null, { root: true })
      return new Promise((resolve, reject) => {
        EventService.getEvent({idOrSlug: idOrSlug, include:"setting,info,event_templates,banners,features"}).then((response) => {
          commit('EVENT_BUILDER_LOADED', response.data.event)
          if (response.data.event.eventTemplates && response.data.event.eventTemplates.length > 0) {
            commit('EVENT_TEMPLATE_UPDATED', response.data.event.eventTemplates[0], { root: true })
          } else {
            commit('EVENT_TEMPLATE_UPDATED', {}, { root: true })
          }
          resolve(response.data.event)
        }).catch((err) => {
          commit('EVENT_BUILDER_ERROR', err.data.message)
          commit('EVENT_TEMPLATE_UPDATE_ERROR', err.data.message, { root: true })
          reject(err)
        })
      })
    },
    publishEvent({ commit }, eventId) {
      commit('EVENT_PUBLISHING')
      return new Promise((resolve, reject) => {
        EventService.publishEvent(eventId).then((response) => {
          if (response.status === 200) {
            commit('EVENT_PUBLISHED', response.data)
            resolve()
          } else {
            commit('EVENT_PUBLISH_ERROR', response.data)
            reject()
          }
        }).catch((err) => {
          commit('EVENT_PUBLISH_ERROR', err.data.message)
          reject(err)
        })
      })
    },
    setBrandingColorAndLogo({ commit }, { id, primaryColor, secondaryColor, image }) {
      commit('EVENT_SETTING_SAVING')
      return new Promise((resolve, reject) => {
        EventService.updateEventSetting({ id, primaryColor, secondaryColor, image }).then((response) => {
          commit('EVENT_SETTINGS_UPDATED', response.data)
          resolve()
        }).catch((err) => {
          commit('EVENT_SETTING_ERROR', err.data.message)
          reject(err)
        })
      })
    },
    loadingEventSetting ({ commit }) {
      commit('EVENT_SETTING_LOADING')
    },
    previewEvent({ commit, rootGetters }) {
      if (rootGetters.ebEventStatus !== processStatuses.LOADED) {
        console.error('event not loaded')
        return
      }

      commit('EVENT_PREVIEW_LOADING')
      return new Promise((resolve, reject) => {
        EventService.previewEvent(rootGetters.ebEventId)
          .then((response) => {
            commit('EVENT_PREVIEW_TOKEN', response.data.token)
            resolve()
          })
          .catch((err) => {
            commit('EVENT_PREVIEW_ERROR', err)
            reject(err)
          })
      })
    },
    getEventConfigs ({ commit, rootGetters }) {
      commit('EVENT_CONFIGS_RESET')
      return new Promise((resolve, reject) => {
        EventService.eventConfigs(rootGetters.ebEventId)
            .then((response) => {
              commit('EVENT_CONFIGURED', response.data.eventConfigs)
              resolve(response.data)
        }).catch((err) => {
          commit('EVENT_BUILDER_ERROR', err.data.message)
          commit('EVENT_TEMPLATE_UPDATE_ERROR', err.data.message, { root: true })
          reject(err)
        })
      })
    },
    createEventConfigs ({ commit }, { eventId, configKey, configValue }) {
      commit('EVENT_CONFIGS_RESET')
      return new Promise((resolve, reject) => {
        EventService.createEventConfigs({eventId, configKey, configValue}).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    updateEventConfigs ({ commit }, { eventId, configKey, configValue, isConfigValueBitMask, bitwiseOperation }) {
      commit('EVENT_CONFIGS_RESET')
      return new Promise((resolve, reject) => {
        EventService.updateEventConfigs({eventId, configKey, configValue, isConfigValueBitMask, bitwiseOperation}).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    eventBuilderUpdateTemplateStatus ({ commit }, eventTemplate) {
      commit('EVENT_BUILDER_TEMPLATE_UPDATING', eventTemplate)
    },
    SetEventConfig({ commit }, eventData) {
      commit('EVENT_CONFIGURED', eventData)
    },
    fetchEventSubscriptionDetails ({ commit, getters }) {
      if (getters.ebEventStatus === processStatuses.LOADED) {
        commit('EVENT_SUBSCRIPTION_DETAIL_LOADING')
        return new Promise((resolve, reject) => {
          PlanService.getPlanSubscriptionDetails(getters.ebEventId).then((response) => {
            commit('EVENT_SUBSCRIPTION_DETAIL_LOADED', response.data)
            resolve(response.data)
          }).catch((err) => {
            commit('EVENT_SUBSCRIPTION_DETAIL_ERROR', err.data.message)
            reject(err)
          })
        })
      }
    },
    updatePlanSubscriptionDetails ({ commit }, { planId, subscriptionPlanId, organizationSubscriptionId }) {
      commit('EVENT_SUBSCRIPTION_DETAIL_LOADING')
      return new Promise((resolve, reject) => {
        SubscriptionService.updatePlanSubscriptionDetails(planId, subscriptionPlanId, organizationSubscriptionId).then((response) => {
          commit('EVENT_SUBSCRIPTION_DETAIL_LOADED', response.data)
          resolve(response.data)
        }).catch((err) => {
          commit('EVENT_SUBSCRIPTION_DETAIL_ERROR', err.data.message)
          reject(err)
        })
      })
    },
    setEventDataChanged ({ commit }, isChanged) {
      commit('SET_EVENT_DATA_CHANGED', isChanged)
    },
    ResetEventBuilderEvent({ commit }, event) {
      commit('RESET_EVENT_BUILDER_EVENT', event)
    },
    ResetEventSettings({ commit }, eventSettings) {
      commit('RESET_EVENT_SETTINGS', eventSettings)
    },
    ResetPreview({ commit }) {
      commit('RESET_PREVIEW')
    }
  }
}
