export default {
  state: () => ({
    iframe: '',
    isHidden: false,
  }),
  getters: {
    getIframe: state => state.iframe,
    getIsHidden: state => state.isHidden
  },
  mutations: {
    SET_IFRAME_DATA (state, {data, isHidden}) {
      state.iframe = data,
      state.isHidden = isHidden
    },
    REMOVE_IFRAME(state) {
      state.iframe = ''
      state.isHidden = false
    },
  },
  actions: {
    triggerIframe({ commit }, { data, isHidden }) {
      console.log('triggerIframe')
      commit('SET_IFRAME_DATA', { data, isHidden })
    },
    removeIframe({ commit }) {
      commit('REMOVE_IFRAME')
    }
  }
}
