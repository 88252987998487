<script setup>
import {ref, computed} from 'vue'
import { useRouter, useRoute } from 'vue-router'

import store from '@/store'

import BaseInput from '@/components/Base/Fields/InputField'
import BaseButton from '@/components/Base/Button/BaseButton'
import ModalLayout from '@/components/Modals/ModalAuthBase'


const props = defineProps({
  verificationCode: {
    type: String,
    required: true
  },
})
const verificationCode = ref(props.verificationCode)
const router = useRouter()
const route = useRoute()

const redirect = route.query?.returnUrl ? route.query.returnUrl : '/events'

//To DO Verification process
const nextStep = () => {
  const payload = {
    verificationCode: verificationCode.value,
    usernameOrEmail: store.getters.verificationCodeEmailOrUserName
  }
  store.dispatch('verifyAccount', payload).then(() => {
    store.dispatch('setAfterRegister', true)
    store.dispatch('ChangeModalType', 'event')
    // store.dispatch('ResetModal')
    router.push(redirect)
  })
}

const checkVerifyErrorMessage = computed(() => {
  return store.getters.checkVerify
})

const sendVerificationCodeEmail = () => {
  store.dispatch('sendVerificationCode', { usernameOrEmail: store.getters.verificationCodeEmailOrUserName })
}
</script>

<template>
  <modal-layout>
    <template #modal-title>
      Verification
    </template>
    <template #modal-body>
      <h6 class="modal-verify-text">
        We just emailed a verification code to <br /><strong>{{ store.getters.verificationCodeEmailOrUserName }}</strong><br />
        Please check your inbox and junk mail for the code.
      </h6>
      <base-input v-model="verificationCode"
                  :model-value="verificationCode"
                  input-name="verificationCode"
                  input-id="verificationCode"
                  input-type="text"
                  class-name="text-black text-center"
                  input-place-holder="Enter your verification code"
                  input-aria-label="Verification Code"
                  :errors="checkVerifyErrorMessage"/>
    </template>
    <template #modal-button>
      <span class="w-full text-center py-4">
      <base-button id="next"
                   name="next"
                   text="continue"
                   class="btn btn-primary"
                   :functions="nextStep" />
    </span>
    </template>
    <template #modal-footer>
    <span class="modal-verify-tryAgain">
      Didn't get it yet? <span class="modal-verify-tryAgain-text" @click="sendVerificationCodeEmail">Try again</span>
    </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.modal-verify {
  @apply h-[25rem] px-10 py-6;
  &-text {
    @apply text-center text-p-md pb-8 -mt-4;
  }
  &-tryAgain {
    @apply text-center text-p-xs mb-12;
    &-text {
      @apply text-product-400 cursor-pointer;
    }
  }
}
</style>
