import axios from 'axios'
import { config } from '@/config'

class CommentService {
  getCommentsByPostId(postId, include) {
    return axios.get(`${config.API_URL}/post/${postId}/comments`, {
      params: {
        postId : postId,
        includes: include
      }
    })
  }
  getCommentsByProjectId(projectId, include) {
    return axios.get(`${config.API_URL}/projects/${projectId}/comments`, {
      params: {
        projectId : projectId,
        includes: include
      }
    })
  }
  getReplyComments(parentId, include) {
    return axios.get(`${config.API_URL}/comments/${parentId}`, {
      params: {
        parentId : parentId,
        includes: include
      }
    })
  }
  getComment(commentId) {
    return axios.get(`${config.API_URL}/comment/${commentId}`, {
      params: {
        commentId: commentId
      }
    })
  }
  updateComment(commentId, comment, updateMask = "body") {
    return axios.patch(`${config.API_URL}/comments/${commentId}`, comment, {
      params: {
        "updateMask": updateMask
      }
    })
  }
  createComment(projectId, comment) {
    return axios.post(`${config.API_URL}/comments/${projectId}`, comment, {})
  }
  deleteComment(commentId) {
    return axios.delete(`${config.API_URL}/comments/${commentId}`, {})
  }
  likeComment(commentId) {
    return axios.post(`${config.API_URL}/comments/${commentId}/like`, {})
  }
  unlikeComment(commentId) {
    return axios.delete(`${config.API_URL}/comments/${commentId}/like`, {})
  }
}

export default new CommentService()
