<script setup>
import { onMounted, ref } from 'vue'
import BaseButton from '@/components/Base/Button/BaseButton'

const active = ref(false)

const props = defineProps({
  dropzoneFile: {
    type: Object
  },
  errors : {
    type: null,
    default: ''
  },
  drop: {
    type: Function,
    default: () => {}
  },
  selectedFile: {
    type: Function,
    default: () => {}
  },
  resetFile: {
    type: Function,
    default: () => {}
  }
})

const toggleActive = () => {
  active.value = !active.value
}

const convertUrlToImage = (file) => {
  const reader = new FileReader()
  const preview = document.querySelector(".dropzone-image")

  reader.addEventListener("load", () => {
    if (reader.result && preview) {
      preview.src = reader.result
    }

  }, false)
  if (!props.errors && file) {
    reader.readAsDataURL(file)
  }
}

onMounted(() => {
  if (props.dropzoneFile) {
    convertUrlToImage(props.dropzoneFile)
  }
})
</script>

<template>
  <div v-show="dropzoneFile"
       class="dropzone-image-container">
    <base-button id="resetPreviewImage"
                 :functions="resetFile"
                 class-name="dropzone-reset"
                 name="resetPreviewImage"
                 btn-type="secondary"
                 text="reset">
      <template #contents>
        <i class="fa-regular fa-lg fa-xmark " />
      </template>
    </base-button>
    <img
        class="dropzone-image"
        :src="convertUrlToImage(dropzoneFile)"
        alt="" />
  </div>
  <div v-show="!dropzoneFile"
       :class="{
         'dropzone-active' : active,
         'dropzone-inActive' : !active
       }"
       class="dropzone"
       @change="selectedFile"
       @dragenter.prevent="toggleActive"
       @dragleave.prevent="toggleActive"
       @dragover.prevent
       @drop.prevent="drop">
    <img src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Fupload%20files.png?alt=media&token=5f604366-5786-4c7a-825b-4b0670eeed19"
         alt="uploadIcon" />
    <span class="font-medium text-h4">Drag or Drop, or
      <label for="dropzoneFile" class="text-product-500 cursor-pointer">
        Upload
      </label>
      <input id="dropzoneFile"
             class="dropzoneFile dropzone-input"
             type="file">
    </span>
    <span class="text-p-sm">Works with any JPG, PNG file</span>
    <span class="text-p-sm mt-2">Recommended 300 x 300px</span>
  </div>
  <p class="dropzone-errors">
    {{errors}}
  </p>
</template>

<style lang="scss" scoped>
.dropzone {
  @apply h-[14rem] w-full flex flex-col justify-center items-center gap-2;
  &-input {
    @apply hidden;
  }
  &-active {
    @apply border-product-500;
  }
  &-inActive {
    @apply border-greys-200;
  }
  &-image {
    @apply max-w-full h-full rounded-md border-2 m-auto;
    &-container {
      @apply w-full h-full relative;
    }
  }
  &-reset {
    @apply h-8 w-8 rounded-full fixed right-10 bg-white -mt-4;
    &:hover {
      @apply bg-greys-50;
    }
  }
  &-errors {
    @apply text-center text-other-red font-medium;
  }
}
</style>
