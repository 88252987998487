<script setup>
import { useRouter } from 'vue-router'

import ErrorLayout from '@/pages/NotFound/ErrorLayout'
import BaseButton from '@/components/Base/Button/BaseButton'


const router = useRouter()
// Redirect Home
const goLogin = () => {
  router.push('/login')
}
</script>

<template>
  <error-layout :is-user="true">
    <template #error-logo>
        <img src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/selfserve%2Fimages%2FerrorUser.png?alt=media&token=4ebf6815-3f9d-46b1-af6d-f04eed14796d"
             alt="pageError"
             class="error-page-image"/>
    </template>
    <template #error-header>
      <span class="">
        Oops! It looks like you don't have access to your account
      </span>
    </template>
    <template #error-content>
      Please contact <a class="text-product-400" href="mailto:connect@projectboard.world">connect@projectboard.world</a> to connect with our team.
    </template>
    <template #error-button>
      <base-button id="errorPageButton"
                   name="errorPageButton"
                   aria-label="error page button"
                   class-name="error-page-button"
                   :functions="goLogin"
                   text="Try Logging in again"/>
    </template>

  </error-layout>
</template>

<style lang="scss" scoped>
.error-page{
  &-image {
    @apply pb-9;
  }
  &-button {
    @apply capitalize text-h5 font-medium py-2 px-7;
  }
}
</style>
