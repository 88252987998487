<script setup>
const props = defineProps({
  id: String,
  name: String,
  ariaLabel: String,
  text: {
    type: String,
    required: true
  }
})

// Loads the tag classes
const getTagClass = (type = 'draft') => {
  switch(type.toLowerCase()) {
  // Project Status
  case 'approved':
  case 'live':
    return 'approved'
  case 'submitted':
    return 'submitted'
  case 'declined':
    return 'declined'
  // Card Status
  case 'paid':
    return 'approved'
  case 'pending':
    return 'submitted'
  case 'active':
    return 'approved'
  default:
    return 'draft'
  }
}

const convertText = (text) => {
  if (text === undefined || text === null) {
    return 'Draft'
  }
  switch(text.toLowerCase()) {
  // Project Status
  case 'approved':
    return 'Approved'
  case 'submitted':
    return 'Submitted'
  case 'declined':
    return 'Declined'
  case 'live':
    return 'Live'
  // Card Status
  case 'paid':
    return 'accepted'
  case 'pending':
    return 'pending'
  // User Status
  case 'active':
    return 'Active'
  default:
    return 'Draft'
  }
}
</script>

<template>
  <span :class="`tag ${getTagClass(props.text)} font-medium`">
    <div :class="`tag-dot ${getTagClass(props.text)}-dot`"/>
    <span class="text-greys-500">
      <slot name="tag-text">
        {{ convertText(props?.text) }}
      </slot>
    </span>
  </span>
</template>

<style lang="scss" scoped>
.tag {
  @apply flex flex-row items-center max-w-[12rem] px-3 py-1 rounded-lg tracking-wider uppercase text-blacks-500 justify-center text-p-xs font-medium;
  &-dot {
    @apply w-2 h-2 rounded-full mr-2 shrink-0;
  }
}

.approved {
  @apply bg-other-green bg-opacity-20;
  &-dot {
    @apply bg-other-green;
  }
}

.draft {
  @apply bg-other-yellow bg-opacity-20;
  &-dot {
    @apply bg-other-yellow;
  }
}

.declined {
  @apply bg-other-red bg-opacity-20;
  &-dot {
    @apply bg-other-red;
  }
}

.submitted {
  @apply bg-other-blue bg-opacity-20;
  &-dot {
    @apply bg-other-blue;
  }
}
</style>
