<script setup>
// Packages
import { onMounted, reactive, watch, computed } from 'vue'
// Custom packages
import store from '@/store'
// Base Components
import { pbButton } from 'pb-components'
// Custom components
import DashboardPageBase from './DashboardPageBase'
import EventCreation from '@/components/Events/EventCreation'
import EventCardList from '@/components/Events/EventCardList'
import TagLabel from '@/components/Base/Tags/Labels'
// Utils
import { processStatuses } from "@/utils"
import { config } from '@/config'

const organizationProperties = reactive({
  isEventsStatusLoaded: false,
  events: [],
  hasSubscriptions: false,
  topSubscriptionName: ''
})

const setOrganizationProperties = () => {
  organizationProperties.isEventsStatusLoaded = store.getters.eventsStatus === processStatuses.LOADED
  organizationProperties.events = store.getters.events || []

  organizationProperties.topSubscriptionName = store.getters.topSubscriptionName
  organizationProperties.hasSubscriptions = store.getters.hasRemainingEvents
}

const remainingPaidEvents = computed(() => {
  return store.getters.getAllRemainingPaidEvents
})

const startEvent = () => {
  store.dispatch('ChangeModalType', 'eventCreate')
  store.dispatch('ToggleModal')
}

const createEvent = (type='free') => {
  store.dispatch('createEventType', type)
  store.dispatch('ToggleModalEvent')
  store.dispatch('ResetEventModal')
  store.dispatch('GetEvents')
}

const isCreditShow = computed(() => {
  return organizationProperties.hasSubscriptions && organizationProperties.isEventsStatusLoaded
})

// Wait until User fetched, pull subscriptions
watch(() => store.getters.userStatus, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue === processStatuses.LOADED) {
    store.dispatch('fetchOrganization', {slug: store.getters.user?.userName, userId: store.getters.user?.id})
  }
})
watch(() => store.getters.getOrganizationStatus, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue === processStatuses.LOADED) {
    store.dispatch('GetEvents')
  }
})
watch(() => store.getters.eventsStatus, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue === processStatuses.LOADED) {
    store.dispatch('fetchSubscriptions')
  }
})
watch(() => store.getters.subscriptionPlansStatus, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue === processStatuses.LOADED) {
    store.dispatch('setPlanNameToEventsMap').then(() => {
      store.dispatch('setRemainingEvents').then(() => {
        store.dispatch('setTopSubscriptionName').then(() => {
          setOrganizationProperties()
        })
      })
    })
  }
})

onMounted(() => {
  store.dispatch('GetEvents')
  store.dispatch('resetRemainingEvents')
})
</script>

<template>
  <dashboard-page-base header-class="events">
    <template #title>
      <span class="events-title">
        Project Showcases
      </span>
    </template>
    <template #rightContainer>
      <span class="header-button-container"
            :class="{
              'header-button-container-free' : !organizationProperties.hasSubscriptions,
              'header-button-container-premium' : organizationProperties.hasSubscriptions
            }">
        <a id="explorePlanBtn"
           target="_parent"
           aria-label="explore plan button"
           class="header-button header-button-plans"
           :href="`${config.PRICING_URL}`">
          Explore Plans
        </a>
        <pb-button id="createFreeBtn"
                   name="createFreeBtn"
                   aria-label="create free button"
                   class-name="header-button"
                   text="+ free showcase"
                   btn-type="secondary"
                   :functions="() => createEvent('free')"/>
      </span>
    </template>
    <template #subContent>
      <div v-if="isCreditShow"
           class="events-card">
        <div class="events-card-info">
          <div class="events-card-info-container">
            <h1 class="events-card-info-title">
              Showcase Credits
            </h1>
            <p class="events-card-info-subTitle">
              Click on Start Showcase to Begin!
            </p>
          </div>
          <pb-button id="startEventBtn"
                     name="startEventBtn"
                     aria-label="start event button"
                     class-name="events-card-button"
                     text="Start Showcase"
                     :disabled="!store.getters.hasRemainingEvents"
                     :functions="startEvent"/>

        </div>
        <div class="events-card-info-content">
          <div class="events-card-info-content-container">
            <p class="mt-2 text-p-md">Remaining:</p>
            <template v-for="[plan, remainingNumber] of Object.entries(remainingPaidEvents)"
                      :key="plan" >
              <tag-label class="ml-4 mt-2"
                         :label-type="remainingNumber === 0 ? 'error' : 'success'"
                         :text="`${remainingNumber} ${plan}`"
                         :has-border="true"/>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <event-creation v-if="organizationProperties.isEventsStatusLoaded && organizationProperties.events.length === 0"
                      :subscription-name=organizationProperties.topSubscriptionName />
      <div class="events-content">
        <event-card-list />
      </div>
    </template>
  </dashboard-page-base>
</template>

<style lang="scss" scoped>
.events {
  &-content {
    @apply md:pb-10 pb-28 md:ml-2;
  }
  &-title {
    @apply flex flex-row w-screen md:w-auto justify-between pr-10;
  }
  &-card {
    @apply border py-4 my-4 max-w-[36rem] px-4 rounded-xl;
    &-info {
      @apply flex md:flex-row flex-col w-full md:justify-between border-b items-center md:pb-2 pb-4;
      &-title {
        @apply text-h2 font-bold;
      }
      &-subTitle {
        @apply text-p-md font-normal py-2;
      }
      &-content {
        @apply w-full flex md:flex-row md:justify-between justify-center flex-wrap items-center mt-2;
        &-container {
          @apply flex md:flex-row flex-wrap items-center md:justify-center;
        }
      }
    }
    &-button {
      @apply capitalize px-8 py-2 text-p-md font-medium rounded-md max-h-10 mt-3 md:my-0;
    }
  }
}
.header {
  &-button {
    @apply capitalize py-2 px-8 text-p-md font-medium rounded-md;
    &-container {
      @apply fixed bottom-0 z-[1] bg-white w-full items-center left-0 py-4 border-t flex flex-row md:relative md:border-none md:bottom-auto md:py-0 md:left-auto md:w-auto md:justify-end;
      &-premium {
        @apply justify-center;
      }
      &-free {
        @apply justify-end;
      }
    }
    &-paid {
      @apply border bg-product-400 text-white ml-4;
    }
    &-plans {
      @apply text-product-400;
    }
  }
}
</style>
