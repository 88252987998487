<template>
  <div class="card">
    <span v-if="comingSoonList.includes(tierList)"
          class="card-comingSoon">
      <i class="fa-solid fa-star"></i>
      Coming soon!
    </span>
    <span class="card-tier"
          :class="{
            'card-tier_active': tierList === tier,
            'card-tier_inActive': tierList !== tier
          }">
      {{tierList}}
    </span>
    <div class="card-features">
      <div v-for="item in data"
           :key="item"
           class="card-features-containers">
        <span v-if="item.tier === tierList">
          <span v-for="feature in item.features" :key="feature.title">
            <div class="card-features-containers_title">
              {{feature.title}}
            </div>
            <ul class="pb-6">
              <li v-for="info in feature.list"
                  :key="info"
                  class="card-features-containers_item">
                <i class="fa-solid fa-check text-purple mr-2"/>
                {{info}}
              </li>
            </ul>
            <div v-if="tierList === 'custom'" class="-mt-5">
              {{feature.desc}}
              <a :href="feature.link" target="_blank" class="text-purple capitalize font-semibold">
                Click here
              </a>
            </div>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
const comingSoonList = ['premium']
defineProps({
  tier: {
    type: String,
    required: true
  },
  tierList: {
    type: String,
    required: true
  },
  data: {
    type: Object,
    required: true
  }
})
</script>

<style lang="scss" scoped>
.card {
  @apply flex flex-col items-center mx-2 mb-4 max-w-[25rem] relative z-0;
  &-comingSoon {
    @apply md:-mt-8 uppercase text-purple text-xs py-2;
  }
  &-tier {
    @apply text-white uppercase px-16 py-1.5 rounded-full z-10 text-xl max-w-[10rem] flex justify-center;
    &_active {
      @apply bg-purple;
    }
    &_inActive {
      @apply bg-black;
    }
  }
  &-features {
    @apply bg-white border py-12 -mt-5 rounded-3xl drop-shadow-xl px-10 md:min-w-[25rem];
    &-containers {
      @apply flex flex-col md:pl-8;
      &_title {
        @apply text-black uppercase font-bold text-lg pb-4 pt-2;
      }
      &_item {
        @apply text-lg capitalize py-0.5;
      }
    }
  }
}
</style>
