<script setup>
defineProps({
  title: {
    type: String,
    required: true
  },
  content: {
    type: String,
    required: true
  },
  bullets: {
    type: Array
  },
  notes: {
    type: Array
  },
  projects: {
    type: Array
  }
})

const parseContent = (content) => {
  if (content) {
    return content.replace('{{supportEmailLink}}', 'projectboardplus@projectboard.zohodesk.com').replace('{{supportEmailLabel}}', 'support@projectboard.world')
  }
  return content
}
</script>

<template>
  <span class="support-item">
    <slot name="support-content">
      <h3 class="support-item-title">
        {{ title }}
      </h3>
      <p class="support-item-content"
         :class="{'pb-2' : content}"
         v-html="parseContent(content)">
      </p>
      <ul class="support-item-container">
        <!--  Bullets -->
        <li v-for="(bullet, index) in bullets"
            :key="index"
            class="support-item-content support-item-list"
            v-html="parseContent(bullet)"/>
        <!--  Notes -->
        <span v-if="notes"
              class="support-item-note">
          Notes:
        </span>
        <li v-for="(note, index) in notes"
            :key="index"
            class="support-item-content support-item-list"
            v-html="parseContent(note)"/>
        <!--  Projects -->
        <span v-for="(project, index) in projects"
            :key="index"
            class="support-item-content ">
          <div class="support-item-note pb-4">
            {{ project.title}}
          </div>
          <li v-for="(bullet, index) in project.bullets"
              :key="index"
              class="support-item-content support-item-list"
              v-html="parseContent(bullet)"/>
        </span>
      </ul>
      <hr class="support-item-separator"/>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
.support-item {
  @apply flex flex-col pb-4;
  &-title {
    @apply text-h5 font-medium pb-4;
  }
  &-content {
    @apply text-p-md text-blacks-300;
  }
  &-container {
    @apply flex flex-col;
  }
  &-list {
    @apply pb-2 list-disc ml-4;
  }
  &-note {
    @apply text-h6 font-medium pb-2 text-black;
  }
  &-separator {
    @apply mt-4;
  }
}
</style>
