<script setup>
// Required Packages
import { onUnmounted, onMounted, computed, ref } from 'vue'
import ModalLayout from '@/components/Modals/ModalBase'
import BaseButton from '@/components/Base/Button/BaseButton'
import VSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
import store from '@/store'
import { eventFeatureCodes, findUserSearchBy, roleToEnum, userRoles } from '@/utils'
import { pbAvatar } from 'pb-components'

const searchParticipantsResults = computed(() => {
  return store.getters.getSearchParticipantsResults
})

const selectedParticipantsToInvite = computed({
  get() {
    return store.getters.getSelectedParticipantsToInvite
  },
  set() {
    // read only computed
  }
})

const invitedParticipants = computed(() => {
  return store.getters.getInvitedParticipants
})

const users = computed(() => {
  return store.getters.combinedParticipants
})

const searchParticipants = (search, loading) => {
  // When user input is empty, don't search
  if (!search || search.length < 1) {
    return
  }
  loading(true)
  store.dispatch('searchParticipants', {
    pageSize: 100,
    nextPageToken: 0,
    filterBy: findUserSearchBy.KEYWORD,
    keyword: search }).then(() => {
    }).finally(() => {
      loading(false)
    })
}

const isTeamSelectable = (option) => {
  const eventFeatureData = store.getters.getEventFeature(eventFeatureCodes.NUMBER_OF_PARTICIPANTS)
  const maxParticipantsSeats = eventFeatureData?.value || 0
  const totalInvites = (selectedParticipantsToInvite?.value?.length || 0) + (invitedParticipants?.value?.length || 0)
  // is user type in a non-existing user, allow it only if it's a valid email
  if (option.isNew === true) {
    // validate option.email is a valid email
    const emailRegex = /\S+@\S+\.\S+/
    return !users?.value?.some(u => u.email === option.email) &&
      emailRegex.test(option.email) && (totalInvites < maxParticipantsSeats - 1)
  } else {
    // Existing user allow only if it's not in the current team
    return !invitedParticipants?.value?.some(m => m.userId === option.id) &&
      !users?.value?.some(u => u.id === option.id) && (totalInvites < maxParticipantsSeats - 1)
  }
}

const createParticipantsFromSearchText = (search) => {
  return {
    userName: search, //For displaying purpose
    email: search,
    isNew: true
  }
}

const filterResult = (options, search) => {
  return options.filter(option => {
    return option.userName.toLowerCase().includes(search.toLowerCase()) ||
      option.firstName.toLowerCase().includes(search.toLowerCase()) ||
      option.lastName.toLowerCase().includes(search.toLowerCase()) ||
      option.email.toLowerCase().includes(search.toLowerCase())
  })
}

const participantsSelected = (selectedOption) => {
  store.dispatch('resetSelectedParticipantsToInvite')
  selectedOption.forEach((option) => {
    store.dispatch('selectParticipantsToInvite', {user: option, role: "ss_user" })
  })
}

const participantsDeselected = (deselectedOption) => {
  store.dispatch('deselectParticipantsToInvite', {user: deselectedOption})
}

const clearResultIfEmpty = (search) => {
  if (!search.data) {
    store.dispatch('resetSearchParticipantsResults')
  }
}

const resetOption = () => {
  store.dispatch('resetSearchParticipantsResults')
}
const eventId = computed(() => {
  return store.getters.ebEventId
})

const sendParticipantsInvitations = (teams) => {
  const payload = {
    userInvitations: teams.map((team) => ({
      emailOrUsername: team.isNew ? team.email : team.userName,
      inviteRole: roleToEnum[userRoles.SS_EVENT_PARTICIPANT]
    })),
    eventId: eventId.value,
  }
  store.dispatch('inviteParticipants', payload).then(() => {
    store.dispatch('resetSelectedParticipantsToInvite')
    fetchParticipants()
  })
}

const inviteParticipantsTeam = () => {
  const filteredSelectedTeams = selectedParticipantsToInvite.value.filter((team) => {
    // Check if the team's email is not present in invitedParticipants
    return !invitedParticipants.value.some((user) => user.email === team.email)
  })

  sendParticipantsInvitations(filteredSelectedTeams)
}

const deleteInviteParticipants = (id) => {
  store.dispatch('deleteInviteParticipants', { id: id }).then(() => {
    fetchParticipants()
  })
}

const toggleInviteParticipantsPreview = () => {
  store.dispatch('ToggleModalInviteParticipants')
  store.dispatch('ToggleModalInviteParticipantsPreview')
}

const primaryName = (user) => {
  const trimmedFirstName = user.firstName ? user.firstName.trim() : '';
  const trimmedLastName = user.lastName ? user.lastName.trim() : '';

  return trimmedFirstName && trimmedLastName
    ? trimmedFirstName + ' ' + trimmedLastName
    : user.userName
      ? user.userName
      : user.email
}

const secondaryName = (user) => {
  if (primaryName(user) === user.userName) {
    return user.email
  } else {
    return user.userName
  }
}

onUnmounted(() => {
  if (!store.getters.modal) {  // If modal is closed, reset the selected participants
    store.dispatch('resetSelectedParticipantsToInvite')
  }
  store.dispatch('resetSearchParticipantsResults')
})

const fetchParticipants = () => {
  let payload = {
    roles: ['ss_event_participant'],
    eventId: eventId.value,
    pageSize: 100,
    nextPageToken: 0,
    include: 'project,invitation'
  }
  store.dispatch('getParticipants', payload)
  let participantPayload = {
    eventId: eventId.value,
    pageSize: 100,
    nextPageToken: 0
  }
  store.dispatch('getInvitedParticipants', participantPayload)
}

onMounted(async () => {
  //fetch invited user list
  if (eventId.value) {
    await fetchParticipants()
  }
})

const tabItems = [
  {
    name: 'manual',
    label: 'Manually'
  },
  {
    name: 'integration',
    label: 'Integrations'
  }
]

const activeTab = ref('manual')

const tabChanged = (tab) => {
  activeTab.value = tab
}

const closeModal = () => {
  store.dispatch('CloseModal')
}

const toggleIntegrationModal = () => {
  store.dispatch('ToggleIntegrationConfirmationModal')
}
</script>

<template>
  <modal-layout modal-class="modal-invite">
    <template #modal-title>
      <h4 class="modal-invite-title">
        Add Participants
      </h4>
      <hr class="modal-invite-underline"/>
    </template>
    <template #modal-content>
      <section role="navigation"
           class="tabs">
        <span class="tabs-container">
          <ul class="tabs-container-menu">
            <li v-for="item in tabItems"
                :key="item.name"
                class="tabs-container-menu-item"
                :class="{
                  'tabs-container-menu-item_active' : activeTab === item.name
                }">
              <a class="cursor-pointer" @click="() => tabChanged(item.name)">
                {{ item.label }}
              </a>
            </li>
          </ul>
        </span>
        <div class="tabs-body-container">
          <div class="tabs-body-container-tab"
                :class="{ 'tabs-body-container-tab-active': activeTab === 'manual'}">
              <span class="modal-invite-content">
                <v-select
                    v-model="selectedParticipantsToInvite"
                    taggable
                    multiple
                    label="userName"
                    :filter="filterResult"
                    index="id"
                    placeholder="Email or username, comma seperated"
                    :options="searchParticipantsResults"
                    class="modal-invite-team-search"
                    :selectable="isTeamSelectable"
                    :create-option="createParticipantsFromSearchText"
                    @input="clearResultIfEmpty"
                    @option:selected="participantsSelected"
                    @option:deselected="participantsDeselected"
                    @close="resetOption"
                    @search="searchParticipants">
                    <!-- :dropdown-should-open="() => true"&ndash;&gt; -->
                  <template #option="{ avatar, userName, firstName, lastName }">
                    <span class="modal-invite-team-search-result-container">
                      <span class="modal-invite-team-search-result-container-avatar">
                        <pb-avatar :image="avatar"/>
                      </span>
                      <span class="modal-invite-team-search-result-container-label">{{ firstName }} {{ lastName }} ({{ userName}})</span>
                    </span>
                  </template>
                  <template #no-options>
                    Please enter email or username
                  </template>
                  <template #open-indicator>
                    <!-- Disable dropdown icon -->
                    <span></span>
                  </template>
                </v-select>
              </span>
              <div class="modal-invite-members">
                <h6 v-if="invitedParticipants.length > 0" class="modal-invite-invited">
                  Already Invited
                </h6>
                <ul class="modal-invite-invitees">
                  <div v-for="invitedData in invitedParticipants"
                            :key="invitedData.id">
                    <span class="modal-invite-invitee">
                      <span class="flex flex-col">
                        <span class="modal-invite-invitee-primary">
                          {{primaryName(invitedData.user)}}
                        </span>
                        <span class="modal-invite-invitee-secondary">
                          {{secondaryName(invitedData.user)}}
                        </span>
                      </span>
                      <span v-if="invitedData.acceptedAt === null" class="modal-invite-invitee-delete" @click="deleteInviteParticipants(invitedData.id)">
                        <i class="fa-regular fa-trash-can"></i>
                      </span>
                    </span>
                  </div>
                </ul>
              </div>
          </div>
          <div class="tabs-body-container-tab"
                :class="{ 'tabs-body-container-tab-active': activeTab === 'integration'}">
            <p class="text-sm py-4">Enhance your ProjectBoard experience by using integrations. These integration allows you to import event, participant data.</p>
            <div class="flex">
              <img src="/images/zfair-logo.png" class="mt-4 w-[8rem]"/>
            </div>
          </div>
        </div>
      </section>
      <hr v-if="activeTab === 'manual'" class="modal-invite-underline"/>
    </template>
    <template #modal-button>
      <span v-if="activeTab === 'manual'" class="modal-invite-btn-container">
        <base-button :functions="toggleInviteParticipantsPreview"
                     class-name="modal-invite-btn modal-invite-btn-back"
                     text=""
                     btn-type="text">
          <template #contents>
            <span class="capitalize font-light">
              <i class="fas fa-eye"></i>
              See Email Preview
            </span>
          </template>
        </base-button>
        <base-button :functions="inviteParticipantsTeam"
                      :disabled="selectedParticipantsToInvite.length === 0"
                      class-name="modal-invite-btn"
                      text="">
          <template #contents>
            <span class="capitalize font-light">
              Send Invite
            </span>
          </template>
        </base-button>
      </span>
      <span v-else-if="activeTab === 'integration'" class="modal-integration-btn-container">
        <base-button :functions="closeModal"
                      class-name="modal-invite-btn"
                      btn-type="text"
                      text="">
          <template #contents>
            <span class="capitalize font-light">
              Cancel
            </span>
          </template>
        </base-button>
        <base-button :functions="toggleIntegrationModal"
                      class-name="modal-invite-btn"
                      text="">
          <template #contents>
            <span class="capitalize font-light">
              Next
            </span>
          </template>
        </base-button>
      </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.modal {
  &-invite {
    @apply md:h-auto max-w-[29rem] md:p-4 p-4 rounded-b-none md:rounded-2xl bg-white;
    &-content {
      @apply text-blacks-300 text-p-md text-center;
    }
    &-members {
      @apply pr-10 md:min-h-[10rem] md:max-h-[30rem] overflow-y-auto;
    }
    &-title {
      @apply text-h4 font-medium text-blacks-500 pt-2 pb-2;
    }
    &-subTitle {
      @apply text-p-md text-blacks-300 text-center px-7;
    }
    &-invited {
      @apply text-p-xs capitalize text-blacks-500 pt-5 pb-2 font-semibold;
    }
    &-invitees {
      @apply flex flex-col pb-4;
    }
    &-invitee {
      @apply flex flex-row py-2 items-center justify-between;
    }
    &-underline {
      @apply text-greys-300 w-[100%] h-0.5 pb-2;
    }
    &-btn {
      @apply w-auto h-[2.5rem] mx-2 normal-case p-4;
      &-container {
        @apply flex flex-row justify-center mt-4 ml-4;
      }
    }
    &-invitee {
      @apply w-full md:w-[24rem] overflow-x-hidden;
      &-name {
        @apply w-1/3;
      }
      &-primary {
        @apply font-medium;
      }
      &-secondary {
        @apply font-light text-p-sm;
      }
      &-delete {
        @apply cursor-pointer w-1/3 text-end;
      }
    }
    &-team-search {
      @apply w-full mr-4;
      &-result-container {
        @apply flex flex-row items-center;
        &-label {
          @apply pl-2 h-[1rem];
        }
      }
      :deep(.vs__dropdown-toggle) {
        @apply bg-white font-light p-2 w-full text-center border rounded-md focus:outline-primary focus:outline focus:outline-2;
      }
      :deep(.vs__search) {
        @apply bg-white;
      }
      :deep(.vs__search::placeholder){
        @apply text-gray-400;
      }
      :deep(.vs__dropdown-menu) {
        @apply accent-other-red max-h-[10rem];
      }
    }
  }
  &-integration {
    &-btn {
      &-container {
        @apply flex flex-row justify-end;
      }
    }
  }
}
.tabs {
  @apply pb-8 overflow-hidden md:overflow-auto;
  &-container {
    @apply flex flex-col w-full;
    &-menu {
      @apply flex flex-row w-full overflow-x-auto pb-2 relative;
      &-item {
        @apply mr-6 text-h5 justify-center;
        &:hover:not(&_disabled) {
          @apply underline underline-offset-8 md:decoration-2 decoration-2 font-medium text-blacks-500;
        }
        &_active {
          @apply underline underline-offset-8 md:decoration-2 decoration-2 font-medium text-blacks-500 decoration-primary;
        }
        &_inActive {
          @apply text-blacks-200;
        }
        &_disabled a {
           @apply cursor-not-allowed;
        }
      }
    }
  }
  &-body {
    &-container {
      @apply p-4;
      &-tab {
        @apply hidden;
        &-active {
          @apply block;
        }
      }
    }
  }
}
.separator {
  @apply w-full h-0.5 px-4 bg-greys-50 -mt-1.5 md:-mt-1;
}
.decoration-other-primary{
  text-decoration-color: var(--primary);
}
</style>
