<script setup>
// Required Components
import { computed, onMounted, ref, watch } from "vue"
import Vue3EasyDataTable from "vue3-easy-data-table"
import day from "dayjs"
// Store
import store from "@/store"
// Layout
import DashboardPageBase from "@/pages/Dashboard/DashboardPageBase"
// Custom Components
import BaseTag from "@/components/Base/Tags"
// Required CSS
import "vue3-easy-data-table/dist/style.css"

const headers = [
  { text: "Date", value: "date", sortable: true },
  { text: "Item Purchased", value: "itemPurchased", sortable: true },
  { text: "Amount", value: "amount", sortable: true },
  { text: "Transaction Code", value: "transactionCode", width: 200 },
  { text: "Status", value: "status" },
  { text: "Payment For", value: "customerName" },
  { text: "Download", value: "receipt" },
]

const items = ref([])

const organization = computed(() => {
  return store.getters.newOrganization
})
const subscriptionPlan = (planId, event) => {
  switch (planId) {
  case "2":
    return "Starter " + event + " Plan"
  case "3":
    return "Essentials " + event + " Plan"
  case "6":
    return "Pro " + event + " Plan"
  default:
    return "Unknown " + event
  }
}

const getTransactions = (orgId) => {
  store
    .dispatch("getTransactions", { organizationId: orgId })
    .then((response) => {
      response.forEach((transaction) => {
        const transactionData = JSON.parse(
          JSON.parse(JSON.stringify(transaction.data))
        )
        const totalAmount = transactionData.amount_total / 100
        items.value.push({
          date: day(transaction.createdAt).format("MMM DD, YYYY h:mm:ss A"),
          itemPurchased: subscriptionPlan(transactionData.metadata.plan_id, ""), // needs to get plan based on id
          amount: totalAmount,
          transactionCode: transaction.transactionId, // set invoice code for a now but it is not ideal
          status: transactionData.payment_status,
          customerName: subscriptionPlan(
            transactionData.metadata.plan_id,
            "Event"
          ), // needs to get plan based on id
          receipt: transaction.receipt, // name should be receipt, change it later
        })
      })
    })
}
const handleDownload = (data) => {
  // TODO: downloadFile(data.invoice, 'pdf')
  const payload = {
    receiptUrl: data.receipt,
    previewType: 'pdf', // Or set the appropriate video type based on the link
  }
  store.dispatch('ToggleModalPaymentReceipt', payload)
}

watch(
  () => organization.value,
  (org) => {
    getTransactions(org.id)
  }
)
onMounted(() => {
  if (organization.value.id !== undefined) {
    getTransactions(organization.value.id)
  }
})
</script>

<template>
  <dashboard-page-base>
    <template #title> Payments</template>
    <template #subContent>
      <p class="transactions-header-subTitle">
        The below page can only be accessed by the admins.
      </p>
    </template>
    <template #content>
      <h2 class="transactions-table-title">Transactions History</h2>
      <vue3-easy-data-table
          :headers="headers"
          :items="items"
          table-class-name="customize-table"
      >
        <template #item-amount="item">
          {{`$` + item.amount + `.00`}}
        </template>
        <!-- Status -->
        <template #item-status="item">
          <base-tag
              v-if="item !== ''"
              :text="item.status"
              class="transactions-table-tags"
          />
        </template>
        <!-- Download -->
        <template #item-receipt="item">
          <div class="transactions-table-download">
            <a
                v-if="item !== ''"
                class="receipt-download"
                href="#"
                @click="handleDownload(item)"
            >
              <i
                  aria-label="download"
                  class="fa fa-download transactions-table-download-icon"
              ></i>
            </a>
            <span v-else> - </span>
          </div>
        </template>
      </vue3-easy-data-table>
    </template>
  </dashboard-page-base>
</template>

<style lang="scss" scoped>
.transactions {
// Header

  &-header {
    &-subTitle {
      @apply text-p-xs text-blacks-300 pt-2;
    }
  }

// Table styles

  &-table {
    &-title {
      @apply text-h2 font-medium pt-8 pb-6 pl-2;
    }

    &-tags {
      @apply max-w-[6.2rem] h-[1.8rem];
    }

    &-download {
      @apply md:max-w-[4rem] w-full flex justify-end items-center;

      &-icon {
        @apply text-p-md text-product-400 cursor-pointer;

        &:hover {
          @apply text-p-xl;
        }
      }
    }
  }
}

// Styling for the table
.customize-table {
  @apply md:max-w-[75rem] w-full;
  --easy-table-border: 1px solid transparent;
  --easy-table-body-row-background-color: transparent;
  --easy-table-header-background-color: transparent;
  --easy-table-footer-background-color: transparent;
  --easy-table-body-row-height: 4rem;
}

// Easy Table CSS V-deep overrides
:deep(.header-text) {
  @apply text-p-md font-normal text-blacks-300;
}

// Styling the TRs
:deep(.direction-left) {
  @apply text-p-md text-blacks-500;
}

// Adding the missing border
.customize-table :deep(table) {
  @apply border-b text-blacks-100;
}

// Remove the border from the footer
.customize-table :deep(.vue3-easy-data-table__footer) {
//@apply border-transparent my-[1rem];
  @apply hidden;
}
</style>
