<script setup>
// Required Component
import { onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// Store
import store from '@/store'
import { processStatuses, eventPlans } from '@/utils'

import { pbCircleLoader } from 'pb-components'

defineProps({
  headerClass: String,
  comingSoon: {
    type:Boolean,
    default: false
  }
})

const route = useRoute()

const router = useRouter()

// TBD
// const hasSubscriptionPlans = computed(() => {
//   return store.getters.subscriptionPayments?.length > 0
// })

const isDashboardReady = computed(() => {
  // Display dashboard when user is fetched
  return store.getters.userStatus === processStatuses.LOADED
})

onMounted(() => {
  store.dispatch('SetSidebarType', 'dashboard')
  // Check for stripe
  router.isReady().then(() => {
    if (route.query) {
      // Dispatch the payment
      if (route.query.pay === 'true' && eventPlans.EventPlans.includes(route.query.plan)) {
        store.dispatch('ChangeModalType', 'plan')
        store.dispatch('ShowModal')
      }
      // Successful payment
      if (route.query.success === 'true') {
        store.dispatch('triggerToast',{ message: 'Payment has been successful', type: 'success'})
      }
      // Failed or cancelled payment
      if (route.query.canceled === 'true') {
        store.dispatch('triggerToast',{ message: 'Payment has been cancelled', type: 'error'})
      }
    }
  })
})
</script>

<template>
  <div v-if="!isDashboardReady"
       class="pages-loader">
    <pb-circle-loader :medium="true"/>
  </div>
  <div v-else class="pages">
    <div :class="`${headerClass ? headerClass : ''}`">
      <div class="pages-container">
        <div>
          <h1 class="pages-title">
            <slot name="title" />
          </h1>
        </div>
        <slot v-if="!comingSoon" name="rightContainer" />
      </div>
      <slot name="subtitle" />
      <slot name="subContent" />
      <slot v-if="comingSoon"
            name="coming-soon">
        <div class="comingSoon"><i class="fa fa-star"/> Coming Soon</div>
      </slot>
    </div>
    <template v-if="!comingSoon">
      <slot v-if="isDashboardReady"
            name="content" />
      <slot v-else name="loading">Loading</slot>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.pages {
  @apply ml-2 mt-14;
  &-loader {
    @apply transition-all duration-300 w-full flex justify-center items-center -mt-[5rem];
  }
  &-title {
    @apply text-h1 font-bold capitalize;
  }
}
.comingSoon{
  @apply text-primary text-sm py-3;
}
</style>
