import OrganizationUsersService from "@/services/organization-user"
import { processStatuses, mapRoleIdToRoleName } from '@/utils'

export default {
  state: () => ({
    users: [],
    // admin team
    userSearchResults: [],
    userSearchResultsStatus: processStatuses.IDLE,
    userSelectedToInvite: [],
    userSelectedToInviteStatus: processStatuses.IDLE,
    // invited users
    invitedUserStatus: processStatuses.IDLE,
    invitedUsers: [],
    invitedUserError: null,
    // sort
    sort: 'firstName',
    isDesc: true
  }),
  getters: {
    // admin team
    getSearchUserResults: state => state.userSearchResults,
    getSearchUserResultsStatus: state => state.userSearchResultsStatus,
    getSelectedTeamsToInvite: state => state.userSelectedToInvite,
    //invited users
    getInvitedUsers: state => state.invitedUsers,
    getInvitedUserStatus: state => state.invitedUserStatus,
    combinedUsers: (state) => {
      const sortBy = state.sort || 'firstName'
      const isDesc = state.isDesc || false

      const acceptedUsers = state.users.map(user => (
          { ...user, status: 'Active', role: user.role, organizationUsers: user.organizationUsers }))
      const pendingInvitedUsers = state.invitedUsers
        .filter(invitedUser => invitedUser.acceptedAt === null)
        .map(invitedUser => ({ ...invitedUser.user, userInvitationsId: invitedUser.id, status: 'Pending', role: 'Admin' }))

      const combinedUsers = [...acceptedUsers, ...pendingInvitedUsers].sort((a, b) => {
        const fieldA = a[sortBy]?.toLowerCase() || ''
        const fieldB = b[sortBy]?.toLowerCase() || ''

        return isDesc ? fieldB.localeCompare(fieldA) : fieldA.localeCompare(fieldB)
      })

      return combinedUsers
    },
    getOrganizationUserRole: (state) => (userId) => {
        const user = state.users.find(user => user.id === userId)
        return user?.role
    },
    getOrganizationOwner: (state) => {
        return state.users.find(user => user.role === 'Owner')
    }
  },
  mutations: {
    ORGANIZATION_USERS_LOADING(state) {
      state.userStatus = processStatuses.LOADING
    },
    ORGANIZATION_USERS_LOADED(state, payload) {
      let userData = payload.map((orgUser) => {
        return {
            ...orgUser.user,
            organizationUsers: orgUser,
            orgUserId: orgUser.id,
            role: mapRoleIdToRoleName(orgUser.roleId)
        }
      })
      state.users = userData
      state.userStatus = processStatuses.LOADED
    },
    ORGANIZATION_USERS_TRANSFERRED(state, payload) {
      const updatedUsers = state.users.map(user => {
        if (user.id === payload.userId) {
          // If the userId matches, update organizationUser directly
          user.role = mapRoleIdToRoleName(payload.roleId)
          user.organizationUser = {
            ...user.organizationUser,
            ...payload,
          }
        }
        return user
      })
      // Update the state with the new array
      state.users = updatedUsers
      state.userStatus = processStatuses.LOADED
    },
    ORGANIZATION_USER_DELETED(state, payload) {
      state.users = state.users.filter((user) => {
        return user.organizationUsers.id !== payload.id
      })
    },
    RESET_SEARCH_RESULT(state) {
      state.userSearchResults = []
    },
    SEARCH_USER_RESULT(state, payload) {
      state.userSearchResultsStatus = processStatuses.LOADED
      state.userSearchResults = payload
    },
    SEARCHING_USER_RESULT(state) {
      state.userSearchResultsStatus = processStatuses.LOADING
    },
    // Invited users
    INVITED_USERS_LOADING(state) {
      state.invitedUserStatus = processStatuses.LOADING
    },
    INVITED_USERS_LOADED(state, payload) {
      state.invitedUsers = payload
      state.invitedUserStatus = processStatuses.LOADED
    },
    INVITED_USERS_ERROR(state, error) {
      state.invitedUserStatus = processStatuses.ERROR
      state.invitedUserError = error
    },
    // Admin List
    INVITE_USER_TEAM_LOADING(state) {
      state.invitedUserStatus = processStatuses.LOADING
    },
    INVITE_USER_TEAM_LOADED(state) {
      state.invitedUserStatus = processStatuses.LOADED
    },
    DELETE_INVITE_USER_LOADED(state, payload) {
      state.userStatus = processStatuses.LOADED
      state.invitedUsers = state.invitedUsers.filter((invitedUser) => {
        return invitedUser.id !== payload.id
      })
    },
    ORGANIZATION_USER_DELETED_ERROR(state) {
      state.userStatus = processStatuses.ERROR
    },
    SELECT_USER_TO_INVITE(state, { user, role }) {
      if (state.userSelectedToInvite) {
        state.userSelectedToInvite.push({
          ...user,
          role: role
        })
      } else {
        state.userSelectedToInvite = [{
          ...user,
          role: role
        }]
      }
      state.userSearchResults = []
      state.userSearchResultsStatus = processStatuses.IDLE
    },
    DESELECT_USER_TO_INVITE(state, { user }) {
      state.userSelectedToInvite = state.userSelectedToInvite.filter((inviteUser) => {
        if (user.isNew === true) {
          return inviteUser.email !== user.email
        } else {
          return inviteUser.id !== user.id
        }
      })
    },
    RESET_SELECTED_USER_TO_INVITE(state) {
      state.userSelectedToInvite = []
    },
    // Sort
    TOGGLE_USER_DESC(state) {
      state.isDesc = !state.isDesc
    },
    SET_USER_SORT_BY(state, filter) {
      state.sort = filter
    }
  },
  actions: {
    fetchExistingInvitedUsers({commit}, payload) {
      commit('INVITED_USERS_LOADING')
      return new Promise((resolve, reject) => {
        OrganizationUsersService.fetchInvitedUsers(payload).then((response) => {
          commit('INVITED_USERS_LOADED', response.data.userInvitations)
          resolve()
        }).catch((err) => {
          reject(err)
          commit('INVITED_USERS_ERROR', err)
        })
      })
    },
    inviteUsers({commit}, { userInvitations, organizationId }) {
      commit('INVITE_USER_TEAM_LOADING')
      return new Promise((resolve, reject) => {
        OrganizationUsersService.inviteUsers({userInvitations, organizationId}).then((response) => {
          commit('INVITE_USER_TEAM_LOADED')
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    reInviteUser({commit}, { email, organizationId }) {
      commit('INVITE_USER_TEAM_LOADING')
      return new Promise((resolve, reject) => {
        OrganizationUsersService.reInviteUser({email, organizationId}).then((response) => {
          commit('INVITE_USER_TEAM_LOADED')
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    acceptUserInvitation({commit}, { token }) {
      commit('INVITE_USER_TEAM_LOADING')
      return new Promise((resolve, reject) => {
        OrganizationUsersService.acceptUserInvitation({token}).then((response) => {
          commit('INVITE_USER_TEAM_LOADED')
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // fetch organization users
    getOrganizationUsers({commit, getters, rootGetters}) {
      const activeOrganization = rootGetters.getActiveOrganization
      if (rootGetters.getUserOrganizationStatus !== processStatuses.LOADED && activeOrganization) {
        console.debug('User organization is not loaded or active organization is not set, skipping organization load')
        return
      }
      if (getters.getOrganizationStatus === processStatuses.LOADING) {
        console.debug('Organization is currently loading, skipping organization load')
        return
      }
      commit('ORGANIZATION_USERS_LOADING')
      return new Promise((resolve, reject) => {
        OrganizationUsersService.getOrganizationUsers({organizationId: activeOrganization.id}).then((response) => {
          commit('ORGANIZATION_USERS_LOADED', response.data.organizationUsers)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    },
    updateOrganizationUser({commit}, payload) {
      return new Promise((resolve, reject) => {
        OrganizationUsersService.updateOrganizationUser(payload).then((response) => {
          commit('ORGANIZATION_USERS_TRANSFERRED', response.data.organizationUsers)
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    deleteOrganizationUser({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('ORGANIZATION_USER_DELETED', payload)
        OrganizationUsersService.deleteOrganizationUser(payload).then(() => {
          commit('ORGANIZATION_USER_DELETED', payload)
          resolve()
        }).catch((err) => {
          commit('ORGANIZATION_USER_DELETED_ERROR', payload)
          reject(err)
        })
      })
    },
    // search user list
    searchUsers({commit}, payload) {
      commit('SEARCHING_USER_RESULT')
      return new Promise((resolve, reject) => {
        OrganizationUsersService.searchUsers(payload).then((response) => {
          commit('SEARCH_USER_RESULT', response?.data?.users)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    },
    selectUserToInvite({commit}, payload) {
      commit('SELECT_USER_TO_INVITE', payload)
    },
    deselectUserToInvite({commit}, payload) {
      commit('DESELECT_USER_TO_INVITE', payload)
    },
    deleteInviteUser({commit, getters, rootGetters}, payload) {
      const activeOrganization = rootGetters.getActiveOrganization
      if (rootGetters.getUserOrganizationStatus !== processStatuses.LOADED && activeOrganization) {
        console.debug('User organization is not loaded or active organization is not set, skipping organization load')
        return
      }
      if (getters.getOrganizationStatus === processStatuses.LOADING) {
        console.debug('Organization is currently loading, skipping organization load')
        return
      }
      payload.organizationId = activeOrganization.id
      return new Promise((resolve, reject) => {
        OrganizationUsersService.deleteInviteUser(payload).then(() => {
          commit('DELETE_INVITE_USER_LOADED', payload)
          resolve()
        }).catch((err) => {
          commit('DELETE_INVITE_USER_ERROR', payload)
          reject(err)
        })
      })
    },
    setUserSortBy({ commit }, sortBy) {
      commit('SET_USER_SORT_BY', sortBy)
    },
    toggleUserDataDesc({ commit }) {
      commit('TOGGLE_USER_DESC')
    },
    resetSelectedTeamsToInvite({commit}) {
      commit('RESET_SELECTED_USER_TO_INVITE')
    },
    resetSearchResults({commit}) {
      commit('RESET_SEARCH_RESULT', [])
    }
  }
}
