import axios from 'axios'
import { config } from '@/config'

class PaymentService {
    createCheckoutSession({ planId, organizationId, eventId = 0, isEducation = false, successUrl = '', cancelUrl = '', metadata = null }) {
        return axios.post(`${config.API_URL}/payments/checkout-sessions`, {
              plan_id: planId,
              organization_id: organizationId,
              event_id: eventId,
              is_education: isEducation,
              success_url: successUrl,
              cancel_url: cancelUrl,
              metadata: metadata
            })
    }
}

export default new PaymentService()
