<script setup>
import { computed, reactive, onMounted, watch, onUnmounted } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
// Base Component
import BaseButton from '@/components/Base/Button/BaseButton'
// Store
import store from '@/store'
// Custom Components
import EventPageBase from '@/pages/EventBuilder/EventPageBase'
import CategoryTable from '@/components/Categories/Table'
import EventBuilderCard from '@/components/Base/Cards/EventBuilder'

import { bitwiseOperators, eventStatuses, processStatuses, eventPlans } from '@/utils'

const category = reactive({
  title: "You haven't set categories yet",
  description: "Get started below."
})

const router = useRouter()

const subscriptionDetails = computed(() => {
  return store.getters.getEventSubscriptionDetails
})

const isPremium = computed(() => {
  return [eventPlans.ESSENTIALS, eventPlans.PROFESSIONAL].includes(store.getters.getEventSubscriptionDetails?.plan?.name)
})

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const isEventPublished = computed(() => {
  return event.value.eventSettings?.status === eventStatuses.LIVE
})

const isCategoryReadOnly = computed(() => {
  // The category page should be read only when event is published or when categories are being saved
  return store.getters.isCategoriesPageReadOnly
})

const addCategories = () => {
  if ([eventPlans.ESSENTIALS, eventPlans.PROFESSIONAL].includes(subscriptionDetails.value?.plan?.name)) {
    store.dispatch('ToggleModalCategoriesLibrary')
  } else {
    if (store.getters.hasRemainingPremiumEvents(subscriptionDetails.value?.plan?.name)) {
      store.dispatch('ToggleModalUpgradeEvent', { eventId: store.getters.ebEventId })
    } else {
      store.dispatch('ToggleModalUpgradePayment', {
        eventId: store.getters.ebEventId,
        currentPlan: subscriptionDetails.value?.plan?.name
      })
    }
  }
}

const next = () => {
  router.push({ name: 'Sponsors', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

const prev = () => {
  router.push({ name: 'Resources', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

onMounted(() => {
  store.dispatch('fetchEventCategories')
  // Add beforeunload event listener when the component is mounted
  addEventListener("beforeunload", beforeUnloadHandler)
})

onUnmounted(() => {
  store.dispatch('resetCategoryState')
  // Remove beforeunload event listener when the component is unmounted
  removeEventListener("beforeunload", beforeUnloadHandler)
})

watch(() => store.getters.ebEventStatus, (status) => {
  if (status === processStatuses.LOADED && store.getters.categoriesStatus === processStatuses.IDLE) {
    store.dispatch('fetchEventCategories')
  }
})

const eventCategories = computed(() => {
  return store.getters.categories
})

const eventCategoriesStatus = computed(() => {
  return store.getters.categoriesStatus
})

const enableCategoryUpdatingButton = computed(() => {
  return store.getters.isCategoriesUpdated && store.getters.categoriesStatus !== processStatuses.LOADING
    && store.getters.categoriesUploadStatus !== processStatuses.SAVING && !isCategoryReadOnly.value
})

const isCategoryUpdating = computed(() => {
  return store.getters.categoriesUploadStatus === processStatuses.SAVING ||  store.getters.categoriesStatus === processStatuses.SAVING
})

const updateCategories = () => {
  // Validate categories
  const invalidCategories = eventCategories.value.filter(category => !category.name || !category.image)
  if (invalidCategories.length > 0) {
    store.dispatch('setCategoriesErrorList', invalidCategories)
    store.dispatch('triggerToast', { message: 'Categories must have both name and icon specified.', type: 'error' })
    return
  }
  // Upload all assets if modified
  store.dispatch('uploadCategoryImages').then(() => {
    // Save all categories
    store.dispatch('saveCategories').then(() => {
      let operation = ''
      if (eventCategories.value.length === 0) {
        operation = bitwiseOperators.REMOVE
      } else {
        operation = bitwiseOperators.COMBINE
      }
      store.dispatch('updateEventConfigs', { eventId: event.value.id, configKey: "event_config_completion", configValue: "32", isConfigValueBitMask: true, bitwiseOperation: operation }).then(() => {
        store.dispatch('getEventConfigs', { eventId: event.value.id })
      }).catch((err) => {
        console.debug(err)
      })
      store.dispatch('GetEventBuilderItems')
      store.dispatch('triggerToast',{ message: 'Categories have been updated', type: 'success'})
    }).catch(() => {
      store.dispatch('triggerToast',{ message: 'Failed to update categories', type: 'error'})
    })
  }).catch(() => {
    store.dispatch('triggerToast',{ message: 'Failed to upload category images', type: 'error'})
  })
}

const isCategoriesUpdated = computed(() => {
  return store.getters.isCategoriesUpdated
})

onBeforeRouteLeave((to, from, next) => {
  if(isCategoriesUpdated.value){
    const payload = {
      confirmAction: () => {
        store.dispatch('ResetModal')
        next()
      },
      cancelAction: () => {
        store.dispatch('ResetModal')
      }
    }
    store.dispatch('ToggleModalUnsavedAlert', payload)
  } else {
    next()
  }
})

const beforeUnloadHandler = async (event) => {
  if (isCategoriesUpdated.value) {
    event.preventDefault()
    // Allow the page to be unloaded
    event.returnValue = true
  }
}
</script>

<template>
  <event-page-base :is-published="isEventPublished ">
    <template #title>
      Project Categories
    </template>
    <template #description>
      <span class="categories-description">
        Add categories for your event showcase. Participants will select a category before submitting their projects.
      </span>
    </template>
    <template #content>
      <div v-if="eventCategoriesStatus === processStatuses.LOADING || eventCategoriesStatus === processStatuses.IDLE">
        Loading
      </div>
      <event-builder-card v-else-if="eventCategories.length === 0 && !isCategoriesUpdated"
                          :title="category.title"
                          :content="category.description"
                          :is-create="true"
                          :btn-text="isPremium ? 'Add Categories' : 'Upgrade To Premium'"
                          :btn-disabled="isCategoryReadOnly"
                          :functions="addCategories"/>
      <category-table v-else />
    </template>
    <template #bottom-nav-container>
      <base-button
          id="bottom-nav-prev"
          name="bottom-nav-prev"
          class-name="bottom-nav-button"
          btn-type="text"
          text="back"
          :functions="prev">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-left" />
            </span>
            Back
          </span>
        </template>
      </base-button>
      <base-button
          id="bottom-nav-next"
          name="bottom-nav-next"
          class-name="bottom-nav-button"
          btn-type="text"
          :functions="next"
          text="next">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-right" />
            </span>
            Next
          </span>
        </template>
      </base-button>
      <base-button text="Update"
                   :disabled="!enableCategoryUpdatingButton"
                   :is-loading="isCategoryUpdating"
                   aria-label="Update Button"
                   class-name="categories-button"
                   :functions="updateCategories" />
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.categories {
  &-description {
    @apply text-h5 text-blacks-500;
  }
  &-content {
    @apply h-[27.875rem] w-full md:max-w-[46rem] bg-grey-100 border border-grey-600 flex flex-col items-center mt-6 rounded-xl;
    &-img {
      @apply ml-6 pt-10 pb-6;
    }
    &-title {
      @apply text-h3 text-blacks-500 font-medium;
    }
    &-description {
      @apply text-p-sm text-blacks-500 pt-3 pb-8;
    }
    &-addBtn {
      @apply capitalize font-normal text-p-md py-3 px-5;
    }
  }
  &-button {
    @apply capitalize px-6 py-2 mt-1;
  }
}
</style>
