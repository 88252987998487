import router from '@/router'
import store from '@/store'

export const adminAuthGuard = (from) => {
   if (store.getters.isStaff) {
      return true
   }

   router.push({ path: from.fullPath })
   return false
}
