<script setup>
import { ref, computed, onMounted } from 'vue'
import BaseInput from '@/components/Base/Fields/InputField'
import store from '@/store'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  shouldOpen: {
    type: Boolean,
    default: false
  }
})

const accordianState = ref(false)

const toggleAccordian = () => {
  return accordianState.value = !accordianState.value
}

const selectCategory = (e, category) => {
  if (e.target.checked) {
    store.dispatch('selectCategoryFromLibrary', category)
  } else {
    store.dispatch('unselectCategoryFromLibrary', category)
  }
}

const numberOfSelectedCategoriesFromLibrary = (categoryId) => {
  return store.getters.numberOfSelectedCategoriesFromLibrary(categoryId)
}

const selectedCategoryFromLibrary = computed(() => {
  return store.getters.selectedCategoryFromLibrary
})

onMounted(() => {
  if (props.shouldOpen) {
    accordianState.value = true
  }
})
</script>

<template>
  <div class="accordian">
    <div class="accordian-header"
          @click.prevent="toggleAccordian">
      <div class="accordian-header-title">
        {{items.name}}
      </div>
      <div v-if="selectedCategoryFromLibrary" class="accordian-header-number">
        {{numberOfSelectedCategoriesFromLibrary(items.id)}}
        <i class="fa-solid ml-2"
           :class="{'fa-chevron-up': accordianState, 'fa-chevron-down' : !accordianState}" />
      </div>
    </div>
    <ul v-if="accordianState">
      <li v-for="item in items.subCategories"
          :key="item"
          class="accordian-border-item">
        <base-input :input-id="item.name"
                    :input-name="item.name"
                    input-type="checkbox"
                    class-name="accordian-border-input"
                    @change="(e) => selectCategory(e, item)" />
        <label class="accordian-border-label"
               :for="item.name">
          {{item.name}}
        </label>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.accordian {
  @apply h-full py-3 border-t border-b cursor-pointer w-full flex flex-col;
  &-header {
    @apply w-full flex flex-row justify-between items-center;
    &-title, &-number {
      @apply font-medium text-p-xl;
    }
  }
  &-border {
    &-item {
      @apply py-1 flex flex-row w-full items-center;
    }
    &-input {
      @apply cursor-pointer flex items-center justify-center flex-row w-full;
    }
    &-label {
      @apply ml-2 cursor-pointer;
    }
  }
}

// Checkbox Override for the arrows
input[type=checkbox] {
  background-color: transparent;
  appearance: none;;
  width: 15px;;
  height: 15px;
  border: 2px solid black;
  border-radius: 2px;
}

input[type=checkbox]:checked {
  appearance: none;
  outline: none;
  padding: 0;
  content: none;
  border: 2px solid #8400F0;
}

input[type=checkbox]:checked::before {
  color: #8400F0;
  transition: 120ms transform ease-in-out;
  content: "\00A0\2713\00A0";
  font-weight: bolder;
  font-size: 12px;
}
</style>
