import PaymentService from '@/services/payment'
import { processStatuses } from '@/utils'
import tracking from '@/utils/tracking'

export default {
  state: () => ({
    checkoutSession: null,
    checkoutSessionStatus: processStatuses.IDLE,
    checkoutSessionError: null
  }),
  getters: {
    getCheckoutSessionUrl: state => {
      return state.checkoutSession?.sessionUrl
    },
    getCheckoutSessionStatus: state => {
        return state.checkoutSessionStatus
    },
    getCheckoutSessionError: state => {
        return state.checkoutSessionError
    }
  },
  mutations: {
    CHECKOUT_SESSION_LOADED(state, payload) {
      state.checkoutSession = payload
      state.checkoutSessionStatus = processStatuses.LOADED
      state.checkoutSessionError = null
    },
    CHECKOUT_SESSION_LOADING(state) {
      state.checkoutSessionStatus = processStatuses.LOADING
    },
    CHECKOUT_SESSION_ERROR(state, error) {
      state.checkoutSessionStatus = processStatuses.ERROR
      state.checkoutSessionError = error
    }
  },
  actions: {
    createCheckoutSession({commit}, {planId, organizationId, eventId = 0, isEducation = false, successUrl = '', cancelUrl = ''}) {
        commit('CHECKOUT_SESSION_LOADING')
        // Get User segment from utm_source
        const utmParams = tracking.getUtmParams()
        return new Promise((resolve, reject) => {
          PaymentService.createCheckoutSession({
            planId: planId,
            organizationId: organizationId,
            eventId: eventId,
            isEducation: isEducation,
            successUrl: successUrl,
            cancelUrl: cancelUrl,
            metadata: utmParams?.utmSource ? [{
              key: 'utm_source',
              value: utmParams.utmSource
            }] : null
          }).then((response) => {
            commit('CHECKOUT_SESSION_LOADED', response.data)
            resolve(response.data)
          }).catch((err) => {
            commit('CHECKOUT_SESSION_ERROR', err)
            reject(err)
          })
        })
      }
  }
}
