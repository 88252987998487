const usernameRegex = /^[a-zA-Z0-9_-]+$/;

function validateEmail(email) {
  // Implement email validation logic here
  const errors = {}

  // Example: Check if the email is valid
  if (email.length > 50) {
    errors.usernameOrEmail = "Email address is too long"
  }

  // Example: Check if the email domain is allowed
  const allowedDomains = ["example.com", "test.com"]
  const emailDomain = email.trim().split("@")[1]?.toLowerCase()
  if (!allowedDomains.includes(emailDomain)) {
    errors.usernameOrEmail = "Email domain is not allowed"
  }

  if (Object.keys(errors).length > 0) {
    return errors.usernameOrEmail
  }

  return null
}

function basicEmailValidation(email) {
  if (!email) {
    return false // Invalid if email is empty or undefined
  }

  const atIndex = email.indexOf('@')
  const dotIndex = email.lastIndexOf('.')

  // Check if "@" symbol exists and is not the first or last character
  if (atIndex <= 0 || atIndex === email.length - 1) {
    return false
  }

  // Check if dot exists after the "@" symbol
  if (dotIndex === -1 || dotIndex <= atIndex + 1 || dotIndex === email.length - 1) {
    return false
  }

  return true // Valid email format
}

// Util function to check username validation
function checkUsernameValidation(usernameOrEmail) {
  const errors = {}
  if (!usernameOrEmail) {
    errors.usernameOrEmail = "Please enter your username or email"
  } else {
    // Check if usernameOrEmail is an email address
    if (basicEmailValidation(usernameOrEmail)) {
      // Perform email validation if it matches the email pattern
      const emailValidationErrors = validateEmail(usernameOrEmail) // Example: Call a separate email validation function
      if (emailValidationErrors) {
        errors.usernameOrEmail = emailValidationErrors
      }
    } else {
      // Check username's max length
      if (usernameOrEmail.length < 4 || usernameOrEmail.length > 20) {
        errors.usernameOrEmail = `username must be between 4 and 20 characters in length`
      }
      // Check for special characters in username
      // Example: Allow alphanumeric, underscore, and hyphen
      if (!usernameRegex.test(usernameOrEmail)) {
        errors.usernameOrEmail = "Username can only contain alphanumeric characters, underscore, and hyphen"
      }
    }
  }
  if (Object.keys(errors).length > 0) {
    return errors
  }
  return null
}

// Util function to check password validation
function checkPasswordValidation(password) {
  const errors = {}

  if (!password || password === '') {
    errors.usernameOrEmail = "Please enter your password"
  } else if (password.length < 6) {
    errors.usernameOrEmail = "Password should contain at least 6 characters"
  }
  //TODO: Password strength Not check for Login (existing user)
  // if (password && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_+.]{6,}$/.test(password)) {
  //   errors.usernameOrEmail = "Password should contain at least 1 number, 1 lowercase character, and 1 uppercase character."
  // }
  if (Object.keys(errors).length > 0) {
    return errors.usernameOrEmail
  }

  return null
}

export {
  checkUsernameValidation,
  checkPasswordValidation,
  basicEmailValidation
}
