<script setup>
import { computed, onMounted } from 'vue'
import day from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
// Store
import store from '@/store'
// Component Lib
import OverflowMenu from '@/components/Base/Menu/Overflow'
import Avatar from '@/components/Base/Avatar'

defineProps({
  menuType: {
    type: String,
    default: 'profile'
  },
  menuToggle: {
    type: Boolean,
    default: false
  },
  menuItems: {
    type: Array,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  id: {
    type: String
  }
})

// Initiate the relative time
day.extend(relativeTime)

// Storing Icons
const icons = {
  user: '/images/icons/notification/user.png',
  review: '/images/icons/notification/review.png',
  draft: '/images/icons/notification/draft.png',
  flag: '/images/icons/notification/flag.png'
}

// Format date for today
const formatDate = (time) => {
  if (day(time).toNow().includes('hour') ||
    day(time).toNow().includes('minute') ||
    day(time).toNow().includes('second')) return `Today at ${day(time).format('HH:mma')}`
  return `${day(time).toNow()} at ${day(time).format('HH:mma')}`
}

// Check user is logged in
const isLoggedIn = computed(() => {
  return store.getters.isLoggedIn
})


// Get the right icons
const getNotificationIcon = (icon) => {
  switch(icon) {
  case 'user':
    return icons.user
  case 'review':
    return icons.review
  case 'draft':
    return icons.draft
  default:
    return icons.flag
  }
}

const closeMobileMenu = () => {
  store.dispatch('ToggleCloseMenu')
}

const user = computed(() => {
  return store.getters.user
})

const userDisplayName = computed(() => {
  return store.getters.displayName
})
const userInitial = computed(() => {
  return store.getters.userNameInitial
})

onMounted(() => {
  if (!user.value && isLoggedIn.value) {
    store.dispatch('fetchCurrentUser')
  }
})
</script>

<template>
  <!-- Profile Menu -->
  <overflow-menu v-if="isLoggedIn"
                 :id="id"
                 :disabled="disabled"
                 :menu-toggle="menuToggle"
                 :menu-items="menuItems"
                 position="navbar">
    <!-- Profile Avatar Icon -->
    <template #menu-icon>
      <!-- Notification -->
      <i v-if="menuType === 'notifications'"
         class="fa-regular fa-bell fa-xl" />
      <!-- Profile Menu -->
      <avatar v-if="menuType === 'profile'"
              class-name="nav-menu-avatar"
              @click.prevent="closeMobileMenu">
        <template #profile>
          <img v-if="user?.avatar" :src="user.avatar"
               alt="user image"
               class="nav-menu-avatar-image" />
          <div v-else class="nav-menu-container">
            <span class="nav-menu-avatar_text">
              {{userInitial}}
            </span>
          </div>
        </template>
      </avatar>
    </template>
    <template #menu-items>
      <!-- Notification -->
      <span v-if="menuType !== 'profile'"
            class="nav-menu-notifications">
        <span class="nav-menu-notifications-container">
            <h5 class="nav-menu-notifications-menuType">
            {{ menuType }}
          </h5>
          <p class="nav-menu-notifications-readAll">
            Mark all as read
          </p>
        </span>
          <ul>
           <p v-if="menuItems && menuItems.length === 0"
              class="nav-menu-notifications-notAvailable">
             No {{menuType}} available
           </p>
           <li v-for="(item, index) in menuItems"
               :key="item.id"
               class="nav-menu-notifications-menuItem">
             <span class="nav-menu-notifications-menuItem-container">
               <span class="relative">
                 <span v-if="!item.isRead"
                       class="nav-menu-notifications-menuItem-hasRead" />
                 <img :src="getNotificationIcon(item.icon)"
                      :alt="item.icon"
                      class="w-8 h-8"/>
               </span>
               <span class="ml-4">
                 <p class="text-p-sm text-black">
                   {{item.title}}
                 </p>
                 <p class="text-p-xs text-blacks-300">
                   {{item.description}}
                 </p>
                 <div class="text-p-xs pt-2">
                   {{ formatDate(item.created_at) }}
                 </div>
               </span>
             </span>
             <hr v-if="index !== menuItems.length - 1"
                 class="w-full h-0.5 bg-blacks-100 mt-4"/>
           </li>
         </ul>
       </span>
       <!-- Profile Menu -->
       <span v-if="menuType === 'profile'"
             class="nav-menu-profile">
         <span class="nav-menu-profile-close">
           <i class="fa-solid fa-xmark text-[1.5rem]"></i>
         </span>
         <!-- Profile User Info -->
        <span class="nav-menu-profile-container">
           <avatar class-name="nav-menu-profile-avatar">
            <template #profile>
                <img :src="user ? user.avatar : 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50'"
                     alt="user image"
                     class="nav-menu-profile-avatar-image" />
            </template>
          </avatar>
          <span class="nav-menu-profile-textContainer">
            <h5 class="nav-menu-profile-name">
              {{userDisplayName}}
            </h5>
          <!-- Temporary Disable for MVP -->
          <!-- <router-link :to="{name: 'Profile'}" -->
          <!--              class="nav-menu-profile-viewText"> -->
          <!--  View Profile -->
          <!-- </router-link> -->
          </span>
         </span>
         <hr class="nav-menu-profile-separator"/>
         <!-- Profile Menu Items -->
         <ul>
           <li v-for="item in menuItems"
               :key="item.name"
               :qa="`qa-${item.name}-item`"
               class="nav-menu-profile-items"
               @click="item.action">
             <router-link v-if="item.name !== 'Logout'"
                          :to="{name: `${item.component}`}">
               <i :class="item.icon" class="mr-4"/>
               {{item.name}}
             </router-link>
             <span v-else>
               <i :class="item.icon" class="mr-4"/>
               {{item.name}}
             </span>
           </li>
        </ul>
       </span>
    </template>
  </overflow-menu>
</template>

<style lang="scss" scoped>
.nav {
  &-menu {
    @apply flex flex-row justify-between items-center;
    &-avatar {
      @apply w-full h-full rounded-full text-sm ring-transparent bg-product-400/[0.1];
      &:hover {
        @apply ring ring-1 ring-secondary;
      }
      &-container {
        @apply w-full flex items-center justify-center;
      }
      &_text {
        @apply font-medium text-product-400 uppercase;
      }
      &-image {
        @apply rounded-full w-full h-full object-cover;
      }
    }
    // Notifications
    &-notifications {
      @apply p-6 rounded-xl w-[22rem] max-h-[30rem] overflow-y-auto;
      &-container {
        @apply flex flex-row justify-between w-full;
      }
      &-menuType {
        @apply text-h5 font-medium capitalize;
      }
      &-readAll {
        @apply text-product-400 text-p-md cursor-pointer;
      }
      &-menuItem {
        @apply flex flex-col py-4;
        &-container {
          @apply flex flex-row;
        }
        &-hasRead {
          @apply w-2 h-2 absolute bg-primary rounded-full -top-1 -left-0.5;
        }
      }
      &-notAvailable {
        @apply text-p-sm text-center pt-4;
      }
    }
    // Profile Styles
    &-profile {
      @apply fixed top-[4rem] left-0 w-full h-full md:m-6 md:rounded-lg md:w-[16rem] bg-background-400 md:relative md:top-auto px-4 md:px-0;
      &-close {
        @apply absolute md:right-0 cursor-pointer text-grey-700 right-2;
      }
      &-container {
        @apply flex md:flex-row items-center justify-center flex-col py-6 md:py-0;
      }
       &-avatar {
          @apply md:max-w-[2.5rem] md:max-h-[2.5rem] rounded-full h-[5rem] w-[5rem] mt-2 md:mt-0;
          &-image {
            @apply rounded-full h-full w-full object-cover;
          }
      }
      &-textContainer {
        @apply flex flex-col ml-4 text-center md:text-left max-w-full;
      }
      &-name {
        @apply text-h5 font-medium py-2 md:py-0 line-clamp-2 mr-8;
      }
      &-viewText {
        @apply text-p-md md:text-p-xs font-medium text-product-400;
      }
      &-items {
        @apply flex flex-row items-center py-4 text-p-sm hover:text-product-400 cursor-pointer;
      }
      &-separator {
        @apply w-full h-0.5 mt-4;
      }
    }
  }
}

.input {
  &-search {
    @apply max-w-[10rem];
  }
}
</style>
