<script setup>
import { computed } from 'vue'
import store from '@/store'
import ModalLayout from '@/components/Modals/ModalBase'
import BaseButton from '@/components/Base/Button/BaseButton'
import Avatar from '@/components/Base/Avatar'

const confirmData = computed(() => {
  return store.getters.modalData
})

const confirmAction = () => {
  store.dispatch('ConfirmAction')
}

const cancelAction = () => {
  store.dispatch('CancelAction')
}

const isDelete = (text) => {
  return text.toLowerCase() === 'delete'
}
</script>

<template>
  <modal-layout :is-close="true"
                class="confirm">
    <template #modal-title>
      <span class="confirm-header">
        <!-- Avatar component for trash can icon -->
        <avatar class-name="iconTrash"
                image="/images/icons/trash.png"/>
        <h1 class="confirm-title">
          {{ confirmData?.data?.title }}
        </h1>
      </span>
    </template>
    <template #modal-content>
      <span class="confirm-body">
        <p class="confirm-content">
          {{ confirmData?.data?.description}}
        </p>
        <span class="confirm-btn-container">
          <base-button :functions="cancelAction"
                       btn-type="secondary"
                       :text="confirmData?.data?.cancelButton"
                       class-name="confirm-btn confirm-btn-back"/>

          <base-button :functions="confirmAction"
                       btn-type="primary"
                       :text="confirmData?.data?.confirmButton"
                       :class-name="`confirm-btn ${isDelete(confirmData?.data?.confirmButton) ?
                       'confirm-btn-delete' : 'confirm-btn-approve' }`"/>
        </span>
      </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.confirm {
  @apply bg-white max-w-[32rem];
  &-header {
    @apply flex flex-col w-full justify-center items-center pt-4;
  }
  &-title {
    @apply capitalize font-medium text-h2 text-center mt-6 -mb-2;
  }
  &-content {
    @apply text-blacks-300 flex flex-col items-center;
  }
  &-btn {
    @apply w-[8rem] mx-2 px-4 py-2.5 capitalize;
    &-container {
      @apply w-full flex flex-row justify-center py-8;
    }
  //back btn style here
    &-back {
      @apply font-medium;
      &:hover {
        @apply border-transparent bg-blacks-300;
      }
    }
    &-decline {
      @apply bg-other-red text-white font-medium;
      &:hover {
        @apply opacity-80;
      }
    }
    &-approve {
      @apply bg-other-green text-white font-medium;
      &:hover {
        @apply opacity-80;
      }
    }
    &-delete {
      @apply bg-other-red text-white font-medium;
      &:hover {
        @apply opacity-80;
      }
    }
  }
}

.iconTrash{
  @apply bg-other-orange border-none ring-transparent w-[5rem] h-[5rem] rounded-full;
}
</style>
