<script setup>
import store from '@/store'
import BaseInput from '@/components/Base/Fields/InputField'
import BaseButton from '@/components/Base/Button/BaseButton'
import ModalLayout from '@/components/Modals/ModalAuthBase'
import {ref, computed} from "vue"

const props = defineProps({
  firstName: {
    type: String,
    required: true
  },
  lastName: {
    type: String,
    required: true
  },
  email: {
    type: String,
    requires: true
  },
  username: {
    type: String,
    required: true
  },
  password: {
    type: String,
    required: true
  },
  passwordConfirm: {
    type: String,
    required: true
  },
  registerError: {
    type: String
  },
  firstCheckBox: {
    type: Boolean,
    required: true
  },
  secondCheckBox:{
    type: Boolean,
    required: true
  }
})

const firstName = ref(props.firstName)
const lastName = ref(props.lastName)
const email = ref(props.email)
const username = ref(props.username)
const password = ref(props.password)
const passwordConfirm = ref(props.passwordConfirm)
// const firstCheckBox = ref(props.firstCheckBox)
// const secondCheckBox = ref(props.secondCheckBox)

// Disabling the 3rd party temporary
const isMvp = true

const register = () => {
  const payload = {
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    username: username.value,
    password: password.value,
    passwordConfirm: passwordConfirm.value
  }
  store.dispatch('validateSignupAccount', payload).then(() => {
    if (!store.getters.isUserVerified) {
      store.dispatch('ChangeModalType', 'verify')
    } else {
      store.dispatch('ChangeModalType', 'login')
    }
  })
}
const registerValidationErrorMessage = computed(() => {
  return store.getters.registerValidationError
})

const registrationValidateError = (type) => {
  let temp = ''
  Object.keys(registerValidationErrorMessage.value).filter(item =>{
    if (item === type) {
      temp = registerValidationErrorMessage.value[type]
    }
  })
  return temp
}
const passwordValidationError = computed ( () => {
  let ValidatePass = store.getters.passwordValidationError
  return Object.keys(ValidatePass).filter((key) => key.includes('password')).reduce((cur, key) => {
    return Object.assign(cur, { [key]: ValidatePass[key] })
  }, {});
})

const toggleModal = () => {
  store.dispatch('ToggleModal')
  store.dispatch('ToggleOverlay')
  store.dispatch('ResetModal')
}
</script>


<template>
  <modal-layout>
    <template #modal-title>
      Sign up
    </template>
    <template #modal-body>
      <span class="register">
        <base-input v-model="firstName"
                    :model-value="firstName"
                    input-type="text"
                    input-name="firstName"
                    input-id="firstName"
                    input-place-holder="Enter your first name"
                    input-aria-label="firstName"
                    class-name="my-1"
                    :errors="registrationValidateError('firstName')"/>

        <base-input v-model="lastName"
                    :model-value="lastName"
                    input-type="text"
                    input-name="lastName"
                    input-id="lastName"
                    input-place-holder="Enter your last name"
                    input-aria-label="lastName"
                    class-name="my-1"
                    :errors="registrationValidateError('lastName')"/>

        <base-input v-model="email"
                    :model-value="email"
                    input-type="email"
                    input-name="email"
                    input-id="email"
                    input-place-holder="Enter your email"
                    input-aria-label="email"
                    class-name="my-1"
                    :errors="registrationValidateError('email')"/>

        <base-input v-model="username"
                    :model-value="username"
                    input-type="text"
                    input-name="username"
                    input-id="username"
                    input-place-holder="Choose an username (ex.AlexSmith)"
                    input-aria-label="username"
                    class-name="my-1"
                    :errors="registrationValidateError('username')"/>

        <base-input v-model="password"
                    :model-value="password"
                    input-type="password"
                    input-name="password"
                    input-id="password"
                    input-place-holder="Choose a password"
                    input-aria-label="password"
                    class-name="my-1"
                    :errors="registrationValidateError('password')"/>

        <base-input v-model="passwordConfirm"
                    :model-value="passwordConfirm"
                    input-type="password"
                    input-name="passwordConfirm"
                    input-id="passwordConfirm"
                    input-place-holder="Confirm your password"
                    input-aria-label="passwordConfirm"
                    class-name="my-1"
                    :errors="registrationValidateError('passwordConfirm')"/>

        <div class="error" >{{ registerValidationErrorMessage.registerError }}</div>
        <div v-for="(item, index) in passwordValidationError" :key="index" class="error">{{item}}</div>

      </span>
      <span class="social">
        <ul v-if="!isMvp"
            class="social-container">
          <li>
            <span class="social-avatar">
            <i class="fa-brands fa-apple text-white"></i>
            </span>
          </li>
          <li>
            <span class="social-avatar">
            <i class="fa-brands fa-google text-white"></i>
            </span>
          </li>
          <li>
            <span class="social-avatar">
            <i class="fa-solid fa-phone text-white"></i>
            </span>
          </li>
          <li>
            <span class="social-avatar">
              <i class="fa-brands fa-windows text-white"></i>
            </span>
          </li>
        </ul>
        <h5 class="login">
          Do you already have an account with ProjectBoard or MakeProjects
          ? <span class="login_text"
                  @click="toggleModal">
          Log in
        </span>
        </h5>
      </span>
    </template>
    <template #modal-button>
      <span class="w-full text-center py-4">
        <base-button id="next"
                      name="next"
                      text="Continue"
                      class-name="btn btn-primary btn-continue"
                      :functions="register" />
      </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.modal-register {
  @apply max-h-[40rem] md:max-h-[80rem] md:min-h-[40rem] justify-center;
}
.register {
  @apply px-10 overflow-y-auto;
}
.social {
  @apply w-full text-center;
  &-container {
    @apply flex flex-row w-full justify-around px-36 py-2;
  }
  &-avatar {
    @apply inline-block h-7 w-7 rounded-full bg-purple justify-center items-center;
  }
}
.login {
  @apply py-2 mx-10 font-bold text-sm;
  &_text {
    @apply text-purple cursor-pointer;
  }
}
.btn {
  &-continue {
    @apply px-4 py-2;
  }
}
</style>
