<script setup>
// Required Components
import { computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'
// Store
import store from '@/store'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'
import BaseInput from '@/components/Base/Fields/InputField'
// Custom Components
import EbTable from '@/components/Base/Table/EventBuilder'
// Utils
import { isFirebaseStorageURL, processStatuses } from '@/utils'


const headers = [
  {
    text: 'Sponsor Logo', value: "image", sortable: false, class: 'min-w-[16rem]'
  },
  {
    text: 'Sponsor URL', value: "url", sortable: true, class: 'min-w-[24rem]'
  },
]

const items = computed(() => {
  return store.getters.sponsors
})

const sponsorsReadOnly = computed(() => {
  return store.getters.sponsorsStatus === processStatuses.SAVING ||
    store.getters.sponsorsStatus === processStatuses.LOADING
})

const sponsorsErrorList = computed(() => {
  return store.getters.sponsorsErrorList
})

const updateSponsorsName = (id, e) => {
  let url = e.target.value
  if (url) {
    // Check for no http or https
    if (!(/(http(s?)):\/\//i.test(url))) {
      // Add https to it
      url = 'https://' + url
    }
  }
  store.dispatch('updateEventSponsor', { id: id, field: 'url', value: url })
}

const toggleIconsModal = (id) => {
  store.dispatch('ToggleModalSponsorsModal', {
    confirmAction: (imageOrIcon) => {
      // Need an update award action
      store.dispatch('updateEventSponsor', { id: id, field: 'image', value: imageOrIcon })
    }
  })
}

const addSponsors = () => {
  const payload = {
    id: uuidv4(),
    name: '',
    image: '',
    url: ''
  }
  store.dispatch('setSponsorsErrorList', {})
  store.dispatch('addEventSponsor', payload)
}

const deleteSponsorRow = (id) => {
  store.dispatch('removeEventSponsor', id)
}

const sort = (filter = 'url') => {
  store.dispatch('setSponsorFilterBy', filter)
  store.dispatch('toggleSponsorsDesc')
}

const convertToObjectUrl = (file) => {
  return URL.createObjectURL(file)
}

const isFile = (file) => {
  if (file === null) {
    return false
  }
  return file instanceof File
}

const maxSponsors = (limit = 3) => {
  return items.value?.length === limit
}

const validInput = (id="", errorList) => {
  if (errorList?.length > 0 && id.length > 0) {
    return errorList.filter(error => {
      return error.id === id
    })
  } else {
    return false
  }
}
</script>

<template>
  <div class="sponsors-add">
    <base-button id="addSponsorsButton"
                 name="addSponsorsButton"
                 class-name="sponsors-button sponsors-button-sponsors"
                 :functions="addSponsors"
                 :disabled="sponsorsReadOnly || maxSponsors()"
                 text="Add Sponsor"/>
  </div>
  <div class="overflow-x-auto">
    <eb-table input-place-holder="New Sponsor"
              :full-width="false"
              :delete-row="deleteSponsorRow"
              :read-only="sponsorsReadOnly"
              :toggle-icons-modal="toggleIconsModal"
              :update-row-name="updateSponsorsName"
              :items="items"
              :invert-color="false"
              :headers="headers"
              :sort-function="sort">
      <template #content-name="{ item }">
        <div class="sponsors-add-container">
          <base-button :functions="() => toggleIconsModal(item.id)"
                       text=""
                       class-name="sponsors-button"
                       :disabled="sponsorsReadOnly"
                       btn-type="text">
            <template #contents >
              <i v-if="item.image === ''"
                 class="fa-light fa-circle-plus fa-2x"></i>
              <div v-else
                   class="sponsors-logo">
                  <img v-if="isFirebaseStorageURL(item.image)"
                       :src="item.image"
                       :alt="item.url"
                       class="sponsors-logo-image" />
                  <img v-else-if="isFile(item.image)"
                       :alt="item.url"
                       class="sponsors-logo-image"
                       :src="convertToObjectUrl(item.image)" />
              </div>

            </template>
          </base-button>
          <div v-if="validInput(item.id, sponsorsErrorList) && !item.image"
               class="sponsors-errors">
            Sponsor Logo Missing
          </div>
        </div>
      </template>
      <template #content-column="{ item }">
        <base-input v-if="!sponsorsReadOnly"
                    input-id="categoryName"
                    input-name="categoryName"
                    :model-value="item.url"
                    input-place-holder="https://"
                    name="categoryName"
                    class="pr-8"
                    @change="(e) => updateSponsorsName(item.id, e)" />
        <span v-else class="pr-8">{{item.url}}</span>
      </template>

    </eb-table>
  </div>
</template>

<style lang="scss" scoped>
.sponsors{
  // sponsors Add
  &-add {
    @apply pt-10;
    &-container {
      @apply flex flex-row relative;
    }
  }
  &-button {
    @apply w-auto text-product-400 min-w-[2.5rem];
    &-sponsors {
      @apply capitalize px-4 py-2 text-p-md text-white;
    }
  }
  &-logo {
    @apply rounded-xl max-w-[20rem] max-h-[4rem] h-[4rem] mt-2 w-[12rem];
    &-image {
      @apply object-contain max-h-[100%] rounded-xl m-auto border-grey-300 border-solid border-[1px] w-[12rem];
    }
  }
  &-errors {
    @apply text-p-xs text-other-red ml-2 absolute -bottom-[1.1rem];
  }
}
</style>
