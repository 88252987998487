<script setup>
import { Icons } from 'icons'

import BaseButton from '@/components/Base/Button/BaseButton'

const awardLibrary = [
  {
    name: 'tro',
    display: 'TROPHIES',
    icons: [
      'gold-star',
      'silver-star',
      'bronze-star',
      'gold',
      'silver',
      'bronze',
      'platinum',
      'emerald',
      'meteorite'
    ]
  },
  {
    name: 'rm',
    display: 'ribbons & medals',
    icons: [
      'blue-star',
      'red-star',
      'silver-star',
      'bronze-star',
      'gold-star',
      'emerald-star',
      'amethyst-star',
      'gold-award',
      'silver-award',
      'bronze-award',
      'gold',
      'silver',
      'bronze'
    ]
  },
  {
    name: 'cert',
    display: 'certificates',
    icons: [
      'alt-1',
      'alt-2',
      'alt-3',
      'alt-4',
      'alt-5',
      'alt-6',
    ]
  },
  {
    name: 'ba',
    display: 'badges',
    icons: [
      'gold',
      'silver',
      'bronze',
      'green-heart',
      'blue-check',
      'purple-star'
    ]
  }
]

const iconLibrary = [
  {
    name: 'isef',
    display: 'official isef categories',
    icons: [
      'animal-sciences',
      'behavioral-and-social-sciences',
      'biochemistry',
      'biomedical-and-health-sciences',
      'biomedical-engineering',
      'cellular-and-molecular-biology',
      'chemistry',
      'computational-biology-bioinformatics',
      'earth-environmental-sciences',
      'embedded-systems',
      'energy-sustainable-materials-design',
      'engineering-technology-statics-dynamics',
      'environmental-engineering',
      'materials-science',
      'mathematics',
      'microbiology',
      'physics-and-astronomy',
      'plant-sciences',
      'robotics-intelligent-machines',
      'systems-software',
      'translational-medical-science'
    ]
  },
  {
    name: 'stem',
    display: 'stem',
    icons: [
      'atom',
      'bacteria',
      'bot',
      'brain',
      'brain-alt',
      'cell',
      'cpu',
      'dna',
      'elephant',
      'engineering',
      'eukaryotic-cells',
      'flame',
      'gears',
      'green-energy',
      'heartbeat',
      'laptop-settings',
      'leaf',
      'mean-kinetic-temperature',
      'microscope',
      'molecule',
      'planet',
      'product-architecture',
      'sigma',
      'telescope',
      'test-tube',
      'test-tube-alt',
      'vaccine'
    ]
  },
  {
    name: 'os',
    display: 'objects & symbols',
    icons: [
      'balloon',
      'battery-charging-2',
      'brightness-half',
      'bulb',
      'certificate',
      'code',
      'color-swatch',
      'currency-dollar',
      'database',
      'device-imac',
      'emoji-thinking',
      'file-text',
      'gift',
      'git-branch',
      'heart',
      'lock-square',
      'mail',
      'mario-box',
      'messages',
      'package',
      'plug',
      'puzzle',
      'ribbon-health',
      'search',
      'shield-lock',
      'shirt',
      'sitemap',
      'solar-panel',
      'speakerphone',
      'sun',
      'think-cloud',
      'triangle-square-circle',
      'video',
      'wifi',
      'wizard-hat'
    ]
  },
  {
    name: 'an',
    display: 'animals & nature',
    icons: [
      'bat',
      'bear-paw',
      'biohazard',
      'bird',
      'boom',
      'butterfly',
      'cat',
      'christmas-tree',
      'cloud',
      'cloud-bolt',
      'cow',
      'crescent-moon',
      'dolphin',
      'droplet',
      'feather',
      'fish',
      'flower',
      'frog',
      'growth',
      'meteor',
      'mountain',
      'mouse',
      'plant',
      'plant-2',
      'rain',
      'seeding',
      'spider',
      'star',
      'sunset',
      'turtle',
      'uv-index',
      'wave',
      'world'
    ]
  },
  {
    name: 'ph',
    display: 'people & health',
    icons: [
      'disabled',
      'doctor',
      'eye',
      'face-mask',
      'graduation',
      'hand-click',
      'hand-finger',
      'hand-move',
      'head-brain',
      'head-heart',
      'head-mask',
      'head-medicine',
      'pills',
      'robot',
      'stethoscope',
      'test-pipe',
      'user-blackboard',
      'user-female',
      'user-group',
      'user-plus-alt',
      'users',
      'vaccine-bottle',
      'virus'
    ]
  },
  {
    name: 'fd',
    display: 'food & drinks',
    icons: [
      'apple',
      'baguette',
      'beer',
      'bottle',
      'carrot',
      'cheese',
      'coffee',
      'grill',
      'meat',
      'microwave',
      'milkshake',
      'mug',
      'paper-bag',
      'pizza',
      'salad',
      'soup'
    ]
  },
  {
    name: 'al',
    display: 'arts & leisure',
    icons: [
      'book',
      'brush',
      'clipboard',
      'color-picker',
      'football',
      'gamepad',
      'handball',
      'masks-theater',
      'palette',
      'ping-pong',
      'pool',
      'roller-skating',
      'scuba-mask',
      'shopping-bag-check',
      'skateboarding',
      'soccer-field',
      'swimming',
      'target-arrow',
      'tic-tac',
      'treadmill',
      'trekking',
      'walk',
      'waterpolo'
    ]
  },
  {
    name: 'pt',
    display: 'places & transportation',
    icons: [
      'building',
      'building-castle',
      'camper',
      'campfire',
      'car',
      'chalkboard',
      'charging-pile',
      'crane',
      'home',
      'hospital',
      'kayak',
      'library',
      'map',
      'motorbike',
      'plane',
      'rocket',
      'school',
      'ship',
      'submarine',
      'tent',
      'train',
      'truck'
    ]
  }
]

const props = defineProps({
  iconImg: {
    type: String,
    default: ''
  },
  iconSelected: {
    type: Function,
    default: () => {}
  },
  modalType: {
    type: String,
    default: 'icons'
  }
})

const selectedIcon = (icon) => {
  props.iconSelected(icon)
}

const getIconList = (types = 'icons') => {
  if (types === 'awards') return awardLibrary
  return iconLibrary
}
</script>

<template>
  <div class="library-icon">
    <div v-for="item in getIconList(modalType)"
         :key="item.name"
         class="library-icon-categories">
      <h5 class="library-icon-categories-text">
        {{item.display}}
      </h5>
      <span class="library-icon-categories-button-container">
        <base-button v-for="icon in item.icons"
                     :key="icon"
                     btn-type="text"
                     class-name="library-icon-categories-button"
                     :functions="() => selectedIcon(`${item.name}-${icon}`)"
                     :text="icon">
          <template #contents>
            <span class="library-icon-categories-border"
                  :class="{
              'library-icon-categories-select' : iconImg === `${item.name}-${icon}`,
              'library-icon-categories-noSelect' : iconImg !== `${item.name}-${icon}`}
            ">
              <icons :name="`${item.name}-${icon}`"
                     size="xl"/>
            </span>
          </template>
        </base-button>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.library-icon {
  @apply w-full py-1;
  &-categories {
    @apply flex flex-col w-full;
    &-text {
      @apply text-p-sm text-blacks-500 uppercase font-bold pt-6;
    }
    &-button {
      @apply  mr-6 pt-6;
      &-container {
        @apply flex flex-row w-full flex-wrap;
      }
      &-active {
        @apply text-blacks-500;
      }
      &-inActive {
        @apply text-product-500;
      }
    }
    &-border {
      @apply border-2 rounded-md;
    }
    &-select {
      @apply border-product-500 ;
    }
    &-noSelect {
      @apply border-transparent;
    }
  }
}
</style>
