<script setup>
// Router
import { useRouter } from 'vue-router'
// Custom Layout
import ErrorLayout from '@/pages/NotFound/ErrorLayout'
// Custom Components
import BaseButton from '@/components/Base/Button/BaseButton'
// Router
const router = useRouter()
// Redirect Home
const goHome = () => {
  router.push('/')
}
</script>

<template>
  <error-layout>
    <template #error-logo>
      <img src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/selfserve%2Fimages%2Ferror.png?alt=media&token=edbfc208-80e1-4dd3-ac0d-b8a29d7b2c92"
           alt="pageError"
           class="error-page-image"/>
    </template>
    <template #error-header>
      Oops! It looks like you don't have access to this Organization.
    </template>
    <template #error-content>
      Please email our <a class="text-product-400" href="mailto:projectboardplus@projectboard.zohodesk.com">support</a> for further questions..
    </template>
    <template #error-button>
      <base-button id="errorPageButton"
                   name="errorPageButton"
                   aria-label="error page button"
                   class-name="error-page-button"
                   :functions="goHome"
                   text="Back To Home"/>
    </template>
  </error-layout>
</template>

<style lang="scss" scoped>
.error-page{
  &-image {
    @apply pb-9;
  }
  &-button {
    @apply capitalize text-h5 font-medium py-2 px-7;
  }
}
</style>
