import FileService from '@/services/file'
import { processStatuses, urlRegex } from "@/utils"
import OrganizationService from "@/services/organization"

export default {
  state: () => ({
    teamSettings: null,
    teamFormValidationError: {},
    teamData: {},
    teamDataStatus: processStatuses.IDLE,
    teamDataError: null,
    fileStatus: processStatuses.IDLE

  }),
  getters: {
    teamLogo: state => state.teamSettings ? state.teamSettings.teamLogo : "",
    teamName: state => state.teamSettings ? state.teamSettings.teamName : "",
    primaryEmail: state => state.teamSettings ? state.teamSettings.primaryEmail : "",
    teamWebsite: state => state.teamSettings ? state.teamSettings.teamWebsite : "",
    teamFormValidationError: state => state.teamFormValidationError,
    teamData: state => state.teamData ? state.teamData : "",
    teamDataStatus: state => state.teamDataStatus,
    teamLogoUploadStatus: state => state.fileStatus
  },
  mutations: {
    TEAM_VALIDATE_ERROR (state, err) {
      state.teamFormValidationError[err.key] = err.errMsg
    },
    TEAM_VALIDATE_RESET (state) {
      state.teamFormValidationError = {}
    },
    SET_FILE (state, data) {
      state.teamLogoFile = data
    },
    FILE_UPLOADING (state) {
      state.fileStatus = processStatuses.SAVING
    },
    FILE_UPLOADED (state) {
      state.fileStatus = processStatuses.LOADED
    },
    FILE_UPLOAD_ERROR (state, error) {
      state.teamFormValidationError = error
    },
    TEAM_DATA_SAVING (state) {
      state.teamDataStatus = processStatuses.SAVING
    },
    TEAM_DATA_LOADED (state, data) {
      state.teamDataStatus = processStatuses.LOADED
      state.teamFormValidationError = {}
      state.teamData = data
    },
    TEAM_DATA_ERROR (state, error) {
      state.teamSettingsError = error
      state.teamDataStatus = processStatuses.ERROR
    },
    RESET_TEAM_VALIDATE_ERROR (state) {
      state.teamFormValidationError = {}
    }
  },
  actions: {
    validateTeamSettings({commit}, {email, teamLogo, teamName, url }) {
      commit('TEAM_VALIDATE_RESET')
      let emailstatus, teamLogoStatus, teamNameStatus, urlStatus = false
      return new Promise((resolve, reject) => {
        if (!teamName) {
          const payload = {'key': 'teamName', 'errMsg': 'Please enter a team name'}
          commit('TEAM_VALIDATE_ERROR', payload)
          reject()
        } else {
          teamNameStatus = true
        }
        if (!email) {
          const payload = {'key': 'email', 'errMsg': 'Please enter a email'}
          commit('TEAM_VALIDATE_ERROR', payload)
          reject()
        } else if (email.indexOf('@') < 1 || email.lastIndexOf('.') < email.indexOf('@') + 2 || email.lastIndexOf('.') + 2 >= email.length) {
          const payload = {'key': 'email', 'errMsg': 'Please enter a valid email'}
          commit('TEAM_VALIDATE_ERROR', payload)
          reject()
        } else {
          emailstatus = true
        }
        if (!teamLogo) {
          const payload = {'key': 'teamLogo', 'errMsg': 'Please select a logo'}
          commit('TEAM_VALIDATE_ERROR', payload)
          reject()
        } else {
          if (typeof teamLogo === 'object' && teamLogo !== null) {
            teamLogoStatus = true
          }
        }
        if (url && !url.match(urlRegex)) {
          const payload = {'key': 'url', 'errMsg': 'Please enter a valid website Link'}
          commit('TEAM_VALIDATE_ERROR', payload)
          reject()
        } else {
          urlStatus = true
        }
        if (emailstatus && teamLogoStatus && teamNameStatus && urlStatus) {
          commit('FILE_UPLOADING')
          FileService.upload({ file: teamLogo }).then((response) => {
            commit('FILE_UPLOADED')
            if (response.status === 200) {
              teamLogo = response.data.link
              resolve(teamLogo)
            }
          }).catch(error => {
            commit('FILE_UPLOAD_ERROR', error)
          })
        } else {
          // when user hit on submit and edit without updating the logo URL
          resolve(teamLogo)
        }
      })
    },
    updateTeamSettings({commit}, {email, teamLogo, teamName, organization }) {
      commit('TEAM_DATA_SAVING')
      return new Promise((resolve, reject) => {
        OrganizationService.updateOrganization({
          organizationId: organization.id,
          slug: organization.slug,
          logo: teamLogo,
          organizationName: teamName,
          email: email
        }).then(res => {
          commit('TEAM_DATA_LOADED', res.data.organization)
          commit('CURRENT_ORGANIZATION', res.data.organization, { root: true })
          commit('USER_ORGANIZATION_UPDATED', res.data.organization)
          resolve(res)
        }).catch(error => {
          const payload = { 'key': 'teamError', 'errMsg': error }
          commit('TEAM_VALIDATE_ERROR', payload)
          commit('TEAM_DATA_ERROR', error)
          reject(error)
        })
      })
    },
    resetTeamSettingsError({commit}) {
      commit('RESET_TEAM_VALIDATE_ERROR', null)
    }
  }
}
