<script setup>
import { ref, onMounted } from 'vue'
import { vOnClickOutside } from '@vueuse/components'

const props = defineProps({
  id: String,
  ariaLabel: String,
  disabled: {
    type: Boolean,
    default: false
  },
  name: String,
  tabIndex: {
    type: Number,
    default: 0
  },
  options: {
    type: Array,
    required: true
  },
  className: String,
  functions: Function
})

const emit = defineEmits(['update:modelValue'])

const dropdownMenu = ref(false)
const selectedItem = ref('')

const toggleDropdownMenu = () => {
  dropdownMenu.value = !dropdownMenu.value
}

const selectItem = (item) => {
  selectedItem.value = item
  emit('update:modelValue', item)
}

const closeMenu = () => {
  dropdownMenu.value = false
}

onMounted(() => {
  if (props.options && props.options.length > 1) {
    selectItem(props.options[0])
  } else {
    selectItem('Not Available')
  }
})
</script>

<template>
  <div v-on-click-outside="closeMenu"
       class="dropdown">
    <button :id="id"
            :disabled="disabled"
            class="dropdown-btn"
            type="button"
            @click.prevent="toggleDropdownMenu">
      {{ selectedItem.name }}
      <svg class="w-4 h-4"
           aria-hidden="true"
           fill="none"
           stroke="currentColor"
           viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7" />
      </svg>
    </button>
    <!-- Dropdown menu -->
    <div id="dropdownHover"
         class="dropdown-menu-container"
         :class="{'hidden' : !dropdownMenu}">
      <ul class="dropdown-menu"
          aria-labelledby="dropdownHoverButton">
        <slot name="dropmenu-items">
          <li v-for="option in options.filter((option) => {
            return option.name !== selectedItem.name
          })"
              :key="option.name"
              class="dropdown-menu-item"
              @click="() => {
                selectItem(option)
                closeMenu()
                functions()
              }">
            {{option.name}}
          </li>
        </slot>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  @apply relative min-w-[11rem];
  &-btn {
    @apply relative text-blacks-500 font-medium rounded-lg text-p-sm px-4 py-2.5 text-center inline-flex justify-between items-center w-full;
    @apply border border-greys-100;
    &:enabled {
      &:hover {
        @apply border-primary;
      }
      &:focus {
        @apply border-primary;
      }
    }
    &:disabled {
      @apply cursor-not-allowed;
    }
  }
  &-menu {
    @apply py-3 text-sm text-blacks-300;
    &-container {
      @apply absolute z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 mt-1 border-greys-400;
    }
    &-item {
      @apply block px-4 py-2 cursor-pointer;
      &:hover {
        @apply bg-gray-100 text-blacks-500;
      }
    }
  }
}
</style>
