<script setup>
// Required Components
import { computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'
// Store
import store from '@/store'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'
import EbTable from '@/components/Base/Table/EventBuilder'

const headers = [
  {
    text: 'Name', value: "name", sortable: true
  },
  {
    text: 'Icon', value: "image", sortable: false
  }
]

const categoriesErrorList = computed(() => {
  return store.getters.categoriesErrorList
})

const items = computed(() => {
  return store.getters.categories
})

const categoryReadOnly = computed(() => {
  return store.getters.isCategoriesPageReadOnly
})

const updateCategoryName = (id, e) => {
  store.dispatch('updateEventCategory', { id: id, field: 'name', value: e.target.value })
}

const toggleIconsModal = (id) => {
  store.dispatch('ToggleModalIconsModal', {
    confirmAction: (imageOrIcon) => {
      store.dispatch('updateEventCategory', { id: id, field: 'image', value: imageOrIcon })
    }
  })
}

const addCategories = () => {
  const payload = {
    id: uuidv4(),
    name: '',
    image: '',
    color: store.getters.eventSettings?.primaryColor ?? '#5C00A8'
  }
  store.dispatch('addEventCategory', payload)
}

const deleteCategories = (id) => {
  store.dispatch('removeEventCategory', id)
}

const sort = (filter = 'name') => {
  store.dispatch('setFilterType', filter)
  store.dispatch('toggleDesc')
}
</script>

<template>
  <div class="category-add">
    <base-button id="addCategoryButton"
                 name="addCategoryButton"
                 class-name="category-button category-button-category"
                 :functions="addCategories"
                 :disabled="categoryReadOnly"
                 text="Add A Category"/>
  </div>
  <div class="overflow-x-auto">
    <eb-table :items="items"
              :headers="headers"
              :has-color="true"
              :read-only="categoryReadOnly"
              :delete-row="deleteCategories"
              :toggle-icons-modal="toggleIconsModal"
              :update-row-name="updateCategoryName"
              :error-list="categoriesErrorList"
              :sort-function="sort" />
  </div>
</template>

<style lang="scss" scoped>
.category{
  // Category Add
  &-add {
    @apply pt-10;
  }
  &-button {
    @apply w-auto text-blacks-500 min-w-[2.5rem];
    &-category {
      @apply capitalize px-4 py-2 text-p-md text-white;
    }
  }
}
</style>
