import SubscriptionService from "@/services/subscription"
import { eventPlans, processStatuses } from "@/utils"

export default {
  state: () => ({
    subscription_plans: [],
    status: processStatuses.IDLE,
    error: null,
    subscription_payments: [],
    top_subscription_name: 'free',
    top_subscription_events: 0,
    subscription_name_event_map: null
  }),
  getters: {
    subscriptionPlans: state => state.subscription_plans,
    subscriptionPlansStatus: state => state.status,
    subscriptionPayments: state => state.subscription_payments,
    topSubscriptionName: state => state.top_subscription_name,
    topSubscriptionEvents: state => state.top_subscription_events,
    getSubscriptionByEventId: state => eventId => {
        return state.status === processStatuses.LOADED ? state.subscription_plans.find(plan => plan.eventId === eventId) : null
    },
    getNumberOfSubscriptionsByPlanName: state => planName => { 
      if (planName in state.subscription_name_event_map) {
        return state.subscription_name_event_map[planName].length
      }
      return 0
    }
  },
  mutations: {
    SUBSCRIPTION_LOADING (state) {
      state.status = processStatuses.LOADING
    },
    SUBSCRIPTION_LOADED (state, payload) {
      state.status = processStatuses.LOADED
      state.subscription_plans = payload

      // Create a map of eventIds to plan names
      state.subscription_name_event_map = payload.reduce((acc, subscriptionDetail) => {
        if (subscriptionDetail.plan.name in acc) {
          acc[subscriptionDetail.plan.name].push(subscriptionDetail.eventId)
        } else {
          acc[subscriptionDetail.plan.name] = [subscriptionDetail.eventId]
        }
        return acc
      }, {})
    },
    SUBSCRIPTION_ERROR (state, error) {
      state.status = processStatuses.ERROR
      state.error = error
    },
    SET_TOP_SUBSCRIPTION_NAME ( state, plan) {
      state.top_subscription_name = plan
    },
    SET_TOP_SUBSCRIPTION_EVENTS ( state, events) {
      state.top_subscription_events = events
    }
  },
  actions: {
    fetchSubscriptions ({ commit, getters, rootGetters }) {
      // Dependent on organizationID
      if (rootGetters.userStatus !== processStatuses.LOADED) {
        console.warn('User not loaded yet, skip fetching subscriptions')
        return
      }

      if (getters.subscriptionPlansStatus !== processStatuses.LOADING) {
        commit('SUBSCRIPTION_LOADING')
        return new Promise((resolve, reject) => {
          SubscriptionService.getSubscriptions({ organizationId: rootGetters.getActiveOrganization?.id, include: "plan" }).then((response) => {
            commit('SUBSCRIPTION_LOADED', response.data.planSubscriptionDetails)
            resolve()
          }).catch((err) => {
            commit('SUBSCRIPTION_ERROR', err.data.message)
            reject(err)
          })
        })
      }
    },
    setTopSubscriptionName ({ commit, getters }) {
      if (getters.getRemainingEvents(eventPlans.PROFESSIONAL) > 0) {
        commit('SET_TOP_SUBSCRIPTION_NAME', eventPlans.PROFESSIONAL)
      } else if (getters.getRemainingEvents(eventPlans.ESSENTIALS) > 0) {
        commit('SET_TOP_SUBSCRIPTION_NAME', eventPlans.ESSENTIALS)
      } else if (getters.getRemainingEvents(eventPlans.STARTER) > 0) {
        commit('SET_TOP_SUBSCRIPTION_NAME', eventPlans.STARTER)
      } else {
        commit('SET_TOP_SUBSCRIPTION_NAME', eventPlans.FREE)
      }
    },
    setTopSubscriptionEvents ({ commit }, events) {
      commit('SET_TOP_SUBSCRIPTION_EVENTS', events)
    }
  }
}
