<script setup>
// Store
import store from '@/store'
// Base Component
import { pbButton } from 'pb-components'
import { eventPlans } from '@/utils'

const props = defineProps({
  subscriptionName: {
    type: String,
    required: true
  }
})

const startEvent = (subscription) => {
  if (subscription === 'free') {
    store.dispatch('createEventType', subscription)
    store.dispatch('ToggleModalEvent')
    store.dispatch('ResetEventModal')
    store.dispatch('GetEvents')
  } else {
    store.dispatch('ChangeModalType', 'eventCreate')
    store.dispatch('ToggleModal')
  }
}

const toggleUpgrade = () => {
  if (props?.subscriptionName !== eventPlans.PROFESSIONAL) {
    store.dispatch('ToggleModalUpgradePayment', { currentPlan: props?.subscriptionName } )
  }
}
</script>

<template>
  <div class="creation">
    <img class="creation-logo"
         src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/selfserve%2Fimages%2Fno_events.png?alt=media&token=5543f8dd-7bee-4653-ba17-bb293f775dd6"
         alt="event_logo">
    <h2 class="creation-text">
      Create Your First Showcase
    </h2>
    <p class="creation-subText">
      <span v-if="subscriptionName === eventPlans.FREE">
        Start creating your first showcase and learn more about the creation process.
      </span>
      <span v-if="subscriptionName !== eventPlans.FREE">
        You've unlocked <span class="creation-subText-strong">The {{subscriptionName}} Plan</span> features.
      </span>
    </p>
    <span class="creation-btn-container">
      <pb-button id="startEventButton"
                   name="startEventButton"
                   class-name="creation-btn"
                   :functions="() => startEvent(subscriptionName)"
                   text="start now"/>
      <pb-button id="upgradeEventPlan"
                   btn-type="secondary"
                   class-name="creation-btn-secondary"
                   name="upgradeEventPlan"
                   :disabled="subscriptionName === eventPlans.PROFESSIONAL"
                   :functions="toggleUpgrade"
                   text="upgrade plan"/>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.creation {
  @apply flex flex-col justify-center items-center py-20 border rounded-2xl mt-10 md:mx-3 bg-[#fbfbfb];
  &-logo {
    @apply h-36 w-auto;
  }
  &-text {
    @apply py-4 text-center text-h2 font-medium capitalize;
  }
  &-subText {
    @apply text-center md:max-w-[25rem] text-p-xl;
    &-strong {
      @apply font-medium capitalize;
    }
  }
  &-btn {
    @apply border bg-product-500 text-white capitalize py-3 px-8 text-p-md font-medium my-4;
    &-container {
      @apply flex flex-col w-full items-center justify-center;
    }
    &-secondary {
      @apply px-4 py-2 capitalize;
    }
  }
}
</style>
