<script setup>
import { computed, ref, reactive, watch, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { pbAdminCard, pbButton } from 'pb-components'
import { useRouter } from 'vue-router'
import store from '@/store';
import BaseButton from '@/components/Base/Button/BaseButton.vue';

const route = useRoute()

const isPreview = computed(() => {
  return !!route.meta?.participantPanel
})

const isProject = computed(() => {
  return !!route.meta?.adminPanel
})

const adminPanelItems = reactive({
  previewParticipant: {
    title: 'Preview Project As Participant',
    name: 'preview',
    hasDropdown: false,
    path: '',
    type: 'participant'
  },
  previewPublic: {
    title: 'Preview Project As Public',
    name: 'preview',
    hasDropdown: false,
    path: '',
    type: 'public'
  },
  plagiarismReport: {
    title: 'Plagiarism Report',
    button: 'upgrade',
    disabled: true,
    hasDropdown: true,
    function: () => {}
  },
})

const participantItems = reactive({
  previewPublic: {
    title: 'Preview Project As Public',
    name: 'preview',
    hasDropdown: false,
    path: '',
    type: 'public'
  },
  projectStatus: {
    title: 'Project Status',
    hasDropdown: true,
    button: 'custom',
    customMessage: '',
    buttonCustomText: 'Draft',
    buttonCustomClass: ''
  },
  plagiarismScan: {
    title: 'Plagiarism Scan Notice',
    button: 'custom',
    customMessage: "This project may be scanned plagiarism when submitted it, so please ensure it's your origin work",
    buttonCustomText: 'View Our Policy',
    hasDropdown: true,
    function: () => {}
  },
})

const mockStudents = reactive({
  student1: {
    name: 'Vince Carmen',
    handle: '@vincecarmen19',
    avatar: 'https://i.pravatar.cc/300',
    content: 'Grade 10 Student from Toronto. Interested in all things Space, Science and Minecraft.'
  },
  student2: {
    name: 'Carmen Vince',
    handle: '@vincecarmen21',
    avatar: `https://i.pravatar.cc/300?img=${Math.random()},`,
    content: 'Grade 10 student with a passion to help solve problems. I also enjoy playing shuffleboard on the weekends with my uncle Bert.'
  }
})

const router = useRouter()

const adminPanelClose = ref(false)
const screenWidth = ref(window.innerWidth)

const isPublic = computed(() => {
  return store.getters.adminPanel === 'public'
})

const eventId = computed(() => {
  return store.getters.ebEventId
})

const eventSlug = computed(() => {
  return store.getters.ebEventSlug
})

const projectId = computed(() => {
  return store.getters.projectID
})

const eventSubscriptionDetail = computed(() => {
  // Get current event subscription plan
  // In Event Builder page event subscription details will be loaded
  let subscriptionPlan = store.getters.getEventSubscriptionDetails
  if (!subscriptionPlan || !subscriptionPlan.id) {
    // In Events listing page, we need to get the subscription plan from Plans store
    subscriptionPlan = store.getters.getSubscriptionByEventId(eventId.value)
  }

  return subscriptionPlan
})

const toggleAdminPanel = () => {
  adminPanelClose.value = !adminPanelClose.value
}

const toggleUpgrade = () => {
  if (store.getters.hasRemainingPaidEvents(eventSubscriptionDetail.value?.plan?.name)) {
    store.dispatch('ToggleModalUpgradeEvent', { eventId: store.getters.ebEventId })
  } else {
    // If we are upgrading from Basic to Premium, give 250 discount
    let discount = null
    if (eventSubscriptionDetail.value?.plan?.name === 'basic') {
      discount = {
        name: 'premium',
        amount: -250
      }
    }
    store.dispatch('ToggleModalUpgradePayment', {
      eventId: store.getters.ebEventId,
      plansToSkip: [ eventSubscriptionDetail.value?.plan?.name ],
      discount: discount
    })
  }
}

const getScreenWidth = () => {
  screenWidth.value = window.innerWidth
}

const getPreview = () => {
  const params = new URLSearchParams(window?.location?.search)
  if (params) return params.get('previewAdmin')
  return false
}

const getPath = (slug, projectId, type) => {
  if (slug?.length > 0 && projectId?.length > 0 && type?.length > 0) {
    return router.resolve({ name: 'Preview', params: { slug: slug, projectId: projectId }, query: { type: type } }).href
  }
  return ''
}

const getAdminItem = () => {
  if (isPreview.value) return participantItems
  return adminPanelItems
}

onMounted(() => {
  adminPanelItems.plagiarismReport.function = toggleUpgrade
  adminPanelItems.plagiarismReport.hasPreview = getPreview()

  participantItems.projectStatus.buttonCustomClass = 'adminPanel-status'
  window.addEventListener('resize', getScreenWidth)
})

watch(() => {
  return screenWidth.value
}, (newVal) => {
  adminPanelClose.value = newVal < 1580;
})

onUnmounted(() => {
  window.removeEventListener('resize', getScreenWidth)
})
</script>

<template>
  <div class="adminPanel"
       :class="{
        'adminPanel-menu-close' : adminPanelClose,
        'adminPanel-menu-open' : !adminPanelClose,
        'adminPanel-base' : isProject,
        'adminPanel-preview' : isPreview,
        'hidden': (!isPreview && !isProject) || isPublic
        }">
    <div v-if="adminPanelClose"
         class="adminPanel-mobile">
      <div class="adminPanel-menu-header-icon">
        <i class="fa-light fa-circle-info fa-xl"></i>
      </div>
    </div>
    <div v-if="!adminPanelClose"
         class="adminPanel-menu">
      <div class="adminPanel-menu-header">
        <div class="adminPanel-menu-header-icon adminPanel-menu-header-icon-menu">
          <i class="fa-light fa-circle-info fa-xl"></i>
        </div>
        <div>
          <h2 class="adminPanel-menu-header-title">
            {{ isPreview ? 'Participant ' : 'Admin ' }}
             Panel
          </h2>
          <p class="adminPanel-menu-header-subTitle">
            Only {{ isPreview ? 'teammates ' : 'admin ' }} can see this.
          </p>
        </div>
      </div>

      <pb-admin-card v-for="(value, key) in getAdminItem()"
                     :key="key"
                     :path="getPath(eventSlug, projectId, value.type)"
                     :has-dropdown="value.hasDropdown"
                     :button-custom-text="value.buttonCustomText"
                     :button-custom-class="value.buttonCustomClass"
                     :custom-message="value.customMessage"
                     :button-disabled="value.disabled"
                     :card-data="value"
                     :button-function="value.function"
                     :has-preview="value.hasPreview"
                     :button-type="value.button" />

      <pb-admin-card v-if="isPreview && !isPublic"
                     :has-dropdown="true">
        <template #admin-dropdown-header-title>
          Team Members
        </template>
        <template #admin-dropdown-content>
          <ul class="adminPanel-student-list">
            <li v-for="(value, key) in mockStudents"
                :key="key"
                class="adminPanel-student">
              <img :src="value.avatar"
                   :alt="value.name"
                   class="adminPanel-student-avatar" />
              <h1 class="adminPanel-student-name">
                {{ value.name }}
              </h1>
              <h6 class="adminPanel-student-handle">
                {{ value.handle }}
              </h6>
              <p class="adminPanel-student-content">
                {{ value.content }}
              </p>
              <base-button functions=""
                           :disabled="true"
                           btn-type="secondary"
                           text="View Profile" />
            </li>
          </ul>
        </template>
      </pb-admin-card>
    </div>
    <pb-button :functions="toggleAdminPanel"
               text=""
               class-name="adminPanel-menu-toggle">
      <template #contents>
        <img src="/images/icons/icon-sidebar.png"
             alt="adminPanel-toggle">
      </template>
    </pb-button>
  </div>
</template>

<style lang="scss">
.adminPanel {
  @apply fixed right-0 h-full bg-white border-2 z-[2] overflow-auto transition-all ease-in-out duration-300;
  &-base {
    @apply top-[7.3rem];
  }
  &-preview {
    @apply top-[3.6rem];
  }
  &-menu {
    @apply py-[58px] pl-[40px];
    &-close {
      @apply w-[5rem]
    }
    &-open {
      @apply w-[361px];
    }
    &-header {
      @apply flex flex-row items-center pb-3;
      &-icon {
        @apply w-[3rem] h-[3rem] bg-product-100 rounded-md flex items-center justify-center;
        &-menu {
          @apply mr-[0.81rem];
        }
      }
      &-title {
        @apply text-h2 font-medium text-blacks-500 font-bold;
      }
      &-subTitle {
        @apply text-p-xl text-blacks-300 pt-1;
      }
    }
    &-toggle {
      @apply bg-white border-2 w-9 h-9 rounded-full fixed -ml-5 top-[40%] #{!important} ;
    }
  }
  &-mobile {
    @apply flex w-full items-center justify-center py-4 pt-[58px];
  }
  &-student {
    @apply w-full h-[340px] shadow bg-white rounded-xl first:mb-11 p-[24px] flex flex-col items-center;
    &-list {
      @apply w-full overflow-auto mt-4 max-h-[46rem] overflow-y-auto;
    }
    &-avatar {
      @apply w-[100px] h-[100px] rounded-full mb-2;
    }
    &-name {
      @apply text-blacks-500 font-medium text-h3;
    }
    &-handle {
      @apply text-blacks-300 text-h6;
    }
    &-content {
      @apply text-p-xs py-3;
    }
  }
  &-status {
    @apply bg-other-blue #{!important};
  }
}
</style>
