<script setup>
import { reactive, computed, onMounted } from 'vue'
import BaseInput from '@/components/Base/Fields/InputField'
import BaseButton from '@/components/Base/Button/BaseButton'
import TextareaField from '@/components/Base/Fields/TextareaField'
import ModalLayout from './Base'
import store from "@/store"
import { handleBackButton } from '@/utils'

const maxCharName = 80
const maxCharDescription = 250

const organization = reactive({
  name: store.getters.newEvent.organizationName ? store.getters.newEvent.organizationName : ''
})

const event = reactive({
  name: store.getters.newEvent ? store.getters.newEvent.eventName : '',
  description: store.getters.newEvent ? store.getters.newEvent.description : ''
})

const hasOrganization = computed(() => {
  return store.getters.newOrganization?.name !== ''
})

const afterRegister = computed(() => {
  return store.getters.afterRegister
})

const eventError = computed(() => {
  return store.getters.newEventError
})

const eventValidateError = (type) => {
  let errMsg = ''
  if(eventError.value != null) {
    Object.keys(eventError.value).filter(item =>{
      if (item === type) {
        errMsg = eventError.value[type]
      }
    })
  }
  return errMsg
}
const checkValidation = (formData) => {
  Object.keys(formData).filter(item => {
    if(!formData[item]) {
      const formItemPayload = {'key': item, 'errMsg': item == "eventName" ? item = "Please enter showcase name": item == "organizationName" ? item =" Please enter organization name": "Please enter " + item}
      store.getters.newEventError[formItemPayload.key] = formItemPayload.errMsg
    }
  })
  if (Object.keys(store.getters.newEventError).length === 0) {
    store.dispatch('setNewOrganization', { organizationName: hasOrganization.value ? store.getters.newOrganization?.name : organization.name })
    store.dispatch('setNewEvent', formData).then(() => {
      store.dispatch('SetEventModal', 'branding')
      store.dispatch('SetProgressBar', 1)
    })
  }
}

const skipStep = () => {
  store.dispatch('ToggleModal')
  store.dispatch('ToggleOverlay')
  store.dispatch('ResetEventModal')
  store.dispatch('ResetModal')
}

const nextStep = () => {
  store.dispatch('resetNewEvent')
  const payload = {
    eventName: event.name,
    description: event.description ,
  }
  if(hasOrganization.value) {
    payload.organizationName = store.getters.newOrganization?.name
  }
  checkValidation(payload)
}

onMounted(() => {
  handleBackButton()
  store.dispatch('ResetEventModal')
})
</script>

<template>
  <modal-layout>
    <template #modal-body>
      <form class="w-full org-form">
        <h3
          v-if="!hasOrganization"
          class="org-form-title"
        >
          Add organization information
        </h3>
        <div
          v-if="!hasOrganization"
          class="org-form-item"
        >
          <base-input v-model="organization.name"
                      :model-value="organization.name"
                      label-text="Organization name*"
                      label-for="organizationName"
                      input-type="text"
                      input-name="organizationName"
                      input-id="organizationName"
                      input-place-holder="Organization name"
                      :errors="eventValidateError('organizationName')"
                      @keydown.enter.prevent/>
        </div>
        <h3 class="org-form-title">
          Add Showcase Information
        </h3>
        <div class="org-form-item">
          <base-input v-model="event.name"
                      :model-value="event.name"
                      label-text="Showcase Name*"
                      label-for="eventName"
                      input-type="text"
                      input-name="eventName"
                      input-id="eventName"
                      input-place-holder="Please enter your showcase name"
                      input-aria-label="showcase name"
                      :input-max-length="maxCharName"
                      :errors="eventValidateError('eventName')"
                      @keydown.enter.prevent/>
        </div>
        <span v-if="event.name && event.name.length" class="w-full flex flex-row justify-end text-xs text-purple-500">
          Characters left: {{maxCharName - event.name.length}}
        </span>
        <div class="org-form-item">
          <textarea-field v-model="event.description"
                          input-id="eventDescription"
                          class-name="org_text"
                          input-name="eventDescription"
                          input-place-holder="Please describe your showcase in a few words"
                          input-aria-label="description"
                          :input-rows="5"
                          :input-resize="true"
                          :max-length="maxCharDescription"
                          label-text="Description*"
                          label-for="eventDescription"
                          :errors="eventValidateError('description')"/>
              <span v-if="event.description && event.description.length"
                    class="org-form-characters">
                Characters left: {{maxCharDescription - event.description.length}}
              </span>
        </div>
      </form>
    </template>
    <template #modal-button>
      <span class="org-buttons">
        <base-button v-if="afterRegister"
                      id="skip"
                      name="skip"
                      class="skipBtn org-buttons-skipBtn"
                      btn-type="text"
                      :functions="skipStep"
                      text="skip" />
        <base-button id="next"
                      name="next"
                      text="next"
                      btn-type="primary"
                      class="nextBtn org-buttons-nextBtn"
                      :functions="nextStep"/>
      </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.org {
  &-form {
    &-title {
      @apply font-bold text-xl py-2 capitalize;
    }
    &-item {
      @apply py-2;
    }
    &-characters {
      @apply w-full flex flex-row justify-end text-xs text-purple-500;
    }
  }
  &-buttons  {
    @apply w-full text-right py-4 px-16 md:px-40;
    &-nextBtn {
      @apply bg-product-400 text-white uppercase rounded-md font-normal px-4 text-p-sm;
    }
    &-skipBtn {
      @apply text-purple uppercase rounded-lg mr-4;
    }
  }
}
</style>
