<script setup>
// Required Components
import { ref } from 'vue'
import { Icons } from 'icons'
// Store
import store from '@/store'
// Utils
import { isFirebaseStorageURL } from '@/utils'
// Base Components
import ColorPicker from '@/components/Base/ColorPicker'
import Avatar from '@/components/Base/Avatar'
import BaseInput from '@/components/Base/Fields/InputField'
import BaseButton from '@/components/Base/Button/BaseButton'

const props = defineProps({
  headers: {
    type: Array,
    required: true
  },
  items: {
    type: Array,
    required: true
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  hasColor: {
    type: Boolean,
    default: false
  },
  updateRowName: {
    type: Function,
    required: false,
    default: () => {}
  },
  toggleIconsModal: {
    type: Function,
    required: false,
    default: () => {}
  },
  deleteRow: {
    type: Function,
    required: false,
    default: () => {}
  },
  invertColor: {
    type: Boolean,
    default: true
  },
  inputPlaceHolder: {
    type: String,
    default: 'New Category'
  },
  sortFunction: {
    type: Function,
    required: true,
    default: () => {}
  },
  fullWidth: {
    type: Boolean,
    default: true
  },
  errorList: {
    type: Array,
    default: () => []
  }
})

const previousColour = ref('')

const setColor = (newColor, id='') => {
  store.dispatch('updateEventCategory', { id: id, field: 'color', value: newColor })
}

const isFile = (file) => {
  if (file === null) {
    return false
  }
  return file instanceof File
}

const convertToObjectUrl = (file) => {
  return URL.createObjectURL(file)
}

const validInput = (id="") => {
  if (props.errorList?.length > 0 && id.length > 0) {
    return props.errorList.filter(error => {
      return error.id === id
    })
  } else {
    return false
  }
}
</script>

<template>
  <table class="table" :class="{ 'w-full': fullWidth }">
    <thead>
    <tr>
      <th v-for="header in headers"
          :key="header.text"
          class="table-headers"
          :class="header.class ?? ''">
        {{header.text}}
        <base-button v-if="header.sortable"
                     :id="`table-${header.value}}`"
                     :name="`table-${header.value}}`"
                     :text="header.value"
                     btn-type="text"
                     class-name="table-sortBtn"
                     :functions="() => sortFunction(header.value)">
          <template #contents>
            <i class="fa-solid fa-arrow-down-arrow-up"></i>
          </template>
        </base-button>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in items"
        :key="item.id"
        class="table-items">
      <td :id="item.name">
        <slot name="content-name" :item="item">
          <base-input v-if="!readOnly"
                      input-id="categoryName"
                      input-name="categoryName"
                      :model-value="item.name"
                      :input-place-holder="inputPlaceHolder"
                      name="categoryName"
                      :errors="validInput(item.id)?.length > 0 ? ' ' : '' "
                      @change="(e) => updateRowName(item.id, e)" />
          <span v-else>{{item.name}}</span>
        </slot>
      </td>
      <td>
        <div class="table-items-container">
          <slot name="content-column" :item="item">
            <div class="flex">
              <base-button :functions="() => toggleIconsModal(item.id)"
                           text=""
                           class-name="table-button"
                           :disabled="readOnly"
                           btn-type="text">
                <template #contents>
                  <i v-if="item.image === ''"
                     class="fa-light fa-circle-plus fa-2x"></i>
                  <avatar v-else
                          :is-large="false"
                          class-name="avatar-medium"
                          :style="{ 'background-color': item.color }">
                    <template #profile>
                      <img v-if="isFirebaseStorageURL(item.image)"
                           :src="item.image"
                           :alt="item.name"
                           :style="{ 'background-color': item.color }"
                           class="w-10 h-10 rounded-full" />
                      <img v-else-if="isFile(item.image)"
                           :alt="item.name"
                           class="w-10 h-10 rounded-full"
                           :style="{ 'background-color': item.color }"
                           :src="convertToObjectUrl(item.image)" />
                      <icons v-else-if="((typeof item.image) === 'string' && item.image?.length > 0)"
                             :name="item.image"
                             :enable-color="true"
                             :invert-color="invertColor"
                             type="cycle"
                             size="xxl"/>
                    </template>
                  </avatar>
                </template>
              </base-button>
              <span v-if="hasColor"
                    class="table-picker">
                <color-picker :id="`colorpicker_${item.id}`"
                              :color="item.color"
                              :previous-colour="previousColour"
                              :disabled="readOnly"
                              :set-color="(color) => setColor(color, item.id)"/>
              </span>
            </div>
          </slot>
          <slot name="action-column" :item="item">
            <base-button :functions="() => deleteRow(item.id)"
                         text="delete icon"
                         :disabled="readOnly"
                         class-name="table-button table-button-delete"
                         btn-type="text">
              <template #contents>
                <i class="fa-regular fa-trash-can fa-lg"></i>
              </template>
            </base-button>
          </slot>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
.table {
  @apply mt-12 mb-44 border-collapse text-sm max-w-[55rem];
  &-sortBtn {
    @apply w-auto text-greys-300;
    &:hover {
      @apply text-blacks-500;
    }
  }
  &-headers {
    @apply text-left text-p-sm text-greys-300 font-normal pb-4 last:px-4 md:last:px-0;
  }
  &-picker {
    @apply pl-20;
  }
  &-items {
    @apply border-b border-t h-[4.5rem];
    &-container {
      @apply md:ml-0 ml-4 flex flex-row w-full justify-between;
    }
  }
  &-button {
    @apply w-auto text-blacks-500 min-w-[2.5rem];
  }
}
</style>
