<script setup>
import ModalBase from "@/components/Modals/ModalBase"
import BaseButton from '@/components/Base/Button/BaseButton'
import store from '@/store'
import { onBeforeUnmount, ref } from 'vue'

const modalAction = ref(null)

const confirmAction = () => {
  modalAction.value = 'confirm'
  store.dispatch('ConfirmAction')
  store.dispatch('setEventDataChanged', false)
}

const cancelAction = () => {
  modalAction.value = 'cancel'
  store.dispatch('CancelAction')
  store.dispatch('setEventDataChanged', true)
}

onBeforeUnmount(() => {
  if (modalAction.value === 'confirm') {
    store.dispatch('setEventDataChanged', false)
  } else {
    store.dispatch('setEventDataChanged', true)
  }
})
</script>

<template>
  <modal-base modal-class="bg-white modal-unsaved" :is-close="true">
    <template #modal-title>
      <div class="modal-unsaved-title">
        <h1 class="modal-unsaved-title-header">Are You Sure You Want To Leave?</h1>
      </div>
    </template>
    <template #modal-content>
      <div class="modal-unsaved-content">
        <p class="modal-unsaved-content-para">Your changes have not been saved and will be lost.</p>
      </div>
    </template>
    <template #modal-button>
      <div class="modal-unsaved-buttons">
          <base-button
            id="go-live"
            name="go-live"
            text="No, Go Back"
            aria-label="go Button"
            class-name="modal-unsaved-buttons-live"
            :functions="cancelAction"/>
          <base-button id="go-cancel"
                       name="go-cancel"
                       class-name="modal-unsaved-buttons-time"
                       btn-type="text"
                       :functions="confirmAction"
                       text="Yes, Continue" />
        </div>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal-unsaved {
  @apply md:h-auto max-w-lg px-5 md:pt-6 md:pb-10 pb-5 rounded-b-none md:rounded-2xl overflow-y-auto;
  &-title {
    &-header {
      @apply md:text-h2 text-h3 mb-3 w-full text-center font-medium;
    }
  }
  &-content {
    &-para {
      @apply max-w-[30rem] text-p-md text-center mx-auto md:leading-6 leading-5 text-blacks-500;
    }
  }
  &-buttons {
    @apply md:flex justify-center px-5 md:flex-row-reverse mt-8;
    &-live {
      @apply md:max-w-[10rem] md:mx-2 capitalize w-full md:mb-0 mb-3 h-11;
    }
    &-time {
      @apply md:max-w-[10rem] md:mx-2 text-product-500 border border-product-500 px-3 py-1.5 rounded-lg capitalize w-full md:mb-0 mb-3 h-11;
    }
  }
}
</style>
