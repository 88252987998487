<script setup>
import { computed, onMounted } from "vue";
import store from "@/store";
import ModalLayout from "@/components/Modals/ModalBase";
import BaseButton from "@/components/Base/Button/BaseButton";
import { handleBackButton } from '@/utils'

const confirmData = computed(() => {
  return store.getters.modalData;
});

const confirmAction = () => {
  store.dispatch("ConfirmAction");
};

const cancelAction = () => {
  store.dispatch("CancelAction");
};

const isDelete = (text) => {
  return text.toLowerCase() === "delete";
};

onMounted(() => {
  handleBackButton()
})
</script>

<template>
  <modal-layout :is-close="true" class="confirm">
    <!-- cancel feedback with textarea section -->
    <template #modal-content>
      <img class="trash" src="https://firebasestorage.googleapis.com/v0/b/test-d9796.appspot.com/o/selfserve%2F04.trash.png?alt=media&token=c3115df4-0c03-4be5-b26d-e51ed44fc1bb"/>
      <h1 class="text-center">Delete Resource</h1>
      <p class="text-center">Are you sure you want to delete this resource?</p>
    </template>

    <!-- Back to Events & Submit Buttons -->
    <template #modal-button>
      <span class="confirm-btn-container">
        <base-button
          :functions="cancelAction"
          :text="confirmData?.data?.cancelButton"
          class-name="confirm-btn confirm-btn-back"
          btn-type="secondary"
        />
        <base-button
          :functions="confirmAction"
          :text="confirmData?.data?.confirmButton"
          :class-name="`confirm-btn ${
            isDelete(confirmData?.data?.confirmButton)
              ? 'confirm-btn-delete'
              : 'confirm-btn-approve'
          }`"
          btn-type="primary"
        />
      </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.confirm {
    @apply bg-white max-w-lg pt-8 px-5;
    h1 {
      @apply text-h4 font-medium mt-5 mb-2;
    }
    &-header {
      @apply flex flex-col w-full justify-center items-center pt-4;
    }
    &-title {
      @apply normal-case font-medium text-h2 text-center;
    }
    &-body {
      @apply flex flex-col justify-center items-center;
    }
    &-content {
      @apply text-blacks-300 text-center px-[3.5rem];
    }
    &-whyCancel {
      @apply mx-[3rem] mt-10;
    }
    &-teamResponseTime {
      @apply mx-[3rem] text-blacks-200;
    }
    &-btn {
      @apply w-24 h-11 mx-2 normal-case;
      &-container {
        @apply w-full flex flex-row justify-center py-8;
      }
      &-back {
        @apply text-primary border-primary font-medium;
        &:hover {
          @apply border-transparent bg-white text-product-500;
        }
      }
      &-decline {
        @apply bg-other-red text-white font-medium uppercase;
        &:hover {
          @apply opacity-80;
        }
      }
      &-approve {
        @apply bg-other-green text-white font-medium;
        &:hover {
          @apply opacity-80;
        }
      }
      &-delete {
        @apply bg-other-red text-white font-medium;
        &:hover {
          @apply opacity-80;
        }
      }
    }
    .trash {
      @apply w-20 mx-auto;
    }
}
</style>
