<script setup>
import { computed } from 'vue'
import store from '@/store'
import VuePdfEmbed from 'vue-pdf-embed'
import { downloadFile, acceptedFileExtensions, getVideoTypeFromUrl } from '@/utils/file-handling'
import BaseButton from '@/components/Base/Button/BaseButton'
import VideoPlayer from '@/components/Video/VideoPlayer'

const previewData = computed(() => {
  return store.getters.modalData.data
})

const isOfficeType = computed(() => {
  return acceptedFileExtensions.acceptedDocumentFilesExtensions.includes(previewData.value.previewType)
})

const close = () => {
  store.dispatch('ResetEventModal')
  store.dispatch('ToggleModal')
  store.dispatch('ToggleOverlay')
  store.dispatch('ResetModal')
}

const isImagePreview = computed(() => {
  return acceptedFileExtensions.acceptedImageFilesExtensions.includes(previewData.value.previewType)
})

const handleDownload = () => {
  downloadFile(previewData.value.resourceUrl, previewData.value.previewType)
}

const resourceVideoMetadata = computed(() => {
  return previewData.value?.metadata ? previewData.value?.metadata : null
})

const videoLink = computed(() => {
  const videoLinks = []
  if (resourceVideoMetadata.value?.hls_url) {
    videoLinks.push({
      src: resourceVideoMetadata.value.hls_url,
      type: 'application/x-mpegURL'
    })
  }

  if (resourceVideoMetadata.value?.dash_url) {
    videoLinks.push({
      src: resourceVideoMetadata.value.dash_url,
      type: 'video/mp4'
    })
  }

  if (resourceVideoMetadata.value?.direct_url) {
    videoLinks.push({
    })
  }

  const videoType = getVideoTypeFromUrl(previewData.value.resourceUrl)
  if (!videoLinks && videoType) {
    videoLinks.push({
      src: previewData.value.resourceUrl,
      type: previewData.value.previewType
    })
  }

  return videoLinks
})
</script>

<template>
  <div class="modal-background" @click="close"></div>
  <div class="modal-content">
    <div v-if="isImagePreview" class="modal-content-inner">
      <img class="modal-content-inner-image" :src="previewData.resourceUrl"/>
    </div>
    <div v-else-if="previewData.previewType === 'pdf'" class="modal-content-inner">
      <vue-pdf-embed class="rounded-xl" :source="previewData.resourceUrl" width="1000"/>
    </div>
    <div v-else-if="previewData.previewType === 'mp4'" class="modal-content-inner">
      <video-player class="video-container"
                    :autoplay="false"
                    :control="true"
                    :sources="videoLink">
      </video-player>
    </div>
    <div v-else-if=" previewData.previewType === 'youtube' || previewData.previewType === 'googledoc'" class="modal-content-inner">
      <iframe class="rounded-xl" :src="previewData.resourceUrl" width="100%" height="100%"></iframe>
    </div>
    <div v-else class="modal-content-inner">
      <div class="w-full center text-h1 text-background-400">
        <p>No preview available.</p>
        <span class="text-h3 text-background-400 p-1">To download the resource, simply click on the <span v-if="isOfficeType || previewData.previewType === 'avi'">"Download"</span> <span v-else>"View"</span> button</span>
        <div class="modal-content-inner-buttons">
          <base-button
              v-if="(previewData.previewType !== 'youtube' && previewData.previewType !== 'other') || isOfficeType"
              :functions="handleDownload"
              class="modal-content-inner-buttons-download"
              btn-type="primary"
              text="Download"></base-button>
          <base-button
              class="modal-content-inner-buttons-close"
              :functions="close"
              btn-type="secondary"
              text="Go back"></base-button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-buttons">
    <a v-if="(previewData.previewType !== 'youtube' && previewData.previewType !== 'googledoc' && previewData.previewType !== 'other') || isOfficeType" class="modal-buttons-download" href="#" @click="handleDownload">
      <i class="fa fa-download modal-buttons-download-icon" aria-label="download"></i> Download
    </a>
    <a v-else class="modal-buttons-download" :href="previewData.resourceUrl" target="_blank">
      <i class="fa fa-external-link modal-buttons-download-icon" aria-label="view"></i> View
    </a>
    <button class="modal-buttons-close" @click="close"><span class="icon"><i class="fal fa-times" aria-label="close"></i></span></button>
  </div>
</template>

<style lang="scss" scoped>
.vue {
  &-pdf {
    &-embed {
      @apply h-full overflow-y-auto mx-auto;
      :deep canvas {
        @apply h-full w-full mx-auto;
      }
    }
  }
}
.modal {
  @apply fixed inset-0 overflow-hidden flex-col	justify-center items-center translate-x-0 translate-y-0;
  &-content {
    @apply items-center inset-x-5 top-20 bottom-5 text-center w-full h-full pb-[1rem];
    &-inner {
      @apply flex items-center inset-x-0 md:p-20 top-0 bottom-0 w-auto text-center h-full;
      &-image {
        @apply max-h-full w-auto max-w-full block mx-auto rounded-xl p-5;
      }
      &-buttons {
        @apply w-full flex flex-row justify-center mt-4;
        &-download {
          @apply md:w-[12rem] h-[3rem] mx-2 flex items-center text-p-xl;
        }
        &-close {
          @apply md:w-[12rem] h-[3rem] mx-2 text-p-xl bg-white/[0.8];
        }
      }
    }
  }
  &-buttons {
    @apply fixed right-5 top-5 flex text-white text-p-md justify-end z-10;
    &-download {
      @apply inline-block mx-10 flex items-center text-p-xl;
      &-icon {
        @apply mr-3 text-h3 bg-blacks-500 rounded-full p-2 w-10 h-10 flex justify-center items-center;
        &:hover {
          @apply bg-product-400
        }
      }
    }
    &-close {
      @apply text-h1;
    }
  }
}
.video-container {
  @apply w-[100%] h-[100%];
}
</style>
