<template>
  <modal-base modal-class="bg-white">
    <template #modal-content>
      <span class="video">
        <iframe class="video_player"
              :src="video"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
              allowfullscreen />
      </span>
    </template>
  </modal-base>
</template>

<script setup>
import { computed } from 'vue'
import ModalBase from '@/components/Modals/ModalBase'

const video = computed(() => {
  return 'https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2FThe%20engineering.com%20Mobile%20App.mp4?alt=media&token=aa124f10-19ea-40e4-b56e-d0cabf5db7c6'
})
</script>

<style lang="scss" scoped>
.video {
  @apply h-[20rem] md:h-[30rem];
  &_player {
    @apply w-full h-full border-transparent;
  }
}
</style>
