<script setup>
import { computed, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
// Custom packages
import store from "@/store"
// Base Component
import BaseButton from '@/components/Base/Button/BaseButton'
// Custom components
import EventPageBase from '@/pages/EventBuilder/EventPageBase'
import BrandingContent from '@/components/Branding/BrandingContent'
// Utils
import { processStatuses } from '@/utils'

// Router
const router = useRouter()

const eventSettings = computed(() => {
  return store.getters.eventSettings
})

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const next = () => {
  router.push({ name: 'ProjectTemplates', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

const prev = () => {
  router.push({ name: 'General', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

// Save function
const nextStep = async () => {
  // Validation
  // If custom banner is selected and no image is uploaded show error
  if (store.getters.getSelectedBannerID === 'upload' && !store.getters.getCustomBanner?.backgroundImage) {
    await store.dispatch('triggerToast',{ message: 'Please upload a banner image.', type: 'error'})
    return
  }

  try {
    await store.dispatch('loadingEventSetting')
    const payload = {
      id: eventSettings.value.id,
      primaryColor: eventSettings.value.primaryColor,
      secondaryColor: eventSettings.value.secondaryColor,
      image: eventSettings.value.image
    }
    if (typeof eventSettings.value.image === 'object' && eventSettings.value.image !== null) {
      payload.image = eventSettings.value.image
      await store.dispatch('setBrandingColorAndLogo', payload)
    } else {
      await store.dispatch('setBrandingColorAndLogo', payload)
    }

    // Update Banner selection
    // If banner set is not available, create new banner set
    if (store.getters.getBannerSetStatus === processStatuses.LOADED && !store.getters.getBannerSet?.id) {
      if (typeof store.getters.getSelectedBannerID === 'number') {
        await store.dispatch('createBannerSets')
      } else if (store.getters.getSelectedBannerID === 'upload') {
        // Upload the selected custom banner
        await store.dispatch('uploadCustomBannerImage')
        // Create new banner
        await store.dispatch('createCustomBanner')
        // Create new banner set
        await store.dispatch('createBannerSets')
      }
    } else if (typeof store.getters.getSelectedBannerID === 'number') {
      await store.dispatch('updateBannerSets')
    } else if (store.getters.getSelectedBannerID === 'default') {
      await store.dispatch('deleteBannersInSets')
    } else if (store.getters.getSelectedBannerID === 'upload') {
      // Upload the selected custom banner
      await store.dispatch('uploadCustomBannerImage')
      if (typeof store.getters.getCustomBanner?.id === 'number') {
        await store.dispatch('updateCustomBanner')
      } else {
        await store.dispatch('createCustomBanner')
      }
      await store.dispatch('updateBannerSets')
    }
    await store.dispatch('triggerToast',{ message: 'Your changes have been successfully saved.', type: 'success'})
  } catch (error) {
    console.error(error)
    await store.dispatch('triggerToast',{ message: 'Fail to save your changes, please refresh the page and try again later.', type: 'error'})
  }
}

onMounted(() => {
  store.dispatch('resetFileUpload')
})

onUnmounted(() => {
  store.dispatch('resetFileUpload')
})
</script>

<template>
  <event-page-base header-class="md:max-w-[47rem]">
    <template #title>
      Design & Branding
    </template>
    <template #description>
      This where you add your logo and colors. The banner is the first thing attendees will see when they land on your event page.
    </template>
    <template #content>
      <branding-content id="1"
                        :position="1"
                        :next-step="nextStep"/>
    </template>
    <template #back-button>
      <base-button
          id="bottom-nav-prev"
          name="bottom-nav-prev"
          class-name="bottom-nav-button"
          btn-type="text"
          text="back"
          :functions="prev">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-left" />
            </span>
            Back
          </span>
        </template>
      </base-button>
    </template>
    <template #next-button>
      <base-button
          id="bottom-nav-next"
          name="bottom-nav-next"
          class-name="bottom-nav-button"
          btn-type="text"
          :functions="next"
          text="next">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-right" />
            </span>
            Next
          </span>
        </template>
      </base-button>
    </template>
    <template #save-button>
      <base-button id="bottom-nav-confirm"
                   name="bottom-nav-confirm"
                   class-name="branding-button"
                   :functions="nextStep"
                   text="update"/>
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.branding {
  &-button {
    @apply capitalize px-6 py-2 mt-1;
  }
}
</style>
