<script setup>
// Card Slot
import CardSlot from '@/components/Base/Cards/CardSlot'
// Base Component
import BaseButton from '@/components/Base/Button/BaseButton'
import { computed } from 'vue'
import store from '@/store'
// Props
defineProps({
  item: {
    type: Object,
    required: true
  }
})

const eventSubscriptionDetail = computed(() => {
  // Get current event subscription plan
  // In Event Builder page event subscription details will be loaded
  let subscriptionPlan = store.getters.getEventSubscriptionDetails
  if (!subscriptionPlan || !subscriptionPlan.id) {
    // In Events listing page, we need to get the subscription plan from Plans store
    subscriptionPlan = store.getters.getSubscriptionByEventId(eventId.value)
  }

  return subscriptionPlan
})

const eventId = computed(() => {
  return store.getters.ebEventId
})

const toggleUpgrade = (plan) => {
  if (plan === 'Custom') {
    const mailtoLink = 'mailto:projectboardplus@projectboard.zohodesk.com'
    window.location.href = mailtoLink
    return
  }
  const hasRemainingPaidEvents = store.getters.hasRemainingPaidEvents(eventSubscriptionDetail.value?.plan?.name)
  const remainingEvents = store.getters.getRemainingEvents(plan.toLowerCase())

  if (hasRemainingPaidEvents) {
    (remainingEvents === 0) ? store.dispatch('ToggleModalUpgradePayment', { eventId: store.getters.ebEventId, currentPlan: plan }) :
    store.dispatch('ToggleModalUpgradeEvent', { eventId: store.getters.ebEventId, selectedPlan: plan.toLowerCase() })

  } else {
    // If we are upgrading from Basic to Premium, give 250 discount
    const discount = (eventSubscriptionDetail.value?.plan?.name === 'basic') ? { name: 'premium', amount: -250 } : null
    store.dispatch('ToggleModalUpgradePayment', { eventId: store.getters.ebEventId, currentPlan: plan, discount })
  }
}
</script>

<template>
  <card-slot class-name="addonCard">
    <template #header>
      <div class="addonCard-header">
        <div class="addonCard-header-container">
          <h3 class="addonCard-header-container-top">
            {{ item.topText }}
          </h3>
        </div>
        <div class="addonCard-header-container">
          <h1 class="addonCard-header-container-middle">
            {{ item.title }}
          </h1>
        </div>
        <div class="addonCard-header-container">
          <h4 class="addonCard-header-container-bottom">
            <span v-html="item.description" />
          </h4>
        </div>
      </div>
    </template>
    <template #content>
      <div class="card-content">
        <span class="flex flex-row items-center">
          <base-button text="upgrade"
                   class-name="text-p-sm ml-4 mb-2"
                   btn-type="secondary"
                   :functions="() => toggleUpgrade(item.plan)">
            <template #contents>
              <span class="addonCard-header-upgradeBtn">
                {{ item.buttonText }}
              </span>
            </template>
          </base-button>
        </span>
      </div>
    </template>
  </card-slot>
</template>

<style lang="scss" scoped>
.addonCard {
  @apply md:w-[22rem] md:min-w-0 min-w-full border rounded-2xl border-greys-200 h-full pb-3;
  &-header {
    @apply p-4 bg-white rounded-t-2xl flex flex-col relative h-[7.9rem];
    &-container {
      @apply w-full flex flex-col justify-between relative;
      &-draft {
        @apply flex flex-col;
      }
      &-top {
        @apply w-full h-[1.5rem] text-h4 font-medium;
      }
      &-middle {
        @apply w-full h-[1.5rem] pt-3 pl-0 text-h2 font-bold;
      }
      &-bottom {
        @apply animate-[pulse-bg_2s_infinite] md:mb-6 text-h6 pt-6 pl-0;
      }
    }
    &-upgradeBtn {
      @apply flex flex-col justify-center items-center p-2;
    }
  }
}
</style>
