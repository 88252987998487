// Map to Project Submission Status in backend/src/server/generated/go/proto/v1/projects/models.pb.go
const DRAFT = "PROJECT_STATUSES_DRAFT"
const SUBMITTED = "PROJECT_STATUSES_SUBMITTED"
const APPROVED = "PROJECT_STATUSES_APPROVED"
const DECLINED = "PROJECT_STATUSES_DECLINED"

const ProjectStatus = [
    DRAFT,
    SUBMITTED,
    APPROVED,
    DECLINED
]

export const projectStatuses = {
    DRAFT,
    SUBMITTED,
    APPROVED,
    DECLINED,
    ProjectStatus
}
