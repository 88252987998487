<script setup>
import DashboardPageBase from './DashboardPageBase'
import { onMounted } from 'vue'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
const router = useRouter()
const route = useRoute()


onMounted(async () => {
  if (store.getters.isLoggedIn) {
    const { query } = route
    if (query?.token) {
      try {
        // Verify user to Accept User Invitation
        const response = await store.dispatch('acceptUserInvitation', { token: query.token })
        if (response.status === 200) {
          if (query?.organizationId) {
            await store.dispatch('setActiveOrganization', query.organizationId)
          }
          await router.push({ name: "Users" }).then(() => {
            // Refresh the page
            router.go(0)
          })
        } else {
          await router.push({ name: "NotAccess" })
        }
      }catch (error) {
        // Handle errors if necessary
        console.error("Error during verification:", error)
        await router.push({ name: "NotAccess" })
      }
    }
  }
})
</script>

<template>
  <dashboard-page-base>
    <template #title>
      Loading ...
    </template>
  </dashboard-page-base>
</template>
