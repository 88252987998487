<script setup>
// import functions
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import LogRocket from 'logrocket'
// Store
import store from './store'
// Config
import { config } from '@/config'
// import components
import Layout from './components/Layout'
import EmptyLayout from './components/Layout/Empty'

// dark mode
const darkMode = computed(() => {
  return store.darkMode || false
})

const route = useRoute()

const layoutComponent = computed(() => {
  switch ((route.meta?.layout ?? '').toLowerCase()) {
  case 'default':
    return Layout
  case 'empty':
    return EmptyLayout
  default:
    return Layout
  }
})

// Track Users
const currentUser = computed(() => {
  return store.getters.user || null
})

watch(currentUser, (newVal, oldVal) => {
  if (newVal && newVal.id !== oldVal?.id && config.LOGROCKET) {
    LogRocket.identify(newVal.uuid, {
      name: newVal.fullName ?? `${newVal.firstName} ${newVal.lastName}`,
      email: newVal.email,
      username: newVal.userName,
      event: 'PB Plus',
    })
  }
})
</script>

<template>
  <div :class="{ 'dark': darkMode }">
    <component :is="layoutComponent">
        <router-view v-slot="{Component, route}">
          <transition :name="route.meta.transition">
            <component :is="Component" />
          </transition>
        </router-view>
    </component>
  </div>
</template>

<style lang="scss">
.scroll-lock {
  @apply overflow-hidden;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
