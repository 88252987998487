import ParticipantsService from '@/services/participants'
import { processStatuses } from '@/utils'

export default {
  state: () => ({
    // Participants List
    participants: [],
    participantsStatus: processStatuses.IDLE,
    participantCounts: 0,
    participantsRemoveStatus: processStatuses.IDLE,

    // Participants Invited
    participantInvited: [],
    participantInvitedStatus: processStatuses.IDLE,
    participantsInvitedRemoveStatus: processStatuses.IDLE,

    // Participants Search
    participantsSearchResults: [],
    participantsSelectedToInvite: [],
    participantsSearchResultsStatus: processStatuses.IDLE,
    participantsSelectedToInviteStatus: processStatuses.IDLE,

    // sort
    sort: 'firstName',
    isDesc: true
  }),
  getters: {
    // Participants List
    getSelectedParticipantsToInvite: state => state.participantsSelectedToInvite,
    getParticipantsCounts: state => {
      return state.participantCounts ? state.participantCounts : 0
    },
    combinedParticipants: (state) => {
      const sortBy = state.sort || 'firstName'
      const isDesc = state.isDesc || false

      const allParticipants = (state.participants && state.participants.length > 0) ?
          state.participants.map(user => {
            return {
              ...user,
              status: user.lastVerified !== null ? 'Active' : 'Pending'
            }
          }) : []

      const pendingInvitedParticipants = state.participantInvited
          .filter(invitedUser => invitedUser.acceptedAt === null)
          .map(invitedUser => ({ ...invitedUser.user, userInvitationsId: invitedUser.id, status: 'Pending' }))

      const combinedParticipants = [...allParticipants, ...pendingInvitedParticipants].sort((a, b) => {
        const fieldA = (a[sortBy] || '').toString().toLowerCase()
        const fieldB = (b[sortBy] || '').toString().toLowerCase()

        return isDesc ? fieldB.localeCompare(fieldA) : fieldA.localeCompare(fieldB)
      })

      const participantsMap = combinedParticipants.reduce((acc, user) => {
        const key = user.email.toLowerCase()
        if (!acc[key]) {
          acc[key] = { active: null, pending: null }
        }
        if (user.status.toLowerCase() === 'pending') {
          acc[key].pending = user
        } else {
          acc[key].active = user
        }
        return acc
      }, {})

      const uniqueParticipants = Object.values(participantsMap).map(({ active, pending }) => pending || active)

      state.participantCounts = uniqueParticipants.length

      return uniqueParticipants
    },
    // Participants Invited
    getInvitedParticipants: state => state.participantInvited,
    // Participants Search
    getSearchParticipantsResults: state => state.participantsSearchResults,
    getParticipantsRemoveStatus: state => state.participantsRemoveStatus,
    getParticipantsInvitedRemoveStatus: state => state.participantsInvitedRemoveStatus,
  },
  mutations: {
    // Participants List
    PARTICIPANTS_USER_LOADING(state) {
      state.participantsStatus = processStatuses.LOADING
    },
    PARTICIPANTS_USER_LOADED(state, payload) {
      state.participants = payload
      state.participantsStatus = processStatuses.LOADED
    },
    PARTICIPANTS_USER_ERROR(state) {
      state.participantsStatus = processStatuses.ERROR
    },

    // Participants invited
    PARTICIPANTS_INVITED_USER_LOADING(state) {
      state.participantInvitedStatus = processStatuses.LOADING
    },
    PARTICIPANTS_INVITED_USER_LOADED(state, payload) {
      state.participantInvited = payload
      state.participantInvitedStatus = processStatuses.LOADED
    },
    PARTICIPANTS_INVITED_USER_ERROR(state) {
      state.participantInvitedStatus = processStatuses.ERROR
    },

    //participants re-invited
    PARTICIPANTS_RE_INVITED_LOADING(state) {
      state.participantInvitedStatus = processStatuses.LOADING
    },
    PARTICIPANTS_RE_INVITED_LOADED(state) {
      state.participantInvitedStatus = processStatuses.LOADED
    },
    PARTICIPANTS_RE_INVITED_ERROR(state) {
      state.participantInvitedStatus = processStatuses.ERROR
    },

    // Participants search
    SEARCH_PARTICIPANTS_RESULT(state, payload) {
      state.participantsSearchResultsStatus = processStatuses.LOADED
      state.participantsSearchResults = payload
    },
    SEARCHING_PARTICIPANTS_RESULT(state) {
      state.participantsSearchResultsStatus = processStatuses.LOADING
    },
    RESET_SEARCH_PARTICIPANTS_RESULT(state) {
      state.participantsSearchResults = []
      state.participantsSearchResultsStatus = processStatuses.IDLE
    },

    // Participants selected to invite
    SELECT_PARTICIPANTS_TO_INVITE(state, { user, role }) {
      if (state.participantsSelectedToInvite) {
        state.participantsSelectedToInvite.push({
          ...user,
          role: role
        })
      } else {
        state.participantsSelectedToInvite = [{
          ...user,
          role: role
        }]
      }
      state.userSearchResults = []
      state.userSearchResultsStatus = processStatuses.IDLE
    },
    DESELECT_PARTICIPANTS_TO_INVITE(state, { user }) {
      state.participantsSelectedToInvite = state.participantsSelectedToInvite.filter((inviteUser) => {
        if (user.isNew === true) {
          return inviteUser.email !== user.email
        } else {
          return inviteUser.id !== user.id
        }
      })
    },
    RESET_SELECTED_PARTICIPANTS_TO_INVITE(state) {
      state.participantsSelectedToInvite = []
      state.participantsSelectedToInviteStatus = processStatuses.IDLE
    },

    // Delete Participants
    PARTICIPANTS_DELETED_LOADING(state) {
      state.participantsRemoveStatus = processStatuses.LOADING
    },
    PARTICIPANTS_DELETED(state, payload) {
      state.participantsRemoveStatus = processStatuses.LOADED
      state.participants = state.participants.filter(participant => participant.id !== payload.id)
    },
    PARTICIPANTS_DELETED_ERROR(state) {
      state.participantsRemoveStatus = processStatuses.ERROR
    },
    PARTICIPANTS_INVITED_DELETED_LOADING(state) {
      state.participantsInvitedRemoveStatus = processStatuses.LOADING
    },
    PARTICIPANTS_INVITED_DELETED(state, payload) {
      state.participantsInvitedRemoveStatus = processStatuses.LOADED
      state.participantInvited = state.participantInvited.filter(invitedParticipant => invitedParticipant.id !== payload.id)
    },
    PARTICIPANTS_INVITED_DELETED_ERROR(state) {
      state.participantsInvitedRemoveStatus = processStatuses.ERROR
    },

    // Sort
    TOGGLE_PARTICIPANTS_DESC(state) {
      state.isDesc = !state.isDesc
    },
    SET_PARTICIPANTS_SORT_BY(state, filter) {
      state.sort = filter
    },
  },
  actions: {
    // Participants List
    getParticipants({commit}, payload) {
      commit('PARTICIPANTS_USER_LOADING')
      return new Promise((resolve, reject) => {
        ParticipantsService.getParticipants(payload, payload.include).then((response) => {
          commit('PARTICIPANTS_USER_LOADED', response.data.users)
          resolve()
        }).catch((err) => {
          commit('PARTICIPANTS_USER_ERROR')
          reject(err)
        })
      })
    },
    // Participants invited
    inviteParticipants({commit}, payload) {
      commit('PARTICIPANTS_INVITED_USER_LOADING')
        return new Promise((resolve, reject) => {
          ParticipantsService.inviteParticipants(payload).then((response) => {
            commit('PARTICIPANTS_INVITED_USER_LOADED', response.data.userInvitations)
            resolve(payload)
          }).catch((err) => {
            reject(err)
            commit('PARTICIPANTS_INVITED_USER_ERROR')
          })
        })
    },
    getInvitedParticipants({commit}, payload) {
      commit('PARTICIPANTS_INVITED_USER_LOADING')
      return new Promise((resolve, reject) => {
        ParticipantsService.fetchInvitedParticipants(payload).then((response) => {
          commit('PARTICIPANTS_INVITED_USER_LOADED', response.data.userInvitations)
          resolve()
        }).catch((err) => {
          commit('PARTICIPANTS_INVITED_USER_ERROR')
          reject(err)
        })
      })
    },
    reInviteParticipants({commit}, { email, eventId }) {
      commit('PARTICIPANTS_RE_INVITED_LOADING')
      return new Promise((resolve, reject) => {
        ParticipantsService.reInviteParticipants({email, eventId}).then((response) => {
          commit('PARTICIPANTS_RE_INVITED_LOADED')
          resolve(response)
        }).catch((err) => {
          reject(err)
          commit('PARTICIPANTS_RE_INVITED_ERROR')
        })
      })
    },
    // Participants selected to invite
    selectParticipantsToInvite({commit}, payload) {
      commit('SELECT_PARTICIPANTS_TO_INVITE', payload)
    },
    deselectParticipantsToInvite({commit}, payload) {
      commit('DESELECT_PARTICIPANTS_TO_INVITE', payload)
    },
    resetSelectedParticipantsToInvite({commit}) {
      commit('RESET_SELECTED_PARTICIPANTS_TO_INVITE')
    },
    resetSearchParticipantsResults({commit}) {
      commit('RESET_SEARCH_PARTICIPANTS_RESULT', [])
    },
    // Delete Participants
    deleteParticipants({commit}, payload) {
      commit('PARTICIPANTS_DELETED_LOADING')
      return new Promise((resolve, reject) => {
        ParticipantsService.deleteParticipants(payload).then(() => {
          commit('PARTICIPANTS_DELETED', payload)
          resolve()
        }).catch((err) => {
          commit('PARTICIPANTS_DELETED_ERROR', payload)
          reject(err)
        })
      })
    },
    deleteInviteParticipants({commit}, payload) {
      commit('PARTICIPANTS_INVITED_DELETED_LOADING')
      return new Promise((resolve, reject) => {
        ParticipantsService.deleteInviteUser(payload).then(() => {
          commit('PARTICIPANTS_INVITED_DELETED', payload)
          resolve()
        }).catch((err) => {
          commit('PARTICIPANTS_INVITED_DELETED_ERROR', payload)
          reject(err)
        })
      })
    },
    // Participants Search
    searchParticipants({commit}, payload) {
      commit('SEARCHING_PARTICIPANTS_RESULT')
      return new Promise((resolve, reject) => {
        ParticipantsService.searchParticipants(payload).then((response) => {
          commit('SEARCH_PARTICIPANTS_RESULT', response?.data?.users)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // Sort
    setParticipantsSortBy({ commit }, sortBy) {
      commit('SET_PARTICIPANTS_SORT_BY', sortBy)
    },
    toggleParticipantsDataDesc({ commit }) {
      commit('TOGGLE_PARTICIPANTS_DESC')
    },
  }
}
