import BannerService from "@/services/banner"
import FileService from "@/services/file"
import { processStatuses } from '@/utils'
import { cloneObject } from '@/utils/clone'

const bannerDefaultState = () => ({
  bannerSets: {},
  bannerSetsStatus: processStatuses.IDLE,
  bannerSetsError: null,
  selectedBannerID: null,
  originalSelectedBannerId: null,
  // Custom Banner
  customBanner: null,
  originalCustomBanner: null,
  customBannerStatus: processStatuses.IDLE,
  customBannerError: null,
  customBannerUploadStatus: processStatuses.IDLE,
  customBannerUploadError: null
})

export default {
  state: bannerDefaultState,
  getters: {
    getBannerSet: state => state.bannerSets,
    getBannerSetStatus: state => state.bannerSetsStatus,
    getSelectedBanner: state => state.bannerSets?.bannersInSets?.find(m => m.sortOrder === 0)?.banner,
    getCustomBanner: state => state.customBanner,
    getSelectedBannerID: state => state.selectedBannerID,
    isCustomBannerImageUpdated: state => {
      return state.customBanner?.backgroundImage !== state.originalCustomBanner?.backgroundImage
    },
    isBannerSelectionUpdated: state => {
      return state.originalSelectedBannerId !== state.selectedBannerID
    }
  },
  mutations: {
    BANNER_SETS_LOADING(state) {
      state.bannerSetsStatus = processStatuses.LOADING
    },
    BANNER_SETS_LOADED(state, payload) {
      state.bannerSets = payload?.[0] ?? {}
      state.bannerSetsStatus = processStatuses.LOADED

      const selectedBannerInSets = state.bannerSets?.bannersInSets?.find(m => m.sortOrder === 0)
      switch (selectedBannerInSets?.banner?.name) {
        case 'custom':
          state.selectedBannerID = "upload"
          state.originalSelectedBannerId = "upload"
          break
        case null:
        case undefined:
          state.selectedBannerID = "default"
          state.originalSelectedBannerId = "default"
          break
        default:
          state.selectedBannerID = selectedBannerInSets?.bannerId
          state.originalSelectedBannerId = selectedBannerInSets?.bannerId
          break
      }
      state.customBanner = state.bannerSets?.bannersInSets?.map(m => m.banner).find(m => m.name === 'custom') ?? {}
      state.originalCustomBanner = cloneObject(state.customBanner)
    },
    BANNERS_LOAD_ERROR(state, error) {
      state.bannerSetsStatus = processStatuses.ERROR
      state.bannerSetsError = error
    },
    BANNER_SETS_UPDATING(state) {
      state.bannerSetsStatus = processStatuses.SAVING
      state.bannerSetsError = null
    },
    BANNER_SELECTED(state, payload) {
      state.selectedBannerID = payload
    },
    CUSTOM_BANNER_SAVING(state) {
      state.customBannerStatus = processStatuses.SAVING
    },
    CUSTOM_BANNER_SAVED(state, banner) {
      state.customBanner = banner
      state.customBannerStatus = processStatuses.LOADED
    },
    CUSTOM_BANNER_ERROR(state, error) {
      state.customBannerStatus = processStatuses.ERROR
      state.customBannerError = error
    },
    CUSTOM_BANNER_IMAGE_UPLOADING(state) {
      state.customBannerUploadStatus = processStatuses.LOADING
    },
    CUSTOM_BANNER_IMAGE_UPLOADED(state, link) {
      state.customBannerUploadStatus = processStatuses.LOADED
      state.customBanner.backgroundImage = link
    },
    CUSTOM_BANNER_IMAGE_UPDATED(state, fileOrLink) {
      state.customBanner.backgroundImage = fileOrLink
    },
    BANNER_RESET_ALL(state) {
      Object.assign(state, bannerDefaultState())
    }
  },
  actions: {
    fetchBannerSets({commit, rootGetters}) {
      commit('BANNER_SETS_LOADING')
      return new Promise((resolve, reject) => {
        BannerService.getBannerSets({eventId: rootGetters.ebEventId}).then((response) => {
          commit('BANNER_SETS_LOADED', response.data.bannerSets)
          resolve()
        }).catch((err) => {
          commit('BANNERS_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    updateBannerSets({commit, getters, rootGetters}) {
      commit('BANNER_SETS_UPDATING')
      return new Promise((resolve, reject) => {
        const bannersToUpdate = []
        if (typeof getters.getSelectedBannerID === 'number') {
            bannersToUpdate.push({
                bannerId: getters.getSelectedBannerID,
                sortOrder: 0 // 0 mean selected
            })
        }

        if (typeof getters.getCustomBanner?.id === 'number') {
          // Always save the custom banner so that we can use it and display the user uploaded image
          bannersToUpdate.push({
              bannerId: getters.getCustomBanner.id,
              sortOrder: getters.getSelectedBannerID === 'upload' ? 0 : 1
          })
        }

        BannerService.updateBannerSets({
          banner_set_id: getters.getBannerSet.id,
          name: rootGetters.ebEventSlug,
          banners: bannersToUpdate}).then((response) => {
          commit('BANNER_SETS_LOADED', [response.data.bannerSets])
          resolve()
        }).catch((err) => {
          commit('BANNERS_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    createBannerSets({commit, getters, rootGetters}) {
      const bannerId = getters.getSelectedBannerID === 'upload' ? getters.getCustomBanner.id : getters.getSelectedBannerID
      if (typeof bannerId !== 'number') {
        console.debug('Banner ID is not a number', bannerId)
        return
      }
      commit('BANNER_SETS_UPDATING')
      return new Promise((resolve, reject) => {
        BannerService.createBannerSets({eventId: rootGetters.ebEventId, name: rootGetters.ebEventSlug, bannerId: bannerId}).then((response) => {
          commit('BANNER_SETS_LOADED', [response.data.bannerSets])
          resolve()
        }).catch((err) => {
          commit('BANNERS_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    deleteBannerSets({commit, getters}) {
      if (getters.getBannerSet?.id) {
        commit('BANNER_SETS_UPDATING')
        return new Promise((resolve, reject) => {
          BannerService.deleteBannerSets({ banner_set_id: getters.getBannerSet.id }).then(() => {
            commit('BANNER_SETS_LOADED', null)
            resolve()
          }).catch((err) => {
            commit('BANNERS_LOAD_ERROR', err)
            reject(err)
          })
        })
      }
    },
    selectBanner({commit}, bannerId) {
      commit('BANNER_SELECTED', bannerId)
    },
    resetBanner({commit, getters}) {
      commit('BANNER_SELECTED', getters.getBannerSet?.bannersInSets[0]?.bannerId)
    },
    deleteBannersInSets({commit, getters}) {
      const bannerSetId = getters.getBannerSet?.id
      const bannerIds = getters.getBannerSet?.bannersInSets.map(m => m.bannerId)

      if (bannerSetId && bannerIds) {
          commit('BANNER_SETS_UPDATING')
          return new Promise((resolve, reject) => {
          BannerService.deleteBannersInSets({ banner_set_id: bannerSetId, banner_ids: bannerIds }).then(() => {
              commit('BANNER_SETS_LOADED', null)
              resolve()
          }).catch((err) => {
              commit('BANNERS_LOAD_ERROR', err)
              reject(err)
          })
        })
      }
    },
    // Use select an image
    setCustomBannerImage({commit}, file) {
      commit('CUSTOM_BANNER_IMAGE_UPDATED', file)
    },
    // Upload image to backend
    uploadCustomBannerImage({commit, getters, rootGetters}) {
      if (getters.isCustomBannerImageUpdated && getters.getCustomBanner?.backgroundImage instanceof File) {
        commit('CUSTOM_BANNER_IMAGE_UPLOADING')
        return new Promise((resolve, reject) => {
          FileService.upload({
            file: getters.getCustomBanner?.backgroundImage,
            directory: `selfserve/${rootGetters.ebEventId}/banners`
          }).then((response) => {
            commit('CUSTOM_BANNER_IMAGE_UPLOADED', response.data.link)
            resolve()
          }).catch((err) => {
            commit('BANNERS_LOAD_ERROR', err)
            reject(err)
          })
        })
      }
    },
    createCustomBanner({commit, getters, rootGetters}) {
      commit('CUSTOM_BANNER_SAVING')
      return new Promise((resolve, reject) => {
          BannerService.createBanner({name: 'custom', title: rootGetters.ebEventSlug, description: rootGetters.ebEventSlug, backgroundImage: getters.getCustomBanner?.backgroundImage}).then((response) => {
            commit('CUSTOM_BANNER_SAVED', response.data.banner)
            resolve()
          }).catch((err) => {
            commit('BANNERS_LOAD_ERROR', err)
            reject(err)
          })
      })
    },
    updateCustomBanner({commit, getters}) {
      commit('CUSTOM_BANNER_SAVING')
      return new Promise((resolve, reject) => {
        BannerService.updateBanner({
          id: getters.getCustomBanner.id,
          name: getters.getCustomBanner.name,
          title: getters.getCustomBanner.title,
          description: getters.getCustomBanner.description,
          backgroundImage: getters.getCustomBanner?.backgroundImage ?? ""
        }).then((response) => {
          commit('CUSTOM_BANNER_SAVED', response.data.banner)
          resolve()
        }).catch((err) => {
          commit('BANNERS_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    resetBannerStore({commit}) {
      commit('BANNER_RESET_ALL', null)
    }
  }
}
