<script setup>
// Required Package
import { onMounted, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
// Store
import store from '@/store'
// Custom Components
import EventBuildNav from '@/components/Layout/Navbars/EventBuilder'
import MobileEventBuildNav from '@/components/Layout/Navbars/Top/eventBuilder'
import EventBuildBottomNav from '@/components/Layout/Navbars/Bottom'
// Utils
import { processStatuses, allSectionsCompleted } from '@/utils'

import { pbCircleLoader } from 'pb-components'

defineProps({
  headerClass: String,
  comingSoon: {
    type:Boolean,
    default: false
  },
  isVisible: {
    type: Boolean,
    default: true
  },
  isPublished: {
    type: Boolean,
    default: false
  },
  isCentred: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()

const isTemplate = computed(() => {
  return route.name === 'Template' || route.name === 'Project'
})

const completionValue = computed(() => {
  return store.getters.getConfigCompletion
})

const event = computed(() => {
  return store.getters.ebEventId
})

const eventSettings = computed ( () => {
  return store.getters.eventSettings
})

const eventSettingStatusLoaded = computed ( () => {
  return store.getters.eventSettingsStatus === processStatuses.LOADED
})

let isToggled = false;

// add watch check store.getters.ebEventStatus == loaded and eventSubscriptionDetailsStatus -- ideal

onMounted( async() => {
  await store.dispatch('GetEvents')
  // Get slug from URL

  const slug = route.params.slug
  if (store.getters.ebEventSlug !== slug || store.getters.ebEventStatus !== processStatuses.LOADED) {
    await store.dispatch('getEvent', slug).then(() => {
      store.dispatch('getEventConfigs', { eventId: store.getters.ebEventId})
      store.dispatch('getTimelineData', store.getters.ebEventId)
      store.dispatch('fetchEventSubscriptionDetails')
    })
  }

  if (store.getters.ebEventStatus === processStatuses.LOADED && store.getters.getEventSubscriptionDetailsStatus !== processStatuses.LOADED) {
    await store.dispatch('fetchEventSubscriptionDetails')
  }

  await store.dispatch('SetSidebarType', 'builder')

  if (store.getters.subscriptionPlansStatus === processStatuses.IDLE) {
    await store.dispatch('fetchSubscriptions')
  }
})

watch([() => store.getters.subscriptionPlansStatus, () => store.getters.getOrganizationStatus], ([newValue, newOrgStatus]) => {
  if (newValue === processStatuses.LOADED && newOrgStatus === processStatuses.LOADED) {
    store.dispatch('setPlanNameToEventsMap').then(() => {
      store.dispatch('setRemainingEvents').then(() => {
        store.dispatch('setTopSubscriptionName')
      })
    })
  }
})

watch([() => eventSettings.value, () => completionValue.value], ([newSettings, newValue]) => {

  let localItemsArray = {}
  const localItems = localStorage.getItem('eventPopupFlag')
  if(localItems !== null) {
    localItemsArray = JSON.parse(localItems)
  }
  const eventTitle = "event" + event.value
  if (eventSettingStatusLoaded.value && allSectionsCompleted(newValue) && !localItemsArray[eventTitle] && (newSettings.status !== undefined && newSettings.status !== 'Published') && !isToggled) {
    localItemsArray[eventTitle] = eventSettings.value.status
    localStorage.setItem('eventPopupFlag', JSON.stringify(localItemsArray))
    store.dispatch('ToggleModalGoLive')
    isToggled = true
  }
})
</script>

<template>
  <div v-if="!event"
       class="event-loader">
    <pb-circle-loader :medium="true" />
  </div>
  <template v-else>
    <!-- Mobile Event Builder -->
    <mobile-event-build-nav />
    <!-- Event builder -->
    <event-build-nav>
      <template #event-name>
        <slot name="event-name" />
      </template>
      <!-- allow EventBuilder page to customize EventBuilder Navbar buttons, do not change if possible -->
      <template #event-status="{ eventStatus }">
        <slot name="event-status" v-bind="{ eventStatus: eventStatus }"/>
      </template>
      <template #event-preview-button="{ previewBaseLink }">
        <slot name="event-preview-button" v-bind="{ previewBaseLink: previewBaseLink }"/>
      </template>
      <template #event-save-button>
        <slot name="event-save-button"/>
      </template>
      <template #event-publish-button>
        <slot name="event-publish-button"/>
      </template>
    </event-build-nav>
    <!-- Event Content -->
    <div :class="`header ${headerClass ? headerClass : ''}`">
       <span class="header-container pages-container-builder">
        <router-link to="/events" class="header_back">
         <i class="fa-solid fa-chevron-left"/> Back To Admin
        </router-link>
        <div v-if="!isTemplate">
          <h1 class="header_title">
            <slot name="title" />
          </h1>
          <p class="header_text">
            <slot name="description" />
          </p>

        </div>
        <slot name="rightContainer" />
      </span>
      <slot v-if="comingSoon"
            name="coming-soon">
        <div class="comingSoon"><i class="fa fa-star" /> Coming Soon</div>
      </slot>
    </div>
    <div v-if="event"
         class="errors"
         :class="{'errors-centred' : isCentred}">
      <div v-if="isPublished"
           class="errors-container">
        <span class="errors-container-icon">
          <i class="fa-regular fa-circle-info fa-xl"></i>
        </span>
        <span>
          <slot name="error-message">
            This section is no longer editable as the site is live. Please email our
            <a class="underline" href="mailto:projectboardplus@projectboard.zohodesk.com"> support</a>
            for further questions.
          </slot>
        </span>
      </div>
    </div>
    <slot v-if="!comingSoon && event"
          name="content" />
    <event-build-bottom-nav v-if="event"
                            :is-visible="isVisible">
      <template #bottom-nav-container>
        <slot name="bottom-nav-container" />
      </template>
      <template #back-button>
        <slot name="back-button" />
      </template>
      <template #next-button>
        <slot name="next-button" />
      </template>
      <template #save-button>
        <slot name="save-button" />
      </template>
    </event-build-bottom-nav>
  </template>
</template>

<style lang="scss" scoped>
.header {
  &-container {
    @apply flex md:flex-row flex-col w-full justify-between items-center md:mt-28 mt-44;
  }
  &_back {
    @apply w-full justify-start md:hidden mt-2 text-p-sm font-medium;
    &:hover {
      @apply text-product-400;
    }
  }
  &_title {
    @apply pt-6 text-h1 font-bold capitalize;
  }
  &_underline {
    @apply bg-purple text-purple h-[3px] w-[8rem] mt-2 mb-4;
  }
  &_text {
    @apply text-sm text-blacks-300 mt-2 max-w-[45rem];
  }
}
.comingSoon{
  @apply text-primary text-sm py-1;
}

.errors {
  @apply w-full flex;
  &-centred {
    @apply items-center mt-4 -mb-4;
  }
  &-container {
    @apply text-other-red bg-other-red/[.3] max-w-[668px] p-4 my-4 text-p-md rounded-md font-normal w-full flex flex-row;
    &-icon {
      @apply mr-4 flex items-center justify-center;
    }
  }
}

.event-loader {
  @apply transition-all duration-300 w-full h-full flex justify-center items-center;
}
</style>
