// Map to projectTeamRoles in backend/src/server/proto/v1/projects/models.proto

const KEYWORD = 'KEYWORD'
const USER_NAME = 'USER_NAME'
const EMAIL = 'EMAIL'
const USER_ID = 'USER_ID'

const FindUserSearchBy = [
    KEYWORD,
    USER_NAME,
    EMAIL,
    USER_ID,
]

export const findUserSearchBy = {
    KEYWORD,
    USER_NAME,
    EMAIL,
    USER_ID,
    FindUserSearchBy
}
