<script setup>
// Required Components
import { computed } from 'vue'
// Store
import store from '@/store'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'
import EbTable from '@/components/Base/Table/EventBuilder'
import { v4 as uuidv4 } from 'uuid'
import { processStatuses } from '@/utils'

const headers = [
  {
    text: 'Name', value: "name", sortable: true, class: 'min-w-[24rem]'
  },
  {
    text: 'Icon', value: "image", sortable: false, class: 'min-w-[8rem]'
  }
]

const items = computed(() => {
  return store.getters.awards
})

const awardsErrorList = computed(() => {
  return store.getters.awardsListError
})

const awardsReadOnly = computed(() => {
  return store.getters.awardsStatus === processStatuses.SAVING ||
    store.getters.awardsStatus === processStatuses.LOADING || store.getters.getAwardsPublished
})

const updateAwardsName = (id, e) => {
  store.dispatch('updateEventAward', { id: id, field: 'name', value: e.target.value })
}

const toggleIconsModal = (id) => {
  store.dispatch('ToggleModalAwardsModal', {
    confirmAction: (imageOrIcon) => {
      // Need an update award action
      store.dispatch('updateEventAward', { id: id, field: 'image', value: imageOrIcon })
    }
  })
}

const addAwards = () => {
  const payload = {
    id: uuidv4(),
    name: '',
    image: ''
  }
  store.dispatch('setErrorList', [])
  store.dispatch('addEventAward', payload)
}

const deleteAwardRow = (id) => {
  store.dispatch('removeEventAward', id)
}

const sort = (filter = 'name') => {
  store.dispatch('setAwardFilterBy', filter)
  store.dispatch('toggleAwardsDesc')
}
</script>

<template>
  <div class="awards-add">
    <base-button id="addAwardsButton"
                 name="addAwardsButton"
                 class-name="awards-button awards-button-awards"
                 :functions="addAwards"
                 :disabled="awardsReadOnly"
                 text="Add Award"/>
  </div>
  <div class="overflow-x-auto">
    <eb-table input-place-holder="New Award"
              :full-width="false"
              :delete-row="deleteAwardRow"
              :read-only="awardsReadOnly"
              :toggle-icons-modal="toggleIconsModal"
              :update-row-name="updateAwardsName"
              :items="items"
              :error-list="awardsErrorList"
              :invert-color="false"
              :headers="headers"
              :sort-function="sort"/>
  </div>
</template>

<style lang="scss" scoped>
.awards{
  // awards Add
  &-add {
    @apply pt-10;
  }
  &-button {
    @apply w-auto text-blacks-500 min-w-[2.5rem];
    &-awards {
      @apply capitalize px-4 py-2 text-p-md text-white;
    }
  }
}
</style>
