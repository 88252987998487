import axios from 'axios'
import { config } from '@/config'

class TransactionActions {
  getTransactions({ organizationId, include }) {
    return axios.get(`${config.API_URL}/payments/transactions`, {
      params: {
        organizationId: organizationId,
        "include": include
      }
    })
  }

}

export default new TransactionActions()
