import store from '@/store'
import { config } from '@/config'

export const authGuard = (to) => {
    if (store.getters.isLoggedIn) {
        return true
    }
    if (to.fullPath !== '/') {
        window.location.href = config.AUTH_URL + '/selfserve/login?returnUrl=' + encodeURIComponent(to.fullPath)
    } else {
        window.location.href = config.AUTH_URL
    }
}
