<script setup>
// Required Package
import { computed, onUnmounted, watch} from 'vue'
import { useRoute } from 'vue-router'
// Store
import store from '@/store'
// Base Component
import BaseButton from '@/components/Base/Button/BaseButton'
import MoleculeItem from '@/components/Base/Menu/Molecule'
// Utils
import { processStatuses, eventPlans } from '@/utils'

import { pbCircleLoader } from 'pb-components'

const props = defineProps({
  eventBuilderItems: {
    type: Array,
    required: true
  },
  user: {
    type: Object
  }
})

const route = useRoute()

const eventId = computed(() => {
  return store.getters.ebEventId
})

const eventSubscriptionDetail = computed(() => {
  // Get current event subscription plan
  // In Event Builder page event subscription details will be loaded
  let subscriptionPlan = store.getters.getEventSubscriptionDetails
  if (!subscriptionPlan || !subscriptionPlan.id) {
    // In Events listing page, we need to get the subscription plan from Plans store
    subscriptionPlan = store.getters.getSubscriptionByEventId(eventId.value)
  }

  return subscriptionPlan
})

const isMenuLoading = computed(() => {
  return props.eventBuilderItems.length === 0
})

const collapseMenu = (name) => {
  store.dispatch('SetCollapseMenu', name)
}

const toggleUpgrade = () => {
  if (store.getters.hasRemainingPaidEvents(eventSubscriptionDetail.value?.plan?.name)) {
    store.dispatch('ToggleModalUpgradeEvent', { eventId: store.getters.ebEventId })
  } else {
    // If we are upgrading from Basic to Premium, give 250 discount
    let discount = null
    if (eventSubscriptionDetail.value?.plan?.name === 'basic') {
      discount = {
        name: 'premium',
        amount: -250
      }
    }
    store.dispatch('ToggleModalUpgradePayment', {
      eventId: store.getters.ebEventId,
      currentPlan: eventSubscriptionDetail.value?.plan?.name,
      discount: discount
    })
  }
}

const IsEventSectionCompleted = (name) => {
  return store.getters.IsEventSectionCompleted(name)
}

const IsEventSectionLocked = (featureFlag) => {
  const feature = store.getters.getEventFeature(featureFlag)
  return feature?.value !== 1
}

const upgradeButtonLoading = computed(() => {
  return store.getters.ebEventStatus !== processStatuses.LOADED
    || store.getters.getEventSubscriptionDetailsStatus !== processStatuses.LOADED
    || store.getters.subscriptionPlansStatus !== processStatuses.LOADED
    || store.getters.getOrganizationStatus !== processStatuses.LOADED
})

const upgradeButtonDisabled = computed(() => {
  return eventSubscriptionDetail.value?.plan?.name === eventPlans.PROFESSIONAL
})

watch([upgradeButtonLoading, upgradeButtonDisabled], () => {
  if (route.query.upgrade === 'true' && upgradeButtonLoading.value === false && upgradeButtonDisabled.value === false) {
    toggleUpgrade()
  }
})

onUnmounted(() => {
  store.dispatch('ResetCompletion')
})
</script>

<template>
  <div v-if="isMenuLoading"
       class="event-builder-loader">
    <pb-circle-loader />
  </div>
  <div v-else
       class="event-builder">
    <div class="event-builder-menu">
      <div v-for="menu in eventBuilderItems"
           :key="menu.name">
        <div class="event-builder-menu-header"
             @click="collapseMenu(menu.name)">
          <h4 class="event-builder-menu-header-title">
            {{menu.display}}
          </h4>
          <i class="fa-solid event-builder-menu-header-arrow"
             :class="{
              'fa-chevron-up' : !menu.collapse,
              'fa-chevron-down' : menu.collapse
             }"/>
        </div>
        <ul v-if="!menu.collapse"
            :id="menu.name"
            class="event-builder-menu-list">
          <molecule-item v-for="(item, index) in menu.items"
                         :key="item.name"
                         :is-route="true"
                         :component="item.name"
                         :name="item.display"
                         :index="menu.items.length > 1 ? item.order : 0"
                         :is-configured="IsEventSectionCompleted(item.name)"
                         :is-locked="item.featureFlag && IsEventSectionLocked(item.featureFlag)"
                         :is-last="index === menu.items.length - 1"/>
        </ul>
      </div>
    </div>
    <div class="event-builder-plan">
      <hr class="event-builder-plan-separator"/>
      <div class="event-builder-plan-container">
        <span class="event-builder-plan-text">
          Your Plan
        </span>
        <span class="event-builder-plan-type">
          {{eventSubscriptionDetail?.plan?.name }} Plan
        </span>
      </div>
      <base-button id="upgradeBtn"
                   name="upgradeBtn"
                   class-name="event-builder-plan-btn"
                   text="upgrade plan"
                   btn-type="success"
                   :is-loading="upgradeButtonLoading"
                   :disabled="upgradeButtonLoading || upgradeButtonDisabled"
                   :functions="toggleUpgrade"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.event-builder {
  @apply flex flex-col h-full justify-between;
  // Loader
  &-loader {
    @apply w-full h-full;
  }
  // Menu
  &-menu {
    @apply overflow-y-auto;
    &-header {
      @apply md:bg-blacks-100 px-7 w-full py-3 bg-background-400 flex flex-row cursor-pointer justify-between items-center;
      &-title {
        @apply text-h4 text-blacks-300 font-medium;
      }
      &-arrow {
        @apply text-greys-300;
      }
    }
    &-list {
      @apply py-4 px-7;
    }
    &-btnContainer {
      @apply w-full flex flex-col text-purple items-center justify-center py-3 md:border-r-2 md:ml-0.5 border-r-white;
      @apply hidden md:inline-flex;
      &_link {
        @apply flex flex-row;
      }
      &_icon {
        @apply mt-[1px] mr-2;
      }
    }
    &-item {
      @apply px-7 py-3 font-light flex flex-row items-center;
      &_notActive {
        @apply hover:bg-purple/[0.1] hover:border-purple hover:border-r-4 cursor-pointer;
      }
      &_active {
        @apply bg-purple/[0.1] border-purple border-r-4 font-bold;
      }
      &_active-eb {
        @apply bg-purple/[0.1] border-purple border-l-4 md:border-r-4 md:border-l-0 font-bold;
      }
      &_text {
        @apply capitalize;
      }
    }
  }
  // Plan
  &-plan {
    @apply md:pb-24 flex-col w-full items-center pb-36 hidden md:inline-flex px-5;
    &-separator {
      @apply w-full bg-blacks-100 h-0.5 hidden md:inline-flex;
    }
    &-container {
      @apply flex flex-row w-full justify-between pt-6 pb-4 items-center;
    }
    &-text {
      @apply font-medium text-h4;
    }
    &-type {
      @apply text-h5 font-medium mb-2 bg-product-400/[0.1] text-product-500 py-2 px-4 rounded mt-1 capitalize;
    }
    &-btn  {
      @apply w-full py-3 capitalize;
    }
  }
}
</style>
