import axios from 'axios'
import { config } from '@/config'
import { timelineTypes } from '@/utils/timelineTypes';

class TimelineService {
    getTimelines(eventId) {
      return axios.get(`${config.API_URL}/timeline`,{ params: { eventId: eventId } })
    }
    getSubmissionDeadline(eventId) {
      return axios.get(`${config.API_URL}/timeline`,{ params: { eventId: eventId, timelineType: timelineTypes.PROJECT_SUBMISSION } })
    }
}

export default new TimelineService()
