export default {
  state: () => ({
    toast_array: []
  }),
  getters: {
    toast: state => state.toast_array,
  },
  mutations: {
    SET_TOAST_DATA (state, data) {
      state.toast_array.push(data)
    },
    REMOVE_TOAST(state, index) {
      state.toast_array = state.toast_array.filter((item, itemIndex) => {
        return itemIndex !== index
      })
    }
  },
  actions: {
    triggerToast({ commit }, data) {
      commit('SET_TOAST_DATA', data)
    },
    removeToast({ commit }, index) {
      commit('REMOVE_TOAST', index)
    }
  }
}
