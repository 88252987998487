<script setup>
import ModalBase from "@/components/Modals/ModalBase"
import BaseButton from '@/components/Base/Button/BaseButton'
import { computed, ref } from 'vue'
import store from '@/store'
import { config } from '@/config'
import Tooltip from '@/components/Lib/Tooltip'

let copied = ref(false)
const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const copyToClipboard = (code) => {
  navigator.clipboard.writeText(code)
  copied.value = true
}

const eventPreviewToken = computed(() => store.getters.getEventPreviewToken)
const eventStatus = computed(() => {
  // Get event status from store
  const status = event.value.eventSettings ? event.value.eventSettings.status : 'Draft'
  switch (status) {
  case "Published":
    return "Live"
  default:
    return status
  }
})

const isEventPublished = computed(() => {
  return eventStatus.value === 'Live'
})

const preview = () => {
  const params = {
    eventId: event.value.id
  }
  store.dispatch('previewEvent', params).then(() => {
    const { slug, eventSettings: { eventCode } } = event.value
    if (isEventPublished.value) {
      window.open(`${config.EVENT_URL}/${slug}/access?q=${eventCode}`, '_blank')
      return
    }
    window.open(`${config.EVENT_URL}/${slug}/access?q=${eventCode}&preview_token=${eventPreviewToken.value}`, '_blank')
  })
}
</script>

<template>
  <modal-base modal-class="bg-white modal-view" :is-close="false">
    <template #modal-title>
      <div class="modal-view-title">
        <img class="modal-view-title-img" src="https://firebasestorage.googleapis.com/v0/b/test-d9796.appspot.com/o/selfserve%2Fparty-horn-duotone%201.png?alt=media&token=8e60a191-e56f-42d2-b411-29a65f838261"/>
        <h1 class="modal-view-title-header">Woohoo! Your Event Is <span>LAUNCHED</span> </h1>
      </div>
    </template>
    <template #modal-content>
      <div class="modal-view-content">
        <p class="modal-view-content-para">Now you can invite participants to start their project with your unique event URL. Visit "participants" to view and manage project submissions.</p>
        <div class="flex modal-view-content-text-copy">
          <tooltip direction="left"
                   :input="config.EVENT_URL + '/' + event.slug"
                   class-name="tooltip"
                   :max-length="30">
            <template #element>
              <p class="modal-view-content-text-copy-para">
                {{ config.EVENT_URL }}/{{event.slug}}
              </p>
            </template>
            <template #content>
              {{ config.EVENT_URL }}/{{event.slug}}
            </template>
          </tooltip>
          <span class="modal-view-content-text-copy-span">
            <i v-if="!copied" class="fa-regular fa-copy modal-view-content-text-copy-icon" @click="copyToClipboard(`${config.EVENT_URL}/${event.slug}`)"></i>
            <span v-if="!copied" class="modal-view-content-text-copy-span-inner" @click="copyToClipboard(`${config.EVENT_URL}/${event.slug}`)">Copy Url</span>
            <i v-if="copied" class="fa-regular fa-check modal-view-content-text-copy-icon"></i>
          </span>
        </div>
      </div>
    </template>
    <template #modal-button>
      <div class="modal-view-buttons">
        <base-button
            id="go-live"
            name="go-live"
            text="View Event"
            aria-label="go Button"
            class-name="modal-view-buttons-view"
            :functions="preview"/>
        <base-button id="go-cancel"
                     name="go-cancel"
                     :disabled="copied"
                     class-name="modal-view-buttons-copyURL"
                     btn-type="secondary"
                     text="Copy Event URL"
                     @click="copyToClipboard(`${config.EVENT_URL}/${event.slug}`)" />
      </div>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal-view {
  @apply md:h-auto overflow-y-auto max-w-lg p-7 pt-0 md:rounded-2xl rounded-b-none;
  &-title {
    &-header {
      @apply text-h4 mb-4 w-full text-center font-medium;
    }
    &-img {
      @apply w-24 mx-auto my-5;
    }
  }
  &-content {
    &-para {
      @apply max-w-[23rem] mb-3 text-p-md text-center mx-auto md:leading-6 leading-5 text-blacks-300;
    }
    &-text-copy {
      @apply border rounded-lg pr-4 pl-4 justify-between;
      &-tooltip {
        @apply w-72;
      }
      &-para {
        @apply line-clamp-1 m-0 text-greys-500 font-normal text-p-xs text-left pr-3 leading-6 break-all;
      }
      &-span {
        @apply flex justify-center items-center;
        &-inner {
          @apply text-purple-dark font-bold hidden cursor-pointer md:inline-flex;
        }
      }
      &-icon {
        @apply text-purple-dark cursor-pointer text-p-xl mr-2;
      }
    }
  }
  &-buttons {
    @apply md:flex justify-center md:flex-row-reverse md:mt-5 mt-3;
    &-view {
      @apply md:max-w-[52rem] md:ml-2 capitalize w-full md:mb-0 mb-3 h-11;
    }
    &-copyURL {
      @apply text-product-500 border border-product-500 px-3 py-1.5 rounded-lg capitalize w-full md:mb-0 mb-3 h-11;
    }
  }
}
.tooltip {
  @apply w-80;
}
</style>
