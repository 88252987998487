import axios from 'axios'
import { config } from '@/config'

class SubscriptionService {
    getSubscriptions({ organizationId, include="event,plan,eventSettings" }) {
        return axios
            .get(`${config.API_URL}/plan_subscription`, { params: { "include": include, "organizationId": organizationId, "pageSize": 100 }})
    }

    updatePlanSubscriptionDetails(planId, subscriptionPlanId, organizationSubscriptionId) {
        return axios.patch(`${config.API_URL}/plan_subscription/${subscriptionPlanId}`, {
                planId: planId,
                organizationSubscriptionId: organizationSubscriptionId
            },
            {
                params: {
                    updateMask: 'plan_id,organization_subscription_id'
                }
            })
    }
}

export default new SubscriptionService()
