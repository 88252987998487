<script setup>
import { computed, onMounted, ref } from 'vue'
import store from '@/store'
import DashboardPageBase from './DashboardPageBase'
import SubscriptionCard from '@/components/Subscriptions/SubscriptionCard'
import SubsciptionPlan from '@/components/Subscriptions/SubscriptionPlan'

const showEventSubscription = ref(false)
const comingSoon = ref(false)

const subscriptionPlan = computed(() => {
  return store.getters.subscriptionPlans
})

onMounted(() => {
  store.dispatch('fetchSubscriptions')
})

const subscriptionTier = computed(() => {
  return store.getters.topSubscriptionName
})

const togglePlan = () => {
  showEventSubscription.value = !showEventSubscription.value
}

onMounted(() => {
  store.dispatch('getSubscriptionTier')
})
</script>

<template>
  <dashboard-page-base>
    <template #title>
      payments & plans
    </template>
    <template #content>
      <span v-if="comingSoon">
      <span v-if="!showEventSubscription">
      <ul v-if="subscriptionPlan && subscriptionPlan.length > 0"
          class="card-container">
        <subscription-card v-for="plan in subscriptionPlan "
                           :key="plan.id"
                           :plan="plan"
                           :toggle-plan="togglePlan"
                           class="card-container_card"/>
      </ul>
      <div v-if="subscriptionPlan && subscriptionPlan.length === 0" class="pl-4">
        No plans available
      </div>
      <span class="flex flex-row items-center p-12">
      <i class="fa-solid fa-star text-purple fa-xs"/>
      <p class="text-purple uppercase text-sm ml-2">upgrade plans coming soon!</p>
    </span>
      </span>
      <!-- Show Subscription PLan -->
      <span v-else>
    <div class="text-purple flex items-center p-4 cursor-pointer"
         @click="togglePlan">
      <i class="fa-light fa-chevron-left fa-2xs mr-2"></i> Back
    </div>
    <div v-if="subscriptionTier && Object.keys(subscriptionTier).length > 0"
         class="flex grow flex-wrap">
      <subsciption-plan v-for="tier in subscriptionTier.tierList"
                        :key="tier"
                        :tier="subscriptionTier.tier"
                        :tier-list="tier"
                        :data="subscriptionTier.data"/>
    </div>
      </span>
      </span>
      <span v-else>
        <span class="flex flex-row items-center">
      <i class="fa-solid fa-star text-purple fa-xs"/>
      <p class="text-purple uppercase text-sm ml-2">payment & plans coming soon!</p>
    </span>
      </span>
    </template>
  </dashboard-page-base>
</template>

<style lang="scss" scoped>
  .card-container {
    @apply flex grow flex-wrap;
    &_card {
      @apply my-4 md:m-4;
    }
  }
</style>
