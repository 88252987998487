<script setup>
import { useRoute } from 'vue-router'
import day from 'dayjs'
// Store
import store from '@/store'
// Props
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  date: String,
  component: {
    type: String,
    required: true
  },
  isLast: {
    type: Boolean,
    default: false
  },
  isDotted: {
    type: Boolean,
    default: false
  },
  isRoute : {
    type: Boolean,
    default: false
  },
  isDeadline: {
    type: Boolean,
    default: false
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
  isConfigured: {
    type: Boolean,
    default: false
  },
  index: {
    type: Number
  },
  isLocked: {
    type: Boolean,
    default: false
  },
  isHidden: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()
// Get current route
const isCurrentRoute = (component) => {
  return component === route.name
}
const closeMobileMenu = () => {
  store.dispatch('ToggleCloseMenu')
}

const formatName = (name) => {
  return props.isLocked ? name.replace(' (Optional)', '') : name
}
</script>

<template>
  <li v-show="!isHidden">
    <!-- No Router Link   -->
    <span v-if="!isRoute" class="molecule">
      <span class="molecule-ring"
            :class="{
                     'border-greys-200' : !isDeadline,
                     'bg-other-green' : isDeadline
              }">
      </span>
      <span class="ml-3.5 flex flex-col">
        <span class="font-medium text-h5">
          {{ name }}
        </span>
        <span class="uppercase text-p-sm text-greys-400">
          {{ day(date).format('MMMM DD, h:mm A') }}
        </span>
      </span>
    </span>
    <!-- Router Link -->
    <router-link v-if="isRoute && !isDisabled"
                 :to="{name: component}"
                 class="molecule"
                 active-class="active"
                 @click.prevent="closeMobileMenu">
      <span class="molecule-ring" :class="{'molecule-active': isConfigured}">
        <i v-if="isConfigured" class="fa-regular fa-check"></i>
        <span v-if="!isConfigured && index"
              class="molecule-number">
          {{index}}
        </span>
        <span v-else class="molecule-dot"/>
      </span>
      <span class="ml-3.5 font-light">
        {{formatName(name)}} <i v-show="isLocked" class="fa-solid fa-lock"></i>
      </span>
    </router-link>
    <!-- Disabled Link -->
    <span v-if="isRoute && isDisabled">
      <span class="molecule"
            :class="{
              'text-greys-300' : !isCurrentRoute(component),
              'text-product-400' : isCurrentRoute(component),
              'cursor-not-allowed' : isDisabled
            }">
        <i class="fa-regular fa-lock"></i>
        <span class="ml-5 font-light">
          {{name}}
        </span>
      </span>
    </span>
    <div v-if="!isLast"
         class="molecule-line"
         :class="{
           'molecule-line-dotted' : !isRoute,
           'bg-greys-200' : isRoute
         }"/>
  </li>
</template>

<style lang="scss" scoped>
.molecule {
  @apply flex flex-row items-center text-greys-300;
  &:hover {
    @apply border-product-400 text-product-400;
  }
  &-number {
    @apply text-p-sm;
  }
  &-ring {
    @apply w-5 h-5 rounded-full border-2 flex items-center justify-center;
  }
  &-dot {
    @apply w-1.5 h-1.5 rounded-full bg-greys-200;
  }
  &-line {
    &-dotted {
      @apply border border-dashed border-greys-200 h-10 -my-1;
    }
    @apply w-0.5 h-6 ml-2 my-1 ;
    &:hover {
      @apply bg-product-400;
    }
  }

  .molecule-ring {
    @apply border-greys-200;
    .molecule-dot {
      @apply border-greys-100;
    }
  }

  &.active {
    @apply text-product-400;
    .molecule-ring {
      @apply border-product-400;
      .molecule-dot {
        @apply bg-product-400;
      }
    }
  }
}
.molecule {
  .molecule-ring {
    &.molecule-active {
      @apply bg-other-green border-other-green;
      .molecule-dot {
        @apply hidden;
      }
      i {
        @apply text-white text-xs;
      }
    }
  }
}
</style>
