<script setup>
// Required Packages
import { computed, onMounted } from 'vue'
// Store
import store from '@/store'
// Base Components
import ModalBase from '@/components/Modals/ModalBase'
// Custom Components
import Accordian from '@/components/Modals/Categories/Accordian'
import BaseButton from '@/components/Base/Button/BaseButton'
import { v4 as uuidv4 } from 'uuid'
import { bitwiseOperators } from '@/utils'

const eventCategories = computed(() => {
  return store.getters.categories
})

const categoryLibrary = computed(() => {
  return store.getters.categoryLibrary
})

// UPDATE LOGIC HERE
const add = () => {
  store.dispatch('saveSelectedCategoriesFromLibrary').then(() => {
    if (eventCategories.value.length !== 0) {
      store.dispatch('updateEventConfigs', { eventId: store.getters.ebEventId, configKey: "event_config_completion", configValue: "32", isConfigValueBitMask: true, bitwiseOperation: bitwiseOperators.COMBINE }).then(() => {
        store.dispatch('getEventConfigs', { eventId: store.getters.ebEventId})
      }).catch((err) => {
        console.error(err)
      })
    }

  })
  store.dispatch('ResetModal')
}

const skip = () => {
  const payload = {
    id: uuidv4(),
    name: '',
    image: '',
    color: store.getters.eventSettings?.primaryColor ?? '#5C00A8'
  }
  store.dispatch('addEventCategory', payload)

  store.dispatch('setSkipCategoriesModal', true)
  store.dispatch('ResetModal')
}

const hasCategoriesSelect = computed(() => {
  return Object.keys(store.getters.selectedCategoryFromLibrary).length === 0
})

onMounted(() => {
  store.dispatch('fetchCategoryLibrary')
})

const resetCategory = () => {
  store.dispatch('resetSelectedCategoriesFromLibrary')
}
</script>

<template>
  <modal-base modal-class="modal-categories"
    :on-modal-close="resetCategory">
    <template #modal-title>
      <h3 class="modal-categories-title">
        Category Library
      </h3>
    </template>
    <template #modal-subContent>
      <p class="modal-categories-description">
        To begin, select from project categories below, or click "Skip" to add your own categories.
      </p>
    </template>
    <template #modal-content>
      <span class="modal-categories-accordian">
        <accordian v-for="(category, index) in categoryLibrary"
                   :key="category.name"
                   :should-open="index === 0"
                   :items="category"/>
      </span>
    </template>
    <template #modal-button>
      <span class="modal-categories-button-wrapper">
        <base-button id="cancelCategoryModal"
                     name="cancelCategoryModal"
                     :functions="skip"
                     class-name="modal-categories-button"
                     text="Skip, I'll Add My Own"
                     btn-type="text"/>
        <base-button id="addCategoryModal"
                     name="addCategoryModal"
                     text="Add"
                     :disabled="hasCategoriesSelect"
                     class-name="modal-categories-button"
                     :functions="add"/>
      </span>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.modal-categories {
  @apply bg-white max-w-[32.625rem] max-h-[46.875rem] px-4 py-4;
  &-title {
    @apply pb-2 pt-6 text-center font-medium text-h2;
  }
  &-description {
    @apply text-center text-p-sm text-[#151515] md:px-8 px-2;
  }
  &-accordian {
    @apply p-6 w-full flex flex-col overflow-hidden;
    &:hover {
      @apply overflow-y-auto;
    }
  }
  &-button {
    @apply capitalize text-p-sm px-4 py-2;
    &-wrapper {
      @apply w-full flex flex-row justify-end;
    }
  }
}
</style>
