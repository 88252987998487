export default {
  state: () => ({
    notification_status: {
      name: 'status',
      data: [
        {
          value: '',
          label: 'All statuses'
        },
        {
          value: 'Read',
          label: 'Read'
        },
        {
          value: 'Unread',
          label: 'Unread'
        },
        {
          value: 'All',
          label: 'All'
        }
      ]
    },
    notification_categories: {
      name: 'categories',
      data: [
        {
          value: '',
          label: 'All categories'
        },
        {
          value: 'Read',
          label: 'Read'
        },
        {
          value: 'Unread',
          label: 'Unread'
        },
        {
          value: 'All',
          label: 'All'
        }
      ]
    },
    notification_events: {
      name: 'events',
      data: [
        {
          value: '',
          label: 'All events'
        },
        {
          value: 'Read',
          label: 'Read'
        },
        {
          value: 'Unread',
          label: 'Unread'
        },
        {
          value: 'All',
          label: 'All'
        }
      ]
    },
  }),
  getters: {
    notificationStatus: state => state.notification_status,
    notificationCategories: state => state.notification_categories,
    notificationEvents: state => state.notification_events
  },
  mutations: {
    // To do
  },
  actions: {
    // To do
  }
}
