<script setup>
import { computed, onBeforeMount, onMounted, watch, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import store from '@/store'
import { ProjectTemplate } from 'project'
import PostService from "@/services/post"
import CommentService from "@/services/comment"
import FileService from '@/services/file'
import AttachmentService from '@/services/attachment'
import CategoryService from '@/services/category'
import { redirectToRoute, processStatuses, bitwiseOperators } from "@/utils"
import { displayModes } from 'project/src/utils/displayMode'
import dayjs from 'dayjs';
import BaseButton from '@/components/Base/Button/BaseButton'

const shareItem = reactive({
  heart: {
    icon:  'fa-solid fa-heart',
    number: 1
  },
  share: {
    icon: 'fa-regular fa-share-nodes',
    text: 'share'
  }
})

const mockStudents = reactive({
  student1: {
    name: 'Vince Carmen',
    handle: '@vincecarmen19',
    avatar: 'https://i.pravatar.cc/300',
    content: 'Grade 10 Student from Toronto. Interested in all things Space, Science and Minecraft.'
  },
  student2: {
    name: 'Carmen Vince',
    handle: '@vincecarmen21',
    avatar: `https://i.pravatar.cc/300?img=${Math.random()},`,
    content: 'Grade 10 student with a passion to help solve problems. I also enjoy playing shuffleboard on the weekends with my uncle Bert.'
  }
})

const router = useRouter()
const route = useRoute()

const eventStatus = computed(() => {
  return store.getters.ebEventStatus
})

const eventCategory = computed(() => {
  return store.getters.categories
})

const categoriesStatus = computed(() => {
  return store.getters.categoriesStatus
})

const hasEventCategory = computed(() => {
  return eventCategory?.value?.length === 0
})

const isProjectAccessible = computed(() => {
  return eventStatus.value === processStatuses.LOADED && isProjectAndPostsLoaded.value && categoriesStatus.value === processStatuses.LOADED
})

const projectOptions = computed (() => {
  return {
    project: {
      canEdit: false,
      canInvite: false,
      canComment: false,
      canLikeComment: false,
      showComments: true,
      showTeamLockIcon: true,
    },
    post: {
      canEditTitle: false,
      canEditCover: false,
      canEditInstruction: false,
      canEditDescription: false,
      canComment: true,
      showComments: true,
      defaultView: displayModes.VIEW,
      showDescriptionPlaceholderOnView: false,
    },
    attachment: {
      canEdit: false,
      canDownloadAttachment: false,
    },
    category: {
      showCategory: true, // Category Section
      showCategoryList: hasEventCategory.value, // Category List
      showLockIcon: true, // Lock Category
      canEditCategory: false, // Edit Category
    }
  }
})

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const theme = computed(() => {
  return store.getters.getEventTheme
})

const backToTemplate = async () => {
  const eventTemplate = await store.getters.getEventTemplate
  if (eventTemplate && eventTemplate.status !== "Draft") {
    store.dispatch('updateEventTemplate', { eventTemplateId: eventTemplate.id, status: "Draft" }).then((res) => {
      if (res.status === "Draft") {
        store.dispatch('updateEventConfigs', {
          eventId: store.getters.ebEventId,
          configKey: "event_config_completion",
          configValue: "8",
          isConfigValueBitMask: true,
          bitwiseOperation: bitwiseOperators.REMOVE
        }).then(() => {
          store.dispatch('getEventConfigs', { eventId: store.getters.ebEventId })
        }).catch((err) => {
          console.error(err)
        })
      }
    })
  }
  return router.push({ name: 'ProjectTemplates', params: { slug: route.params.slug } })
}

const projectSubmissionDeadline = computed(() => {
  return projectSubmissionDeadlineStatus.value === processStatuses.LOADED ? store.getters.projectSubmissionDeadline[0]?.endAt : null
})

const projectSubmissionDeadlineStatus = computed(() => {
  return store.getters.projectSubmissionDeadlineStatus
})

const uploadToFirestore = async (file) => {
  // To do, specify upload path to better organize assets in firestore
  const response = await FileService.upload({ file: file })
  return response.data.link
}

const sideMenuDesktop = computed(() => {
  return store.getters.sideMenu
})

const isPublic = computed(() => {
  return store.getters.adminPanel === 'public'
})

const project = computed(() => {
  return store.getters.activeProject
})

const eventTemplateUpdateStatus = computed(() => {
  return store.getters.eventTemplateUpdatingStatus
})

// Project Post List and Title/Image Edit view
const fetchPosts = (projectId) => {
  return new Promise((resolve, reject) => {
    PostService.getPosts({ projectId: projectId, limit: 10, include: "", nextPageToken: 0 }).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const onPostsLoad = (data) => {
  console.debug("onPostsLoad", data)
}

// Post Modal
const fetchPost = async (postId) => {
  return new Promise((resolve, reject) => {
    PostService.getPost({ postId: postId }).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const isProjectAndPostsLoaded = computed(() => {
  return store.getters.isPostsLoaded && store.getters.projectsLoaded
})

// const adminPanelType = computed(() => {
//   return store.getters.adminPanel
// })

watch(() => store.getters.ebEventStatus, () => {
  if (store.getters.ebEventStatus === processStatuses.LOADED) {
    store.dispatch('fetchEventCategories')
  }
})

onMounted(async () => {
  await store.dispatch('setProjectID', route.params.projectId)
  await store.dispatch('getProject', route.params.projectId)
  await store.dispatch('getPosts', route.params.projectId)
  await store.dispatch('fetchProjectSubmissionDeadline')
})

onBeforeMount(async () => {
  const slug = route.params.slug
  await store.dispatch('getEvent', slug)
  if (route?.query) {
    await store.dispatch('SetAdminPanelType', route.query.type)
  }
})

// Attachments
const fetchAttachments = async (postId) => {
  console.debug('fetchAttachments', postId)
  return await AttachmentService.getAttachments({ postId: postId })
}

// Comment functions
const user = computed(() => {
  return store.getters.user
})

const fetchCommentsOnPost = async (postId) => {
  return await CommentService.getCommentsByPostId(postId, "users")
}

const fetchCommentsOnProject = async (projectId) => {
  return await CommentService.getCommentsByProjectId(projectId, "users")
}

const fetchReplies = async (parentId) => {
  return await CommentService.getReplyComments(parentId, "users")
}

const fetchComment = async (commentId) => {
  return await CommentService.getComment(commentId)
}

const onCommentSave = async (projectId, payload) => {
  return await CommentService.createComment(projectId, payload)
}

const onCommentEdit = async (commentId, payload) => {
  return await CommentService.updateComment(commentId, payload)
}

const onCommentDelete = async (commentId) => {
  return await CommentService.deleteComment(commentId)
}

const onCommentLike = async (commentId) => {
  return await CommentService.likeComment(commentId)
}

const onCommentUnlike = async (commentId) => {
  return await CommentService.unlikeComment(commentId)
}

const onEventCategoryFetch = async () => {
  return new Promise((resolve, reject) => {
    CategoryService.getCategories(event.value.id).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}
</script>

<template>
  <div v-if="isProjectAndPostsLoaded"
       class="preview">
    <div class="w-full">
      <div class="nav-container">
        <nav v-if="!isPublic"
             class="nav"
             role="navigation">
          <span v-if="projectSubmissionDeadlineStatus === processStatuses.LOADING"
                class="nav-left">Loading submission deadline..</span>
          <span v-if="projectSubmissionDeadlineStatus === processStatuses.LOADED && projectSubmissionDeadline"
                class="text-sm mt-2 nav-left">
            <span class="font-semibold mr-1">Submission Deadline | </span>
           {{ dayjs(new Date(projectSubmissionDeadline)).format("MMMM DD YYYY, h:mmA") }}
         </span>

          <span class="nav-right">
            <span class="w-full flex flex-row md:justify-end justify-between md:relative md:w-auto">
              <base-button id="saveDraftBtn"
                           name="saveDraftBtn"
                           class-name="btn-draft"
                           btn-type="secondary"
                           text="save as draft"
                           :disabled="true"
                           :functions="backToTemplate"/>
              <base-button id="selectBtn"
                           name="selectBtn"
                           class-name="btn-select"
                           text="FINISH"
                           :disabled="true"
                           :is-loading="eventTemplateUpdateStatus === processStatuses.SAVING"
                           functions=""/>
            </span>
          </span>
        </nav>
      </div>
      <div class="flex flex-row"
           :class="{ 'justify-center mt-[5rem]' : isPublic }">
        <ul class="preview-social">
          <li v-for="(item, key) in shareItem"
              :key="key"
              class="preview-social-item">
          <span class="preview-social-icon">
            <i :class="`${item.icon} ${key === 'heart' ? 'text-other-red' : ''}`"/>
          </span>
            <span class="preview-social-icon-text">
            {{ item.number }}
            {{ item.text }}
          </span>
          </li>
        </ul>
        <project-template v-if="isProjectAccessible"
                          :client-event-theme="theme"
                          :choose-btn="backToTemplate"
                          :redirect-to-route="redirectToRoute"
                          on-project-detail-save=""
                          on-project-image-upload=""
                          on-project-instructions-save=""
                          on-project-video-upload=""
                          on-project-video-save=""
                          :side-menu-desktop="sideMenuDesktop"
                          :project="project"
                          :options="projectOptions"
                          :max-char-main-text-area="700"
                          :event="event"
                          :project-main-edit=false
                          project-edit-trigger=""
                          project-main-toggle=""
                          project-card-error=""
                          :fetch-posts="fetchPosts"
                          :fetch-post="fetchPost"
                          :on-posts-load="onPostsLoad"
                          on-posts-edit=""
                          on-posts-save=""
                          on-posts-cancel=""
                          on-post-update=""
                          on-post-image-upload=""
                          on-post-save=""
                          comments-data=""
                          :user="user"
                          :fetch-comments-on-post="fetchCommentsOnPost"
                          :fetch-comments-on-project="fetchCommentsOnProject"
                          :fetch-replies="fetchReplies"
                          :fetch-comment="fetchComment"
                          :on-comment-save="onCommentSave"
                          :on-comment-edit="onCommentEdit"
                          :on-comment-delete="onCommentDelete"
                          :on-comment-like="onCommentLike"
                          :on-comment-unlike="onCommentUnlike"
                          :fetch-attachments="fetchAttachments"
                          on-attachment-update=""
                          on-attachments-save=""
                          on-attachment-create=""
                          :on-attachment-upload="uploadToFirestore"
                          :on-event-category-fetch="onEventCategoryFetch"
                          event-categories=""
                          :is-event-published="false"
                          can-delete-comment=""/>
        <ul v-if="isPublic"
            class="preview-student-list">
          <li v-for="(value, key) in mockStudents"
              :key="key"
              class="preview-student">
            <img :src="value.avatar"
                 :alt="value.name"
                 class="preview-student-avatar"/>
            <h1 class="preview-student-name">
              {{ value.name }}
            </h1>
            <h6 class="preview-student-handle">
              {{ value.handle }}
            </h6>
            <p class="preview-student-content">
              {{ value.content }}
            </p>
            <base-button functions=""
                         :disabled="true"
                         btn-type="secondary"
                         text="View Profile"/>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.preview {
  @apply mt-[4rem] w-full flex flex-row justify-center;
  &-social {
    @apply -mt-[1rem] mr-[50px];
    &-item {
      @apply flex flex-row items-center gap-x-3 py-2 w-[97px];
    }

    &-icon {
      @apply shadow w-10 h-10 bg-white flex items-center justify-center rounded-full;
      &-text {
        @apply text-p-sm text-blacks-500 capitalize;
      }
    }
  }

  &-student {
    @apply w-[247px] h-[340px] shadow bg-white rounded-xl mb-11 p-[24px] flex flex-col items-center;
    &-list {
      @apply w-auto ml-[40px];
    }

    &-avatar {
      @apply w-[100px] h-[100px] rounded-full mb-2;
    }

    &-name {
      @apply text-blacks-500 font-medium text-h3;
    }

    &-handle {
      @apply text-blacks-300 text-h6;
    }

    &-content {
      @apply text-p-xs py-3;
    }
  }
}

.nav {
  @apply py-3 -ml-4 flex flex-row justify-between bg-white z-[1] fixed bottom-0 shadow w-full;
  @apply md:relative md:ml-0 md:py-8 md:bg-transparent md:shadow-none md:max-w-[55rem];
  &-right {
    @apply flex md:justify-end w-full sm:justify-between ml-4 px-2 md:px-0 md:ml-0 md:w-auto;
  }

  &-left {
    @apply md:inline-flex hidden;
  }

  &-container {
    @apply w-full flex flex-col ml-[9.2rem];
  }
}

.btn {
  &-back {
    @apply text-p-md font-normal border rounded border-product-400/[.0] text-product-400 capitalize hidden md:inline-flex items-center hover:border-product-400/[1];
    i {
      @apply mr-2;
    }
  }

  &-draft {
    @apply rounded w-[8rem] text-sm capitalize py-2 hover:bg-product-400 border;
    &:enabled {
      @apply hover:text-white;
    }

    &:disabled {
      @apply border-product-400 text-product-400 hover:bg-transparent opacity-40;
    }
  }

  &-choose {
    @apply text-purple-700 text-sm border-transparent capitalize px-2 md:mr-2;
    &:hover {
      @apply border-purple-500 rounded;
    }
  }

  &-select {
    @apply text-sm bg-product-400 px-3.5 rounded md:ml-2 w-[9rem] py-2 border border-product-400;
    &:enabled {
      @apply hover:bg-transparent hover:text-product-400;
    }

    &:disabled {
      @apply border-product-100
    }
  }
}
</style>
