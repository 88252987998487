const LIVE = 'Published'
const DRAFT = 'Draft'
const ARCHIVED = 'Archived'

const EventStatuses = [LIVE, DRAFT, ARCHIVED]

export const eventStatuses = {
    LIVE,
    DRAFT,
    ARCHIVED,
    EventStatuses
}
