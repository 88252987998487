<script setup>
// import packages
import { computed } from 'vue'
// import components
import store from '@/store'
import { isMobile } from '@/utils/mobile-devices'
// Overlay
const overlaySelected = () => {
  if (modalData.value.data?.disableOverlayClose) return
  if (store.getters.isLoggedIn) {
    store.dispatch('OverlaySelected')
    if (modal.value) store.dispatch('ToggleModal')
  }
}
// Modal
const modal = computed(() => {
  return store.getters.modal || false
})
const modalData = computed(() => {
  return store.getters.modalData || {}
})
// Forgot Password
const isForgetPassword = computed(() => {
  return (store.getters.typeOfModal === 'forget-password'
    || store.getters.typeOfModal === 'check-reset-password-email')
    || false
})
// Mobile Menu
const mobileMenu = computed(() => {
  return store.getters.menu || false
})
// Profile Menu
const profileMenu = computed(() => {
  return store.getters.profileMenu && isMobile || false
})

const isEventBuilder = computed(() => {
  return window.location.pathname.includes('event-builder')
})
</script>

<template>
  <div class="overlay"
       :class="{
          'hidden' : !modal && !mobileMenu && !profileMenu,
          'z-30': modal,
          'z-[3]' : !modal && !isEventBuilder,
          'overlay-regular' : !isForgetPassword,
          'overlay-password' : isForgetPassword
        }"
       @click="overlaySelected">
  </div>
</template>

<style lang="scss" scoped>
.overlay {
  @apply h-full w-screen fixed md:absolute;
  &-regular {
    @apply bg-slate-900/80;
  }
  &-password {
    @apply bg-white;
    &-light {
      @apply w-20 md:w-28 h-auto ml-9 transform -scale-y-100 -rotate-180;
    }
  }
}
</style>
