<script setup>
import { computed, ref, onMounted } from 'vue'
// Store
import store from '@/store'
// Custom elements
import ModalBase from '@/components/Modals/ModalBase'
import BaseButton from '@/components/Base/Button/BaseButton'
// Tabs elements
import tabLibrary from '@/components/Modals/Icons/Library'
import tabCustom from '@/components/Modals/Icons/Custom'
// Select Tab
const selectedTab = ref(null)
// Image upload
const imageUploadFile = ref(null)
const imageIcon = ref('')

const supportedFeatures = {
  'choose-icon': {
    name: 'Icon Library',
    value: 'lib'
  },
  'upload': {
    name: 'Upload Your Own',
    value: 'upload'
  }
}

// Modal Type
const modalType = computed(() => {
  return store.getters.typeOfModal
})

const modalData = computed(() => {
  return store.getters.modalData?.data
})

// Close Modal
const closeIconModal = () => {
  store.dispatch('ResetModal')
}
// Select Tab
const selectTab = (tab) => {
  selectedTab.value = tab
}
// Upload Image
const setImageUploadFile = (file) => {
  imageUploadFile.value = file
}
// Select Icon
const setImageIcon = (icon) => {
  imageIcon.value = icon
}
// User selection
const chooseUpload = (tab = 'lib') => {
  if (tab === 'lib') {
    store.dispatch('ConfirmAction', imageIcon.value)
  } else {
    store.dispatch('ConfirmAction', imageUploadFile.value)
  }
  store.dispatch('ResetModal')
}

const getTitle = () => {
  // Allows to override the title for sponsor page
  if (modalData.value?.title) {
    return modalData.value?.title
  }

  return 'Choose Your Icon'
}

const tabs = computed(() => {
  // override the tabs
  if (modalData.value?.features && modalData.value?.features.length > 0) {
    return modalData.value?.features.map(m => {
      return supportedFeatures[m]
    })
  }
  return Object.values(supportedFeatures)
})

onMounted(() => {
  // Default to first tab
  selectedTab.value = tabs.value[0].value
})
</script>

<template>
  <modal-base modal-class="icons-modal">
    <template #modal-title>
      <h4 class="icons-modal-title">
        {{ getTitle() }}
      </h4>
    </template>
    <template #modal-subContent>
      <div class="icons-modal-tabs">
        <span v-for="tab in tabs"
              :key="tab.name"
              class="icons-modal-tab"
              :class="{
                'icons-modal-tab-active' : selectedTab === tab.value,
                'icons-modal-tab-inActive' : selectedTab !== tab.value
              }"
              @click.prevent="()=>selectTab(tab.value)">
          {{tab.name}}
        </span>
      </div>
    </template>
    <template #modal-content>
      <section class="icons-modal-content no-scrollbar">
        <tab-library v-if="selectedTab === 'lib'"
                     :modal-type="modalType"
                     :icon-selected="setImageIcon"
                     :icon-img="imageIcon"/>
        <tab-custom v-if="selectedTab === 'upload'"
                    :set-image-upload-file="setImageUploadFile"
                    :image-upload-file="imageUploadFile"/>
      </section>
    </template>
    <template #modal-button>
      <div class="icons-modal-button-container">
        <base-button id="closeIconButton"
                     :functions="closeIconModal"
                     class-name="icons-modal-button"
                     name="closeIconButton"
                     text="cancel"
                     btn-type="text">
          Cancel
        </base-button>
        <base-button id="chooseIconButton"
                     :functions="() => chooseUpload(selectedTab)"
                     class-name="icons-modal-button"
                     :disabled="imageIcon.length === 0 && imageUploadFile === null"
                     name="chooseIconButton"
                     text="choose">
          Choose
        </base-button>
      </div>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.icons-modal {
  @apply bg-white max-w-[35rem] min-h-[31.25rem] px-12 py-4;
  &-title {
    @apply text-h4 font-medium w-full text-center pt-2 pb-4 border-b;
  }
  &-content {
    @apply h-[17rem] overflow-hidden;
    &:hover {
      @apply overflow-y-auto;
    }
  }
  &-tabs {
    @apply flex flex-row pt-6 pb-8 items-center;
  }
  &-tab {
    @apply cursor-pointer text-p-sm font-medium capitalize pr-4;
    &-active {
      @apply text-blacks-500 underline-offset-8 underline decoration-product-500 decoration-2;
    }
    &-inActive {
      @apply text-blacks-300;
      &:hover {
        @apply text-blacks-500 underline-offset-8 underline decoration-product-500 decoration-2;
      }
    }
    &:last-child {
      @apply pr-0;
    }
  }
  &-button {
    @apply px-5 py-2 capitalize;
    &-container {
      @apply flex flex-row w-full justify-end py-6;
    }
  }
}
</style>
