<script setup>
import { reactive, computed, onMounted } from 'vue'
import store from '@/store'
import ModalLayout from '@/components/Modals/ModalBase'
import BaseButton from '@/components/Base/Button/BaseButton'
import TextareaField from '@/components/Base/Fields/TextareaField'
import { handleBackButton } from '@/utils'

const confirmModal = reactive({
  feedback: ''
})

const confirmData = computed(() => {
  return store.getters.modalData
})

const errorMessage = computed(() => {
  return store.getters.feedbackValidationError
})
const feedbackError = computed(() => {
  return store.getters.feedbackError
})

const confirmAction = () => {
  if(errorMessage.value['feedback'] !== '') {
    store.dispatch('eventCancelFeedback', confirmModal.feedback)
    store.dispatch('ConfirmAction')
  }
}

const cancelAction = () => {
  store.dispatch('CancelAction')
}

const isDelete = (text) => {
  return text.toLowerCase() === 'delete'
}

onMounted(() => {
  handleBackButton()
})

const feedbackValidateError = (type) => {
  let temp = ''
  Object.keys(errorMessage.value).filter(item =>{
    if (item === type) {
      temp = errorMessage.value[type]
    }
  })
  return temp
}
</script>

<template>
  <modal-layout :is-close="true"
                class="confirm">
    <template #modal-title>
      <span class="confirm-header">
        <h1 class="confirm-title">
          {{ confirmData?.data?.title }}
        </h1>
      </span>
    </template>
    <template #modal-subContent>
      <span class="confirm-body">
        <p class="confirm-content">
          {{ confirmData?.data?.description }}
        </p>
      </span>
    </template>

    <!-- cancel feedback with textarea section -->
    <template #modal-content>
      <span class="confirm-message">
        <p class="confirm-whyCancel">
          {{ confirmData?.data?.eventCancelFeedback }}
        </p>
        <textarea-field v-model="confirmModal.feedback"
                        :model-value="confirmModal.feedback"
                        input-place-holder="Write your feedback"
                        class-name="border rounded-2xl border-greys-200 h-[5rem] text-p-md"
                        :errors="feedbackValidateError('feedback')"/>
      </span>
      <div v-if="feedbackError && feedbackError.length > 0" class="confirm-error">{{ feedbackError }}</div>
    <!-- team response time section -->
      <span class="confirm-body">
        <p class="confirm-teamResponseTime">
          * {{ confirmData?.data?.eventTeamResponseTime }}
        </p>
      </span>
    </template>

    <!-- Back to Events & Submit Buttons -->
    <template #modal-button>
      <span class="confirm-btn-container">
          <base-button :functions="cancelAction"
                       :text="confirmData?.data?.cancelButton"
                       class-name="confirm-btn confirm-btn-back"
                        btn-type="secondary"/>
          <base-button :functions="confirmAction"
                       :text="confirmData?.data?.confirmButton"
                       :class-name="`confirm-btn ${isDelete(confirmData?.data?.confirmButton) ?
                        'confirm-btn-delete' : 'confirm-btn-approve' }`"
                        btn-type="primary"/>
        </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.confirm {
  @apply bg-white max-w-[29rem] px-9 py-8;
  &-header {
    @apply flex flex-col w-full justify-center items-center;
  }
  &-title {
    @apply normal-case font-medium text-h4 text-center text-blacks-500 pb-3;
  }
  &-body {
    @apply flex flex-col justify-center items-center;
  }
  &-content {
    @apply text-blacks-300 text-p-md text-center;
  }
  &-whyCancel{
    @apply text-p-md text-blacks-500;
  }
  &-message {
    @apply mt-10 mb-2;
  }
  &-teamResponseTime{
    @apply text-blacks-200 text-p-xs mb-10;
  }
  &-error {
    @apply text-red-500 text-p-md font-medium
  }
  &-btn {
    @apply w-[12rem] h-[3rem] mx-1 normal-case;
    &-container {
      @apply w-full flex flex-row justify-center;
    }
    &-back {
      @apply text-primary border-primary font-medium;
      &:hover {
        @apply border-transparent bg-white text-product-500;
      }
    }
    &-decline {
      @apply bg-other-red text-white font-medium uppercase;
      &:hover {
        @apply opacity-80;
      }
    }
    &-approve {
      @apply bg-other-green text-white font-medium;
      &:hover {
        @apply opacity-80;
      }
    }
    &-delete {
      @apply bg-other-red text-white font-medium;
      &:hover {
        @apply opacity-80;
      }
    }
  }
}
</style>
