<script setup>
import { computed } from 'vue'
import { FocusTrap } from 'focus-trap-vue'
import store from '@/store'

import LoadModal from '@/components/Base/Loading/Modal'

const props = defineProps({
  modalClass: String,
  isClose: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  onModalClose: {
    type: Function,
    default: () => {}
  }
})

const toggleModal = () => {
  store.dispatch('ResetEventModal')
  store.dispatch('ToggleModal')
  store.dispatch('ToggleOverlay')
  store.dispatch('ResetModal')
  onModalClose()
}

const modalState = computed({
  get: () => store.getters.modal,
  set: (value) => {
    if (value) {
      store.dispatch('ShowModal')
    } else  {
      onModalClose()
    }
  }
})

const onModalClose = () => {
  if (props.onModalClose && typeof props.onModalClose === 'function') {
    props.onModalClose()
  }
}
</script>

<template>
  <focus-trap v-model:active="modalState" :on-deactivate="onModalClose">
    <main :class="`modal-main ${modalClass ? modalClass : ''}`">
      <span class="modal-hero">
        <!--
          focus trap requires at least one table item to work
          use button here to stop focus trap from complaining
        -->
        <button v-if="!isClose"
                class="modal-close-btn" @click="toggleModal">
          <i class="fa-light fa-lg fa-xmark modal-hero-icon"/>
        </button>
        <slot v-if="!isLoading"
              name="modal-title" />
      </span>
      <span v-if="isLoading"
            class="modal-loading-container">
        <load-modal />
      </span>
      <slot v-if="!isLoading"
            class="ease-in duration-300"
            name="modal-subContent" />
      <slot v-if="!isLoading"
            class="ease-in duration-300"
            name="modal-content" />
      <slot v-if="!isLoading"
            class="ease-in duration-300"
            name="modal-button" />
      <slot v-if="!isLoading"
            class="ease-in duration-300"
            name="modal-footer" />
    </main>
  </focus-trap>
</template>

<style lang="scss" scoped>
.modal {
  &-loading {
    &-container {
      @apply w-full min-w-[20rem] min-h-[20rem] flex items-center justify-center ease-in-out duration-300 #{!important};
    }
  }
  &-main {
    @apply border rounded-xl flex flex-col w-screen md:h-auto md:w-[60rem];
  }
  &-hero {
    @apply w-full;
    &-text {
      @apply text-center font-bold mt-16 text-4xl;
    }
  }
  &-close {
    &-btn {
      @apply absolute right-2 py-2 px-3 cursor-pointer;
    }
  }
}
.header {
  @apply w-full text-center;
}
</style>
