<script setup>
// Required packages
import { reactive, computed, onMounted } from 'vue'
// Store
import store from '@/store'
// Route
import { useRouter, useRoute } from 'vue-router'
// Base Components
import {pbButton, pbInputField} from 'pb-components'
// Helper
import { processStatuses } from '@/utils'
// Auth Component
import AuthBase from './AuthBase'
// Firebase
import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup, fetchSignInMethodsForEmail } from 'firebase/auth'
import { auth } from '@/utils/firebase'
// Config
import { config } from '@/config'

// Set Auth Objects
const user = reactive({
  usernameOrEmail: '',
  password: ''
})

let router = useRouter()
const route = useRoute()

const authLoading = computed(() => {
  return store.getters.authLoading === processStatuses.LOADING
})
const signIn = () => {
  let payload = {}
  payload = {
    usernameOrEmail: user.usernameOrEmail,
    password: user.password
  }
  store.dispatch('login', payload).then(() => {
    const { query } = route
    if (query?.returnUrl) {
      router.push(query.returnUrl)
    } else {
      router.push({name: 'Events'})
    }
  }).catch(err => {
    if (!store.getters.isUserVerified && err === 'Account is not verified')  {
      store.dispatch('ToggleModalLogin')
      store.dispatch('ChangeModalType', 'verify')
    }
    console.error(err)
  })
}
const signUp = () => {
  router.push('/signup')
}
const resetPassword = () => {
  store.dispatch('ToggleModalLogin')
  store.dispatch('ChangeModalType', 'forget-password')
}
const passwordError = computed(() => {
  return store.getters.passwordError
})
const userNameEmailError = computed(() => {
  return store.getters.userNameEmailError
})
const loginServerError = computed(() => {
  return store.getters.loginServerError
})

onMounted(() => {
  if (store.getters.isLoggedIn) {
    const { query } = route
    if (query?.returnUrl) {
      router.push(query.returnUrl)
    } else {
      router.push({name: 'Home'})
    }
  } else {
    window.location.href = config.AUTH_URL + '/selfserve/login'
  }
})
const facebook = () => {
  return new FacebookAuthProvider()
}

const google = () => {
  return new GoogleAuthProvider()
}

const providerLogin = (provider, providerName) => {
  signInWithPopup(auth, provider).then(response => {
    checkProviderLoginResponse(response, providerName)
  }).catch((err) => {
    console.error(err)
    if (err.code === 'auth/account-exists-with-different-credential' || err.code === 'auth/email-already-in-use') {
      fetchSignInMethodsForEmail(auth, err.email).then((providers) => {
        store.dispatch('SetLoginError', `Please login with your ${providers[0]} account first to link your account.`)
      })
    } else if (err.code !== 'auth/popup-closed-by-user') {
      store.dispatch('SetLoginError', 'An unexpected error occurred. Please try again.')
    }
  })
}

const checkProviderLoginResponse = (response, providerName) => {
  if (!response.user.email) {
    this.error = 'An email address is required to sign-in.'
    response.user.delete()
    return
  }

  auth.currentUser.getIdToken().then(token => {
    const data = {
      idToken: token,
      signupSource: providerName,
    }
    store.dispatch('firebaseLogin', data).then(() => {
      const { query } = route
      if (query?.returnUrl) {
        router.push(query.returnUrl)
      } else {
        router.push({name: 'Events'})
      }
    })
  })
}

const makeLogin = () => {
  window.open(`${config.API_ROOT}/oauth/login?plus=true`, '_blank', 'location=0,status=0,width=1200,height=800')
  window.addEventListener('message', event => {
    if (event.origin) {
      makeCallback(event.data)
    }
  })
}

const makeCallback = (data) => {
  store.dispatch('makeLogin', JSON.parse(data)).then(() => {
    const { query } = route
    if (query?.returnUrl) {
      router.push(query.returnUrl)
    } else {
      router.push({name: 'Events'})
    }
  })
}
</script>

<template>
  <auth-base :auth-submit-function="signIn">
    <template #auth-title>
      Welcome to ProjectBoard
    </template>
    <template #auth-description>
      Connect and celebrate young innovators worldwide with STEM project showcases.
    </template>
    <template #form-title>
      Login
    </template>
    <template #form-subText>
      Not an User? <span class="loginPage-form_link"
                         tabindex="1"
                         qa="qa-sign-up"
                         @click.prevent="signUp">
      Sign up
    </span>
    </template>
    <template #form-inputs>
      <span>
        <label id="username"
               for="username"
               class="loginPage-form_label">
          Username or Email
        </label>
        <pb-input-field v-model="user.usernameOrEmail"
                        label-text=""
                        input-id="username"
                        input-aria-label="username"
                        input-place-holder="Type Username or Email"
                        :errors="userNameEmailError"
                        class-name="w-[22rem] text-p-xs h-[2.5rem] border-purple bg-purple/[.05]"
                        :model-value="user.usernameOrEmail" />
      </span>
      <span class="pt-1 max-w-[23rem]">
        <label id="password"
               for="password"
               class="loginPage-form_label">
          Password
        </label>
        <pb-input-field v-model="user.password"
                        label-text=""
                        input-type="password"
                        input-id="password"
                        input-aria-label="password"
                        input-place-holder="Type Password"
                        :errors="passwordError"
                        class-name="w-[22rem] text-p-xs h-[2.5rem] border-purple bg-purple/[.05]"
                        :model-value="user.password" />
        <div class="py-2">
          <div class="loginPage-icon-container">
            <div class="loginPage-icon px-2"
                 @click="providerLogin(google(), 'google')">
              <i class="fab fa-google" />
            </div>
            <div v-if="false"
                 class="loginPage-icon px-2"
                 @click="providerLogin(facebook(), 'facebook')">
              <i class="fab fa-facebook" />
            </div>
            <div class="loginPage-icon px-2"
                 @click="makeLogin">
              <img src="/images/makey-logo.svg" alt="Make.co logo" class="w-auto h-[1.7rem]" />
            </div>
          </div>
        </div>
        <div class="py-2">
          <p class="loginPage-form_subText">Forget Your Password?</p>
          <ul class="loginPage-form-passwordContainer">
            <li class="ml-1 font-normal text-p-xs"
                qa="qa-reset-password"
                @click.prevent="resetPassword">
              Reset Password
            </li>
            <li v-if="false"
                class="ml-1" >| Email Login Link</li>
          </ul>
        </div>
      </span>
      <div class="error"
           qa="qa-error-message">
        {{loginServerError?.usernameOrEmail || loginServerError?.password || loginServerError }}
      </div>
    </template>
    <template #form-submissions>
      <pb-button id="login"
                 name="login"
                 aria-label="login"
                 :disabled="authLoading"
                 :is-loading="authLoading"
                 class-name="loginPage-btn"
                 :functions="signIn"
                 text="Login" />
    </template>
    <template #third-party-login>
      <template v-if="1 == 2">
        <ul class="loginPage-form-otherLogin">
          <li class="loginPage-form-loginCircle">
            <i class="fa-brands fa-google-plus-g"/>
          </li>
          <li class="loginPage-form-loginCircle">
            <i class="fa-solid fa-phone"/>
          </li>
          <li class="loginPage-form-loginCircle">
            <img class="w-5 h-5"
                 alt="windowsLogin"
                 src="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/logo%2Fmslogo-99b72510-3b81-11ed-8dc3-99376c705d0f?alt=media&token=f6970eb1-efa8-44da-81c0-88f6afaee306" />
          </li>
        </ul>
      </template>
    </template>
  </auth-base>
</template>

<style lang="scss" scoped>
.loginPage {
  @apply px-4 md:px-0 md:pl-12 h-screen flex md:items-center bg-white/[0.5] bg-center bg-contain bg-no-repeat bg-bottom bg-[url('https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2FMobile%20background.png?alt=media&token=4379ec37-bd49-4c7d-b29c-dba3fa11290c')] md:bg-right-bottom md:bg-[url('https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2FDesktop.png?alt=media&token=412887d9-5d19-4c66-9aad-3a2dce13a3fd')];
  &-btn {
    @apply bg-product-400 py-3 w-[22rem] rounded text-p-md font-medium;
  }
  &-formContainer {
    @apply flex w-full h-full pt-28 md:pt-0 md:items-center justify-center md:w-1/2 bg-[length:7rem] bg-no-repeat md:bg-left-top bg-right-top md:bg-[url('https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2FSelfServe_large-Lamps-vertical.png?alt=media&token=ccb3955f-ff0e-45b8-b983-dba5de54b762')] bg-[url('https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Flarge-Lamps.png?alt=media&token=0f55e80d-87aa-48fa-b4ae-155439df5fb6')];
  }
  &-form {
    @apply flex flex-col items-center relative z-20;
    &-headerContext {
      @apply w-full mb-12;
    }
    &_header {
      @apply text-center text-4xl font-bold;
    }
    &_subText {
      @apply text-center text-p-xs py-1 text-gray-400;
    }
    &_link {
      @apply text-primary font-bold cursor-pointer;
      &:focus {
        @apply outline-primary outline outline-1;
      }
    }
    &_label {
      @apply text-p-md ;
    }
    &-passwordContainer {
      @apply flex flex-row text-xs text-primary px-1.5 items-center justify-center font-bold;
      & li {
        @apply cursor-pointer;
      }
    }
    &-loginContainer {
      @apply py-4 flex flex-col items-center;
    }
    &-otherLogin {
      @apply flex flex-row justify-around w-[8rem];
    }
  }
  &-icon {
    @apply text-2xl text-primary w-[3rem] h-[3rem] cursor-pointer;
    &-container {
      @apply flex flex-row items-center justify-center;
    }
  }
}
</style>
