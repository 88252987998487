<script setup>
const props = defineProps({
  id: String,
  name: String,
  ariaLabel: String,
  btnType: {
    type: String,
    default: 'primary'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  text: {
    type: String,
    required: true
  },
  className: String,
  functions: {
    type: Function,
    required: true
  },
  tabIndex: {
    type: Number,
    default: 0
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  isLocked: {
    type: Boolean,
    default: false
  },
  tooltip: {
    type: String
  }
})

const getBtnClass = (type = 'primary') => {
  return `btn-${type} ${props.className ? props.className : 'btn-size'}`
}
</script>

<template>
  <button :id="id"
          :name="name"
          :aria-label="ariaLabel"
          :disabled=disabled
          :tabindex="tabIndex"
          class="btn"
          :tooltip="tooltip"
          :qa="`qa-${id}-btn`"
          :class='[getBtnClass(btnType), tooltip && tooltip !== "" ? "tooltip" : ""]'
          @click.prevent="functions">
    <template v-if="isLoading">
      <svg class="btn-spin"
           fill="none"
           viewBox="0 0 24 24">
        <circle class="btn-spin-layer1"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4" />
        <path class="btn-spin-layer2"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
    </template>
    <slot name="contents">
      <span :class="{'inline-flex flex-row' : isLocked}">
        <i v-if="isLocked"
           class="fa-solid fa-lock-keyhole mt-0.5 mr-2" />
        {{ text }}
      </span>
    </slot>
  </button>
</template>

<style lang="scss" scoped>
.btn {
  /* General */
  @apply inline-flex items-center justify-center relative;
  &:disabled {
    @apply cursor-not-allowed;
  }
  &-size {
    @apply py-2 px-6;
  }
  // Primary
  &-primary {
    @apply text-background-500 rounded-lg font-medium;
    &:disabled {
      @apply bg-primary bg-product-400/[.3];
    }
    &:enabled {
      &:focus {
        @apply outline-secondary outline outline-1;
      }
      @apply bg-primary;
      &:hover {
        @apply bg-secondary;
      }
    }
  }
  // Secondary
  &-secondary {
    @apply border-primary text-primary rounded-lg border-2 font-medium;
    &:disabled {
      @apply border-product-400/[.3] text-product-400/[.3];
    }
    &:enabled {
      &:focus {
        @apply outline-primary outline outline-1;
      }
      &:hover {
        @apply border-primary border-opacity-50 text-primary text-opacity-50;
      }
    }
  }
  // Text
  &-text {
    @apply text-primary font-medium;
    &:enabled {
      &:focus {
        @apply outline-primary outline outline-0;
      }
    }
    &:disabled {
      @apply text-product-400/[.3];
    }
  }
  // Tertiary
  &-tertiary {
    @apply bg-greys-50 font-medium text-blacks-500 rounded-xl;
    &:enabled {
      &:focus {
        @apply bg-greys-100;
      }
    }
    &:disabled {
      @apply text-blacks-200;
    }
  }
  // Success
  &-success {
    @apply text-background-500 rounded-lg font-medium;
    &:disabled {
      @apply bg-other-green bg-other-green/[.3];
    }
    &:enabled {
      &:focus {
        @apply outline-other-green outline outline-1;
      }
      @apply bg-other-green;
      &:hover {
        @apply bg-other-green;
      }
    }
  }
// Alert
  &-alert {
    @apply text-background-500 rounded-lg font-medium;
    &:disabled {
      @apply bg-other-red bg-other-red/[.3];
    }
    &:enabled {
      &:focus {
        @apply outline-other-red outline outline-1;
      }
      @apply bg-other-red;
      &:hover {
        @apply bg-other-red;
      }
    }
  }
  /* Loading Screen */
  &-spin {
    @apply animate-spin mr-3 h-5 w-5;
    &-layer1 {
      @apply opacity-25;
    }
    &-layer2 {
      @apply opacity-75;
    }
  }
  &.tooltip[tooltip]:hover:after {
    content: attr(tooltip);
    text-transform: none;
    top: calc(100% + 0.5rem);
    @apply bg-greys-400 right-0 absolute rounded-xl text-xs px-3 py-1 w-[12rem];
  }
}
</style>
