<script setup>
// Required Component
import { ref, reactive, onMounted, computed } from 'vue'
// Store
import store from '@/store'
// Custom components
import ModalBase from '@/components/Modals/ModalBase'
// Base components
import BaseButton from '@/components/Base/Button/BaseButton'
import { processStatuses } from '@/utils'

const organizationProperties = reactive({
  isEventsStatusLoaded: false,
  remainingPremiumEvents: 0,
  remainingBasicEvents: 0,
  disablePremium: false,
  disableBasic: false
})

const hasPlansSubscription = (planName) => {
  return store.getters.hasSubscription(planName)
}

const selectedEventType = ref('premium')

const createEvent = (type='free') => {
  store.dispatch('ResetModal')
  store.dispatch('createEventType', type)
  store.dispatch('ToggleModalEvent')
  store.dispatch('ResetEventModal')
  store.dispatch('GetEvents')
}

const closeCreateEventModal = () => {
  store.dispatch('ResetModal')
}

const remainingPaidEvents = computed(() => {
  return store.getters.getAllRemainingPaidEvents
})

const setOrganizationProperties = () => {
  organizationProperties.isEventsStatusLoaded = store.getters.eventsStatus === processStatuses.LOADED

  for (const [planName, value] of Object.entries(remainingPaidEvents.value)) {
    if (value > 0) {
      selectedEventType.value = planName
      break
    }
  }
}

onMounted(() => {
  setOrganizationProperties()
})
</script>

<template>
  <modal-base :is-close="true"
              modal-class="create-event">
    <template #modal-title>
      <h2 class="create-event-title">
        Start Showcase
      </h2>
    </template>
    <template #modal-subContent>
      <p class="create-event-subTitle">
        <template v-if="hasPlansSubscription('basic') && hasPlansSubscription('premium')">
          You have both basic and premium plans available to you. Please choose one to begin!
        </template>
        <template v-else-if="hasPlansSubscription('basic')">
          You have basic plan available to you. Please confirm and create a new Showcase.
        </template>
        <template v-else-if="hasPlansSubscription('premium')">
          You have premium plan available to you. Please confirm and create a new showcase.
        </template>
      </p>
    </template>
    <template #modal-content>
      <span class="create-event-radio-container">
        <span v-for="[planName, numberRemaining] of Object.entries(remainingPaidEvents)"
              :key="`plan_modal_${planName}`"
              class="create-event-radio-item"
              :class="{
                'create-event-radio-item-enabled' : numberRemaining > 0,
                'create-event-radio-item-disabled' : numberRemaining === 0
              }">
          <input :id="`${planName}-create`"
                 v-model="selectedEventType"
                 class="create-event-radio" :disabled="numberRemaining === 0" type="radio" :name="`${planName}-create`" :value="planName">
          <label class="create-event-radio-text"
                 :for="`${planName}-create`">
            {{ planName }} ({{numberRemaining}} Remaining)
          </label>
        </span>
      </span>
    </template>
    <template #modal-button>
      <span class="create-event-btn-container">
        <base-button id="closeCreateEventModal"
                     :functions="closeCreateEventModal"
                     name="closeCreateEventModal"
                     class-name="create-event-btn create-event-btn-cancel"
                     btn-type="text"
                     text="Cancel" />
        <base-button id="createEventModal"
                   :functions="() => createEvent(selectedEventType)"
                   name="createEventModal"
                   class-name="create-event-btn"
                   text="Create Event" />
      </span>
    </template>
  </modal-base>
</template>

<style lang="scss" scoped>
.create-event {
  @apply bg-white max-w-[30.5rem] py-4 px-8;
  &-title {
    @apply text-h2 font-medium text-center;
  }
  &-subTitle {
    @apply text-p-md text-center text-blacks-300 py-2;
  }
  &-radio {
    @apply accent-product-400;
    &-container {
      @apply py-6 flex flex-col w-full px-4;
    }
    &-item {
      @apply py-2;
      &-enabled {
        @apply cursor-pointer;
      }
      &-disabled {
        @apply cursor-default text-gray-500;
      }
    }
    &-text {
      @apply ml-4 text-p-xl font-normal capitalize;
    }
  }
  &-btn {
    @apply capitalize px-4 py-2 text-p-xl font-medium;
    &-cancel {
      @apply text-blacks-500;
      &:enabled {
        &:focus {
          @apply outline-none;
        }
      }
    }
    &-container {
      @apply flex flex-row w-full justify-end pb-4;
    }
  }
}
</style>
