const hoursInADay = 24

// A utility function that converts total hours to the equivalent number of days.
function convertHoursToDays(totalHours) {
  // Calculate the number of days by dividing total hours by hours in a day, and rounding down.
  return Math.floor(totalHours / hoursInADay)
}

// A utility function that converts total days to the equivalent number of hours.
function convertDaysToHours(totalDays) {
  // Calculate the number of hours by multiplying total days by hours in a day.
  return totalDays * hoursInADay
}

export {
  convertHoursToDays,
  convertDaysToHours
}
