<script setup>
// Required Package
import { computed, reactive } from 'vue'
import store from '@/store'
// Custom Component
import AddOnCard from '@/components/Participants/AddOnCard'
import { eventFeatureCodes } from '@/utils'

const isStaff = computed(() => {
  return store.getters.isStaff
})

const addOnData = reactive([
  {
    id: 1,
    title: '100 Participants',
    topText: "Unlock",
    plan: "Basic",
    description: 'With the purchase of the <strong>Basic Plan</strong>.',
    buttonText: "Upgrade",
  },
  {
    id: 2,
    title: '200 Participants',
    topText: "Unlock",
    plan: "Premium",
    description: 'With the purchase of the <strong>Premium Plan</strong>.',
    buttonText: "Upgrade",
  },
  {
    id: 3,
    title: 'Custom',
    topText: "Need More Admins?",
    plan: "Custom",
    description: 'Connect with us for a Quote.',
    buttonText: "Contact us",
  }
])

const participantsAddOns = computed(() => {
  const eventFeatureData = store.getters?.getEventFeature(eventFeatureCodes.NUMBER_OF_PARTICIPANTS)

  // Check if eventFeatureData?.value is 100, then filter out the addOnData with id: 1 (basic)
  if (eventFeatureData?.value === 100) {
    return addOnData.filter(addon => addon.id !== 1)
  }

  // Check if eventFeatureData?.value is 200, then filter out the addOnData with id: 2 and 1 (premium and basic)
  if (eventFeatureData?.value >= 200) {
    return addOnData.filter(addon => addon.id !== 2 && addon.id !== 1)
  }

  // If no condition matches, return the original array
  return addOnData
})
</script>

<template>
  <div v-if="isStaff"
       class="addon">
    <h2 class="addon-header">
      Add Ons <span class="addon-header_option">(Optional)</span>
    </h2>
    <ul class="addon-list">
      <add-on-card v-for="item in participantsAddOns"
                   :key="item.id"
                   :item="item"/>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.addon {
  @apply w-full flex flex-col;
  &-header {
    @apply text-h2 font-bold;
    &_option {
      @apply font-normal text-h4;
    }
  }
  &-list {
    @apply w-full flex gap-5 flex-wrap py-6;
  }
}
</style>
