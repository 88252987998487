import AwardService from '@/services/award'
import { processStatuses } from '@/utils'
import { cloneObject } from '@/utils/clone'
import FileService from '@/services/file'

const defaultAwardsState = () => {
    return {
      // Awards Managements
      awards: {},
      originalAwards: {},
      awardsStatus: processStatuses.IDLE,
      awardsError: null,
      awardsListError: null,
      // Award Images Upload
      awardImagesStatus: {},
      awardImageUploadStatus: processStatuses.IDLE,
      // Filter
      filterAwards: '',
      isDescending: false,
    }
}

export default {
  state: defaultAwardsState(),
  getters: {
    // Admin Award Management
    awards: state => {
      return Object.values(state.awards).sort(
          (a, b) => {
            // Get the type
            const hasFilter = state.filterAwards.length > 0 ? state.filterAwards : 'priority'
            // Check if it is empty string
            const isEmpty = a[hasFilter] === '' || b[hasFilter] === ''
            const isNewRecord = typeof a['id'] === 'string' || typeof b['id'] === 'string'
            // Check if it is string type
            const isString = typeof a[hasFilter] === 'string' || typeof b[hasFilter] === 'string'
            // Descending and ascending logic
            if (state.isDescending) {
              return (isEmpty || isNewRecord) ? -1 :
                  isString ? (b[hasFilter].toLowerCase()).localeCompare(
                      (a[hasFilter].toLowerCase())) : b[hasFilter] - a[hasFilter]
            } else {
              return (isEmpty || isNewRecord) ? -1 :
                  isString ? (a[hasFilter].toLowerCase()).localeCompare(
                      (b[hasFilter].toLowerCase())) : a[hasFilter] - b[hasFilter]
            }
          })
    },
    allEventAwards: state => Object.values(state.awards),
    originalAwards: state => Object.values(state.originalAwards),
    awardsStatus: state => state.awardsStatus,
    awardsError: state => state.awardsError,
    awardsListError: state => state.awardsListError,
    isAwardsUpdated: state => {
      if (Object.keys(state.awards).length !== Object.keys(state.originalAwards).length) {
        return true
      }

      for (const awardId in state.awards) {
        if (!(awardId in state.originalAwards)) {
          return true
        }
        const fieldsToCheck = ['name', 'image', 'priority']
        if (fieldsToCheck.some(field => state.awards[awardId][field] !== state.originalAwards[awardId][field])) {
          return true
        }
      }

      return false
    },
    getModifiedAwards: state => {
      if (state.awardsStatus === processStatuses.IDLE) {
        return { newAwards: [], updatedAwards: [], deletedAwardIds: [] }
      }

      // Group awards into New and Existing items depends on the ID
      // e.g. New record will be assigned a UUID instead of an integer ID
      const { newAwards, existingAwards } = Object.values(state.awards).reduce(({ newAwards, existingAwards }, award) => {
        if (Number.isInteger(award.id)) {
          existingAwards[award.id] = award
        } else {
          newAwards[award.id] = award
        }
        return { newAwards, existingAwards }
      }, { newAwards: {}, existingAwards: {} })

      // Check if any fields got updated from the existing awards
      const updatedAwards = {}
      const fieldsToCheck = ['name', 'description', 'image']
      for (const awardId in existingAwards) {
        if (fieldsToCheck.some(field => existingAwards[awardId][field] !== state.originalAwards[awardId][field])) {
            updatedAwards[awardId] = existingAwards[awardId]
        }
      }

      // check deleted awards
      const originalAwardIds = Object.keys(state.originalAwards)
      const deletedAwardsIds = originalAwardIds.filter(id => !(id in state.awards))

      return { newAwards: Object.values(newAwards), updatedAwards: Object.values(updatedAwards), deletedAwardIds: deletedAwardsIds }
    },
    awardImageUploadStatus: state => state.awardImageUploadStatus,
    // Filter
    filterAwards: state => state.filterAwards,
  },
  mutations: {
    AWARDS_LOADING(state) {
      state.awardsStatus = processStatuses.LOADING
    },
    AWARDS_LOADED(state, awards) {
      state.awards = awards.reduce((acc, award) => {
        acc[award.id] = award
        return acc
      }, {})
      state.originalAwards = cloneObject(state.awards)
      state.awardsStatus = processStatuses.LOADED
    },
    AWARDS_LOAD_ERROR(state, err) {
      state.awardsError = err
      state.awardsStatus = processStatuses.ERROR
    },
    AWARDS_SET_ERROR_LIST(state, errorList) {
      state.awardsListError = errorList
    },
    AWARDS_SAVING(state) {
        state.awardsStatus = processStatuses.SAVING
    },
    ADD_AWARD(state, newAward) {
      state.awards[newAward.id] = newAward
    },
    REMOVE_AWARD(state, id) {
      delete state.awards[id]
    },
    UPDATE_AWARD(state, { id, field, value }) {
      if (id in state.awards) {
        state.awards[id][field] = value
      }
    },
    AWARD_IMAGE_UPLOADING(state, awardId) {
      state.awardImagesStatus[awardId] = processStatuses.LOADING
    },
    AWARD_IMAGE_UPLOADED(state, { awardId, link }) {
      state.awards[awardId].image = link
      state.awardImagesStatus[awardId] = processStatuses.LOADED
    },
    AWARD_IMAGE_UPLOAD_ERROR(state, { awardId, err }) {
      state.awardImagesStatus[awardId] = processStatuses.ERROR
      console.debug(err)
    },
    AWARD_IMAGES_UPLOADING(state) {
      state.awardImageUploadStatus = processStatuses.SAVING
    },
    AWARD_IMAGES_UPLOADED(state) {
      state.awardImageUploadStatus = processStatuses.LOADED
    },
    SET_FILTER_TYPE (state, filter) {
      state.filterAwards = filter
    },
    TOGGLE_AWARDS_DESC(state) {
      state.isDescending = !state.isDescending
    },
    RESET_AWARDS_STATE(state) {
      Object.assign(state, defaultAwardsState())
    }
  },
  actions: {
    // Admin Award Management
    fetchEventAwards({commit, rootGetters}) {
      // Fetch Event Categories only if the event builder is loaded
      // i.e. User land on the event builder page.
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      commit('AWARDS_LOADING')
      return new Promise((resolve, reject) => {
        AwardService.getAwards(rootGetters.ebEventId).then((response) => {
          commit('AWARDS_LOADED', response.data.awards)
          resolve()
        }).catch(err => {
          commit('AWARDS_LOAD_ERROR', err)
          reject(err)
        })
      })
    },
    addEventAward({ commit }, newAward) {
      commit('ADD_AWARD', newAward)
    },
    removeEventAward({ commit }, id) {
      commit('REMOVE_AWARD', id)
    },
    updateEventAward({ commit }, { id, field, value }) {
      commit('UPDATE_AWARD', { id, field, value })
    },
    async uploadAwardImages({ commit, getters, rootGetters }) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      const awardsToUpload = getters.awards.filter(m => m.image && m.image instanceof File)

      if (awardsToUpload.length > 0) {
        commit('AWARD_IMAGES_UPLOADING')
        await Promise.all(awardsToUpload.map(award => {
          commit('AWARD_IMAGE_UPLOADING', award.id)
          return new Promise((resolve, reject) => {
            FileService.upload({
              file: award.image,
              directory: `selfserve/${rootGetters.ebEventId}/awards`
            }).then(response => {
              commit('AWARD_IMAGE_UPLOADED', { awardId: award.id, link: response.data.link })
              resolve()
            }).catch(err => {
              commit('AWARD_IMAGE_UPLOAD_ERROR', { awardId: award.id, err })
              reject(err)
            })
          })
        }))
        commit('AWARD_IMAGES_UPLOADED')
      }
    },
    async saveEventAwards({commit, getters, rootGetters }) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      const { newAwards, updatedAwards, deletedAwardIds } = getters.getModifiedAwards

      commit('AWARDS_SAVING')
      if (deletedAwardIds.length > 0) {
        try {
          await AwardService.deleteAwards(rootGetters.ebEventId, deletedAwardIds)
        } catch (err) {
          commit('AWARDS_LOAD_ERROR', err)
          throw err
        }
      }

      if (updatedAwards.length > 0) {
        const updateTasks = updatedAwards.map(award => {
          return AwardService.updateAward(award.id, { name: award.name, image: award.image, priority: award.priority })
        })

        try {
            await Promise.all(updateTasks)
        } catch (err) {
          commit('AWARDS_LOAD_ERROR', err)
          throw err
        }
      }

      if (newAwards.length > 0) {
        // Bulk creation will return the full list of awards for the current event
        const newAwardsToCreate = newAwards.map(award => {
            return {
              name: award.name,
              image: award.image,
              priority: award.priority
            }
        })
        try {
          const response = await AwardService.createAwards(rootGetters.ebEventId, newAwardsToCreate)
          commit('AWARDS_LOADED', response.data.awards)
        } catch (err) {
          commit('AWARDS_LOAD_ERROR', err)
          throw err
        }
      } else {
        try {
          // Fetch again to get the latest awards
            const response = await AwardService.getAwards(rootGetters.ebEventId)
            commit('AWARDS_LOADED', response.data.awards)
        } catch (err) {
            commit('AWARDS_LOAD_ERROR', err)
            throw err
        }
      }

    },
    // Admin Award Management End
    resetAwardsState({ commit }) {
      commit('RESET_AWARDS_STATE')
    },
    setAwardFilterBy({ commit }, filter) {
      commit('SET_FILTER_TYPE', filter)
    },
    toggleAwardsDesc({ commit }) {
      commit('TOGGLE_AWARDS_DESC')
    },
    setErrorList({ commit }, errorList) {
      commit('AWARDS_SET_ERROR_LIST', errorList)
    }
  }
}
