export default {
  state: () => ({
    menu_is_active: false,
    sideMenu_is_active: false,
    profileMenu_is_active: false,
    dark_is_active: false,
    overlay_is_active: false,
    tab_selected: '',
    admin_panel: ''
  }),
  getters: {
    menu: state => state.menu_is_active,
    sideMenu: state => state.sideMenu_is_active,
    profileMenu: state => state.profileMenu_is_active,
    darkMode: state => state.dark_is_active,
    overlay: state => state.overlay_is_active,
    tabSelected: state => state.tab_selected,
    adminPanel: state => state.admin_panel,
  },
  mutations: {
    TOGGLE_MENU (state) {
      state.menu_is_active = !state.menu_is_active
    },
    TOGGLE_CLOSE_MENU (state) {
      state.menu_is_active = false
    },
    TOGGLE_SIDEMENU (state) {
      state.sideMenu_is_active = !state.sideMenu_is_active
    },
    SET_STATE_SIDEMENU (state, prevState) {
      state.sideMenu_is_active = prevState
    },
    TOGGLE_PROFILEMENU (state) {
      state.profileMenu_is_active = !state.profileMenu_is_active
      state.overlay_is_active = false
    },
    TOGGLE_DARKMODE (state) {
      state.dark_is_active = !state.dark_is_active
    },
    TOGGLE_OVERLAY (state) {
      state.overlay_is_active = !state.overlay_is_active
    },
    TAB_SELECTED (state, index) {
      state.tab_selected = index
      state.overlay_is_active = false
    },
    OVERLAY_SELECTED (state) {
      state.menu_is_active = false
      state.overlay_is_active = false
      state.profileMenu_is_active = false
    },
    SHOW_OVERLAY (state) {
      state.overlay_is_active = true
    },
    HIDE_OVERLAY (state) {
      state.overlay_is_active = false
    },
    SET_ADMIN_PANEL_TYPE (state, type) {
        state.admin_panel = type
    }
  },
  actions: {
    ToggleMenu ({ commit }) {
      commit ('TOGGLE_MENU')
    },
    ToggleCloseMenu ({ commit }) {
      commit ('TOGGLE_CLOSE_MENU')
    },
    ToggleSideMenu ({ commit }) {
      commit ('TOGGLE_SIDEMENU')
    },
    SetStateSideMenu ({commit}, prevState) {
      commit ('SET_STATE_SIDEMENU', prevState)
    },
    ToggleProfileMenu ({ commit }) {
      commit ('TOGGLE_PROFILEMENU')
    },
    ToggleDarkMode ({ commit }) {
      commit ('TOGGLE_DARKMODE')
    },
    ToggleOverlay ({ commit }) {
      commit ('TOGGLE_OVERLAY')
    },
    TabSelected ({ commit }, index) {
      commit('TAB_SELECTED', index)
    },
    OverlaySelected ({ commit }) {
      commit ('OVERLAY_SELECTED')
    },
    SetAdminPanelType({ commit }, type) {
      commit('SET_ADMIN_PANEL_TYPE', type)
    }
  }
}
