<script setup>
import store from '@/store'
import router from '@/router'
import { computed, ref } from 'vue'
import BaseImgFileInput from "@/components/Base/Fields/ImageField"
import BaseButton from '@/components/Base/Button/BaseButton'
import ModalLayout from './Base'
import { ColorPicker } from 'vue-color-kit'
import 'vue-color-kit/dist/vue-color-kit.css'
import { debounce } from 'lodash'
import { processStatuses } from '@/utils'

const logoImage = ref('')
const hasLogoErrorInsideBaseImgFileInput = ref(false)
const isEventLogoRemoved = ref(false)

/**
 * If there is an error, it is set to true. In case
 * of reupload after error, it has to be resetted from
 * BasicImgFileInput component
 * @param {boolean} isError
 */
const handleImageError = (isError) => {
  hasLogoErrorInsideBaseImgFileInput.value = isError
}

const nextStep = debounce(async () => {
  try {
    if (typeof logoImage.value === 'object' && logoImage.value !== null) {
      await store.dispatch('uploadFile', logoImage.value)
    }

    // logo is optional, but in case of errors, we need to check
    // if there is no error
    if (eventLogoImageErrorMessage.value.length === 0 && !hasLogoErrorInsideBaseImgFileInput.value) {
      const uploadedImagePath = store.getters.getUploadedFile
      store.dispatch('setNewEventSetting', {
        primaryColor: primaryColorSelected.value,
        secondaryColor: secondaryColorSelected.value,
        imagePath: uploadedImagePath,
      }).then(() => {
        if (events.value.length === 0) {
          const currentUser = store.getters.user
          store.dispatch('fetchOrganization', {slug: currentUser.userName, userId: currentUser.id}).then((organization) => {
            store.dispatch('updateOrganization', {id: organization.id, slug: organization.slug, userId: organization.userId, updateMask: "name"}).then(() => {
              createEvent()
            })
          })
        } else {
          createEvent()
        }
      })
    }
  } catch (error) {
    console.error(error)
  }
}, 1500)

const backStep = () => {
  store.dispatch('SetEventModal', 'organization')
  store.dispatch('SetProgressBar', 0)
}

const eventLogoImageErrorMessage = computed(() => {
  if (store.getters.getFileUploadError) {
    if (store.getters.getFileUploadError.data.message === 'failed to get file \'attachment\'') {
      return 'Please select event logo'
    }
    // catch grpc error grpc: received message larger than
    else if (store.getters.getFileUploadError.data.message.includes('message larger than')) {
      return 'Image size is too big'
    }
    else {
      return store.getters.getFileUploadError.data.message
    }
  }
  return ''
})

const isEventLoading = computed(() => {
  return store.getters.getFileUploadStatus === processStatuses.LOADING || store.getters.newEventStatus === processStatuses.SAVING
})

const createEvent = async () => {
  try {
    await store.dispatch('createEvent')
    await store.dispatch('SetEventModal', 'organization')
    await store.dispatch('SetProgressBar', 0)
    toggleModal()
    await store.dispatch('resetNewEvent')
    await store.dispatch('resetNewOrganization')
    await store.dispatch('fetchOrganization', {slug: store.getters.user?.userName, userId: store.getters.user?.id})
    await store.dispatch('GetEvents')
    await store.dispatch('fetchSubscriptions')
    await router.push('/events')
  } catch (err) {
    console.error(err)
  }
}

const events = computed(() => {
  return store.getters.events
})

const error = computed(() => {
  return store.getters.newEventError ?? {}
})

// Modal
const toggleModal = () => {
  store.dispatch('ToggleModal')
  store.dispatch('ToggleOverlay')
  store.dispatch('ResetModal')
}

/// Color Picker
const colorPlaceholder = ref('#8400F0')
const primaryColorSelected = ref('#8400F0')
const secondaryColorSelected = ref('#8400F0')
const suckerCanvas = ref(null)
const suckerArea = ref([])
const showPrimary = ref(false)
const showSecondary = ref(false)


const primaryChangeColor = (color) => {
  const { r,g,b,a} = color.rgba
  color.rgba = `rgba(${r}, ${g}, ${b}, ${a})`
  primaryColorSelected.value = color.hex
}

const secondaryChangeColor = (color) => {
  const { r,g,b,a} = color.rgba
  color.rgba = `rgba(${r}, ${g}, ${b}, ${a})`
  secondaryColorSelected.value = color.hex
}

const toggleColorPicker = (color) => {
  if (isEventLoading.value) {
    return
  }
  if (color === 'primary') {
    showPrimary.value = !showPrimary.value
  } else if (color === 'secondary') {
    showSecondary.value = !showSecondary.value
  } else {
    showPrimary.value = false
    showSecondary.value = false
  }
}

const acceptedFileTypes = computed(() => {
  return ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml']
})
</script>

<template>
  <modal-layout>
    <template #modal-body>
      <div v-if="showPrimary || showSecondary" class="w-full h-full absolute top-0 left-0" @click="toggleColorPicker('close')"></div>
      <div class="flex flex-col w-full modal-branding">
        <h3 class="font-semibold text-lg py-2 capitalize text-center sm:text-left">
          Design & Branding
        </h3>
        <div class="flex flex-col sm:flex-row p-4 justify-around items-center sm:items-baseline">
          <div class="flex flex-col w-56 parent-object-fit">
            <p class="inputLabel text-center">
              Showcase Logo
            </p>
            <p class="text-xs py-1 text-center">Try to upload images with a square aspect ratio for better user experience.</p>
            <span class="flex flex-col items-center w-full justify-center">
              <base-img-file-input
                v-model="logoImage"
                :src="logoImage"
                aria-label="Logo image file attach"
                :disabled="isEventLoading"
                :img-file-error="eventLogoImageErrorMessage"
                :image-has-changed="true"
                :image-has-removed="isEventLogoRemoved"
                :accepted-file-types="acceptedFileTypes"
                @on-error="handleImageError"
               />
            </span>
          </div>
          <div>
            <div class="modal-branding-colorInputContainer">
              <label class="inputLabel" for="primaryColorPicker">
                Primary Color
              </label>
              <div class="modal-branding-colorInputs" :class="{'modal-branding-colorInputs_primaryActive' : showPrimary}"
                   @click="toggleColorPicker('primary')">
                <div class="modal-branding-colorPickerButton"
                     :style="{ 'background-color': primaryColorSelected}">
                </div>
                <div class="text-center w-full">
                  <p class="modal-branding-colorPickerText">{{ primaryColorSelected }}</p>
                </div>
              </div>
            </div>
            <color-picker
                class="modal-branding-colorPicker"
                :class="{'modal-branding-colorPicker_active' : showPrimary}"
                theme="light"
                :color="colorPlaceholder"
                :sucker-hide="true"
                :sucker-canvas="suckerCanvas"
                :sucker-area="suckerArea"
                :disabled="isEventLoading"
                @change-color="primaryChangeColor"/>
            <div class="modal-branding-colorInputContainer">
              <label class="inputLabel" for="secondaryColorPicker">
                Secondary Color
              </label>
              <div class="modal-branding-colorInputs"
                   :class="{'modal-branding-colorInputs_secondaryActive' : showSecondary}"
                   @click="toggleColorPicker('secondary')">
                <div class="modal-branding-colorPickerButton bg-emerald-300"
                     :style="{ 'background-color': secondaryColorSelected}">
                </div>
                <div class="text-center w-full">
                  <p class="modal-branding-colorPickerText">{{ secondaryColorSelected }}</p>
                </div>
              </div>
              <p class="text-p-xs max-w-[15rem] py-2">
                We recommend not using white for better user experience
              </p>
            </div>
            <color-picker
                class="modal-branding-colorPicker"
                :class="{'modal-branding-colorPicker_active' : showSecondary}"
                theme="light"
                :color="colorPlaceholder"
                :sucker-hide="true"
                :sucker-canvas="suckerCanvas"
                :sucker-area="suckerArea"
                :disabled="isEventLoading"
                @change-color="secondaryChangeColor"/>
          </div>
        </div>
        <div class="error">{{error['server'] ?? ''}}</div>
      </div>
    </template>
    <template #modal-button>
      <span class="modal-branding-buttons">
        <base-button id="back"
                      name="back"
                      class="backBtn modal-branding-buttons-backBtn"
                      btn-type="text"
                      :functions="backStep"
                      :disabled="isEventLoading"
                      text="back" />
        <base-button id="submit"
                      name="submit"
                      text="submit"
                      class="nextBtn modal-branding-buttons-nextBtn"
                      :disabled="isEventLoading"
                      :is-loading="isEventLoading"
                      :functions="nextStep"/>
      </span>
    </template>
  </modal-layout>
</template>

<style lang="scss" scoped>
.modal {
  &-branding {
    &-colorPicker {
      @apply hidden h-[15.375rem] absolute overflow-hidden top-[16rem] sm:top-[45%] right-[calc(50%-13.625rem/2)] sm:right-[2%] z-30 w-[13.625rem];
      &_active {
        @apply block;
      }
    }
    &-colorInputContainer {
      @apply py-2 flex flex-col;
    }
    &-colorInputs {
      @apply flex flex-row items-center bg-white p-1 border-2 cursor-pointer w-[15rem];
      &_primaryActive {
        @apply border-gray-600;
      }
      &_secondaryActive {
        @apply border-gray-600;
      }
    }
    &-colorPickerText {
      @apply border-0 focus-visible:outline-none cursor-pointer;
    }
    &-colorPickerButton {
      @apply w-10 h-7 m-1 border-2 border-black bg-purple rounded-sm;
    }
    &-buttons {
      @apply w-full text-right py-4 px-16 md:px-40;
      &-nextBtn {
        @apply bg-purple text-white uppercase rounded-md text-p-sm font-normal px-4;
      }
      &-backBtn {
        @apply text-p-sm text-product-400 font-normal bg-transparent uppercase rounded-md mr-4 border-greys-100 border px-4;
      }
    }
  }
}
</style>
