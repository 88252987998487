<script setup>
import { ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'

defineProps({
  position: {
    type: String,
    default: 'bottom'
  },
  menuItems: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    default: null
  }
})

// Menu Variable
const overflowMenu = ref(false)
// Menu Toggle
const toggleOverflowMenu = () => {
  overflowMenu.value = !overflowMenu.value
}

const closeMenu = () => {
  overflowMenu.value = false
}
</script>

<template>
  <span v-on-click-outside="closeMenu"
        :class="{'overflowMenu' : position !== 'navbar'}">
    <button class="overflowMenu-btn"
            :qa="id ? `qa-overflowMenu-${id}` : null"
            role="button"
            :class="{
              'overflowMenu-btn-regular' : position !== 'navbar' && !disabled,
              'overflowMenu-btn-regular_active' : position !== 'navbar' && overflowMenu,
              'overflowMenu-btn-nav' : position === 'navbar' && !disabled,
              'overflowMenu-btn-nav_disabled' : disabled,
              'overflowMenu-btn-regular_nav' : position === 'navbar' && overflowMenu
            }"
            @click.prevent="toggleOverflowMenu">
      <slot name="menu-icon">
        <i class="fa-solid fa-ellipsis-vertical vertical-icon" />
        <i class="fas fa-ellipsis-h cover-img-update-icon"></i>
      </slot>
    </button>
    <ul v-if="overflowMenu && !disabled"
        :class="{
          'top-10' : position === 'bottom',
          '-top-[8rem]' : position === 'top',
          'top-10 right-0' : position === 'left',
          'top-20 right-2' : position === 'navbar'
        }"
        class="overflowMenu-menu"
        @click.prevent="closeMenu">
      <slot name="menu-items">
        <li v-for="(item) in menuItems"
            :key="item.name"
            class="overflowMenu-menu-item"
            @click.prevent="() => {
              item.onMenuItemClick()
              toggleOverflowMenu()
            }">
          <i :class="item.icon" class="text-other-red"/>
          <span class="overflowMenu-menu-item_text">
            {{item.name}}
          </span>
        </li>
      </slot>
    </ul>
  </span>
</template>

<style lang="scss" scoped>
.overflowMenu {
  @apply relative;
  &-base {
    @apply w-20;
  }
  &-btn {
    @apply w-8 h-8;
    &-nav {
      &_disabled {
        @apply cursor-not-allowed;
        &:hover {
          @apply text-greys-200;
        }
      }
      &_active {
        @apply text-secondary;
      }
      &:hover {
        @apply text-secondary;
      }
    }
    &-regular {
      &_active {
        @apply bg-greys-100 rounded;
      }
      &:hover {
        @apply bg-greys-100 rounded;
      }
    }
  }
  &-menu {
    @apply absolute flex flex-col rounded-2xl min-w-[11rem] rounded shadow-2xl border bg-background-400 z-10;
    &-item {
      @apply p-4 flex flex-row items-center border-b-greys-200 border-b last:border-none;
      &:hover {
        @apply bg-greys-100 cursor-pointer;
      }
      &_text {
        @apply ml-2 text-other-red;
      }
    }
  }
}
.cover-img-update {
  .resource {
    &-card {
      &-header {
        .overflowMenu-btn {
          @apply bg-white rounded-full -right-4 relative -top-2.5 shadow-[1px_1px_10px_rgba(0,0,0,0.1)];
        }
        .overflowMenu-menu {
          @apply md:-ml-12 -ml-32;
        }
      }
    }
  }
}
.cover-img-update-icon {
  @apply hidden;
}
.cover-img-update {
  .vertical-icon {
    @apply hidden;
  }
}
.cover-img-update {
  .cover-img-update-icon {
    @apply block;
  }
}
.cover-img-update-icon {
  @apply hidden;
}

.branding {
  &-box {
    &-header {
      .overflowMenu-btn {
        @apply bg-white rounded-full -right-4 relative -top-2.5 shadow-[1px_1px_10px_rgba(0,0,0,0.1)];
      }
      .overflowMenu-menu {
        @apply md:-ml-12 -ml-32;
      }
      .vertical-icon {
        @apply hidden;
      }
      .cover-img-update-icon {
        @apply block;
      }
    }
  }
}
</style>
