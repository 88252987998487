// import axios from 'axios'
// import { config } from '@/config'

class FormService {
    getForms() {
        // Mock the data for now, later we call API to get custom questions
        return new Promise((resolve) => {
            return resolve({
                data: {
                    forms: [
                        {
                            "id": 231,
                            "question": "Self Serve: First Name",
                            "customerID": 0,
                            "language": "en",
                            "labels": {
                                "en": "First name *"
                            },
                            "type": "text",
                            "isChecked": false,
                            "grouping": 1,
                            "link": "",
                            "context": "",
                            "typeQuestion": false,
                            "optional": false,
                            "required": true
                        },
                        {
                            "id": 232,
                            "question": "Self Serve: Last Name",
                            "customerID": 0,
                            "language": "en",
                            "labels": {
                                "en": "Last name *"
                            },
                            "type": "text",
                            "isChecked": false,
                            "grouping": 1,
                            "link": "",
                            "context": "",
                            "typeQuestion": false,
                            "optional": false,
                            "required": true
                        },
                        {
                            "id": 233,
                            "question": "Self Serve: Username",
                            "customerID": 0,
                            "language": "en",
                            "labels": {
                                "en": "Username *"
                            },
                            "type": "text",
                            "isChecked": false,
                            "grouping": 1,
                            "link": "",
                            "context": "",
                            "typeQuestion": false,
                            "optional": false,
                            "required": true
                        },
                        {
                            "id": 234,
                            "question": "Self Serve: Phone Number",
                            "customerID": 0,
                            "language": "en",
                            "labels": {
                                "en": "Phone Number or Email Address * (For Notifications and Password Retrievals)"
                            },
                            "type": "text",
                            "isChecked": false,
                            "grouping": 1,
                            "link": "",
                            "context": "",
                            "typeQuestion": false,
                            "optional": false,
                            "required": true
                        },
                        {
                            "id": 235,
                            "question": "Self Serve: Event Code",
                            "language": "en",
                            "labels": {
                                "en": "Unique Event Code *"
                            },
                            "type": "text",
                            "isChecked": false,
                            "grouping": 1,
                            "link": "",
                            "context": "",
                            "typeQuestion": false,
                            "optional": false,
                            "required": true
                        }
                    ]
                }
            })
        })
    }
}

export default new FormService()
