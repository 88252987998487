import ProjectService from '@/services/project'
import { processStatuses } from '@/utils'

export default {
  state: () => ({
    projectSubmissions: [],
    projectSubmissionsCounts: {},
    nextPageToken: 0,
    projectSubmissionsStatus: processStatuses.IDLE,
    projectSubmissionsCountsStatus: processStatuses.IDLE,
    participantNotifyStatus: processStatuses.IDLE,
    participantReNotifyStatus: {},
    // sort
    sort: 'firstName',
    isDesc: true,
    // filter
    projectSubmissionsFilter: 'all',
    searchParticipants: ''
  }),
  getters: {
    getProjectSubmissions: state => {
      const searchKeyword = (state.searchParticipants || '').toLowerCase()
      return (state.projectSubmissions || [])
          .filter(project => {
            const teamMembers = (project?.memberships || [])
                .map(membership => membership?.user ? (membership.user?.firstName + ' ' + membership.user?.lastName).trim() || membership.user?.userName : '')
                .join(', ')

            return teamMembers.toLowerCase().includes(searchKeyword)
          })
          .map(project => ({
            ...project,
            team: (project?.memberships || [])
                .map(membership => membership?.user ? (membership.user?.firstName + ' ' + membership.user?.lastName).trim() || membership.user?.userName : '')
                .join(', ')
          }))
    },
    getProjectSubmissionStatus: state => {
      return state.projectSubmissionsStatus
    },
    getProjectSubmissionsCounts: state => {
      return state.projectSubmissionsCounts ? state.projectSubmissionsCounts : {}
    },
    getProjectSubmissionsCountStatus: state => {
      return state.projectSubmissionsCountsStatus
    },
    getParticipantNotifyStatus: state => {
      return state.participantNotifyStatus
    },
    getParticipantsNotified: (state, getters, rootState, rootGetters) => {
      return rootGetters.eventSettings?.participantNotified
    },
    getSearchParticipants: state => {
      return state.searchParticipants
    },
    getParticipantReNotifyStatus: state => (projectId) => {
      return state.participantReNotifyStatus[projectId]
    }
  },
  mutations: {
    SET_PROJECT_SUBMISSIONS(state, payload) {
      state.projectSubmissions = payload.filter(project => {
        return state.projectSubmissionsFilter === 'all' || project.submissionStatus === state.projectSubmissionsFilter
      })
      state.projectSubmissionsStatus = processStatuses.LOADED
    },
    SET_PROJECT_SUBMISSION_STATUS(state, {projectId, status }) {
      const project = state.projectSubmissions.find(project => project.id === projectId)
      if (project) {
        project.submissionStatus = status
      }
    },
    SET_PROJECT_SUBMISSIONS_LOADING(state) {
        state.projectSubmissionsStatus = processStatuses.LOADING
    },
    SET_PROJECT_SUBMISSIONS_COUNTS(state, payload) {
      state.projectSubmissionsCounts = payload.totalCountsByStatus
      state.projectSubmissionsCountsStatus = processStatuses.LOADED
    },
    SET_PROJECT_SUBMISSIONS_COUNTS_LOADING(state) {
      state.projectSubmissionsCountsStatus = processStatuses.LOADING
    },
    SET_PROJECT_SUBMISSIONS_ERROR(state) {
      state.projectSubmissionsStatus = processStatuses.ERROR
    },
    SET_PROJECT_SUBMISSIONS_COUNTS_ERROR(state) {
      state.projectSubmissionsCountsStatus = processStatuses.ERROR
    },
    SET_PROJECT_SUBMISSIONS_PAGE(state, payload) {
      state.nextPageToken = payload
    },
    TOGGLE_PROJECT_SUBMISSIONS_DESC(state) {
      state.isDesc = !state.isDesc
    },
    SET_PROJECT_SUBMISSIONS_SORT_BY(state, filter) {
      state.sort = filter
    },
    PARTICIPANT_NOTIFY_LOADING(state) {
      state.participantNotifyStatus = processStatuses.LOADING
    },
    PARTICIPANT_NOTIFY_LOADED(state) {
      state.participantNotifyStatus = processStatuses.LOADED
    },
    PARTICIPANT_NOTIFY_ERROR(state) {
      state.participantNotifyStatus = processStatuses.ERROR
    },
    SET_PROJECT_SUBMISSIONS_FILTER_BY(state, filter) {
      state.projectSubmissionsFilter = filter
    },
    SET_SEARCH_PARTICIPANTS(state, search) {
      state.searchParticipants = search
    },
    PARTICIPANT_RENOTIFY_INIT(state, projects) {
      state.participantReNotifyStatus = projects.reduce((acc, item) => {
        acc[item.id] = processStatuses.IDLE
        return acc
      }, {})
    },
    PARTICIPANT_RENOTIFY_LOADING(state, projectId) {
      state.participantReNotifyStatus[projectId] = processStatuses.LOADING
    },
    PARTICIPANT_RENOTIFY_LOADED(state, projectId) {
      state.participantReNotifyStatus[projectId] = processStatuses.LOADED
    },
    PARTICIPANT_RENOTIFY_UPDATING(state, projectId) {
      state.participantReNotifyStatus[projectId] = processStatuses.SAVING
    },
    PARTICIPANT_RENOTIFY_UPDATED(state, projectId) {
      state.participantReNotifyStatus[projectId] = processStatuses.LOADED
    },
    PARTICIPANT_RENOTIFY_ERROR(state, projectId) {
      state.participantReNotifyStatus[projectId] = processStatuses.ERROR
    },
  },
  actions: {
    async getProjectSubmissions({commit}, {eventId}) {
      commit('SET_PROJECT_SUBMISSIONS_LOADING')
      try {
        const result = await ProjectService.getParticipantProjects(eventId, 'user, memberships')
        const projectSubmissions = result.data.projects
        commit('SET_PROJECT_SUBMISSIONS', projectSubmissions)
        commit('PARTICIPANT_RENOTIFY_INIT', projectSubmissions)
        const nextPageToken = result.data.pagination.nextPageToken
        commit('SET_PROJECT_SUBMISSIONS_PAGE', nextPageToken)
      } catch (e) {
        console.error(e)
        commit('SET_PROJECT_SUBMISSIONS_ERROR')
      }
    },
    async getProjectSubmissionsCounts({commit}, {eventId}) {
      commit('SET_PROJECT_SUBMISSIONS_COUNTS_LOADING')
      try {
        const result = await ProjectService.getParticipantProjectCounts(eventId)
        commit('SET_PROJECT_SUBMISSIONS_COUNTS', result.data)
      } catch (e) {
        console.error(e)
        commit('SET_PROJECT_SUBMISSIONS_COUNTS_ERROR')
      }
    },
    toggleProjectSubmissionDesc({ commit }) {
      commit('TOGGLE_PROJECT_SUBMISSIONS_DESC')
    },
    setProjectSubmissionSortBy({ commit }, sortBy) {
      commit('SET_PROJECT_SUBMISSIONS_SORT_BY', sortBy)
    },
    sendProjectSubmissionsStatusEmail({commit, rootGetters}) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }
      // Only publish awards if the event is published
      if (rootGetters.eventSettings.status !== 'Published') {
        return
      }
      commit('PARTICIPANT_NOTIFY_LOADING')
      return new Promise((resolve, reject) => {
        ProjectService.sendProjectSubmissionsStatusEmail(rootGetters.ebEventId).then((res) => {
          commit('PARTICIPANT_NOTIFY_LOADED')
          commit('EVENT_SETTINGS_UPDATED', res.data.eventSettings)
          resolve()
        }).catch((err) => {
          commit('PARTICIPANT_NOTIFY_ERROR')
          reject(err)
        })
      })
    },
    resentProjectSubmissionsStatusEmail({commit, rootGetters}, projectId) {
      const ebEventStatus = rootGetters.ebEventStatus
      if (ebEventStatus !== processStatuses.LOADED) {
        return
      }

      if (rootGetters.eventSettings.status !== 'Published') {
        return
      }
      commit('PARTICIPANT_RENOTIFY_UPDATING', projectId)
      return new Promise((resolve, reject) => {
        ProjectService.sendProjectSubmissionsStatusEmail(rootGetters.ebEventId, [projectId]).then(() => {
          commit('PARTICIPANT_RENOTIFY_UPDATED', projectId)
          resolve()
        }).catch((err) => {
          commit('PARTICIPANT_RENOTIFY_ERROR', projectId)
          reject(err)
        })
      })
    },
    setProjectSubmissionFilterBy({ commit }, filter) {
      commit('SET_PROJECT_SUBMISSIONS_FILTER_BY', filter)
    },
    setSearchParticipants({ commit }, search) {
      commit('SET_SEARCH_PARTICIPANTS', search)
    }
  }
}
