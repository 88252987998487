import axios from 'axios'
import store from '@/store'
import { config } from '@/config'
import { sanitizeAuthToken } from '@/utils'

export const jwtInterceptor = () => {
    axios.interceptors.request.use(request => {
        const isLoggedIn = store.getters.isLoggedIn
        const isApiUrl = request.url.startsWith(config.API_URL)
        const token = store.getters.authToken
        if (isLoggedIn && isApiUrl && token) {
            request.headers.set('Authorization', `Bearer ${sanitizeAuthToken(token)}`)
        }

        //ToDo Allow the x-event headers to be adjusted when triggered from a specific event
        request.headers.set('x-event', 'selfserve')

        return request
    })
}

export const getDecodedRoles = () => {
    try {
        const tokenPayload = localStorage.getItem('auth')?.split('.')[1]
        if (!tokenPayload) {
            throw new Error('Token payload is empty, null, or undefined')
        }
        const decodedToken = JSON.parse(atob(tokenPayload))
        if (!decodedToken.roles || !Array.isArray(decodedToken.roles)) {
            throw new Error('Decoded token roles are missing or not in the expected format')
        }
        return decodedToken.roles
    } catch (error) {
        console.error('Error getting decoded roles:', error)
        return []
    }
}
