import axios from 'axios'
import { config } from '@/config'

class PlanService {
    getPlans() {
        return axios
            .get(`${config.API_URL}/plans`, {
                    params: {
                        include: 'features',
                    }
                }
            )
    }
    getPlanSubscriptionDetails(eventId) {
        return axios.get(`${config.API_URL}/partners/events/${eventId}/plan_subscription`, {
            params: {
                include: 'plan',
                pageSize: 100
            }
        })
    }
}

export default new PlanService()
