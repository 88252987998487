<script setup>
import { ref, computed } from 'vue'
import store from '@/store'
import AddOnCard from '@/components/Users/AddOnCard'
import { organizationFeatureCodes } from '@/utils/organizationFeatures'

const isAddOnsLoading = ref(false)

const defaultAddOns = [
  {
    id: 1,
    name: "2 Admins",
    value: 2,
    plan: "Starter",
    topText: "Unlock",
    subText: "With the purchase of the <span class='font-bold'> Starter Plan </span>",
    buttonText: "Upgrade",
  },
  {
    id: 2,
    name: "5 Admins",
    value: 5,
    plan: "Essentials",
    topText: "Unlock",
    subText: "With the purchase of the <span class='font-bold'> Essentials Plan </span>",
    buttonText: "Upgrade",
  },
  {
    id: 3,
    name: "10 Admins",
    value: 10,
    plan: "Professional",
    topText: "Unlock",
    subText: "With the purchase of the <span class='font-bold'> Professional Plan </span>",
    buttonText: "Upgrade",
  },
  {
    id: 4,
    name: "Custom",
    value: 99,
    plan: "Custom",
    topText: "Need More Admins?",
    subText: "Contact with us for Quote",
    buttonText: "Contact us",
  }
]

const addOnsList = computed(() => {
  const organizationFeatureData = store.getters?.getOrganizationFeature(organizationFeatureCodes.NUMBER_OF_ADMIN_SEATS)
  if (organizationFeatureData?.value > 0) {
    return defaultAddOns.filter(addon => addon.value > organizationFeatureData?.value)
  }
  return defaultAddOns
})
</script>

<template>
  <div>
    <span class="text-h3 font-bold">Add Ons </span>
    <span class="text-p-sm mb-4">(Optional)</span>
  </div>
  <ul class="list" :class="{'skeleton': isAddOnsLoading}">
    <add-on-card v-for="addOnsItem in addOnsList"
                :key="addOnsItem.id"
                :card="addOnsItem"
                :is-loading="isAddOnsLoading"/>
  </ul>
</template>

<style scoped lang="scss">
.list {
  @apply mt-6 flex grow flex-wrap gap-4;
}
</style>
