<script setup>
// Required Components
import { watchEffect, computed } from 'vue'
// Store
import store from '@/store'
// Base Component
import Avatar from '@/components/Base/Avatar'

defineProps({
  toastType: {
    type: String,
    default: 'success'
  },
  toastMessage: {
    type: String,
    default: 'Your changes have been saved.'
  },
  index: {
    type: Number
  }
})

const toastMessages = computed(() => {
  return store.getters.toast || []
})

const getToastType = (type) => {
  switch(type.toLowerCase()) {
  case 'error':
    return 'error'
  default:
    return 'success'
  }
}

const closeToastMessage = (index) => {
  store.dispatch('removeToast', index)
}

watchEffect(() => {
  const interval = setInterval(() => {
    if (toastMessages.value.length > 0) {
      store.dispatch('removeToast', 0)
      clearInterval(interval)
    }
  }, 15000)
})
</script>

<template>
  <div class="toast"
       :class="`toast-${getToastType(toastType)}`"
       @click.prevent="closeToastMessage(index)">
    <avatar class-name="toast-icon">
      <template #profile>
        <i class="fa-solid fa-sm"
           :class="{
            'toast-icon-success' : toastType === 'success',
            'toast-icon-error' : toastType === 'error',
            'fa-check fa-times' : toastType === 'success',
            'fa-xmark' : toastType === 'error'
        }"/>
      </template>
    </avatar>
    <p class="toast-message">
      {{ toastMessage }}
    </p>
    <i class="fa-solid toast-close" />
  </div>
</template>

<style lang="scss" scoped>
.toast {
  @apply flex flex-row w-full p-4 rounded-xl justify-between cursor-pointer transition ease-in-out delay-150 duration-300 mb-3 last:mb-0;
  &-success {
    @apply bg-toast-success;
  }
  &-error {
    @apply bg-toast-error;
  }
  &-icon {
    @apply bg-white rounded-full w-5 h-5 flex items-center justify-center;
    &-success {
      @apply text-other-green;
    }
    &-error {
      @apply text-other-red;
    }
  }
  &-message {
    @apply text-white font-medium text-p-md px-2 md:max-w-[17rem];
  }
  &-close {
    @apply text-white mt-0.5;
  }
}
</style>
