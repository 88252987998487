// Map to TimelineType in backend/src/server/proto/v1/partners/models.proto

const STUDENT_REGISTRATION = 0
const JUDGE_REGISTRATION = 1
const PROJECT_SUBMISSION = 2
const PUBLIC = 3
const CUSTOM = 4

const TimelineTypes = [
    STUDENT_REGISTRATION,
    JUDGE_REGISTRATION,
    PROJECT_SUBMISSION,
    PUBLIC,
    CUSTOM,
]

export const timelineTypes = {
    STUDENT_REGISTRATION,
    JUDGE_REGISTRATION,
    PROJECT_SUBMISSION,
    PUBLIC,
    CUSTOM,
    TimelineTypes
}
