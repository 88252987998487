<script setup>
// Required Packages
import { computed, onMounted, ref, watch } from 'vue'
import store from '@/store'
import { config } from '@/config'
// Custom Component
import DashboardTable from '@/components/Base/Table/Dashboard'
import ListCard from '@/components/Participants/ListCard'
import { eventFeatureCodes } from '@/utils/eventFeatures'
import BaseTag from '@/components/Base/Tags'
import Overflow from '@/components/Base/Menu/Overflow'
import { userRoles } from '@/utils/user-roles'

const submissionStatusCode = ref('all')

const participantsCounts = computed(() => {
  return store.getters.getParticipantsCounts
})

const maxParticipants = computed(() => {
  return store.getters.getEventFeature(eventFeatureCodes.NUMBER_OF_PARTICIPANTS)
})

const participantCount = computed(() => {
  return {
    status: 'participants',
    text: 'participants',
    number: participantsCounts.value + '/' + (maxParticipants.value?.value ?? '-')
  }
})

const filterOptions = computed(() => {
  if (submissionStatusCode.value !== 'all') {
    return [{
      field: 'submissionStatus',
      comparison: '=',
      criteria: submissionStatusCode.value,
    }]
  }
  return []
})

const eventId = computed(() => {
  return store.getters.ebEventId
})

const participantData = computed(() => {
  return store.getters.combinedParticipants
})

const headers = [
  { text: "First Name", value: "firstName", sortable: true },
  { text: "Last Name", value: "lastName", sortable: true },
  { text: "Email", value: "email" },
  { text: "Project", value: "project" },
  { text: "Status", value: "status", sortable: true},
  { text: "", value: "options" },
]

const fetchParticipants = () => {
  let payload = {
    eventId: eventId.value,
    pageSize: 100,
    nextPageToken: 0
  }
  store.dispatch('getParticipants', payload, payload.include = 'project,invitation')
  let participantPayload = {
    eventId: eventId.value,
    pageSize: 100,
    nextPageToken: 0
  }
  store.dispatch('getInvitedParticipants', participantPayload)
}

const removeParticipants = (userData) => {
  try {
    if (userData.id > 0) {
      const payload = {
        userId: userData.id,
        title: 'Are You Sure You Want To Remove This Participants?',
        description: 'After removal, participants will no longer be part of this showcase, and you will have to invite them again.',
        confirmButton: 'Yes, Remove',
        cancelButton: 'No, Go Back',
        confirmAction: async () => {
          try {
            const userInvitationId = userData?.userInvitations?.id || userData?.userInvitationsId

            if (userInvitationId) {
              await store.dispatch('deleteInviteParticipants', { id: userInvitationId })
            } else {
              await store.dispatch('deleteParticipants', {
                userId: userData.id,
                eventId: eventId.value,
                roles: userRoles.SS_EVENT_PARTICIPANT
              })
              // create an API to remove the membership of the user from the project
              await store.dispatch('deleteUserFromEventProjects', {
                userId: userData.id,
                eventId: eventId.value
              })
            }
            await fetchParticipants()
            await store.dispatch('ResetModal')
          } catch (error) {
            console.error('Error while removing participant:', error)
          }
        },
        cancelAction: () => {
          store.dispatch('ResetModal')
        },
        type: 'removeParticipants',
      }
      store.dispatch('ToggleModalRemoveParticipants', payload)
    }
  } catch (error) {
    console.error('Error in removeParticipants function:', error)
  }
}

watch([eventId], () => { fetchParticipants() }, { deep: true })
watch([filterOptions], () => { fetchParticipants() }, { deep: true })

const eventIsPublished = computed(() => {
  return store.getters.eventSettings.status === 'Published'
})

const sort = (sortBy = 'firstName') => {
  store.dispatch('toggleParticipantsDataDesc')
  store.dispatch('setParticipantsSortBy', sortBy)
}

const resendParticipantsInvite = (email) => {
  const payload = {
    email: email,
    eventId: eventId.value,
  }
  store.dispatch('reInviteParticipants', payload).then(() => {
    store.dispatch('triggerToast', { message: 'Invite Send', type: 'success' })
  })
}

const generateProjectLink = (id) => {
  const encodedLink = encodeURIComponent(`/${store.getters.ebEventSlug}/project/${id}`)
  // To avoid non-logged user to be redirected to 404
  // Redirect to login page by default, event site will handle the redirection for us.
  return `${config.AUTH_URL}/${store.getters.ebEventSlug}/login?returnUrl=${encodedLink}`
}

onMounted(async () => {
  if (eventId.value) {
    await fetchParticipants()
  }
})
</script>

<template>
  <div class="plist plist-withPeople">
    <ul v-if="participantsCounts" class="plist-list">
      <list-card class="active" :project="participantCount" />
    </ul>
    <span v-if="participantsCounts ? participantsCounts === 0 : 0" class="plist-noPeople">
      <img src="/images/icons/add-group.png"
           alt="add group"
           class="w-32 h-32"/>
      <p v-if="eventIsPublished" class="plist-noPeople_text">
        You don't have any participants yet! <br/>
        To add participants, share the event URL with them.
      </p>
      <p v-else class="plist-noPeople_text">
        You don't have any participants yet! <br/>
        Please make sure your event is live to add participants.
      </p>
    </span>
    <div v-else class="users-table">
      <dashboard-table :sort-function="sort" :headers="headers" :items="participantData">
        <template #content-column="{ item }">
          <td>{{ item.firstName }}</td>
          <td>{{ item.lastName }}</td>
          <td>{{ item.email }}</td>
          <td>
            <!-- Loop through item.primaryProjects and display titles -->
            <span v-if="item.primaryProjects && item.primaryProjects.length">
              <span v-for="(project, index) in item.primaryProjects" :key="index">
                <a target="_blank" :href="generateProjectLink(project.id)" class="project-link"> {{ project.title }} </a>
                <!-- Add a comma if it's not the last project -->
                <br v-if="index < item.primaryProjects.length - 1">
              </span>
            </span>
            <span v-else>
              -
            </span>
          </td>
          <td>
            <base-tag
                v-if="item !== ''"
                :text="item.status"
                class="users-table-tags" />
          </td>

        </template>
        <template #action-column="{ item }">
          <div class="transactions-table-download">
            <overflow class="users-table-btn" position="left">
              <template #menu-items>
                <ul class="users-table-menu-list">
                  <li class="users-table-menu-list-item" @click.prevent="removeParticipants(item)">
                    Remove Participant
                  </li>
                  <li v-if="item.status !== 'Active'" class="users-table-menu-list-item" @click.prevent="resendParticipantsInvite(item.email)">
                    Resend Invite
                  </li>
                </ul>
              </template>
            </overflow>
          </div>
        </template>

      </dashboard-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.users {
  &-table {
    &-title {
      @apply text-h2 font-medium pt-8 pb-6 pl-2;
    }
    &-tags {
      @apply max-w-[6.2rem] h-[1.8rem];
    }
    &-btn {
      @apply ml-6;
    }
    &-menu { // Project Card Header Menu
      &-list {
        @apply text-p-xs flex flex-col items-start;
        &-item {
          @apply px-5 py-3 cursor-pointer hover:text-primary w-full;
        }
      }
    }
  }
}
.plist {
  @apply border rounded-xl border-greys-200 my-6 bg-greys-50 max-w-[83.5rem] p-4 flex flex-col h-fit;
  &-list {
    @apply w-full flex gap-5 flex-wrap py-6 justify-start;
  }
  &-noPeople {
    @apply flex flex-col py-4 w-full justify-center items-center h-full;
    &_text {
      @apply py-6 text-center max-w-[24.125rem] text-p-md;
    }
  }
  &-withPeople {
    @apply border-none bg-transparent p-0;
  }
}
.name-wrapper {
  @apply flex flex-row items-center;
}
.avatar {
  @apply inline-block w-[2rem] h-[2rem] rounded-full object-cover;
}
.avatar-wrapper {
  @apply flex justify-items-center items-center shrink-0 mr-1;
}
.project-link {
  @apply text-product-400 underline;
}
.page-button {
  @apply px-2 py-[3px] mx-1 bg-greys-100 border rounded-md border-greys-200;
  &:disabled {
    @apply bg-greys-50 text-greys-400 cursor-not-allowed
  }
}
</style>
