import axios from 'axios'
import { config } from '@/config'

class CategoryService {
    getCategories(eventId) {
      const params = {
        'pagination.pageSize': 100,
        include: 'sub_categories'
      }
      if (eventId) {
          params.eventId = eventId
      }
      return axios
          .get(`${config.API_URL}/categories`, {
              params: params
          })
    }
    createCategories(eventId, categories) {
        return axios
          .post(`${config.API_URL}/categories`, {
            categories: categories,
            eventId: eventId
          })
    }
    updateCategory(categoryId, {name, image, priority, color}, updateMask = "name,image,priority,color") {
        return axios
          .patch(`${config.API_URL}/categories/${categoryId}`, {
            category: {
              name: name,
              image: image,
              priority: priority,
              color: color
            },
            updateMask: updateMask
          })
    }
    deleteCategories(eventId, categoryIds) {
        return axios
          .delete(`${config.API_URL}/categories`, {
            data: {
              categoryIds: categoryIds,
              eventId: eventId
            }
          })
    }
}

export default new CategoryService()
