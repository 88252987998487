<script setup>
import { ref, computed } from 'vue'
import store from '@/store'
import ImageUpload from '@/components/Modals/Icons/ImageUpload'

const props = defineProps({
  imageUploadFile: {
    type: null,
    default: {}
  },
  setImageUploadFile: {
    type: Function,
    default: () => {}
  }
})

const dropzoneFile = ref(props.imageUploadFile)
const errors = ref(null)

let allowedImage = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml'
]

const modalType = computed(() => {
  return store.getters.typeOfModal
})

const getAllowedImage = (mType) => {
  switch (mType.value) {
  case 'sponsors':
    return allowedImage.filter(item => {
      return !item.includes("svg")
    })
  default:
    return allowedImage
  }
}

const drop = (e) => {
  dropzoneFile.value = e.dataTransfer.files[0]
  if (!getAllowedImage(modalType).includes(dropzoneFile.value?.type)) {
    errors.value = 'Unsupported image type'
    dropzoneFile.value = null
  } else {
    errors.value = ''
  }
  props.setImageUploadFile(dropzoneFile.value)
}

const selectedFile = () => {
  dropzoneFile.value = document.querySelector('.dropzoneFile').files[0]
  if (!getAllowedImage(modalType).includes(dropzoneFile.value?.type)) {
    errors.value = 'Unsupported image type'
    dropzoneFile.value = null
  } else {
    errors.value = ''
  }
  props.setImageUploadFile(dropzoneFile.value)
}

const resetFile = () => {
  dropzoneFile.value = null
  props.setImageUploadFile(dropzoneFile.value)
}
</script>

<template>
  <image-upload :dropzone-file="imageUploadFile"
                :reset-file="resetFile"
                :drop="drop"
                :errors="errors"
                :selected-file="selectedFile"/>
</template>
