import axios from 'axios'
import { config } from '@/config'
import { submissionStatusType } from '@/utils/submission-status'

class AwardService {
    getAwards(eventId) {
      const params = {
        'pagination.pageSize': 100,
        'pagination.nextPageToken': 0,
        eventId: eventId
        // projectId: projectId
      }
      return axios
          .get(`${config.API_URL}/awards`, {
              params: params
          })
    }
    getAward(awardId) {
        return axios
            .get(`${config.API_URL}/awards/${awardId}`)
    }
    updateAward(awardId, { name, image, priority }, updateMask = "name,image,priority") {
      const body = {
        "award": {
          "name": name,
          "image": image,
          "priority": priority
        },
        "updateMask": updateMask
      }

      return axios
          .patch(`${config.API_URL}/awards/${awardId}`, body)
    }
    createAwards(eventId, awards) {
      const body = {
        "awards": awards,
        "eventId": eventId
      }

      return axios
          .post(`${config.API_URL}/awards`, body)
    }
    deleteAwards(eventId, awardIds) {
        return axios
            .delete(`${config.API_URL}/awards`, {
                data: {
                    "awardIds": awardIds,
                    "eventId": eventId
                }
            })
    }
    getProjectAwards(eventId) {
      return axios.get(`${config.API_URL}/projects`, {
        params: {
          includes: 'user,awards',
          submissionStatus: submissionStatusType.APPROVED,
          eventId: eventId,
          pageSize: 1000
        }
      })
    }
    assignAwardsToProjects(eventId, projectAwards) {
      /* e.g.
        [{
          "projectId": 1,
          "awardId": 2
        }]
       */
        const body = {
          "projectAwards": projectAwards,
          "eventId": eventId
        }

        return axios
            .post(`${config.API_URL}/awards/projects`, body)
    }
    removeAwardsFromProjects(eventId, projectAwards) {
        /* e.g.
            [{
            "projectId": 1,
            "awardId": 2
            }]
         */
            const body = {
            "projectAwards": projectAwards,
            "eventId": eventId
            }

            return axios
                .delete(`${config.API_URL}/awards/projects`, { data: body })
    }
    publishAwards(eventId) {
      return axios
          .post(`${config.API_URL}/awards/publish`, {
            "eventId": eventId
          })
    }
}

export default new AwardService()
