<script setup>
// Ref
import { ref } from 'vue'
// Props
const props = defineProps({
  inputType: {
    type: String,
    default: 'text'
  },
  inputMaxLength: Number,
  inputAriaLabel: String,
  inputPlaceHolder: String,
  inputId: String,
  inputName: String,
  modelValue: {
    type: [String, Boolean, Number],
    required: true
  },
  tabIndex: {
    type: Number,
    default: 0
  },
  className: String,
  disabled: {
    type: Boolean,
    default: false
  },
  checked: {
    type: Boolean,
    default: false
  }
})
// Sync with input
const currentType = ref(props.inputType)
const emit = defineEmits(['update:modelValue'])
const isSearching = ref(false)

const toggleSearch = () => {
  isSearching.value = !isSearching.value
}

const clearSearch = () => {
  emit('update:modelValue', '')
  toggleSearch()
}
</script>

<template>
  <span v-if="!isSearching">
    <i class="fa-solid fa-magnifying-glass ml-2" @click.prevent="toggleSearch" />
  </span>
  <span v-else class="field">
    <i class="fa-solid fa-magnifying-glass field-search-icon"/>
    <input :id="inputId"
           class="field-input field-search"
           :class="`${className && className.length > 0 ? className : 'field-input-base'}
           ${ inputType === 'number' ? 'field-input-number' : 'field-input-text'}`"
           :type="currentType"
           :name="inputName"
           :value="modelValue"
           :placeholder="inputPlaceHolder"
           :qa="`qa-${inputId}`"
           @input="$emit('update:modelValue', $event.target.value)"/>
    <span class="field-search-clear" @click.prevent="clearSearch">
      <i class="fa-regular fa-xmark"></i>
    </span>
  </span>
</template>

<style lang="scss" scoped>
.field {
  @apply relative pl-3;
  &-notInputs {
    @apply flex flex-row-reverse items-center justify-end accent-primary capitalize;
  }
  &-label {
    @apply mb-1.5 ml-0.5 text-p-md;
    &-notInputs {
      @apply mt-0.5 ml-2 text-p-xl font-medium;
    }
  }
  &-regular {
    @apply px-3.5;
  }
  &-search {
    @apply pl-7 pr-3.5 w-full md:max-w-[13.75rem];
    &:enabled {
      &:hover {
        @apply bg-background-400;
      }
      &:focus {
        @apply bg-background-400;
      }
    }
    &:disabled {
      @apply cursor-not-allowed;
    }
    &-icon {
      @apply absolute md:bottom-[-0.1rem] left-[1.4rem] text-greys-300 text-opacity-60;
    }
    &-clear {
      @apply absolute md:bottom-[-1.4rem] md:left-[12rem] py-5 px-5 flex justify-center items-center cursor-pointer;
    }
  }
  &-input {
    @apply rounded-lg border py-1;
    &-border {
      @apply border-greys-200;
    }
    &-base {
      @apply py-0.5 w-full md:w-[21.875rem];
    }
    // Enabled Styles
    &:enabled {
      &:focus {
        @apply outline-primary outline outline-1;
      }
      &:hover {
        @apply border-primary;
      }
    }
  }
}
</style>
